import moment from 'moment';

class Program {
  /**
   * @description 프로그램이 예약가능한 상태인지를 반환합니다. isClosed : 마감상태, openAllTimes: 상시 오픈 (신청이 필요없음)
   * @param program
   * @pay {{isClosed: boolean, openAllTimes: boolean}}
   */
  static getReservationStatus(program) {
    const { times } = program;

    const openAllTimes = !!times && times[0].id === null;
    const hasRemainSeat = times?.some((time) => time.remainingSeat !== 0);
    const isClosed = !openAllTimes && !hasRemainSeat;

    return { openAllTimes, isClosed };
  }
  /**
   * @description 프로그램 스케쥴을 찾습니다.
   * @param selectedProgram
   * @param programs
   * @pay {{remainingSeat: number, visitCount: number, ... }}
   * */
  static findSchedule(selectedProgram, programs) {
    for (let i = 0; i < programs.length; i++) {
      const { times } = programs[i];

      if (!times) {
        continue;
      }

      for (let j = 0; j < times.length; j++) {
        if (times[j].id === selectedProgram.timeID) {
          return { ...programs[i], ...times[j], ...selectedProgram };
        }
      }
    }
    return null;
  }
  /**
   * @description HH:mm:ss 시간을 HH:mm 포맷 형태로 반환합니다.
   * @param hhmmss
   * @returns {string|null}
   */
  static hhmmssTohhmm(hhmmss) {
    if (!hhmmss) return null;
    return moment(hhmmss, 'HH:mm:ss').format('HH:mm');
  }
  /**
   * @description 프로그램 스케줄을 바탕으로 form 프로그램 초기세팅을 합니다.
   * @param programSchedules
   * @param form
   * @returns {*}
   */
  static initialProgramsSetting(programSchedules, form) {
    return programSchedules.map((programSchedule) => {
      const { programName, id, times, cost, ...otherProgramElements } = programSchedule;

      const newTimes = times.sort((a, b) => a.programTime.localeCompare(b.programTime));

      const registeredProgram = form.programs.find((item) => item.programID === programSchedule.id);

      return {
        id: registeredProgram?.id,
        timeID: registeredProgram?.timeID || null,
        visitCount: registeredProgram?.visitCount || 0,
        cost: Number(cost) || 0,
        programName,
        programID: id,
        times: newTimes || [],
        ...otherProgramElements,
      };
    });
  }
}

export default Program;
