import YoutubeVideoStyle from './YoutubeVideo.module.scss';
import YouTube from 'react-youtube';
import { cx } from '@emotion/css';
import MainVideoStyle from '@/components/main/sections/a.MainSection/MainVideo/MainVideo.module.scss';
import { useRef, useState } from 'react';
import useChangeCursor from '@/hooks/useChangeCursor.hooks';
import { useIsPCOrMobile } from '@/hooks';

const YoutubeVideo = (props) => {
  const { className, onClose, videos, selectedIndex } = props;
  const { isPC } = useIsPCOrMobile({});

  const youtubeRef = useRef();
  const { triggerRef: previousButtonTriggerRef, cursorRef: previousButtonTargetRef } = useChangeCursor({ center: true });
  const { triggerRef: nextButtonTriggerRef, cursorRef: nextButtonTargetRef } = useChangeCursor({ center: true });
  const [videoIndex, setVideoIndex] = useState(selectedIndex);

  const onClickPrevious = () => {
    const nextIndex = videoIndex - 1;
    const targetIndex = nextIndex < 0 ? videos.length - 1 : nextIndex;
    setVideoIndex(targetIndex);
  };

  const onClickNext = () => {
    const nextIndex = videoIndex + 1;
    const targetIndex = nextIndex % videos.length;
    setVideoIndex(targetIndex);
  };

  const closeVideoModal = () => {
    if (isPC) return onClose();

    return setTimeout(() => onClose(), 500);
  };

  const DynamicLink = (props) => {
    const { video } = props;
    const { link, content } = video;

    return (
      <>
        {link ? (
          <a href={link} target="_blank" rel="noreferrer" className={YoutubeVideoStyle.videoLinkText}>
            {content}
          </a>
        ) : (
          <span className={YoutubeVideoStyle.videoLinkText}>{content}</span>
        )}
      </>
    );
  };

  return (
    <section className={cx(className, YoutubeVideoStyle.container)}>
      <button ref={previousButtonTriggerRef} className={YoutubeVideoStyle.controlButton} onClick={onClickPrevious}>
        <span ref={previousButtonTargetRef} className={YoutubeVideoStyle.controlButtonText}>
          {'{'}PREV{'}'}
        </span>
      </button>
      <div className={YoutubeVideoStyle.videoContainer}>
        <button className={YoutubeVideoStyle.closeButton} onClick={closeVideoModal} />
        <div className={YoutubeVideoStyle.videoArea}>
          <div className={YoutubeVideoStyle.videoWrapper}>
            <YouTube
              ref={youtubeRef}
              className={MainVideoStyle.videoListItem}
              iframeClassName={MainVideoStyle.videoIframe}
              videoId={videos[videoIndex].youtubeId}
              opts={{
                height: '100%',
                width: '100%',
                playerVars: { autoplay: 1, controls: 0 },
              }}
            />
          </div>
          <div className={YoutubeVideoStyle.videoLink}>
            <DynamicLink video={videos[videoIndex]} />
          </div>
        </div>
      </div>
      <button ref={nextButtonTriggerRef} className={YoutubeVideoStyle.controlButton} onClick={onClickNext}>
        <span ref={nextButtonTargetRef} className={YoutubeVideoStyle.controlButtonText}>
          {'{'}NEXT{'}'}
        </span>
      </button>
    </section>
  );
};

export default YoutubeVideo;
