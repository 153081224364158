import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import moment from 'moment';
import { useEffect, useState } from 'react';

registerLocale('ko', ko);

const Datepicker = ({ selectedDate, onChangeSelectedDay, holidays }) => {
  const [disabledDate, setDisabledDate] = useState([]);
  useEffect(() => {
    setDisabledDate(holidays.map((holiday) => moment(holiday.holidayDate).toDate()));
  }, [holidays]);

  const HeaderContents = ({ date, decreaseMonth, increaseMonth }) => {
    return (
      <div className="react-datepicker__header--custom--month">
        <button className="react-datepicker__navigation react-datepicker__navigation--previous" onClick={decreaseMonth} />
        <strong className="react-datepicker__month-name">
          {moment(date).year()}.{moment(date).month() + 1}
        </strong>
        <button className="react-datepicker__navigation react-datepicker__navigation--next" onClick={increaseMonth} />
      </div>
    );
  };

  return (
    <DatePicker
      inline
      renderCustomHeader={HeaderContents}
      selected={selectedDate}
      onChange={(date) => {
        onChangeSelectedDay(date);
      }}
      excludeDates={disabledDate}
      minDate={new Date()}
      maxDate={moment().add(60, 'days').toDate()}
      locale="ko"
    />
  );
};

Datepicker.propTypes = {
  holidays: PropTypes.array,
  selectedDate: PropTypes.instanceOf(Date),
  onChangeSelectedDay: PropTypes.func,
};

Datepicker.defaultProps = {
  holidays: [],
  onChangeSelectedDay: () => {},
};

export default Datepicker;
