import { cx } from '@emotion/css';
import styles from './ReservationProgress.module.scss';
const ReservationProgress = ({ progressItems, currentProgress }) => {
  const currentStatusIndex = progressItems.findIndex((item) => item.value === currentProgress);
  return (
    <div className={styles.container}>
      {progressItems.map(({ name, value }, index) => {
        const isPrevStatus = index < currentStatusIndex;
        const isCurrentStatus = index === currentStatusIndex;
        const isNextStatus = index > currentStatusIndex;
        const isLastProgress = progressItems.length - 1 === index;
        return (
          <span key={name}>
            <p className={cx(styles.state, isCurrentStatus ? styles.current_state : isNextStatus ? styles.next_state : styles.prev_state)}>{name}</p>
            {!isLastProgress && (
              <>
                <span className={cx(styles.dot, !isPrevStatus ? styles.next_dot : styles.prev_dot)} />
                <span className={cx(styles.dot, !isPrevStatus ? styles.next_dot : styles.prev_dot)} />
                <span className={cx(styles.dot, !isPrevStatus ? styles.next_dot : styles.prev_dot)} />
              </>
            )}
          </span>
        );
      })}
    </div>
  );
};
export default ReservationProgress;
