import styles from './Navigation.module.scss';
import { cx } from '@emotion/css';
import { useEffect, useState } from 'react';
import { useIsMobileDevice } from '@/hooks';

const links = [
  { name: 'HOME', id: 'home' },
  { name: 'HIGHLIGHT', id: 'highlight' },
  { name: 'PLAY ROUTE', id: 'play-route' },
  { name: 'NEWS', id: 'news' },
  { name: '/invite you_', id: 'invite-you' },
];

const Navigation = () => {
  const [currentNav, setCurrentNav] = useState();
  const { isMobileDevice } = useIsMobileDevice();

  useEffect(() => {
    const options = {
      threshold: [0.55],
    };
    const observer = new IntersectionObserver(onIntersect, options);
    const observeElements = links.map((link) => document.getElementById(link.id));
    observeElements.forEach((element) => observer.observe(element));
    return () => {
      observer.disconnect();
    };
  }, []);

  const onIntersect = (entries) => {
    entries.forEach((entry) => {
      const { isIntersecting, target } = entry;
      if (!isIntersecting) return;
      setCurrentNav(target.id);
    });
  };

  const anchorMove = (id) => {
    const destinationElement = document.getElementById(id);
    const relativeScrollPosition = destinationElement.getBoundingClientRect().top;
    window.scrollTo({
      top: window.scrollY + relativeScrollPosition,
      behavior: 'smooth',
    });
  };

  return (
    <div className={cx(styles.navigationContainer, isMobileDevice ? styles.isMobile : styles.isPc)}>
      <ul>
        {links.map((link) => {
          const isSelected = currentNav === link.id;
          return (
            <li key={link.id} className={styles.navigationWrapper}>
              <button className={cx(styles.navigationItem, isSelected && styles.selectedItem, styles[`navigation-${link.id}`])} onClick={() => anchorMove(link.id)} />
              <div className={styles.navigationName}>{link.name}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Navigation;
