import ServerAPI from '@/apis/server';
import BannerBook from '@/apis/books/banner.json';

const getLineBanner = () => {
  return ServerAPI.get(`${BannerBook.REST}/lines`);
};

const getSquareBanners = () => {
  return ServerAPI.get(`${BannerBook.REST}/squares`);
};

export { getLineBanner, getSquareBanners };
