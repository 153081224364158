const MEDIA_QUERY = {
  IS_SMALL: () => window.innerWidth <= 999,
};

const SVG_XLINK_NS = 'http://www.w3.org/1999/xlink';
const SVG_NS = 'http://www.w3.org/2000/svg';

const createElementNS = (tagName) => {
  return document.createElementNS(SVG_NS, tagName);
};

const createSVGImageContainer = (images = []) => {
  const svg = createElementNS('svg');
  svg.classList.add('navigation-images');

  const filter = createElementNS('filter');
  const randomId = 'navigationImagesFilter' + Math.random();
  filter.setAttributeNS(null, 'id', randomId);

  const feTurbulence = createElementNS('feTurbulence');
  feTurbulence.setAttributeNS(null, 'type', 'fractalNoise');
  feTurbulence.setAttributeNS(null, 'baseFrequency', '0.1');
  feTurbulence.setAttributeNS(null, 'numOctaves', '5');
  feTurbulence.setAttributeNS(null, 'seed', '2');
  feTurbulence.setAttributeNS(null, 'stitchTiles', 'noStitch');
  feTurbulence.setAttributeNS(null, 'x', '0%');
  feTurbulence.setAttributeNS(null, 'y', '0%');
  feTurbulence.setAttributeNS(null, 'width', '100%');
  feTurbulence.setAttributeNS(null, 'height', '100%');
  feTurbulence.setAttributeNS(null, 'result', 'noise');

  const feDisplacementMap = createElementNS('feDisplacementMap');

  feDisplacementMap.classList.add('fedisplacementmap');
  feDisplacementMap.setAttributeNS(null, 'in', 'SourceGraphic');
  feDisplacementMap.setAttributeNS(null, 'in2', 'noise');
  feDisplacementMap.setAttributeNS(null, 'scale', '0');
  feDisplacementMap.setAttributeNS(null, 'xChannelSelector', 'R');
  feDisplacementMap.setAttributeNS(null, 'yChannelSelector', 'B');
  feDisplacementMap.setAttributeNS(null, 'x', '0%');
  feDisplacementMap.setAttributeNS(null, 'y', '0%');
  feDisplacementMap.setAttributeNS(null, 'width', '100%');
  feDisplacementMap.setAttributeNS(null, 'height', '100%');
  feDisplacementMap.setAttributeNS(null, 'filterUnits', 'userSpaceOnUse');

  filter.appendChild(feTurbulence);
  filter.appendChild(feDisplacementMap);

  const g = createElementNS('g');

  g.setAttributeNS(null, 'filter', `url(#${randomId})`);
  images.forEach((imageOption) => {
    const image = createElementNS('image');
    image.classList.add('overImg');
    image.setAttributeNS(SVG_XLINK_NS, 'xlink:href', imageOption.url);
    image.setAttributeNS(null, 'x', '0');
    image.setAttributeNS(null, 'y', '0');
    image.setAttributeNS(null, 'width', imageOption.width);
    image.setAttributeNS(null, 'height', imageOption.height);

    g.appendChild(image);
  });

  svg.append(filter);
  svg.append(g);

  return svg;
};

const lineEq = (y2, y1, x2, x1, currentVal) => {
  // y = mx + b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  return m * currentVal + b;
};

const lerp = (a, b, n) => (1 - n) * a + n * b;

const distance = (x1, x2, y1, y2) => {
  const a = x1 - x2;
  const b = y1 - y2;
  return Math.hypot(a, b);
};

const getMousePos = (e) => {
  if (!e) e = window.event;
  const positionX = e.clientX || 0;
  const positionY = e.clientY || 0;
  return { x: positionX, y: positionY };
};

let winsize;

const calcWinsize = () => (winsize = { width: window.innerWidth, height: window.innerHeight });

class HoverFollowUpImage {
  constructor(props = {}) {
    const { containerElement, menuLinkElements, images = [] } = props;

    calcWinsize();
    this.DOM = {
      containerElement,
      svg: createSVGImageContainer(images),
      menuLinks: menuLinkElements,
    };

    this.DOM.feDisplacementMapEl = this.DOM.svg.querySelector('.fedisplacementmap');

    if (this.DOM.containerElement) {
      this.DOM.containerElement.prepend(this.DOM.svg);
    } else {
      document.body.prepend(this.DOM.svg);
    }

    this.DOM.imgs = [...this.DOM.svg.querySelectorAll('g > image')];
    this.mousePos = { x: winsize.width / 2, y: winsize.height / 2 };
    this.lastMousePos = {
      translation: { x: winsize.width / 2, y: winsize.height / 2 },
      displacement: { x: 0, y: 0 },
    };
    this.dmScale = 0;
    this.current = -1;

    this.initEvents();
    requestAnimationFrame(() => this.render());
  }

  initEvents() {
    window.addEventListener('resize', calcWinsize);
    window.addEventListener('mousemove', this.onMousemove.bind(this));

    this.DOM.menuLinks.forEach((item, pos) => {
      const mouseenterFn = () => {
        if (MEDIA_QUERY.IS_SMALL()) return;

        this.current = pos;
        const currentImageWidth = this.DOM.imgs[this.current].getAttribute('width');
        const currentImageHeight = this.DOM.imgs[this.current].getAttribute('height');
        this.DOM.svg.setAttribute('width', currentImageWidth);
        this.DOM.svg.setAttribute('height', currentImageHeight);

        TweenMax.to(this.DOM.imgs[this.current], 0.5, {
          ease: Quad.easeOut,
          opacity: 1,
        });
      };

      const mouseleaveFn = () => {
        if (MEDIA_QUERY.IS_SMALL()) return;

        TweenMax.to(this.DOM.imgs[this.current], 0.5, {
          ease: Quad.easeOut,
          opacity: 0,
        });
      };

      item.addEventListener('mouseenter', mouseenterFn);
      item.addEventListener('mouseleave', mouseleaveFn);
    });
  }

  onMousemove(event) {
    this.mousePos = getMousePos(event);
  }

  render() {
    this.lastMousePos.translation.x = lerp(this.lastMousePos.translation.x, this.mousePos.x, 0.15);
    this.lastMousePos.translation.y = lerp(this.lastMousePos.translation.y, this.mousePos.y, 0.15);

    const targetWidth = this.DOM.svg.getAttribute('width') || winsize.width / 2;
    const targetHeight = this.DOM.svg.getAttribute('height') || winsize.height / 2;

    const halfWidth = Number(targetWidth) / 2;
    const halfHeight = Number(targetHeight) / 2;
    // this.DOM.svg.style.transform = `translateX(${
    //   this.lastMousePos.translation.x - halfWidth
    // }px) translateY(${this.lastMousePos.translation.y - halfHeight}px)`;
    this.DOM.svg.style.left = `${this.lastMousePos.translation.x - halfWidth}px`;
    this.DOM.svg.style.top = `${this.lastMousePos.translation.y - halfHeight}px`;
    // Scale goes from 0 to 50 for mouseDistance values between 0 to 100
    this.lastMousePos.displacement.x = lerp(this.lastMousePos.displacement.x, this.mousePos.x, 0.07);
    this.lastMousePos.displacement.y = lerp(this.lastMousePos.displacement.y, this.mousePos.y, 0.07);
    const mouseDistance = distance(this.lastMousePos.displacement.x, this.mousePos.x, this.lastMousePos.displacement.y, this.mousePos.y);
    this.dmScale = Math.min(lineEq(50, 0, 100, 0, mouseDistance), 50);
    this.DOM.feDisplacementMapEl.scale.baseVal = this.dmScale;

    requestAnimationFrame(() => this.render());
  }

  destroy() {
    this.DOM.svg.remove();
    window.removeEventListener('resize', calcWinsize);
    window.removeEventListener('mousemove', this.onMousemove.bind(this));
  }
}

export default HoverFollowUpImage;
