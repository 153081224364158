import { gsap } from 'gsap';
import determineTopOrBottomByMouseEvent from '@/utils/main/Marquee/determineDirection';

const defaultAnimationOption = { duration: 0.6, ease: 'expo' };

class Marquee {
  static isTop(event) {
    return determineTopOrBottomByMouseEvent(event) === 'top';
  }

  static build(props) {
    const { DOM } = props;
    const { hoverElement, wrapperElement, innerElement } = DOM;

    if (!hoverElement || !wrapperElement || !innerElement) {
      throw new Error('require 3 elements : hover, wrapper and inner element');
    }

    const marquee = new Marquee(props);
    marquee.start();

    return marquee;
  }

  constructor(props = {}) {
    const { DOM = {} } = props;
    this.DOM = {
      hoverElement: DOM.hoverElement,
      wrapperElement: DOM.wrapperElement,
      innerElement: DOM.innerElement,
    };
  }

  start() {
    this.bindEvents();
  }

  bindEvents() {
    this.DOM.hoverElement.addEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.DOM.hoverElement.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }

  onMouseEnter(event) {
    event.preventDefault();
    this.DOM.innerElement.classList.add('on-marquee');
    const isTop = Marquee.isTop(event);
    gsap
      .timeline({ defaults: defaultAnimationOption })
      .set(this.DOM.wrapperElement, { y: isTop ? '-101%' : '101%' }, 0)
      .set(this.DOM.innerElement, { y: isTop ? '101%' : '-101%' }, 0)
      .to([this.DOM.wrapperElement, this.DOM.innerElement], { y: '0%' }, 0);
  }

  onMouseLeave(event) {
    event.preventDefault();
    this.DOM.innerElement.classList.remove('on-marquee');
    const isTop = Marquee.isTop(event);
    gsap
      .timeline({ defaults: defaultAnimationOption })
      .to(this.DOM.wrapperElement, { y: isTop ? '-101%' : '101%' }, 0)
      .to(this.DOM.innerElement, { y: isTop ? '101%' : '-101%' }, 0);
  }

  destroy() {
    this.DOM.hoverElement.removeEventListener('mouseenter', this.onMouseEnter);
    this.DOM.hoverElement.removeEventListener('mouseleave', this.onMouseLeave);
  }
}

export default Marquee;
