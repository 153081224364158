import { ReservationStatus, ReservationType, PaymentMethods } from '../commons';
import moment from 'moment';

const GroupType = {
  CHILD: '유아',
  ELEMENTARY_STUDENT: '초등학생',
  MIDDLE_STUDENT: '중학생',
  HIGH_STUDENT: '고등학생',
  ADULT: '성인',
  ETC: '기타',
};
const RegionType = {
  SEOUL: '서울',
  BUSAN: '부산',
  INCHEON: '인천',
  DAEGU: '대구',
  GWANGJU: '광주',
  DAEJEON: '대전',
  ULSAN: '울산',
  SEJONG: '세종시',
  GYEONGGI: '경기도',
  GANGWON: '강원도',
  CHUNGCHEONG: '충청도',
  GYEONGSANG: '경상도',
  JEOLLA: '전라도',
  JEJU: '제주도',
};
const TransportType = {
  PUBLIC_TRANSPORT: '대중교통',
  GROUP_BUS: '단체버스',
  RENTAL_CAR: '렌터카(또는 자가용)',
};

class ReservationDTO {
  id = -1;
  reservationType = '';
  schedule = {
    reservationDate: '',
    reservationTime: '',
  };
  stayTime = 0;
  visitorCount = 0;
  reservationStatus = '';
  completeSendDate = null;
  infoSendDate = null;
  requester = {
    name: '',
    phone: '',
    email: '',
    requirements: '',
  };
  management = {
    shortMemo: '',
    longMemo: '',
    blacklistReason: '',
    specialReason: '',
  };
  group = {};
  createdDate = '';
  admissions = [];
  programs = [];
  agreement = {
    overFourteenYearsOld: '',
    privacyUsageAgreement: '',
    privacyCollectionAgreement: '',
    eventInfoReceiveAgreement: '',
  };
  approval = {};
  refunds = [];
  constructor(props = {}) {
    const {
      id,
      reservationType,
      schedule,
      stayTime,
      visitorCount,
      reservationStatus,
      completeSendDate,
      infoSendDate,
      requester,
      management,
      group,
      createdDate,
      admissions,
      programs,
      agreement,
      approval,
      refunds,
    } = props;
    this.id = id;
    this.reservationType = reservationType;
    this.schedule = schedule;
    this.stayTime = stayTime;
    this.visitorCount = visitorCount;
    this.reservationStatus = reservationStatus;
    this.completeSendDate = completeSendDate;
    this.infoSendDate = infoSendDate;
    this.requester = requester;
    this.management = management;
    this.group = group;
    this.createdDate = createdDate;
    this.admissions = admissions || [];
    this.programs = programs;
    this.agreement = agreement;
    this.approval = approval || null;
    this.refunds = refunds || [];
  }
  get reservationStatusName() {
    return ReservationStatus[this.reservationStatus];
  }
  get reservationTypeName() {
    return ReservationType[this.reservationType];
  }
  get groupTypeName() {
    return GroupType[this.group.groupType];
  }
  get regionTypeName() {
    return RegionType[this.group.regionType];
  }
  get transportTypeName() {
    return TransportType[this.group.transportType];
  }
  get paymentMethodName() {
    if (!this.approval) return;
    return PaymentMethods[this.approval.paymentMethod.toUpperCase()];
  }
  get paymentDate() {
    if (!this.approval) return;
    return moment(this.approval.transactionDatetime).format('YYYY-MM-DD HH:mm');
  }
  get refundDate() {
    const refundsLength = this.refunds.length;
    if (!refundsLength > 0) return;
    const { refundDateTime } = this.refunds[refundsLength - 1];
    return moment(refundDateTime).format('YYYY-MM-DD hh:mm');
  }
  get refundSum() {
    const initialValue = {
      admissionPrice: 0,
      programPrice: 0,
      discountPrice: 0,
      totalPrice: 0,
    };
    if (!this.refunds) return initialValue;
    return this.refunds.reduce((acc, cur) => {
      const admissionPrice = acc.admissionPrice + cur.admissionRefundPrice;
      const programPrice = acc.programPrice + cur.programRefundPrice;
      const discountPrice = acc.discountPrice + cur.discountRefundPrice;
      const totalPrice = acc.totalPrice + cur.refundPrice;
      return {
        admissionPrice,
        programPrice,
        discountPrice,
        totalPrice,
      };
    }, initialValue);
  }
  get isFullRefund() {
    if (!this.approval) return;
    return this.refundSum.totalPrice === this.approval.transactionPrice;
  }
  get isPartialRefund() {
    if (!this.approval) return;
    return this.refunds.length > 0 && !this.isFullRefund;
  }
  get isCancelable() {
    const isRequestStatus = this.reservationStatus === 'REQUEST';
    const isCompleteStatus = this.reservationStatus === 'COMPLETE';
    const isRefund = this.isFullRefund || this.isPartialRefund;
    const beforeReservation2Hour = moment(`${this.schedule.reservationDate} ${this.schedule.reservationTime}`).subtract(2, 'hours');
    const isCancelableTime = moment().isSameOrBefore(beforeReservation2Hour);
    // 예약 신청 또는 완료 상태이면서, 환불 이력이 없으면서, 예약 시간 2시간 전인 경우 취소 가능
    return (isRequestStatus || isCompleteStatus) && !isRefund && isCancelableTime;
  }
  get isEditable() {
    const isEditableGroupReservation = this.reservationStatus === 'REQUEST' && this.reservationType === 'GROUP';
    const isEditablePersonalReservation = this.reservationStatus === 'COMPLETE' && this.reservationType === 'PERSONAL';
    const beforeReservation30Minute = moment(`${this.schedule.reservationDate} ${this.schedule.reservationTime}`).subtract(30, 'minutes');
    const isEditableTime = moment().isSameOrBefore(beforeReservation30Minute);
    // ((개인예약 && 예약 완료상태) || (단체예약 && 예약 신청상태)) && 예약시간 30분 전
    return (isEditableGroupReservation || isEditablePersonalReservation) && isEditableTime;
  }
  get settingForm() {
    const { email, ...othersRequester } = this.requester;
    const [emailID, emailDomain] = email.split('@');
    return {
      id: this.id,
      reservationType: this.reservationType,
      schedule: this.schedule,
      stayTime: this.stayTime,
      visitorCount: this.visitorCount,
      reservationStatus: this.reservationStatus,
      completeSendDate: this.completeSendDate,
      infoSendDate: this.infoSendDate,
      requester: {
        ...othersRequester,
        emailID,
        emailDomain,
      },
      group: this.group,
      createdDate: this.createdDate,
      programs: this.programs || [],
      admissions: this.admissions || [],
      couponDescription: null,
      agreement: {
        privacyUsageAgreement: this.agreement?.privacyUsageAgreement || 'N',
        overFourteenYearsOld: this.agreement?.overFourteenYearsOld || 'N',
        privacyCollectionAgreement: this.agreement?.privacyCollectionAgreement || 'N',
        eventInfoReceiveAgreement: this.agreement?.eventInfoReceiveAgreement || 'N',
      },
    };
  }

  // 개인예약 수정 폼 => 예약시간, 프로그램시간 초기화
  get personalEditForm() {
    const { group, ...others } = this;
    return {
      ...others,
      schedule: {
        ...this.schedule,
        originReservationTime: this.schedule.reservationTime,
        reservationTime: null,
      },
      programs: this.programs.map((program) => ({
        ...program,
        originTimeID: program.timeID,
        timeID: null,
      })),
    };
  }

  static parseForm(settingForm) {
    const {
      id,
      reservationType,
      schedule,
      stayTime,
      visitorCount,
      reservationStatus,
      completeSendDate,
      infoSendDate,
      requester: { emailID, emailDomain, ...othersRequester },
      group,
      createdDate,
      admissions,
      programs,
      couponDescription,
      agreement,
      order,
    } = settingForm;
    const isGroupReservation = reservationType === 'GROUP';
    return {
      id: id || null,
      reservationType: reservationType || null,
      schedule: {
        reservationDate: schedule.reservationDate || null,
        reservationTime: schedule.reservationTime || null,
      },
      stayTime: stayTime || null,
      visitorCount: visitorCount || null,
      reservationStatus: reservationStatus || !!order?.paymentMethod ? 'ORDER' : 'REQUEST',
      completeSendDate: completeSendDate || null,
      infoSendDate: infoSendDate || null,
      requester: {
        agency: othersRequester?.agency || null,
        name: othersRequester?.name || null,
        phone: othersRequester?.phone || null,
        requirements: othersRequester?.requirements || null,
        email: !!emailID && !!emailDomain ? emailID + '@' + emailDomain : null,
      },
      group: isGroupReservation
        ? {
            groupName: group?.groupName,
            groupType: group?.groupType,
            regionType: group?.regionType,
            leaderCount: parseInt(group?.leaderCount),
            transportType: group?.transportType,
            transportCount: group?.transportType === 'PUBLIC_TRANSPORT' ? null : parseInt(group?.transportCount),
          }
        : null,
      createdDate: createdDate || null,
      admissions: Array.isArray(admissions)
        ? admissions
            .filter((ticket) => Number(ticket.count) > 0)
            .map((ticket) => ({
              code: ticket.code,
              count: ticket.count,
            }))
        : [],
      couponDescription: couponDescription || null,
      programs: programs
        .filter((program) => parseInt(program.visitCount) > 0 || !!program.id)
        .map((program) => ({
          id: program.id || null,
          timeID: program.timeID,
          programID: program.programID,
          visitCount: parseInt(program.visitCount),
          gradeOrAge: program.gradeOrAge || null,
        })),
      agreement: {
        privacyUsageAgreement: agreement?.privacyUsageAgreement || 'N',
        overFourteenYearsOld: agreement?.overFourteenYearsOld || 'N',
        privacyCollectionAgreement: agreement?.privacyCollectionAgreement || 'N',
        eventInfoReceiveAgreement: agreement?.eventInfoReceiveAgreement || 'N',
      },
      order: order || null,
    };
  }

  static personalInitialForm() {
    return {
      reservationType: 'PERSONAL',
      schedule: {
        reservationDate: undefined,
        reservationTime: '',
      },
      visitorCount: 0,
      reservationStatus: '',
      completeSendDate: null,
      infoSendDate: null,
      requester: {
        name: '',
        phone: '',
        emailID: '',
        emailDomain: '',
        requirements: '',
      },
      createdDate: '',
      programs: [],
      admissions: [],
      couponDescription: null,
      couponInfo: {},
      agreement: {
        overFourteenYearsOld: 'N',
        privacyUsageAgreement: 'N',
        privacyCollectionAgreement: 'N',
        eventInfoReceiveAgreement: 'N',
      },
    };
  }

  static groupInitialForm({ minSeat }) {
    return {
      reservationType: 'GROUP',
      schedule: {
        reservationDate: undefined,
        reservationTime: '',
      },
      stayTime: '',
      visitorCount: minSeat,
      reservationStatus: '',
      completeSendDate: null,
      infoSendDate: null,
      requester: {
        agency: '',
        name: '',
        phone: '',
        emailID: '',
        emailDomain: '',
        requirements: '',
      },
      group: {
        groupName: '',
        groupType: '',
        regionType: '',
        leaderCount: '',
        transportType: '',
        transportCount: '',
      },
      createdDate: '',
      programs: [],
      agreement: {
        overFourteenYearsOld: 'N',
        privacyUsageAgreement: 'N',
        privacyCollectionAgreement: 'N',
        eventInfoReceiveAgreement: 'N',
      },
    };
  }

  static initialFilter() {
    return {
      page: 1,
      size: 6,
      direction: 'DESC',
    };
  }

  // 개인예약 수정 제출 폼
  static parsePersonalEditForm(props) {
    const { schedule, programs } = props;
    const { reservationDate, reservationTime } = schedule;
    return {
      ...props,
      schedule: { reservationDate, reservationTime },
      programs: programs
        .filter((program) => parseInt(program.visitCount) > 0 || !!program.id)
        .map((program) => ({
          id: program.id || null,
          timeID: program.timeID,
          programID: program.programID,
          visitCount: parseInt(program.visitCount),
          gradeOrAge: program.gradeOrAge || null,
        })),
    };
  }
}

export default ReservationDTO;
export { GroupType, RegionType, TransportType, ReservationStatus, ReservationType };
