const changeCursor = (triggerElement, cursorElement, config = { center: false }) => {
  const { center = false, hideTriggerCursor = true } = config;
  const makeVisibleElement = () => {
    if (hideTriggerCursor) triggerElement.style.cursor = 'none';

    cursorElement.style.opacity = '1';
    cursorElement.style.visibility = 'visible';
  };

  const makeHideElement = () => {
    if (hideTriggerCursor) triggerElement.style.cursor = '';

    cursorElement.style.opacity = '0';
    cursorElement.style.visibility = 'hidden';
  };

  const makeMoveElement = (event) => {
    const halfWidth = cursorElement.clientWidth / 2;
    const halfHeight = cursorElement.clientHeight / 2;
    const left = center ? event.clientX - halfWidth : event.clientX;
    const top = center ? event.clientY - halfHeight : event.clientY;
    cursorElement.style.left = `${left}px`;
    cursorElement.style.top = `${top}px`;
  };

  triggerElement.addEventListener('mouseover', makeVisibleElement);
  triggerElement.addEventListener('mouseout', makeHideElement);
  triggerElement.addEventListener('mousemove', makeMoveElement);

  const destroy = () => {
    if (hideTriggerCursor) triggerElement.style.cursor = '';
    triggerElement.removeEventListener('mouseover', makeVisibleElement);
    triggerElement.removeEventListener('mouseout', makeHideElement);
    triggerElement.removeEventListener('mousemove', makeMoveElement);
  };

  return {
    destroy,
  };
};

export { changeCursor };
