import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import PlayListStickerStyle from './PlayListSticker.module.scss';
import { RouteSticker, StickerNameRenderer } from '@/components';
import { cx } from '@emotion/css';
import React, { useState } from 'react';
import { StickerName } from './StickerName';
import { changeCursor } from '@/utils/main/changeCursor';
import { useIsPCOrMobile } from '@/hooks';
import { useRouter } from 'next/router';
import { isIOS } from '@/utils';

const stickers = ['single', 'hybrid', 'family', 'tanker', 'x', 'm'];

const PlayListSticker = forwardRef((props, playListSticker) => {
  const { className, style = {}, onLoad } = props;
  const [hoveredRoute, setHoveredRoute] = useState('');
  const router = useRouter();

  const wrapperRef = useRef();
  const scrollRef = useRef();
  const stickerContainerRef = useRef();
  const stickersRefs = useRef([]);
  const stickerNameRef = useRef();

  const { isPC } = useIsPCOrMobile({});

  useEffect(() => {
    if (!isPC) return;

    const cursors = [];
    for (const index of stickers.keys()) {
      const triggerElement = stickersRefs.current[index];
      const cursorElement = stickerNameRef.current;
      cursors.push(changeCursor(triggerElement, cursorElement));
    }
    return () => {
      cursors.forEach((cursor) => cursor.destroy());
    };
  }, [isPC]);

  useImperativeHandle(playListSticker, () => ({
    wrapperRef,
    scrollRef,
    stickerContainerRef,
  }));

  const addRefs = (index, element) => {
    stickersRefs.current[index] = element;
  };

  const moveToFlowDetailPage = (route) => {
    return router.push(`/flow/detail/${route}`);
  };

  // https://jiradev.nexon.com/browse/NCM-464?focusedCommentId=2451268&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-2451268
  // https://stackoverflow.com/questions/3038898/ipad-iphone-hover-problem-causes-the-user-to-double-click-a-link
  // 일부 아이패드에서 발생하는 호버이벤트로 더블클릭 해야만 클릭이 되는 이슈
  const onTouchEndMoveToFlowDetailPage = (route) => {
    if (!isIOS()) return;

    return moveToFlowDetailPage(route);
  };

  return (
    <div ref={wrapperRef} className={cx(className, PlayListStickerStyle.wrapper)} style={style}>
      <div ref={scrollRef} className={PlayListStickerStyle.scrollArea}>
        {isPC && (
          <StickerName ref={stickerNameRef}>
            <StickerNameRenderer type={hoveredRoute} subLanguageClassName={PlayListStickerStyle.stickerKoreanName} />
          </StickerName>
        )}
        <div ref={stickerContainerRef} className={PlayListStickerStyle.container}>
          {stickers.map((route, index) => {
            const isSelected = hoveredRoute === route;

            return (
              <div key={index} className={PlayListStickerStyle.stickerWrapper}>
                <div
                  className={PlayListStickerStyle.stickerInteract}
                  ref={(element) => addRefs(index, element)}
                  onMouseEnter={() => setHoveredRoute(route)}
                  onMouseLeave={() => setHoveredRoute('')}
                  onFocus={() => setHoveredRoute(route)}
                  onClick={() => moveToFlowDetailPage(route)}
                  onTouchEnd={() => onTouchEndMoveToFlowDetailPage(route)}
                >
                  <RouteSticker className={PlayListStickerStyle.sticker} type={route} isStopped={!isSelected} onLoad={onLoad} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

PlayListSticker.displayName = 'PlayListSticker';

export default PlayListSticker;
