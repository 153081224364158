import styles from '@/style/reservation.module.scss';
import editStyles from './index.module.scss';
import { useState } from 'react';
import { Dropdown, MainTitle, ReservationButton, System } from '@/components';
import { Program } from '@/utils';
import moment from 'moment';
import { cx } from '@emotion/css';
import ReservationDTO from '@/apis/DTOs/ReservationDTO';
const ReservationEdit = ({ initialForm, reservationSchedule, onUpdate, goBack }) => {
  const [form, setForm] = useState(initialForm);
  const onChangeReservationTime = (event) => {
    const { value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      schedule: {
        ...prevState.schedule,
        reservationTime: value,
      },
      programs: prevState.programs.map((program) => ({
        ...program,
        timeID: null,
      })),
    }));
  };
  const onChangeProgramTime = (event, index) => {
    const { value } = event.target;
    let newPrograms = [...form.programs];
    newPrograms[index].timeID = value;
    setForm((prevState) => ({
      ...prevState,
      programs: newPrograms,
    }));
  };
  const onSubmit = async () => {
    const newForm = ReservationDTO.parsePersonalEditForm(form);
    if (!newForm.schedule.reservationTime) {
      return System.alert({ title: '예약 시간 미입력', text: '예약 시간을 입력해주세요.' });
    }
    for (const program of newForm.programs) {
      if (!program.timeID) {
        return System.alert({ title: '프로그램 시간 미입력', text: '프로그램 시간을 입력해주세요.' });
      }
    }
    onUpdate(newForm);
  };
  const hasPrograms = form.programs.length > 0;
  return (
    <div className={styles.reservationContainer}>
      <MainTitle className={editStyles.mainTitle}>예약 수정</MainTitle>
      <div className={cx(styles.infoAnnounce, editStyles.infoAnnounce)}>일자 변경은 취소 후 재예약 바랍니다.</div>
      <div className={styles.editInfoContainer}>
        <h3 className={styles.visitInfoTitle}>
          예약 일자<sup className={styles.visitInfoTitleSuperscript}>Date</sup>
        </h3>
        <div className={styles.visitInfoValue}>{form.schedule.reservationDate}</div>
      </div>
      <div className={styles.editInfoContainer}>
        <h3 className={styles.visitInfoTitle}>
          예약 인원<sup className={styles.visitInfoTitleSuperscript}>Number of Admission</sup>
        </h3>
        <div className={styles.visitInfoValue}>{form.visitorCount}명</div>
      </div>
      <div className={styles.visitInfoContainer}>
        <h3 className={styles.visitInfoTitle}>
          예약 시간<sup className={styles.visitInfoTitleSuperscript}>Entry Time</sup>
        </h3>
      </div>
      <div className={styles.editDropdownContainer}>
        <div className={styles.editDropdownWrapper}>
          <Dropdown
            height={50}
            selected={form.schedule.originReservationTime}
            optionList={[{ name: Program.hhmmssTohhmm(form.schedule.originReservationTime), value: form.schedule.originReservationTime }]}
            disabled={true}
          />
        </div>
        <i className={styles.rightArrow} />
        <div className={styles.editDropdownWrapper}>
          <Dropdown
            name="reservationDate"
            height={50}
            defaultColor={'#eaeaea'}
            selected={form.schedule.reservationTime}
            defaultValue="시간 선택"
            optionList={reservationSchedule.map((timeObject) => {
              const reservationDate = form.schedule.reservationDate;
              const reservationTime = form.schedule.reservationTime;
              const remainingSeat = reservationTime === timeObject.time ? timeObject.remainingSeat + form.visitorCount : timeObject.remainingSeat;
              const isDisabled = remainingSeat < form.visitorCount || moment(`${reservationDate} ${timeObject.time}`).isBefore(moment(), 'minute');
              return {
                name: Program.hhmmssTohhmm(timeObject.time),
                value: timeObject.time,
                disabled: isDisabled,
              };
            })}
            onChange={onChangeReservationTime}
          />
        </div>
      </div>
      {hasPrograms && (
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            프로그램<sup className={styles.visitInfoTitleSuperscript}>Program</sup>
          </h3>
        </div>
      )}
      {form.programs.map((program, index) => {
        const isDropdownDisabled = !form.schedule.reservationTime;
        const originTime = program.times.find((time) => time.id === program.originTimeID);
        return (
          <div key={program.id}>
            <div className={styles.editProgramWrapper}>
              <h3 className={styles.editProgramName}>{program.programName}</h3>
              <h3 className={styles.editProgramCount}>{program.visitCount}명</h3>
            </div>
            <div className={styles.editDropdownContainer}>
              <div className={styles.editDropdownWrapper}>
                <Dropdown height={50} selected={originTime.id} optionList={[{ name: Program.hhmmssTohhmm(originTime.programTime), value: originTime.id }]} disabled={true} />
              </div>
              <i className={styles.rightArrow} />
              <div className={styles.editDropdownWrapper}>
                <Dropdown
                  name="program"
                  height={50}
                  defaultColor={isDropdownDisabled ? null : '#eaeaea'}
                  selected={program.timeID}
                  defaultValue="시간 선택"
                  optionList={program.times.map((time) => {
                    return {
                      name: Program.hhmmssTohhmm(time.programTime),
                      value: time.id,
                      disabled: time.programTime < form.schedule.reservationTime,
                    };
                  })}
                  onChange={(event) => onChangeProgramTime(event, index)}
                  disabled={isDropdownDisabled}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className={styles.lineBreak} />
      <ReservationButton notFixed={true}>
        <button className={cx(styles.footerButton, styles.cancelButton)} onClick={goBack}>
          취소
        </button>
        <button className={cx(styles.footerButton, styles.nextButton)} onClick={onSubmit}>
          예약 수정 완료
        </button>
      </ReservationButton>
    </div>
  );
};
export default ReservationEdit;
