import { useEffect, useState } from 'react';
const useCheckbox = ({ ids = [], checkRequiredList }) => {
  const [checkList, setCheckList] = useState([]);
  const [isCheckedRequiredItem, setIsCheckedRequiredItem] = useState(false);

  useEffect(() => {
    const isNotChecked = checkRequiredList.some((item) => !checkList.includes(item));
    setIsCheckedRequiredItem(!isNotChecked);
  }, [checkList, checkRequiredList]);

  const onCheckSingle = (event) => {
    const { name, checked } = event.target;
    if (checked && !checkList.includes(name)) {
      setCheckList((prevState) => [...prevState, name]);
    } else if (!checked) {
      setCheckList((prevState) => prevState.filter((item) => item !== name));
    }
  };
  const onCheckAll = () => {
    if (checkList.length !== ids.length) {
      setCheckList(ids);
    } else setCheckList([]);
  };

  return {
    checkList,
    isCheckedRequiredItem,
    onCheckSingle,
    onCheckAll,
  };
};
export default useCheckbox;
