import styles from './SquareBanner.module.scss';
import { getSquareBanners } from '@/apis/requests/banner';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { cx } from '@emotion/css';
const SquareBanner = ({ className }) => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchSquareBanners = async () => {
      const { data: squareBannerData } = await getSquareBanners();
      const banners = squareBannerData.data.filter((item) => {
        const { useState, startDate, endDate } = item;
        const isNotUse = useState === 'NOT_USE';
        const isIndefinite = !startDate && !endDate;
        const isShowDate = moment().isSameOrAfter(startDate, 'day') && moment().isSameOrBefore(endDate, 'day');

        return !isNotUse && (isIndefinite || isShowDate);
      });
      banners.sort((a, b) => a.showOrder - b.showOrder);
      if (mounted) setBanners(banners);
    };
    fetchSquareBanners();
    return () => {
      mounted = false;
    };
  }, []);

  const openLink = (link) => {
    if (!link) return;
    window.open(link, '_blank');
  };

  const onClose = (event, id) => {
    event.stopPropagation();
    setBanners((prevState) => prevState.filter((banner) => banner.id !== id));
  };

  return (
    <div className={className}>
      {banners.length > 0 && (
        <div className={styles.bannerContainer}>
          {banners.map((banner) => (
            <div key={banner.id} onClick={() => openLink(banner.link)} className={cx(styles.banner, banner.link && styles.link)}>
              <span>{banner.content}</span>
              <button className={styles.closeButton} onClick={(event) => onClose(event, banner.id)} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SquareBanner;
