const DONOR_COLLECTION = [
  { year: 2022, donor: '문기현', item: 'IBM 29 Card Punch 외 4,572점', isMajor: true },
  { year: 2013, donor: '오영욱', item: '국내 컴퓨터·게임 잡지 디지털 파일 5,316권', isMajor: true },
  { year: 2021, donor: '김성대', item: 'PlayStation 외 720점', isMajor: true },
  { year: 2014, donor: '이영진', item: '재믹스 V 외 435점', isMajor: true },
  { year: 2014, donor: '이택수', item: '임진록2+ 조선의 반격 외 344점', isMajor: true },
  { year: 2022, donor: '이태성', item: '망국전기' },
  { year: 2020, donor: '김성수', item: '폭스레인저 외 1점' },
  { year: 2020, donor: '강경란', item: 'PCCS 학술대회 비디오 테이프' },
  { year: 2019, donor: '이창섭', item: '천랑열전 외 2점' },
  { year: 2018, donor: '최대웅', item: '은행나무 소녀 밀' },
  { year: 2018, donor: '최준호', item: 'Xerox 6060 ' },
  { year: 2017, donor: '이정섭', item: 'FC-150 외 4점' },
  { year: 2017, donor: '김철민', item: '조랑이의 바람일기' },
  { year: 2017, donor: '박진형', item: 'IBM 한글 PC-DOS H 6.3 ' },
  { year: 2016, donor: '조현퇴', item: 'Clie N700C ' },
  { year: 2016, donor: '송성준', item: 'PS/2 Model 30 외 1점' },
  { year: 2016, donor: '박지환', item: 'IQ-2000 외 1점' },
  { year: 2016, donor: '최윤아', item: 'SCH-W420' },
  { year: 2016, donor: '전종섭', item: 'Final Fantasy VIII 외 163점' },
  { year: 2015, donor: '김학', item: '마이크로소프트웨어 창간호 외 2점' },
  { year: 2015, donor: '박성배', item: '80C48 포토 마스크' },
  { year: 2015, donor: '정왕호', item: '고려시스템 홍보 비디오 테이프' },
  { year: 2015, donor: '심봉일', item: 'Motorola DynaTAC 8000M 외 2점' },
  { year: 2015, donor: '정진환', item: 'BeBox 외 1점' },
  { year: 2015, donor: '오유라', item: 'MSX DPF-510' },
  { year: 2015, donor: '김국현', item: 'IBM ThinkPad 560Z 외 11점' },
  { year: 2015, donor: '이지선', item: 'Sony PCG-4A1L 외 3점' },
  { year: 2015, donor: '김유신', item: 'IBM ThinkPad T21 외 3점' },
  { year: 2015, donor: '박성훈', item: '창세기전 3 외 17점' },
  { year: 2015, donor: '윤병주', item: 'HandBall III 외 2점' },
  { year: 2015, donor: '하장훈', item: '소리샘' },
  { year: 2015, donor: '이영민', item: '라이워드 35' },
  { year: 2015, donor: '김동건', item: '마비노기프로젝트 개발 완수 보고서' },
  { year: 2015, donor: '도록태', item: 'WONDERSWAN SDK ' },
  { year: 2015, donor: '전상수', item: 'V3 365 Clinic PC 주치의' },
  { year: 2014, donor: '이수익', item: '한국아이비엠 25년 발자취' },
  { year: 2014, donor: '이동병', item: 'Silver Reed EZ10' },
  { year: 2014, donor: '하정원', item: '3.5" Floppy Diskettes 외 9점' },
  { year: 2014, donor: '이원근', item: 'SCH-V500 외 80점' },
  { year: 2014, donor: '박진환', item: 'ATI 3D Rage Pro 외 25점' },
  { year: 2014, donor: '이찬진', item: 'Tandy Z 외 4점' },
  { year: 2014, donor: '최준원', item: 'Atari CX-2600 외 24점' },
  { year: 2014, donor: '정동혁', item: 'Sega Mega Drive II 외 8점' },
  { year: 2014, donor: '이재호', item: 'iPod U2 Special Edition' },
  { year: 2014, donor: '정용진', item: 'TELEO PDA' },
  { year: 2014, donor: '임한백', item: 'Macintosh PowerBook 140' },
  { year: 2014, donor: '경종민', item: 'HK386 칩셋' },
  { year: 2014, donor: '전길남', item: 'Computer Lib/Dream Machines' },
  { year: 2014, donor: '정원철', item: 'HighScreen BlueNote2' },
  { year: 2014, donor: '박정윤', item: 'iPad Mini' },
  { year: 2014, donor: '이병철', item: 'Macintosh XL' },
  { year: 2014, donor: '김정주', item: 'iPhone 3GS 외 6점' },
  { year: 2014, donor: '정성윤', item: 'TriGem 88S' },
  { year: 2014, donor: '이종민', item: 'MS DOS Operating System Version 3.3' },
  { year: 2014, donor: '이우영', item: 'DJMAX 트릴로지: Over The Maximum' },
  { year: 2014, donor: '이상준', item: "Street Fighter II' Turbo: Hyper Fighting 외 1점" },
  { year: 2014, donor: '박재훈', item: '아이프렌드 WT 외 1점' },
  { year: 2014, donor: '윤영옥', item: '심포니 홈 멀티넷 ' },
  { year: 2014, donor: '김미경', item: 'Microsoft SideWinder' },
  { year: 2013, donor: '김종형', item: 'Sony VAIO PCG-Z505 외 8점' },
  { year: 2013, donor: '이종원', item: 'SGI O2' },
  { year: 2013, donor: '박진영', item: '바람의나라 가이드북 외 7점' },
  { year: 2013, donor: '채승윤', item: 'Sony Ericsson Xperia Mini Pro' },
  { year: 2013, donor: '박정협', item: 'PlayStation 2' },
  { year: 2013, donor: '서대영', item: 'Compaq Contura 2820D' },
  { year: 2013, donor: '서민', item: 'PlayStation 2 Slimline 외 93점' },
  { year: 2013, donor: '김유신', item: 'Diablo II 외 13점' },
  { year: 2013, donor: '정영원', item: '창세기전 1 외 6점' },
  { year: 2013, donor: '김현우', item: 'GP32' },
  { year: 2013, donor: '임을규', item: 'Sony PCG-N505VE' },
  { year: 2013, donor: '조성호', item: 'YS II' },
  { year: 2013, donor: '이상렬', item: 'SHARP MZ-6541 외 57점' },
  { year: 2013, donor: '강병호', item: '조립 서버 1U 외 17점' },
  { year: 2013, donor: '성광천', item: 'SENS 5850NT 외 4점' },
  { year: 2013, donor: '한정호', item: 'Apple II 호환 기종 외 23점' },
  { year: 2013, donor: '박진환', item: 'TDN-101 외 3점' },
  { year: 2013, donor: '하지형', item: 'Magic Station M4600 외 3점' },
  { year: 2013, donor: '지충현', item: 'PalmPilot Professional 외 5점' },
  { year: 2013, donor: '서재원', item: 'Newton MessagePad 2000 외 12점' },
  { year: 2011, donor: '김상범', item: 'IQ-1000 외 5점' },
  { year: 2018, donor: '조창제', item: 'Qrium CD663N 외 2점', noImage: true },
  { year: 2015, donor: '박윤규', item: 'Smith Corona SL600', noImage: true },
  { year: 2014, donor: '박일웅', item: 'Word Processor Bubble-Jet J5', noImage: true },
];

export default DONOR_COLLECTION;
