import { useEffect, useState } from 'react';

const ClientOnly = ({ children }) => {
  const [childComponent, setChildComponent] = useState(null);

  useEffect(() => {
    setChildComponent(children);
  }, [children]);

  return childComponent;
};

export default ClientOnly;
