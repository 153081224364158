import { shuffle } from '@/utils';

class Collections {
  static collectionInfos = {
    '1F': {
      totalLength: 12,
    },
    '2F': {
      totalLength: 8,
    },
    '3F': {
      totalLength: 8,
    },
    B1: {
      totalLength: 7,
    },
  };
  static floors = Object.keys(Collections.collectionInfos);
  static initialFloorCollections = Collections.floors.reduce(
    (prev, floor) => ({
      ...prev,
      [floor]: [],
    }),
    {}
  );
  collections = [];
  randomCollections = Collections.initialFloorCollections;

  constructor(props) {
    const { collections = [] } = props;
    this.collections = Collections.initializeCollections(collections);
    this.randomCollections = this.randomFloorCollections();
  }

  /**
   * 층수가 일치하는 소장품들을 반환한다.
   * @param floor
   * @param collections
   * @returns {Array}
   */
  static filterCollectionByFloor(floor, collections) {
    return collections.filter((collection) => collection.floor === floor);
  }

  /**
   * 고정 게시인 소장품과 그렇지 않은 소장품을 분류한다.
   * @param collections
   * @returns {Object}
   */
  static classifyCollectionsByFixed(collections) {
    const fixedCollections = collections.filter((collection) => collection.toggle === 1);
    const notFixedCollections = collections.filter((collection) => collection.toggle === 0);
    return {
      fixed: fixedCollections || [],
      notFixed: notFixedCollections || [],
    };
  }

  /**
   * 배열을 무작위로 섞는다.
   * @param array
   * @returns {Array}
   */
  static shuffle(array) {
    return shuffle(array);
  }

  /**
   * 빈 아이템을 반환한다.
   * @returns {Object}
   */
  static emptyItem() {
    return {
      type: 'EMPTY',
    };
  }

  /**
   * 초기화된 소장품을 반환한다.
   * @param collections
   * @returns {Array}
   */
  static initializeCollections(collections) {
    return collections.map((collection) => ({
      ...collection,
      type: collection.toggle === 1 ? 'FIXED' : 'NOT_FIXED',
    }));
  }

  /**
   * 들어갈 수 있는 소장품 개수만큼 무작위로 뽑는다.
   * @param floor
   * @param collections
   * @returns {Array}
   */
  static pickRandomCollections(floor, collections) {
    const collectionInfo = Collections.collectionInfos[floor];
    const { fixed, notFixed } = Collections.classifyCollectionsByFixed(collections);
    const randomCollectionsLength = collectionInfo.totalLength - fixed.length;

    if (notFixed.length <= randomCollectionsLength) return collections;
    if (randomCollectionsLength < 0) return fixed;

    const pickedNotFixedItems = Collections.shuffle(notFixed).slice(0, randomCollectionsLength);
    return [...pickedNotFixedItems, ...fixed];
  }

  /**
   * 다음 소장품 정보를 반환한다.
   * @param randomCollections
   * @param floor
   * @param currentIndex
   * @returns {Object}
   */
  static nextCollection(randomCollections, floor, currentIndex) {
    const currentFloorCollections = randomCollections[floor];
    for (let index = currentIndex + 1; index < currentFloorCollections.length; index++) {
      if (currentFloorCollections[index].type === 'NOT_FIXED' || currentFloorCollections[index].type === 'FIXED') {
        return {
          collection: currentFloorCollections[index],
          floor,
          index,
        };
      }
    }
    const currentFloorIndex = Collections.floors.indexOf(floor);
    const nextFloor = Collections.floors[currentFloorIndex + 1];

    return nextFloor ? Collections.nextCollection(randomCollections, nextFloor, -1) : null;
  }

  /**
   * 이전 소장품 정보를 반환한다.
   * @param randomCollections
   * @param floor
   * @param currentIndex
   * @returns {Object}
   */
  static prevCollection(randomCollections, floor, currentIndex) {
    const currentFloorCollections = randomCollections[floor];
    for (let index = currentIndex - 1; index >= 0; index--) {
      if (currentFloorCollections[index].type === 'NOT_FIXED' || currentFloorCollections[index].type === 'FIXED') {
        return {
          collection: currentFloorCollections[index],
          floor,
          index,
        };
      }
    }
    const currentFloorIndex = Collections.floors.indexOf(floor);

    if (currentFloorIndex <= 0) return null;

    const prevFloor = Collections.floors[currentFloorIndex - 1];
    const prevFloorLength = Collections.collectionInfos[prevFloor].totalLength;

    return Collections.prevCollection(randomCollections, prevFloor, prevFloorLength);
  }

  /**
   * 소장품을 무작위로 배치한다.
   * @param floor
   * @returns {Array}
   */
  placeRandomCollections(floor) {
    const collectionInfo = Collections.collectionInfos[floor];
    const collectionsByFloor = Collections.filterCollectionByFloor(floor, this.collections);
    const pickedCollections = Collections.pickRandomCollections(floor, collectionsByFloor);

    const result = [...pickedCollections];

    while (result.length < collectionInfo.totalLength) {
      result.push(Collections.emptyItem());
    }
    return Collections.shuffle(result);
  }

  /**
   * 층 별 무작위 배치된 소장품 배열을 리턴한다.
   * @returns {Object}
   */
  randomFloorCollections() {
    const floorCollections = Collections.initialFloorCollections;
    Collections.floors.forEach((floor) => {
      floorCollections[floor] = this.placeRandomCollections(floor);
    });
    return floorCollections;
  }
}

export default Collections;
