import { useState, useRef } from 'react';
import { cx } from '@emotion/css';
import { useClickOutsideHook, useDropdownPosition } from '@/hooks';
import styles from './index.module.scss';

const Dropdown = ({ name, selected, defaultValue, optionList, onChange, className, height, defaultColor, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef();
  const isUp = useDropdownPosition(listRef);

  const { elementRef } = useClickOutsideHook({
    onClickOutSide: () => setIsOpen(false),
  });
  const findSelectedOption = optionList.find((option) => option.value === selected);
  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };
  const onClick = (value) => {
    if (disabled) return;
    onChange({
      target: {
        name,
        value,
      },
    });
    toggleDropdown();
  };

  const cssValues = {
    [`--height`]: `${height}px`,
    [`--defaultColor`]: `${defaultColor}`,
  };
  return (
    <div style={cssValues} className={cx(styles.dropdownContainer, disabled && styles.allDisabled, className)} ref={elementRef}>
      <a className={cx(styles.selectedItem, isOpen && styles.openState)} onClick={toggleDropdown}>
        <span>{findSelectedOption?.name || defaultValue}</span>
      </a>
      <ul
        ref={listRef}
        className={cx(styles.dropdownList, 'scroll', isUp ? styles.upperDropdownList : styles.lowerDropdownList)}
        style={{
          display: 'block',
          visibility: isOpen ? 'visible' : 'hidden',
        }}
      >
        {defaultValue && (
          <li>
            <a name={name} value={''} onClick={() => onClick('')} className={cx(styles.dropdownItem, !findSelectedOption && styles.select)}>
              {defaultValue}
            </a>
          </li>
        )}
        {optionList.map((option) => (
          <li key={option.value}>
            <a
              name={name}
              value={option.value}
              onClick={() => onClick(option.value)}
              className={cx(styles.dropdownItem, findSelectedOption?.value === option.value && styles.select, option.disabled && styles.disabledItem)}
            >
              {option.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
