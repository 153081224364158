import { useEffect, useRef } from 'react';

const usePreventInitialEffect = (effect, deps) => {
  const mountRef = useRef(false);

  useEffect(() => {
    if (mountRef.current) effect();
    else mountRef.current = true;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, deps);
};

export default usePreventInitialEffect;
