import ServerAPI from '@/apis/server';
import programReservationBook from '@/apis/books/programReservation.json';

const getProgramSchedules = (payload) => {
  return ServerAPI.get(programReservationBook.REST, {
    params: { ...payload },
  });
};

export { getProgramSchedules };
