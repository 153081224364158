import styles from './ReservationButton.module.scss';
import reservationStyles from 'style/reservation.module.scss';
import { useEffect, useRef, useState } from 'react';
import { cx } from '@emotion/css';

const ReservationButton = ({ children, notFixed, totalPriceLabel = '결제 예정 금액', totalPrice, ...otherProps }) => {
  const [isFixed, setIsFixed] = useState(false);
  const [priceBottom, setPriceBottom] = useState(0);
  const buttonsRef = useRef();
  const helperRef = useRef();

  useEffect(() => {
    const helperElement = helperRef.current;
    const onIntersect = ([entry]) => {
      if (notFixed) return;
      const { isIntersecting, boundingClientRect } = entry;
      // 버튼이 위로 사라지는 경우는 제외
      if (!isIntersecting && boundingClientRect.top <= 0) {
        return;
      }
      setIsFixed(!isIntersecting);
    };
    const observer = new IntersectionObserver(onIntersect, {
      threshold: [0.5],
    });
    observer.observe(helperElement);
  }, [notFixed]);

  useEffect(() => {
    const setBottom = () => {
      const bottom = buttonsRef.current.offsetHeight - 50;
      setPriceBottom(bottom);
    };
    setBottom();
    window.addEventListener('resize', setBottom);
    return () => window.removeEventListener('resize', setBottom);
  }, [isFixed]);

  return (
    <div className={styles.container}>
      <div ref={buttonsRef} className={cx(styles.buttons, isFixed && styles.fixedButtons, !!totalPrice && styles.priceFixedButtons)} {...otherProps}>
        {!!totalPrice && (
          <div className={styles.totalPriceContainer} style={{ bottom: `${priceBottom}px` }}>
            <div>{totalPriceLabel}</div>
            <div className={cx(reservationStyles.totalPrice, reservationStyles.highlighter)}>
              <strong>{totalPrice}</strong>원
            </div>
          </div>
        )}
        {children}
      </div>
      <div className={styles.helper} ref={helperRef} />
    </div>
  );
};
export default ReservationButton;
