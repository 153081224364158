import { useState } from 'react';
import { useCoupon, useTicketPrice, useValidationHook, useIsMobileDevice, useReservationCheckAll } from '@/hooks';
import { Checkbox, XSSShield, Dropdown, ToggleContent, OperationText, ReservationButton, MainTitle } from '@/components';
import { Program, findGuideContent, formatPrice } from '@/utils';
import { EMAIL_DOMAINS, CHECKBOX_LIST } from '@/constants';
import styles from '@/style/reservation.module.scss';
import { cx } from '@emotion/css';

const RequesterForm = (props) => {
  const { initialForm, guides, onChange, onSubmit, returnToPrevStep } = props;
  const { isMobileDevice } = useIsMobileDevice();
  const [form, setForm] = useState(initialForm);
  const { checkValidation } = useValidationHook({
    data: form,
    validate: {
      requester: {
        name: ['isExist', 'isNotHavingComma'],
        phone: ['isExist', 'isPhoneNumber'],
        emailID: ['isExist', 'isEmailID'],
        emailDomain: ['isExist', 'isEmailDomain'],
      },
      agreement: {
        overFourteenYearsOld: ['isChecked'],
      },
    },
    customValidator: {
      isChecked: (value) => value === 'Y',
    },
  });
  const { visitorPrice, programPrice, totalPrice, discountPrice } = useTicketPrice({ form });
  const { isCouponApplicable, searchKeyword, onUpdateKeyword, checkCouponCode, foundCoupon, toggleCoupon } = useCoupon({ form, setForm, visitorPrice });

  const { checkAll, isCheckAll } = useReservationCheckAll({ setForm, agreementList: form.agreement });

  const onClickDone = async (event) => {
    if (!checkValidation()) {
      return;
    }
    let submitForm = { ...form };
    event.target.classList.add(styles.disabled);
    submitForm.order = {
      paymentMethod: totalPrice > 0 ? 'Card' : null,
      currency: 'WON',
      goodsName: '입장권',
      platform: isMobileDevice ? 2 : 1,
    };
    await onSubmit(submitForm);
    event.target.classList.remove(styles.disabled);
  };

  const registeredProgram = form.programs.filter((program) => !!program.timeID && program.visitCount > 0) || [];

  return (
    <div className={styles.reservationContainer}>
      <MainTitle>개인 예약</MainTitle>
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>예약자명</h2>
        <sup className={styles.subTitleSuperscript}>Name</sup>
      </div>
      <input
        className={styles.inputForm}
        type="text"
        name="name"
        value={form.requester.name || ''}
        onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
        placeholder="이름을 입력해주세요."
        maxLength="20"
        autoComplete="off"
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>휴대폰 번호</h2>
        <sup className={styles.subTitleSuperscript}>Phone Number</sup>
      </div>
      <input
        className={styles.inputForm}
        type="text"
        pattern={'^[0-9]+$'}
        name="phone"
        value={form.requester.phone || ''}
        onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
        placeholder="- 없이 숫자만 입력"
        maxLength="11"
        autoComplete="off"
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>이메일</h2>
        <sup className={styles.subTitleSuperscript}>Email</sup>
      </div>
      <div className={styles.emailFormContainer}>
        <div className={styles.emailForm}>
          <input
            className={cx(styles.inputForm, styles.emailInput)}
            type="text"
            name="emailID"
            value={form.requester.emailID || ''}
            onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
            maxLength="20"
            autoComplete="off"
          />
          <span className={styles.at}>@</span>
          <input
            className={cx(styles.inputForm, styles.emailInput)}
            type="text"
            name="emailDomain"
            value={form.requester.emailDomain || ''}
            onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
            maxLength="40"
            autoComplete="off"
          />
        </div>
        <div className={cx(styles.dropdownWrapper, styles.emailDropdownWrapper)}>
          <Dropdown
            height={50}
            defaultColor={'#eaeaea'}
            name="emailDomain"
            selected={form.requester.emailDomain}
            defaultValue="직접 입력"
            optionList={EMAIL_DOMAINS.map((domain) => ({
              name: domain,
              value: domain,
            }))}
            onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
          />
        </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>요청사항</h2>
        <sup className={styles.subTitleSuperscript}>Request</sup>
      </div>
      <textarea
        className={cx('scroll', styles.textAreaForm)}
        name="requirements"
        value={form.requester.requirements || ''}
        onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
        maxLength="200"
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <i className={styles.noteIcon} />
        <h2 className={styles.subTitle}>예약 내역</h2>
        <sup className={styles.subTitleSuperscript}>Visit Information</sup>
      </div>
      <hr className={styles.visitInfoDividingLine} />
      <div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            예약 일자<sup className={styles.visitInfoTitleSuperscript}>Date</sup>
          </h3>
          <div className={styles.visitInfoValue}>{form.schedule?.reservationDate}</div>
        </div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            예약 시간<sup className={styles.visitInfoTitleSuperscript}>Entry Time</sup>
          </h3>
          <div className={styles.visitInfoValue}>{Program.hhmmssTohhmm(form.schedule?.reservationTime)}</div>
        </div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            입장권<sup className={styles.visitInfoTitleSuperscript}>Admission</sup>
          </h3>
          <div className={styles.visitInfoValue}>{form.visitorCount}명</div>
        </div>
        <ul className={styles.ticketList}>
          {form.admissions
            .filter((ticket) => ticket.count > 0)
            .map(({ code, name, count, price }) => (
              <li key={code} className={styles.ticketItem}>
                <span>{name}</span>
                <span className={styles.ticketContent}>
                  {count}명 / {formatPrice(price * count, '원')}
                </span>
              </li>
            ))}
        </ul>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            프로그램<sup className={styles.visitInfoTitleSuperscript}>Program</sup>
          </h3>
          <div className={styles.visitInfoValue}>{registeredProgram.length}개</div>
        </div>
        <ul className={styles.ticketList}>
          {registeredProgram.map(({ programName, programID, cost, timeID, times, visitCount }) => {
            const { programTime } = times.find((time) => time.id === timeID);
            return (
              <li key={programID} className={styles.ticketItem}>
                <span className={styles.name}>{programName}</span>
                <span className={styles.ticketContent}>
                  {Program.hhmmssTohhmm(programTime)} / {visitCount}명 / {formatPrice(cost * visitCount, '원')}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      <hr className={cx(styles.dividingLine, styles.requesterLine)} />
      <div className={styles.goBackButtonWrapper}>
        <button className={styles.goBackButton} onClick={() => returnToPrevStep(form)}>
          다시 선택하기
        </button>
      </div>
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>할인코드</h2>
        <sup className={styles.subTitleSuperscript}>Coupon</sup>
      </div>
      <div className={styles.couponInputContainer}>
        <input
          className={styles.inputForm}
          type="text"
          value={searchKeyword}
          onChange={onUpdateKeyword}
          disabled={!isCouponApplicable}
          placeholder={isCouponApplicable ? '할인 코드를 입력해주세요.' : '입장료 할인과 중복 사용할 수 없습니다.'}
          maxLength="15"
          autoComplete="off"
        />
        <button className={styles.button} onClick={checkCouponCode} disabled={!isCouponApplicable}>
          확인
        </button>
      </div>
      {foundCoupon && (
        <div>
          <input type="checkbox" id="coupon" onChange={toggleCoupon} hidden />
          <label className={cx(styles.coupon, form.couponDescription && styles.appliedCoupon)} htmlFor="coupon">
            <div>
              <div className={cx(styles.couponPrice, styles.highlighter)}>{formatPrice(foundCoupon.value, foundCoupon.unit)}</div>
              <div className={styles.couponDescription}>{foundCoupon.name}</div>
            </div>
            <div className={styles.couponDate}>{foundCoupon.endDate}까지</div>
          </label>
        </div>
      )}
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>이용약관 동의</h2>
        <sup className={styles.subTitleSuperscript}>Agreement to the terms and condition for use</sup>
      </div>
      <ul className={styles.checkList}>
        <li className={styles.checkItem}>
          <Checkbox checked={isCheckAll()} onChange={checkAll}>
            전체 동의
          </Checkbox>
        </li>
        {CHECKBOX_LIST.map((checkbox, index) => (
          <li key={checkbox.type} className={styles.checkItem}>
            <Checkbox name={checkbox.type} checked={form.agreement[checkbox.type] === 'Y'} onChange={(event) => onChange({ objectName: 'agreement', event, setForm })}>
              <div>
                {checkbox.isRequired ? '[필수] ' : <span className={styles.optionalCheck}>(선택) </span>}
                {checkbox.name}
              </div>
            </Checkbox>
            {checkbox.guideType && <ToggleContent innerHTML={findGuideContent(guides, checkbox.guideType)} />}
          </li>
        ))}
      </ul>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>유의사항</h2>
        <sup className={styles.subTitleSuperscript}>Notice</sup>
      </div>
      <OperationText>
        <XSSShield content={findGuideContent(guides, 'PERSONAL_RESERVATION_NOTICE')} />
      </OperationText>
      <hr className={styles.dividingMarginLine} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>결제 정보</h2>
        <sup className={styles.subTitleSuperscript}>Payment Information</sup>
      </div>
      <ul className={styles.ticketList}>
        <li className={styles.ticketItem}>
          <span>관람 금액</span>
          <span className={styles.ticketContent}>{formatPrice(visitorPrice, '원', '0원')}</span>
        </li>
        <li className={styles.ticketItem}>
          <span>프로그램 금액</span>
          <span className={styles.ticketContent}>{formatPrice(programPrice, '원', '0원')}</span>
        </li>
        {discountPrice > 0 && (
          <li className={cx(styles.ticketItem, styles.ticketDiscountItem)}>
            <span>할인 금액</span>
            <span className={styles.ticketContent}>{`-${formatPrice(discountPrice, '원', '0원')}`}</span>
          </li>
        )}
      </ul>
      <ReservationButton totalPrice={formatPrice(totalPrice, '', 0)}>
        <button className={cx(styles.footerButton, styles.nextButton)} onClick={onClickDone}>
          결제하기
        </button>
      </ReservationButton>
    </div>
  );
};

export default RequesterForm;
