import Styles from './BoardNavigationBar.module.scss';
import { useEffect, useRef, useState } from 'react';

const BoardNavigationBar = () => {
  const wrapperRef = useRef();
  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const getElementInfo = () => {
      const wrapperElement = wrapperRef.current;
      const wrapperWidth = wrapperElement.offsetWidth;

      const list = document.getElementsByClassName('splide__list')[0];
      const items = document.querySelectorAll('.splide__slide:not(.splide__slide--clone)');
      const itemsLength = items.length;
      const itemMarginRight = window.getComputedStyle(items[0]).getPropertyValue('margin-right');
      const itemWidth = items[0].clientWidth + parseInt(itemMarginRight, 10);
      const itemsWidth = itemWidth * itemsLength;
      return { list, itemsWidth, wrapperWidth };
    };

    const changeBarWidth = () => {
      if (!wrapperRef.current) return;
      const { itemsWidth, wrapperWidth } = getElementInfo();
      const barWidth = (window.innerWidth / itemsWidth) * wrapperWidth;
      setWidth(barWidth);
    };

    let rAF;
    const changeBarLeft = () => {
      if (!wrapperRef.current) return;
      const wrapperElement = wrapperRef.current;
      if (!wrapperElement) return;
      const { list, itemsWidth, wrapperWidth } = getElementInfo();
      const listStyle = list?.style;
      const translateX = new WebKitCSSMatrix(listStyle.transform).m41;
      const left = ((Math.abs(translateX) % itemsWidth) / itemsWidth) * wrapperWidth;
      setLeft(left);
      rAF = window.requestAnimationFrame(changeBarLeft);
    };

    rAF = window.requestAnimationFrame(changeBarLeft);
    changeBarWidth();
    window.addEventListener('resize', changeBarWidth);

    return () => {
      window.cancelAnimationFrame(rAF);
      window.removeEventListener('resize', changeBarWidth);
    };
  }, []);

  return (
    <div ref={wrapperRef} className={Styles.navigationWrapper}>
      <div style={{ transform: `translateX(${left}px)`, width: `${width}px` }} className={Styles.navigationBar} />
    </div>
  );
};

export default BoardNavigationBar;
