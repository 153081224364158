import axios from 'axios';
import getConfig from 'next/config';
import { getCookie, isServer } from '@/utils';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
const { apiServer } = publicRuntimeConfig;
const { ssrApiServer } = serverRuntimeConfig;

const ServerAPI = axios.create({
  baseURL: isServer ? ssrApiServer : apiServer,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

ServerAPI.interceptors.request.use(function (config) {
  if (!config.headers.common['Authorization']) {
    const accessToken = getCookie({ name: 'accessToken' });
    config.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

// use in server side
const setAuthorizationHeader = (accessToken) => {
  ServerAPI.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};

// Server to Server API 에서 인증을 위해 사용
const getAPIKeyHeader = () => {
  return {
    headers: {
      'x-ncm-api-key': 'eeb8286b-3faf-41ad-a191-d34d0b5449fc',
    },
  };
};

export default ServerAPI;
export { setAuthorizationHeader, getAPIKeyHeader };
