import { useImperativeHandle, forwardRef } from 'react';
import getConfig from 'next/config';

const PaymentPC = forwardRef((props, ref) => {
  const formID = 'pay-form';
  const idPrefix = 'pc';
  const { publicRuntimeConfig } = getConfig();
  useImperativeHandle(ref, () => ({
    pay: (payload) => {
      Object.entries(payload).forEach(([id, value]) => {
        const element = document.getElementById(`${idPrefix}_${id}`);
        if (element) {
          element.value = value;
        }
      });

      INIStdPay.pay(formID);
    },
  }));

  return (
    <form id={formID} method="POST">
      <input type={'hidden'} name={'version'} value={'1.0'} />
      <input type={'hidden'} name={'gopaymethod'} id={`${idPrefix}_paymentMethod`} value={'Card'} />
      <input type={'hidden'} name={'mid'} id={`${idPrefix}_marketId`} />
      <input type={'hidden'} name={'oid'} id={`${idPrefix}_orderId`} />
      <input type={'hidden'} name={'price'} id={`${idPrefix}_price`} />
      <input type={'hidden'} name={'timestamp'} id={`${idPrefix}_timestamp`} />
      <input type={'hidden'} name={'signature'} id={`${idPrefix}_signature`} />
      <input type={'hidden'} name={'mKey'} id={`${idPrefix}_mKey`} />
      <input type={'hidden'} name={'currency'} id={`${idPrefix}_currency`} value={'WON'} />
      <input type={'hidden'} name={'goodname'} id={`${idPrefix}_goodsName`} />
      <input type={'hidden'} name={'buyername'} id={`${idPrefix}_buyerName`} />
      <input type={'hidden'} name={'buyertel'} id={`${idPrefix}_buyerTel`} />
      <input type={'hidden'} name={'buyeremail'} id={`${idPrefix}_buyerEmail`} />
      <input type={'hidden'} name={'returnUrl'} id={`${idPrefix}_returnUrl`} />
      <input type={'hidden'} name={'closeUrl'} id={`${idPrefix}_closeUrl`} value={`${publicRuntimeConfig.baseURL}/payment/close`} />
      <input type={'hidden'} name={'acceptmethod'} value="below1000" />
    </form>
  );
});
PaymentPC.displayName = 'PaymentPC';
export default PaymentPC;
