import { useEffect, useState } from 'react';

// 모바일 해상도인지, PC 해상도인지 여부 반환
const useIsPCOrMobileHooks = (props) => {
  const { pivot = 1000 } = props;
  const [isPC, setIsPC] = useState(false);

  useEffect(() => {
    const checkIsPC = () => {
      const width = window.innerWidth;
      setIsPC(width >= pivot);
    };
    checkIsPC();
    window.addEventListener('resize', checkIsPC);
    return () => window.removeEventListener('resize', checkIsPC);
  }, [pivot]);

  return { isPC, isMobile: !isPC };
};
export default useIsPCOrMobileHooks;
