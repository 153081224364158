import { useEffect, useState } from 'react';
import moment from 'moment';
import { useRouter } from 'next/router';

const useHideBanner = () => {
  const [hideBanner, setHideBanner] = useState(false);
  const router = useRouter();
  useEffect(() => {
    const exceptBannerKeys = ['reservation'];
    /**
     * https://nexonanalysis.slack.com/archives/C03F6JY4YH2/p1675736776818219?thread_ts=1675733429.180679&cid=C03F6JY4YH2
     * - 매년 3월 1일 00:00:00 ~ 3월 27일 17:59:59 에 박물관 휴관표기를 위한 예약배너 숨김
     * => 3월 휴관처리 제거 처리함.
     */
    const currentYear = moment().year();
    const hideBannerPeriods = [
      // {
      //   startDate: `${currentYear}-03-01 00:00:00`,
      //   endDate: `${currentYear}-03-27 17:59:59`,
      // },
    ];

    const isHideBannerPeriod = hideBannerPeriods.some(
      ({ startDate, endDate }) => moment().isSameOrAfter(moment(startDate), 'second') && moment().isSameOrBefore(moment(endDate), 'second')
    );
    const isHideBannerKey = exceptBannerKeys.some((key) => router.route.includes(key));
    setHideBanner(isHideBannerPeriod || isHideBannerKey);
  }, [router.route]);
  return { hideBanner };
};
export default useHideBanner;
