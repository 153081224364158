import styles from './LineBanner.module.scss';
import { useEffect, useState } from 'react';
import { getLineBanner } from '@/apis/requests/banner';
import { cx } from '@emotion/css';

const LineBanner = ({ className }) => {
  const [banner, setBanner] = useState({
    enContent: '',
    koContent: '',
  });
  useEffect(() => {
    let mounted = true;
    const fetchLineBanner = async () => {
      const { data: lineBannerData } = await getLineBanner();
      const { enContent, koContent, useState } = lineBannerData.data[0];
      const isNotUse = useState === 'NOT_USE';
      if (mounted) {
        setBanner({
          enContent: isNotUse ? '' : enContent,
          koContent: isNotUse ? '' : koContent,
        });
      }
    };
    fetchLineBanner();
    return () => {
      mounted = false;
    };
  }, []);
  return (
    <div className={className}>
      {banner.enContent && banner.koContent && (
        <div className={styles.bannerContainer}>
          <div className={styles.bannerRolling}>
            {Array.from({ length: 4 }).map((item, index) => (
              <span key={index} className={styles.bannerContents}>
                <span className={styles.bannerContent}>{banner.koContent}</span>
                <span className={cx(styles.bannerContent, styles.enContent)}>{banner.enContent}</span>
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default LineBanner;
