import { useEffect, useRef } from 'react';

const useClickOutsideHook = (props) => {
  const { onClickOutSide } = props;
  const elementRef = useRef(null);

  useEffect(() => {
    const clickOutSide = (event) => {
      const path = event.composedPath();
      const isInside = !!path && path.some((element) => element === elementRef.current);
      if (!isInside) {
        onClickOutSide();
      }
    };
    document.addEventListener('click', clickOutSide);
    return () => document.removeEventListener('click', clickOutSide);
  }, [onClickOutSide]);

  return { elementRef };
};

export { useClickOutsideHook };
