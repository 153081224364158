import { useState, useEffect, useRef } from 'react';
import styles from './Intro.module.scss';
import { RouteSticker, StickerNameRenderer } from '@/components';
import { useHorizontalScroll, useIsPCOrMobile } from '@/hooks';
import { cx } from '@emotion/css';
import { useRouter } from 'next/router';
import { COURSE_TYPES } from '@/constants';
import { changeCursor } from '@/utils/main/changeCursor';
import { isIOS } from '@/utils';

const TOTAL_PAGE = 2;

const Intro = () => {
  const router = useRouter();
  const cursorRef = useRef();
  const [hoveredType, setHoveredType] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const { isPC } = useIsPCOrMobile({});
  const { wrapperRef, scrollRef, currentPage, scrollPaging } = useHorizontalScroll({
    condition: isPC,
  });

  useEffect(() => {
    const footer = document.getElementById('footer');
    let cursor = '';
    if (isPC) {
      footer.style.display = 'none';
      const cursorElement = cursorRef.current;
      cursor = changeCursor(wrapperRef.current, cursorElement);
    }
    return () => {
      footer.style.display = '';
      if (cursor) cursor.destroy();
    };
  }, [isPC, wrapperRef]);

  const goToDetail = () => {
    return router.push(`/flow/detail/${selectedType}`);
  };

  // https://jiradev.nexon.com/browse/NCM-464?focusedCommentId=2451268&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-2451268
  // https://stackoverflow.com/questions/3038898/ipad-iphone-hover-problem-causes-the-user-to-double-click-a-link
  // 일부 아이패드에서 발생하는 호버이벤트로 더블클릭 해야만 클릭이 되는 이슈
  const onTouchEndGoToDetail = () => {
    if (!isIOS()) return;

    return router.push(`/flow/detail/${selectedType}`);
  };

  return (
    <div ref={wrapperRef} className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Play Route</div>
        <div className={styles.subTitle}>- SELECT -</div>
      </div>
      <div ref={cursorRef} className={styles.cursor} />
      <div ref={scrollRef} className={styles.scrollArea}>
        <div className={styles.routesContainer}>
          {COURSE_TYPES.map((type, index) => {
            const isSelected = selectedType === type;
            const isHovered = hoveredType === type;
            return (
              <button
                key={index}
                className={styles.route}
                onMouseEnter={() => setHoveredType(type)}
                onMouseLeave={() => setHoveredType(null)}
                onClick={() => setSelectedType(type)}
                onTouchEnd={() => setSelectedType(type)}
              >
                <RouteSticker type={type} isSelected={isSelected} isStopped={!isSelected && !isHovered} />
                {isPC && (
                  <div className={cx(styles.typeTitle, (isSelected || isHovered) && styles.selected)}>
                    <StickerNameRenderer type={type} subLanguageClassName={styles.stickerKoreanName} />
                  </div>
                )}
              </button>
            );
          })}
        </div>
      </div>
      {selectedType && (
        <button className={styles.selectRouteButton} onClick={goToDetail} onTouchEnd={onTouchEndGoToDetail}>
          <div className={styles.selectRouteButtonBackground}>
            <div className={styles.selectRouteButtonText}>
              <StickerNameRenderer type={selectedType} textTransform={'uppercase'} subLanguageClassName={styles.selectRouteKoreanText} /> ROUTE
            </div>
          </div>
          <div className={styles.highlight} />
        </button>
      )}
      <div className={styles.page}>
        <button className={cx(styles.pageButton, styles.prev, currentPage <= 1 && styles.hidden)} onClick={() => scrollPaging(currentPage - 1)} />
        <div>
          {currentPage} / {TOTAL_PAGE}
        </div>
        <button className={cx(styles.pageButton, styles.next, currentPage >= TOTAL_PAGE && styles.hidden)} onClick={() => scrollPaging(currentPage + 1)} />
      </div>
    </div>
  );
};
export default Intro;
