import { useEffect, useRef } from 'react';
import { changeCursor } from '@/utils/main/changeCursor';

const useChangeCursor = (config) => {
  const triggerRef = useRef();
  const cursorRef = useRef();

  useEffect(() => {
    const cursor = changeCursor(triggerRef.current, cursorRef.current, config);

    return () => cursor.destroy();
  }, [triggerRef, cursorRef, config]);

  return {
    triggerRef,
    cursorRef,
  };
};

export default useChangeCursor;
