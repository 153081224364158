import { useEffect } from 'react';

const getChangeCondition = () => {
  const isTooSmall = () => {
    return window.innerWidth < 360;
  };

  const isGalaxyFold = () => {
    const { navigator } = window;
    const userAgent = navigator.userAgent;
    return userAgent.includes('SM-F');
  };

  return [isTooSmall, isGalaxyFold].some((condition) => condition());
};

const useChangeViewportScaleHook = (props = {}) => {
  const { baseScale = 1, changeScale = 0.75, targetViewportWidth = 1000 } = props;

  useEffect(() => {
    let isAlreadyFired = false;

    const changeViewport = (config = {}) => {
      const { forceToBase = false } = config;
      const viewportElement = document.querySelector('meta[name=viewport]');

      if (isAlreadyFired) {
        isAlreadyFired = false;
        return;
      }

      const needChangeViewport = getChangeCondition();

      if (needChangeViewport) {
        isAlreadyFired = true;
      }

      const calculatedBrowserScale = needChangeViewport ? changeScale : baseScale;
      const browserScale = forceToBase ? baseScale : calculatedBrowserScale;
      viewportElement.setAttribute('content', `width=device-width, initial-scale=${browserScale}, maximum-scale=${browserScale}, minimum-scale=${browserScale}, user-scalable=no`);
    };

    changeViewport();
    window.addEventListener('resize', changeViewport);

    return () => {
      window.removeEventListener('resize', changeViewport);
      changeViewport({ forceToBase: true });
    };
  }, [baseScale, changeScale, targetViewportWidth]);
};

export default useChangeViewportScaleHook;
