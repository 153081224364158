import Styled from './index.styled';
import { useIsPCOrMobile } from '@/hooks';

const TextButton = ({ children, pcBorderWidth = 2, ...otherProps }) => {
  const { isPC } = useIsPCOrMobile({ pcWidth: 1000 });
  return (
    <Styled.Button borderWidth={isPC ? pcBorderWidth : 2} {...otherProps}>
      {children}
      <span className={'border'} />
    </Styled.Button>
  );
};
export default TextButton;
