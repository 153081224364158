const EMAIL_DOMAINS = ['naver.com', 'gmail.com', 'daum.net', 'nate.com', 'kakao.com'];

const CHECKBOX_LIST = [
  {
    type: 'overFourteenYearsOld',
    name: '만 14세 이상입니다.',
    isRequired: true,
    guideType: null,
  },
  {
    type: 'privacyUsageAgreement',
    name: '개인정보 수집 및 이용 동의',
    isRequired: false,
    guideType: 'PRIVACY_USAGE_AGREEMENT',
  },
  {
    type: 'privacyCollectionAgreement',
    name: '개인정보 수집 및 이용 동의',
    isRequired: false,
    guideType: 'PRIVACY_COLLECTION_AGREEMENT',
  },
  {
    type: 'eventInfoReceiveAgreement',
    name: '행사 및 이벤트 정보 수신 동의',
    isRequired: false,
    guideType: 'EVENT_INFO_RECEIVE_AGREEMENT',
  },
];

const EDUCATION_CHECKBOX_LIST = [
  {
    type: 'overFourteenYearsOld',
    name: '만 14세 이상입니다.',
    isRequired: true,
    guideType: null,
  },
  {
    type: 'privacyCollectionAgreement',
    name: '개인정보 수집 및 이용 동의',
    isRequired: false,
    guideType: 'EDUCATION_PRIVACY_USAGE_AGREEMENT',
  },
];

export { EMAIL_DOMAINS, CHECKBOX_LIST, EDUCATION_CHECKBOX_LIST };
