import { useState } from 'react';
import { cx } from '@emotion/css';
import { OperationText, XSSShield } from '@/components';
import styles from './index.module.scss';

const ToggleContent = ({ innerHTML }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((prevState) => !prevState);
  return (
    <>
      <button className={cx(styles.toggleButton, isOpen && styles.open)} onClick={toggleOpen}>
        <span className="hidden">내용보기</span>
      </button>
      <div className={styles.operationTextWrapper}>
        <OperationText>
          <XSSShield
            className={styles.operationText}
            style={{
              display: isOpen ? 'block' : 'none',
            }}
            content={innerHTML}
          />
        </OperationText>
      </div>
    </>
  );
};
export default ToggleContent;
