import styles from './PlayListNavigationBar.module.scss';
import { useMemo, useRef } from 'react';
import React from 'react';

const PlayListNavigationBar = React.forwardRef(({ scrollWidth, scrollLeft }, ref) => {
  const navigationRef = useRef(null);

  const NavValueStyle = useMemo(() => {
    const navElement = navigationRef.current;
    if (!navElement) return;
    const containerWidth = navElement.offsetWidth;

    return {
      left: `${(scrollLeft / scrollWidth) * containerWidth}px`,
      width: `${(window.innerWidth / scrollWidth) * containerWidth}px`,
    };
  }, [scrollLeft, scrollWidth]);

  return (
    <div ref={ref} className={styles.navigationWrapper}>
      <div ref={navigationRef} className={styles.navigationBar}>
        <div style={NavValueStyle} className={styles.navigationValue} />
      </div>
    </div>
  );
});
PlayListNavigationBar.displayName = 'PlayListNavigationBar';

export default PlayListNavigationBar;
