import CollectionHighlightStyle from './b.CollectionHighlight.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Collections } from '@/utils';
import { StaticImage } from '@/components';
import Marquee from '@/utils/main/Marquee';

const imagePrefix = '/static/images/main/b-collection';

const collectionList = [
  {
    name: '마우스의 원래 이름은?',
    engTitle: 'Engelbart Mouse',
    banner: 'banner-engelbart-mouse.svg',
    backgroundColor: '#ff855c',
  },
  {
    name: '70년대 개발자들의 워너비',
    engTitle: 'Altair 8800',
    banner: 'banner-altair-8800.svg',
    backgroundColor: '#f4a16f',
  },
  {
    name: '오락실을 침공한 외계인',
    engTitle: 'Space Invaders',
    banner: 'banner-space-invader.svg',
    backgroundColor: '#f4f26f',
  },
  {
    name: '세상을 바꾼 사과',
    engTitle: 'Apple I',
    banner: 'banner-apple-i.svg',
    backgroundColor: '#e0f592',
  },
  {
    name: '넥슨은 다람쥐를 뿌려라!',
    engTitle: 'The Kingdom of the Winds',
    banner: 'banner-kow.svg',
    backgroundColor: '#8bedb2',
  },
  {
    name: '올해의 인물이 된 컴퓨터',
    engTitle: 'PC 5150',
    banner: 'banner-pc-5150.svg',
    backgroundColor: '#acbeff',
  },
  {
    name: 'ATM0 ATDT0141X',
    engTitle: 'Dial-up Internet',
    banner: 'banner-dial-up.svg',
    backgroundColor: '#a58ff9',
  },
  {
    name: '숑숑 피웅피웅피웅 ㄲ ㅗㅏ o !',
    engTitle: 'Galaga',
    banner: 'banner-galaga.svg',
    backgroundColor: '#eeb8e5',
  },
  {
    name: '그때는 가볍고 지금은 무거운',
    engTitle: 'Osborne 1',
    banner: 'banner-osborne-1.svg',
    backgroundColor: '#f7f7f7',
  },
];

const COLLECTIONS_LENGTH = 5;

const BCollectionHighlight = (props) => {
  const { id } = props;
  const [collections, setCollections] = useState([]);
  const bannerHoverRefs = useRef([]);
  const bannerWrapperRefs = useRef([]);
  const bannerInnerRefs = useRef([]);
  const bannerContainerRefs = useRef([]);

  useEffect(() => {
    const shuffledList = Collections.shuffle(collectionList);
    setCollections(shuffledList.slice(0, COLLECTIONS_LENGTH));
  }, []);

  useEffect(() => {
    const bannerHoverElements = bannerHoverRefs.current;
    const marquees = bannerHoverElements.map((bannerHoverElement, index) =>
      Marquee.build({
        DOM: {
          hoverElement: bannerHoverElement,
          wrapperElement: bannerWrapperRefs.current[index],
          innerElement: bannerInnerRefs.current[index],
        },
      })
    );

    return () => {
      marquees.forEach((marquee) => marquee.destroy());
    };
  });

  const calculateVelocity = useCallback((index) => {
    const banner = bannerContainerRefs.current[index];
    if (!banner) return;
    const bannerWidth = banner.offsetWidth;
    banner.style.animationDuration = `${bannerWidth * 2.1}ms`;
  }, []);

  return (
    <section id={id} className={CollectionHighlightStyle.container}>
      {/* at-root 가 되지 않아서 사용 */}
      <style>
        .on-marquee {`.${CollectionHighlightStyle.bannerContainer}`} {'{'}
        animation-play-state: running;
        {'}'}
      </style>
      <div className={CollectionHighlightStyle.logoContainer}>
        <div className={CollectionHighlightStyle.logoAssets}>
          <div className={CollectionHighlightStyle.rotatingLogo} />
          <div className={CollectionHighlightStyle.illustLogo} />
        </div>
      </div>
      <ul className={CollectionHighlightStyle.collectionList}>
        {collections.map((collection, index) => {
          return (
            <li ref={(element) => (bannerHoverRefs.current[index] = element)} key={index} id={`collection-${index}`} className={CollectionHighlightStyle.collectionListItem}>
              <div className={CollectionHighlightStyle.collectionBase}>
                <strong className={CollectionHighlightStyle.collectionName}>{collection.name}</strong>
                <sup className={CollectionHighlightStyle.collectionSup}>{collection.engTitle}</sup>
              </div>
              <div
                ref={(element) => (bannerWrapperRefs.current[index] = element)}
                className={CollectionHighlightStyle.collectionHover}
                style={{ backgroundColor: collection.backgroundColor }}
              >
                <div ref={(element) => (bannerInnerRefs.current[index] = element)} className={CollectionHighlightStyle.bannerWrapper}>
                  <div ref={(element) => (bannerContainerRefs.current[index] = element)} className={CollectionHighlightStyle.bannerContainer}>
                    {Array.from({ length: 5 }).map((item, index) => (
                      <span className={CollectionHighlightStyle.banner} key={index}>
                        <StaticImage className={CollectionHighlightStyle.bannerImage} src={`${imagePrefix}/${collection.banner}`} onLoad={() => calculateVelocity(index)} />
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default BCollectionHighlight;
