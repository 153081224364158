import sanitizeHTML from 'sanitize-html';

const sanitizeOptions = {
  disallowedTagsMode: 'escape',
  allowedTags: sanitizeHTML.defaults.allowedTags.concat(['img', 'iframe', 'font']),
  allowedAttributes: false,
};

const XSSShield = (props) => {
  const { tagName = 'div', content = '', ...otherProps } = props;
  const Component = tagName;
  const clean = sanitizeHTML(content, sanitizeOptions);
  return <Component {...otherProps} dangerouslySetInnerHTML={{ __html: clean }} />;
};

export default XSSShield;
