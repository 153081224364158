import { useEffect, useRef } from 'react';
import Link from 'next/link';
import { cx } from '@emotion/css';

import { IconCrossMover } from '@/utils';
import FooterStyle from './Footer.module.scss';
import MoreThing from './MoreThing';

const Footer = (props) => {
  const { className, isSubPage } = props;

  const iconElements = useRef([]);

  const footerMenus = [
    { name: '관람 안내', href: '/guide' },
    { name: '이달의 프로그램', href: '/monthlyprogram' },
    { name: '단체 관람 가이드 (교사용)', href: '/static/files/단체관람가이드_교사용.pdf', download: '단체관람가이드_교사용' },
    { name: '단체 관람 가이드 (학생용)', href: '/static/files/단체관람가이드_학생용.pdf', download: '단체관람가이드_학생용' },
  ];

  const footerSocials = [
    {
      iconClassName: FooterStyle.iconSocialNaverBlog,
      href: 'https://blog.naver.com/ncm_master',
    },
    {
      iconClassName: FooterStyle.iconSocialFacebook,
      href: 'https://www.facebook.com/NexonComputerMuseum',
    },
    {
      iconClassName: FooterStyle.iconSocialTwitter,
      href: 'https://twitter.com/NCM_Master',
    },
    {
      iconClassName: FooterStyle.iconSocialInstagram,
      href: 'https://www.instagram.com/nexoncomputermuseum/',
    },
    {
      iconClassName: FooterStyle.iconSocialYouTube,
      href: 'https://www.youtube.com/channel/UCIHQ1jIq_RyWSIceb_e9VFw',
    },
    {
      iconClassName: FooterStyle.iconSocialKakaotalk,
      href: 'https://pf.kakao.com/_xakQGs',
    },
  ];

  useEffect(() => {
    const elements = iconElements.current;
    const iconMovers = elements.map((element) => {
      return IconCrossMover.build({ element });
    });

    return () => iconMovers.forEach((iconMover) => iconMover.destroy());
  }, [iconElements]);

  const openBusinessInformation = () => {
    const href = 'http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=1448115947';
    window.open(href, '_blank', 'width=750, height=700');
  };

  return (
    <footer id={'footer'} className={cx(className, 'footer', FooterStyle.footer)}>
      <div>
        {!isSubPage && <MoreThing />}
        <div className={FooterStyle.moreInteresting}>
          <ul className={FooterStyle.moreInterestingList}>
            {footerMenus.map((footerMenu) => {
              return (
                <li key={footerMenu.name} className={FooterStyle.moreInterestingListItem}>
                  <a href={footerMenu.href} download={footerMenu.download} className={cx(FooterStyle.interestingItem, FooterStyle.pencilUnderline)}>
                    {footerMenu.name}
                  </a>
                </li>
              );
            })}
          </ul>
          <ul className={FooterStyle.moreInterestingSocialList}>
            {footerSocials.map((footerSocial, index) => {
              return (
                <li key={footerSocial.href} className={FooterStyle.moreInterestingSocialListItem}>
                  <a target="_blank" rel="noopener noreferrer" href={footerSocial.href} className={FooterStyle.linkSocial}>
                    <i ref={(element) => (iconElements.current[index] = element)} className={cx(FooterStyle.iconSocial, footerSocial.iconClassName)} />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={FooterStyle.footerInformation}>
        <ul className={cx(FooterStyle.privacyPolicy, FooterStyle.paragraph)}>
          <li>
            <Link href={'/policy/privacy'} className={cx(FooterStyle.privacyPolicyFont, FooterStyle.linkUnderline)}>
              개인정보처리방침
            </Link>
          </li>
        </ul>
        <ul className={cx(FooterStyle.business, FooterStyle.paragraph)}>
          <li>상호명: (주)넥슨스페이스 사업자등록번호 : 144-81-15947 대표자명: 지준숙</li>
          <li>사업자주소: 경기도 성남시 분당구 판교로 256번길 7 고객센터 연락처: 064-745-1994</li>
          <li>
            통신판매업 신고번호 : 제2021-성남분당C-1344호{' '}
            <a className={FooterStyle.businessLink} onClick={openBusinessInformation}>
              사업자정보확인
            </a>
          </li>
        </ul>
        <address className={cx(FooterStyle.address, FooterStyle.paragraph)}>
          (우) 63091 제주특별자치도 제주시 1100로 3198-8
          <br />
          넥슨컴퓨터박물관 (노형동) +82 064 745 1994
        </address>
        <small className={cx(FooterStyle.copyright, FooterStyle.paragraph)}>Copyrightⓒ Nexon Space. All Right Reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
