/**
 * 오브젝트를 바탕으로 URL Query parameter를 만들어주는 함수
 * @param queryObject {object}
 * @return string
 */
const makeUrlQueryString = (queryObject) => {
  const queryString = Object.entries(queryObject)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  return queryString;
};

export default makeUrlQueryString;
