import PropTypes from 'prop-types';
import getConfig from 'next/config';
import Image from 'next/image';
import styled from '@emotion/styled';
const { publicRuntimeConfig } = getConfig();

const imageLoader = ({ src }) => {
  const inHouseRegExp = new RegExp(/(http(s)?:\/\/).+/);
  return inHouseRegExp.test(src) ? src : `${publicRuntimeConfig.fileAssetPrefix}/${src}`;
};

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .autoImage {
    object-fit: cover !important;
    position: relative !important;
    height: auto !important;
  }
`;

const FileImage = (props) => {
  const { src, width, height, useNextImage = true, ...otherProps } = props;

  if (!useNextImage) {
    return <img alt="image" src={imageLoader({ src })} {...otherProps} />;
  }

  const sizeOptions =
    width && height
      ? { width, height }
      : {
          fill: true,
        };

  return (
    <ImageWrapper>
      <Image alt="image" src={src} loader={imageLoader} sizes={'fit-content'} {...sizeOptions} {...otherProps} />
    </ImageWrapper>
  );
};

FileImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

FileImage.defaultProps = {
  src: '',
};

export default FileImage;
export { imageLoader };
