import { useMutation, useQueryClient } from 'react-query';
import { getCoupon } from '@/apis/requests/coupon';
import { useCallback, useEffect, useState } from 'react';
import { System } from '@/components';
import moment from 'moment';
import { getEducationCoupon } from '@/apis/requests/educations';

const DISCOUNT_TYPES = {
  1: {
    unit: '원',
  },
  2: {
    unit: '%',
  },
};

const couponApplicableTickets = [1, 2, 3, 4];

/**
 * 쿠폰 검색 / 사용을 돕는 hook 입니다.
 * @param form
 * @param setForm
 * @param visitorPrice - 입장권 total 가격
 * @param isEducationReservation - 교육 프로그램 예약 여부 (예약 결제인 경우 false / 교육프로그램 예약 결제인 경우 true)
 */
const useCoupon = ({ form, setForm, visitorPrice, isEducationReservation = false }) => {
  const queryClient = useQueryClient();
  const [isCouponApplicable, setIsCouponApplicable] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [foundCoupon, setFoundCoupon] = useState(null);

  // 쿠폰 정보 초기화
  const initializeCouponInfo = useCallback(() => {
    setForm((prevState) => ({
      ...prevState,
      couponDescription: null,
      couponInfo: {},
    }));
  }, [setForm]);

  useEffect(() => {
    // 쿠폰 초기화 - 쿠폰 적용 대상 변경될 가능성이 있기 때문
    initializeCouponInfo();
  }, [initializeCouponInfo]);

  useEffect(() => {
    // 예약 결제의 경우
    // 0원인 경우거나, 일반 이외의 티켓을 예약하는 경우, 할인코드 적용이 불가
    if (!isEducationReservation) {
      const isNotApplicable = form.admissions.some(({ code, count }) => !couponApplicableTickets.includes(code) && count > 0) || visitorPrice === 0;
      setIsCouponApplicable(!isNotApplicable);
    }
    // 교육프로그램 결제의 경우
    // 0원인 경우 적용 불가
    else {
      setIsCouponApplicable(visitorPrice !== 0);
    }
  }, [form.admissions, isEducationReservation, visitorPrice]);

  const ERROR_CODE = {
    INVALID_COUPON_DATE: -1301, // 에러코드 삭제
    OVER_COUPON_USE_COUNT: -1302, // 에러코드 삭제
    NOT_EXIST_COUPON: -1303,
    NOT_EXIST_EDUCATION_COUPON: -1402,
  };

  // 예약 결제 쿠폰 조회
  const getCouponMutation = useMutation(getCoupon, {
    onSuccess: async ({ data: response }) => {
      processCouponResponse(response);
    },
  });

  // 교육 프로그램 쿠폰 조회
  const getEducationCouponMutation = useMutation(getEducationCoupon, {
    onSuccess: ({ data: response }) => {
      processCouponResponse(response);
    },
  });

  const processCouponResponse = (response) => {
    const { code, data: coupon } = response;
    switch (code) {
      case 0:
        const { isBeforeStartDate, isAfterEndDate } = checkValidDate(coupon);
        if (isBeforeStartDate) {
          // 시작일 이전의 쿠폰
          return System.alert({
            title: '사용불가',
            text: '유효하지 않은 코드입니다.',
          });
        } else if (isAfterEndDate) {
          // 종료일 이후의 쿠폰
          return System.alert({
            title: '만료',
            text: '유효기간이 만료되어 사용하실 수 없습니다.',
          });
        }
        // 사용횟수 초과
        if (coupon.useCount >= coupon.maxCount) {
          return System.alert({
            title: '사용불가',
            text: '유효하지 않은 코드입니다.',
          });
        }
        setFoundCouponWithUnit(coupon);
        break;
      case ERROR_CODE.NOT_EXIST_COUPON:
      case ERROR_CODE.NOT_EXIST_EDUCATION_COUPON:
        return System.alert({
          title: '오입력',
          text: '입력한 정보가 존재하지 않습니다. 확인 후 다시 입력해주세요.',
        });
      default:
        return System.alert({
          title: '에러',
          text: '알 수 없는 에러가 발생했습니다. 잠시 후 다시 시도해주세요.',
        });
    }
  };

  // 쿠폰 만료일자 검사 - 시작일 이전인지, 종료일 이후인지
  const checkValidDate = (coupon) => {
    const isBeforeStartDate = moment().isBefore(moment(coupon.startDate), 'day');
    const isAfterEndDate = moment().isAfter(moment(coupon.endDate), 'day');
    return { isBeforeStartDate, isAfterEndDate };
  };

  const onUpdateKeyword = (event) => {
    const { value } = event.target;
    setSearchKeyword(value);
  };

  const checkCouponCode = async () => {
    if (!searchKeyword) {
      return System.alert({
        title: '할인코드 미입력',
        text: '할인코드를 입력해주세요.',
      });
    }
    if (queryClient.isMutating()) return;
    initializeCouponInfo();
    if (isEducationReservation) getEducationCouponMutation.mutate({ description: searchKeyword });
    else getCouponMutation.mutate({ description: searchKeyword });
  };

  const setFoundCouponWithUnit = (coupon) => {
    setFoundCoupon({
      ...coupon,
      unit: DISCOUNT_TYPES[coupon.discountType].unit,
    });
  };

  const toggleCoupon = async () => {
    const { description, value, discountType } = foundCoupon;
    // 일반예약 - 금액할인 초과인 경우
    if (!isEducationReservation && discountType === 1 && visitorPrice < value) {
      return System.alert({
        title: '할인 불가',
        text: '할인 금액 초과로 쿠폰 적용이 불가합니다.',
      });
    }
    // 교육프로그램예약 - 할인 적용시 0원 되는 경우
    if (isEducationReservation && ((discountType === 1 && visitorPrice <= value) || (discountType === 2 && value >= 100))) {
      return System.alert({
        title: '할인 불가',
        text: '할인 금액 초과로 쿠폰 적용이 불가합니다.',
      });
    }
    if (!!form.couponDescription) {
      return initializeCouponInfo();
    }
    setForm((prevState) => ({
      ...prevState,
      couponDescription: description,
      couponInfo: foundCoupon,
    }));
  };

  return {
    isCouponApplicable,
    searchKeyword,
    onUpdateKeyword,
    checkCouponCode,
    foundCoupon,
    toggleCoupon,
  };
};

export default useCoupon;
export { couponApplicableTickets };
