class IconCrossMover {
  constructor(props) {
    const { element } = props;
    this.element = element;
    this.moveIndex = 0;
    this.nextPixel = 0;
    this.endPoint = 0;
    this.requestId = null;
  }

  static build(props) {
    const { element } = props;
    if (!element) throw new Error('Require Element.');
    const iconCrossMover = new IconCrossMover(props);
    iconCrossMover.start();
    return iconCrossMover;
  }

  start() {
    this.bindEvent();
  }

  bindEvent() {
    this.element.addEventListener('mouseover', this.moveStart.bind(this));
  }

  update() {
    if (this.nextPixel >= this.endPoint) {
      if (this.moveIndex >= 2) return this.moveEnd();
      this.moveIndex < 1 ? this.centerToLeft() : this.leftToCenter();
      this.moveIndex++;
    }
    this.move();
    this.render();
    this.requestId = window.requestAnimationFrame(() => this.update());
  }

  getTranslatePosition() {
    const parentWidth = this.element.parentElement.clientWidth;
    return (parentWidth / 3) * 2;
  }

  centerToLeft() {
    this.nextPixel = 0;
    this.endPoint = this.getTranslatePosition();
  }

  leftToCenter() {
    this.nextPixel = -this.getTranslatePosition();
    this.endPoint = 0;
  }

  moveStart(event) {
    if (!this.isHovering(event)) return;
    this.update();
  }

  isHovering(event) {
    const targetElement = event.target;
    const { hovering } = targetElement.dataset;
    const isHovering = hovering === 'true';

    if (isHovering) return false;

    targetElement.dataset.hovering = 'true';
    return true;
  }

  move() {
    this.nextPixel += 4;
  }

  moveEnd() {
    this.moveIndex = 0;
    this.element.dataset.hovering = 'false';
    window.cancelAnimationFrame(this.requestId);
  }

  render() {
    this.element.style.transform = `translateX(${this.nextPixel}px)`;
    this.element.style.transitionProperty = `translateX`;
  }

  destroy() {
    this.moveEnd();
    this.element.removeEventListener('mouseover', this.moveStart);
  }
}

export default IconCrossMover;
