import { forwardRef } from 'react';
import { withAssetPrefix } from '@/HOC';

const StaticVideo = forwardRef((props, ref) => {
  return <video ref={ref} {...props} />;
});

StaticVideo.displayName = 'StaticVideo';

export default withAssetPrefix(StaticVideo);
