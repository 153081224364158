import Router from 'next/router';
import Cookies from 'universal-cookie';

const isServer = typeof window === 'undefined';

const routingServerAndClientSide = ({ res, path }) => {
  if (isServer) {
    res.writeHead(307, { Location: path });
    res.end();
  } else {
    Router.replace(path);
  }
};

const getCookie = ({ req, name }) => {
  const cookieProps = isServer ? req?.headers?.cookie : undefined;
  const cookies = new Cookies(cookieProps);
  return cookies.get(name);
};

const setCookie = ({ res, name, value }) => {
  if (isServer) {
    if (!!value) {
      res.setHeader('Set-Cookie', `${name}=${value}; path=/; domain=.nexon.com;`);
    } else res.setHeader('Set-Cookie', `${name}=; path=/; domain=.nexon.com;`);
  } else {
    const cookies = new Cookies();
    if (!!value) cookies.set(name, value, { path: '/', domain: '.nexon.com' });
    else cookies.remove(name, { path: '/', domain: '.nexon.com' });
  }
};

export { routingServerAndClientSide, getCookie, setCookie, isServer };
