import ServerAPI from '@/apis/server';
import ReservationBook from '@/apis/books/reservation.json';

const getReservations = (payload) => {
  const { pageParam = 0, filter } = payload;
  return ServerAPI.get(ReservationBook.REST, {
    params: { ...filter, page: pageParam },
  });
};

const getReservation = (payload) => {
  const { id } = payload;
  return ServerAPI.get(`${ReservationBook.REST}/${id}`);
};

const createReservation = (payload) => {
  const { form } = payload;
  return ServerAPI.post(ReservationBook.REST, form);
};

const createReservationOrder = (payload) => {
  const { form } = payload;
  return ServerAPI.post(`${ReservationBook.REST}/orders`, form);
};

const updateReservation = (payload) => {
  const { form } = payload;
  const { id } = form;
  return ServerAPI.put(`${ReservationBook.REST}/${id}`, form);
};

const updateStatusByCancel = (payload) => {
  const { id, reservationType } = payload;
  return ServerAPI.patch(`${ReservationBook.REST}/${id}/cancel`, {
    reservationType,
    reservationStatus: 'CANCEL',
  });
};

const getLimitSeat = () => {
  return ServerAPI.get(`${ReservationBook.LIMIT_SEAT_COUNT}`);
};

const cancelPayment = (payload) => {
  const { id } = payload;
  return ServerAPI.put(`${ReservationBook.REST}/${id}/refund`);
};

export { getReservations, getReservation, createReservation, createReservationOrder, updateReservation, updateStatusByCancel, getLimitSeat, cancelPayment };
