import useChangeViewportScaleHook from '@/hooks/useChangeViewportScale.hook';
import Header from '@/layouts/Header';
import Footer from '@/layouts/Footer';
import { LandscapeBlocker } from '@/layouts/LandscapeBlocker';
import LayoutStyle from '@/layouts/Layout.module.scss';
import { cx } from '@emotion/css';
import { useHideBanner } from '@/hooks';

const DefaultLayout = (props) => {
  const { children } = props;
  const { hideBanner } = useHideBanner();
  useChangeViewportScaleHook();

  return (
    <>
      <article className={LayoutStyle.layout}>
        <Header className={LayoutStyle.header} hideBanner={hideBanner} />
        <main className={cx(LayoutStyle.main, hideBanner && 'hideBanner')}>{children}</main>
        <Footer className={LayoutStyle.footer} />
      </article>
      <LandscapeBlocker />
    </>
  );
};

export default DefaultLayout;
