/**
 * 배열을 무작위로 섞는다.
 * @param array
 * @returns {Array}
 */
const shuffle = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default shuffle;
