import { useMutation, useQueryClient } from 'react-query';
import { createReservation, createReservationOrder, updateReservation } from '@/apis/requests/reservation';
import { getUser } from '@/apis/requests/login';
import { System } from '@/components';
import { useRouter } from 'next/router';
import ReservationDTO from '@/apis/DTOs/ReservationDTO';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getCookie } from '@/utils';
import { setAuthorizationHeader } from '@/apis/server';
import { VisitorDTO } from '@/apis/DTOs/VIsitorDTO';

const useReservation = (props) => {
  const { reservationType, payRef } = props;

  const [form, setForm] = useState(null);
  const queryClient = useQueryClient();
  const router = useRouter();
  const { id } = router.query;
  const isUpdateForm = !!id;

  const STORAGE_KEY = `${reservationType}Reservation`;
  const PREV_FORM_URL = `/reservation/${reservationType}/schedule/?${isUpdateForm ? `id=${id}` : ''}`;

  useEffect(() => {
    const getForm = sessionStorage.getItem(STORAGE_KEY);
    if (!getForm) {
      router.push(PREV_FORM_URL);
      return;
    }
    setForm(JSON.parse(getForm));
  }, [STORAGE_KEY, PREV_FORM_URL, router]);

  const updateReservationMutation = useMutation(updateReservation, {
    onSuccess: async (response, variables) => {
      const { data, code } = response.data;
      switch (code) {
        case 0:
          await handlingADConsent();
          await System.alert({
            title: '예약 수정',
            text: '예약 수정이 완료되었습니다.',
          });
          // 스토리지 초기화
          sessionStorage.removeItem(STORAGE_KEY);
          return router.push(`/reservation/list`);
        default:
          return reservationErrorHandler(response, variables);
      }
    },
  });

  const createReservationMutation = useMutation(createReservation, {
    onSuccess: async (response, variables) => {
      const { data, code } = response.data;
      switch (code) {
        case 0:
          const { reservationId } = data;
          await handlingADConsent();
          return router.push(`/reservation/${reservationType}/complete?id=${reservationId}`);
        default:
          return reservationErrorHandler(response, variables);
      }
    },
  });

  const createReservationOrderMutation = useMutation(createReservationOrder, {
    onSuccess: async (response, variables) => {
      const { data, code } = response.data;
      switch (code) {
        case 0:
          await handlingADConsent();
          return await payment({ data, form: variables.form });
        default:
          return reservationErrorHandler(response, variables);
      }
    },
  });

  const checkLogin = async () => {
    const accessToken = getCookie({ name: 'accessToken' });
    setAuthorizationHeader(accessToken);
    const responseUser = await getUser();
    if (responseUser.data.code !== 0) {
      await router.push(`/login?redirectURL=${router.asPath}`);
      return false;
    }
    return true;
  };

  const onChange = ({ objectName, event, setForm }) => {
    const { name, value, pattern, checked, type } = event.target;
    if (pattern && value) {
      const regex = new RegExp(pattern);
      const test = regex.test(value);
      if (!test) return;
    }
    setForm((prevState) => ({
      ...prevState,
      [objectName]: {
        ...prevState[objectName],
        [name]: type === 'checkbox' ? (checked ? 'Y' : 'N') : value,
      },
    }));
  };

  const onSubmit = async (form) => {
    // 쿠폰 정보 초기화
    const newForm = { ...form, couponDescription: null, couponInfo: {} };
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newForm));
    const isLogin = await checkLogin();
    if (!isLogin) return;

    if (queryClient.isMutating()) return;

    const parseForm = ReservationDTO.parseForm(form);
    setForm(parseForm);
    const requestPayload = { form: parseForm };
    if (isUpdateForm) {
      // 예약 수정
      await updateReservationMutation.mutate(requestPayload);
    } else if (payRef && !!form.order.paymentMethod) {
      // 예약 생성 (결제 O)
      await createReservationOrderMutation.mutate(requestPayload);
    } else {
      // 예약 생성 (결제 X)
      await createReservationMutation.mutate(requestPayload);
    }
    return true;
  };

  const payment = async ({ data, form }) => {
    const orderForm = VisitorDTO.getOrderForm({ data, form });
    payRef.current.pay({ ...orderForm });
  };

  const returnToPrevStep = (form) => {
    // 쿠폰 정보 초기화
    const newForm = { ...form, couponDescription: null, couponInfo: {} };
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newForm));
    return router.push(PREV_FORM_URL);
  };

  const handlingNoShow = async (data, form) => {
    let { expiredDate, reservationDate } = data;
    expiredDate = moment(expiredDate).format('YYYY년 MM월 DD일');
    reservationDate = moment(reservationDate).format('YYYY년 MM월 DD일');
    const ChildContent = () => {
      const highlightStyle = { color: '#fd3168' };
      return (
        <p>
          <b>{form.requester.name}</b>님께서는 <b>{reservationDate}</b>에<br /> 박물관 관람을 예약하신 후에 별도의 취소 절차 없이 방문하지 않으셨기에
          <br />
          <b style={highlightStyle}>{expiredDate}</b>까지 예약이 제한됩니다.
          <br />더 편안한 예약 및 관람 환경을 조성하기 위함으로 양해 부탁 드립니다.
        </p>
      );
    };
    await System.alert({
      title: '예약 불가',
      modalContainerStyle: { maxWidth: '500px' },
      ChildContent,
    });
  };

  const handlingADConsent = async () => {
    const { privacyCollectionAgreement, eventInfoReceiveAgreement } = form.agreement;
    if (privacyCollectionAgreement !== 'Y' || eventInfoReceiveAgreement !== 'Y') return;
    const today = moment().format('YYYY년 MM월 DD일');
    return System.alert({
      title: '안내',
      text: `[넥슨컴퓨터박물관] ${today}\n이벤트 수신 동의가 처리되었습니다.`,
    });
  };

  const reservationErrorHandler = async (response, variables) => {
    const { data, code } = response.data;
    const { form } = variables;
    switch (code) {
      case -1108:
        await handlingNoShow(data, form);
        return router.push(PREV_FORM_URL);
      case -1105:
        await System.alert({
          title: '오류',
          text: '예약 정원 또는 프로그램 정원이 초과되었습니다.',
        });
        return router.push(PREV_FORM_URL);
      default:
        await System.alert({
          title: '오류',
          text: '서비스 이용 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
        });
        return router.push(PREV_FORM_URL);
    }
  };

  return {
    form,
    isUpdateForm,
    onChange,
    returnToPrevStep,
    onSubmit,
  };
};

const RESERVATION_TYPE = {
  PERSONAL: 'personal',
  GROUP: 'group',
};

export default useReservation;
export { RESERVATION_TYPE };
