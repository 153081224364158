import { forwardRef, useImperativeHandle } from 'react';
import getConfig from 'next/config';

const PaymentMobile = forwardRef((props, ref) => {
  const formName = 'pay-form';
  const idPrefix = 'mo';
  const { publicRuntimeConfig } = getConfig();
  useImperativeHandle(ref, () => ({
    pay: (payload) => {
      Object.entries(payload).forEach(([id, value]) => {
        const element = document.getElementById(`${idPrefix}_${id}`);
        if (element) {
          element.value = value;
        }
      });
      const form = document[formName];
      form.action = `${publicRuntimeConfig.inicisMobileBaseURL}/smart/payment`;
      form.target = '_self';
      form.submit();
    },
  }));

  return (
    <form name={formName} method="post" acceptCharset="euc-kr">
      <input type={'hidden'} name={'P_INI_PAYMENT'} id={`${idPrefix}_paymentMethod`} value={'CARD'} />
      <input type={'hidden'} name={'P_MID'} id={`${idPrefix}_marketId`} />
      <input type={'hidden'} name={'P_OID'} id={`${idPrefix}_orderId`} />
      <input type={'hidden'} name={'P_AMT'} id={`${idPrefix}_price`} />
      <input type={'hidden'} name={'P_GOODS'} id={`${idPrefix}_goodsName`} />
      <input type={'hidden'} name={'P_UNAME'} id={`${idPrefix}_buyerName`} />
      <input type={'hidden'} name={'P_MOBILE'} id={`${idPrefix}_buyerTel`} />
      <input type={'hidden'} name={'P_EMAIL'} id={`${idPrefix}_buyerEmail`} />
      <input type={'hidden'} name={'P_HPP_METHOD'} id={`${idPrefix}_hpPaymentMethod`} value={'2'} />
      <input type={'hidden'} name={'P_NEXT_URL'} id={`${idPrefix}_returnUrl`} />
      <input type={'hidden'} name={'P_RESERVED'} value={'below1000=Y'} />
    </form>
  );
});
PaymentMobile.displayName = 'PaymentMobile';
export default PaymentMobile;
