import styles from './index.module.scss';
import Stickers from './json';
import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import lottie from 'lottie-web';
import { useEffect, useRef } from 'react';
import { StaticImage } from '@/components';
import { COURSE_TYPES } from '@/constants';

const RouteSticker = ({ type, className = '', width, height, isSelected = false, isStopped = false, onLoad, ...otherProps }) => {
  const element = useRef();
  const lottieInstance = useRef();

  const animationData = Stickers[type];

  useEffect(() => {
    if (!element.current) return;
    lottieInstance.current = lottie.loadAnimation({
      loop: true,
      autoplay: false,
      renderer: 'svg',
      rendererSettings: {
        progressiveLoad: true,
      },
      animationData,
      container: element.current,
    });
    return () => lottieInstance.current.destroy();
  }, [animationData]);

  useEffect(() => {
    if (!lottieInstance.current) return;
    if (isStopped) {
      lottieInstance.current.goToAndStop(65, true);
    } else {
      lottieInstance.current.goToAndPlay(0, true);
    }
  }, [isStopped]);

  const style = () => {
    return {
      ...(width && { width: `${width}px` }),
      ...(height && { height: `${height}px` }),
    };
  };

  return (
    <div className={cx(className, styles.stickerContainer, isSelected && styles.selected, !isStopped && styles.play)}>
      <StaticImage className={styles.stickerImage} src={`/static/images/flow/stickers/${type}.svg`} width={'100%'} height={'100%'} onLoad={onLoad} />
      <div ref={element} style={style()} className={cx(styles.container)} {...otherProps} />
    </div>
  );
};

RouteSticker.propTypes = {
  type: PropTypes.oneOf(COURSE_TYPES).isRequired,
  className: PropTypes.string,
  isStopped: PropTypes.bool,
};

export default RouteSticker;
