import { Program } from '@/utils';
import { cx } from '@emotion/css';
import { CountButton, Dropdown, FileImage } from '@/components';
import styles from './ProgramSelect.module.scss';

const programDescriptionClass = {
  programTime: styles.time,
  place: styles.location,
  cost: styles.amount,
  experienceDevice: styles.game,
  audioGuide: styles.audio,
};
const getTimeDataOfProgram = (program) => {
  const { times, timeID } = program;
  return times.find((time) => time.id === timeID) || {};
};

const ProgramSelect = ({ form, setForm }) => {
  const { reservationTime } = form.schedule;
  const onChangeProgram = (event, index) => {
    const { name, value } = event.target;
    const newValue = name === 'visitCount' ? Number(event.target.getAttribute('value')) : value;
    const isTimeChanged = name === 'timeID';
    let newPrograms = [...form.programs];
    newPrograms[index][name] = newValue;
    if (isTimeChanged) {
      newPrograms[index].visitCount = !!value ? 1 : 0;
    }
    setForm((prevState) => ({
      ...prevState,
      programs: newPrograms,
    }));
  };

  return (
    <>
      <div className={styles.programWrapper}>
        <div id={'dropdown-container'} className={cx(styles.programContainer, 'scroll')}>
          <ul className={styles.programList}>
            {form.programs.map((program, index) => {
              const isFree = program.cost === 0;
              const { isClosed, openAllTimes } = Program.getReservationStatus(program);
              return (
                <li key={program.programID} className={styles.programItem}>
                  <div className={cx(styles.programImageWrapper, isClosed && styles.closed, isClosed && isFree && styles.closedFree)}>
                    {program.imageURL && <FileImage className={styles.programImage} src={program.imageURL} width="125" height="125" alt="program image" />}
                  </div>
                  <div className={styles.programDescription}>
                    <h3 className={styles.programName}>{program.programName}</h3>
                    <div className={styles.programContent}>{program.content}</div>
                    <ul className={styles.programInfoList}>
                      {Object.entries(programDescriptionClass).map(([infoName, className]) => {
                        const isNotNull = program[infoName] !== null && program[infoName] !== undefined;
                        const isFree = infoName === 'cost' && program[infoName] === 0;
                        return (
                          isNotNull && (
                            <li key={infoName} className={cx(styles.programInfoItem, className)}>
                              {isFree ? '무료' : program[infoName]}
                            </li>
                          )
                        );
                      })}
                    </ul>
                  </div>
                  {!openAllTimes && !isClosed && (
                    <div className={styles.programAdmission}>
                      <div className={styles.title}>프로그램 신청</div>
                      <div className={styles.programAdmissionSelect}>
                        <div className={styles.dropdownWrapper}>
                          <Dropdown
                            height={40}
                            name={'timeID'}
                            defaultValue={'선택'}
                            selected={program.timeID}
                            optionList={
                              program.times.map((time) => ({
                                value: time.id,
                                name: `${Program.hhmmssTohhmm(time.programTime)}
                                (${time.remainingSeat}명)`,
                                disabled: time.programTime < reservationTime || time.remainingSeat === 0,
                              })) || []
                            }
                            onChange={(event) => onChangeProgram(event, index)}
                          />
                        </div>
                        <div className={styles.countButtonContainer}>
                          <span className={styles.countButtonWrapper}>
                            <CountButton
                              name={'visitCount'}
                              buttonType={'minus'}
                              value={Number(program.visitCount) - 1}
                              onClick={(event) => onChangeProgram(event, index)}
                              disabled={!program.timeID || program.visitCount <= 0}
                            />
                          </span>
                          <span>{program.visitCount} 명</span>
                          <span className={styles.countButtonWrapper}>
                            <CountButton
                              name={'visitCount'}
                              buttonType={'plus'}
                              value={Number(program.visitCount) + 1}
                              onClick={(event) => onChangeProgram(event, index)}
                              disabled={!program.timeID || Math.min(getTimeDataOfProgram(program).remainingSeat, form.visitorCount) <= program.visitCount}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
export default ProgramSelect;
