import { useEffect, useState } from 'react';

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

const usePagination = (props) => {
  const { page, onChange, count, interval = 5 } = props;
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const currentLevel = Math.floor((currentPage - 1) / interval);
  const currentLevelMaxPage = Math.min(count, (currentLevel + 1) * interval);
  const pageMaker = range(currentLevel * interval + 1, currentLevelMaxPage);

  const handleChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
    onChange(event, pageNumber);
  };

  const pageChecker = currentPage % interval === 0 ? interval : currentPage % interval;
  const disabledPrev = currentPage <= interval;
  const disabledNext = currentPage - pageChecker + interval >= count;
  const hideArrow = disabledPrev && disabledNext;
  const disabledFirst = currentPage <= 1;
  const disabledLast = currentPage >= count;

  const onPrev = {
    disabled: disabledPrev,
    hide: hideArrow,
    onClick: (event) => {
      const pageChecker = currentPage % interval === 0 ? interval : currentPage % interval;
      const prevPage = currentPage - pageChecker;
      if (prevPage <= 0) {
        return;
      }
      handleChange(event, prevPage);
    },
  };

  const onNext = {
    disabled: disabledNext,
    hide: hideArrow,
    onClick: (event) => {
      const pageChecker = currentPage % interval === 0 ? interval : currentPage % interval;
      const nextPage = currentPage - pageChecker + interval + 1;
      if (nextPage > count) {
        return;
      }
      handleChange(event, nextPage);
    },
  };

  const onFirst = {
    disabled: disabledFirst,
    onClick: (event) => {
      handleChange(event, 1);
    },
  };

  const onLast = {
    disabled: disabledLast,
    onClick: (event) => {
      handleChange(event, count);
    },
  };

  const pages = pageMaker.map((pageNumber) => {
    return {
      number: pageNumber,
      onClick: (event) => handleChange(event, pageNumber),
      selected: currentPage === pageNumber,
    };
  });

  return {
    onPrev,
    onNext,
    onFirst,
    onLast,
    pages,
  };
};

export { usePagination };
