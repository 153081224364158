import { getCookie, setCookie } from '@/utils';
import { postLogout } from '@/apis/requests/login';

const authReducer = async (state, action) => {
  switch (action.type) {
    case 'LOGOUT': {
      await postLogout();
      setCookie({ name: 'accessToken' });
      return {
        auth: {
          isLoggedIn: true,
          isNotLoggedIn: false,
          data: {},
        },
      };
    }
    default:
      return {
        auth: {
          isLoggedIn: false,
          isNotLoggedIn: true,
          ...state.data,
        },
      };
  }
};

export default authReducer;
