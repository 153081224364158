import { PaymentMethods } from '@/apis/DTOs/commons';
import moment from 'moment';

const EDUCATION_RESERVATION_STATUSES = {
  WAIT: {
    name: '대기 접수',
    value: 'WAIT',
  },
  // 임의로 만들어낸 상태값
  PAYMENT: {
    name: '대기 접수',
    value: 'PAYMENT',
  },
  COMPLETE: {
    name: '예약 완료',
    value: 'COMPLETE',
  },
  CANCEL: {
    name: '예약 취소',
    value: 'CANCEL',
  },
  VISIT: {
    name: '참여 완료',
    value: 'VISIT',
  },
  VISIT_CANCEL: {
    name: '참여 취소',
    value: 'VISIT_CANCEL',
  },
};

class EducationReservationDTO {
  reservationId = null;
  reservationType = 'NORMAL'; // 프론트 예약은 NORMAL / 백오피스는 DAY 타입
  reservationStatus = '';
  programName = '';
  optionName = '';
  term = '';
  programId = '';
  optionId = '';
  visitorCount = 0;
  name = '';
  phone = '';
  email = '';
  requirements = '';
  privacyCollectionAgreement = '';
  overFourteenYearsOld = '';
  couponDescription = '';
  receivedDate = ''; // 대기접수일
  confirmedDate = ''; // 결제일
  transactionId = '';
  transactionDateTime = '';
  paymentMethod = '';
  paymentNotifyDate = '';
  transactionPrice = 0;
  programPrice = 0;
  discountPrice = 0;
  refund = {};

  constructor(props = {}) {
    const {
      reservationId,
      reservationType,
      reservationStatus,
      programName,
      optionName,
      term,
      programId,
      optionId,
      visitorCount,
      name,
      phone,
      email,
      requirements,
      privacyCollectionAgreement,
      overFourteenYearsOld,
      couponDescription,
      receivedDate,
      confirmedDate,
      transactionId,
      transactionDateTime,
      paymentMethod,
      paymentNotifyDate,
      transactionPrice,
      programPrice,
      discountPrice,
      refund,
    } = props;
    this.reservationId = reservationId;
    this.reservationType = reservationType;
    this.reservationStatus = reservationStatus === 'WAIT' && !!paymentNotifyDate ? 'PAYMENT' : reservationStatus;
    this.optionName = optionName;
    this.programPrice = programPrice;
    this.term = term;
    this.programId = programId;
    this.optionId = optionId;
    this.visitorCount = visitorCount;
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.requirements = requirements;
    this.privacyCollectionAgreement = privacyCollectionAgreement;
    this.overFourteenYearsOld = overFourteenYearsOld;
    this.couponDescription = couponDescription;
    this.receivedDate = receivedDate;
    this.confirmedDate = confirmedDate;
    this.transactionId = transactionId;
    this.transactionDateTime = transactionDateTime;
    this.paymentMethod = paymentMethod;
    this.paymentNotifyDate = paymentNotifyDate;
    this.transactionPrice = transactionPrice;
    this.programName = programName;
    this.discountPrice = discountPrice;
    this.refund = refund || null;
  }

  get educationReservationStatusName() {
    const status = EDUCATION_RESERVATION_STATUSES[this.reservationStatus];
    if (status.value === 'CANCEL' && this.receivedDate) return '대기 접수 취소';
    return status?.name;
  }

  get paymentMethodName() {
    if (!this.transactionId) return;
    return PaymentMethods[this.paymentMethod.toUpperCase()];
  }

  get paymentDate() {
    if (!this.transactionDateTime) return;
    return moment(this.transactionDateTime).format('YYYY-MM-DD HH:mm');
  }

  get confirmDate() {
    if (!this.confirmedDate) return;
    return moment(this.confirmedDate).format('YYYY-MM-DD');
  }

  get paymentAvailableDate() {
    if (!this.paymentNotifyDate) return;
    return moment(this.paymentNotifyDate).add(1, 'day');
  }

  get isPaymentAvailable() {
    if (!this.paymentAvailableDate) return;
    return moment().isSameOrBefore(this.paymentAvailableDate);
  }

  // 교육프로그램 수정 폼
  get settingForm() {
    const [emailID, emailDomain] = this.email.split('@');
    return {
      reservationId: this.reservationId,
      reservationType: this.reservationType,
      reservationStatus: this.reservationStatus,
      optionId: this.optionId,
      visitorCount: this.visitorCount,
      name: this.name,
      phone: this.phone,
      emailID: emailID,
      emailDomain: emailDomain,
      requirements: this.requirements,
      privacyCollectionAgreement: this.privacyCollectionAgreement || 'N',
      overFourteenYearsOld: this.overFourteenYearsOld || 'N',
      couponDescription: this.couponDescription,
    };
  }

  // 교육프로그램 리스트 초기 필터
  static initialFilter() {
    return {
      page: 1,
      size: 6,
      direction: 'DESC',
    };
  }

  // 초기 교육프로그램 폼
  static initialForm() {
    return {
      reservationType: 'NORMAL',
      optionId: '',
      visitorCount: 1,
      name: '',
      phone: '',
      emailID: '',
      emailDomain: '',
      requirements: '',
      privacyCollectionAgreement: 'N',
      overFourteenYearsOld: 'N',
      couponDescription: '',
    };
  }

  // 교육 프로그램 제출 폼 변환
  static parseForm(settingForm) {
    const { emailID, emailDomain } = settingForm;
    return {
      optionId: settingForm.optionId || null,
      visitorCount: settingForm.visitorCount || null,
      name: settingForm.name || null,
      phone: settingForm.phone || null,
      email: !!emailID && !!emailDomain ? emailID + '@' + emailDomain : null,
      requirements: settingForm.requirements,
      privacyCollectionAgreement: settingForm.privacyCollectionAgreement,
      overFourteenYearsOld: settingForm.overFourteenYearsOld,
    };
  }

  static orderInformation(settingForm) {
    return {
      couponDescription: settingForm.couponDescription || null,
    };
  }
}

export default EducationReservationDTO;
export { EDUCATION_RESERVATION_STATUSES };
