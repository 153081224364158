import styles from './index.module.scss';
import { CountButton, System } from '@/components';
import { formatPrice } from '@/utils';

const ticketCategories = [
  {
    title: '일반',
    begin: 0,
    end: 4,
  },
  {
    title: '일반할인',
    begin: 4,
    end: 11,
  },
  {
    title: '도민할인',
    begin: 11,
    end: 15,
  },
];
const AdmissionSelect = ({ form, setForm, maxSeat }) => {
  const onChangeNumber = async ({ event, value }) => {
    const { name } = event.target;
    if (form.visitorCount + value > maxSeat) {
      return System.alert({
        title: '예약 안내',
        text: `예약은 본인 포함 ${maxSeat}명까지 가능합니다.`,
      });
    }
    const tickets = [...form.admissions];
    let reservationTicket = tickets.find((ticket) => ticket.code === Number(name));
    if (!reservationTicket) return;
    reservationTicket.count += value;
    const visitorSum = tickets.reduce((acc, cur) => acc + cur.count, 0);
    setForm((prevState) => ({
      ...prevState,
      schedule: {
        ...prevState.schedule,
        reservationTime: '',
      },
      admissions: [...tickets],
      visitorCount: visitorSum,
      programs: prevState.programs.map((program) => ({
        ...program,
        visitCount: 0,
      })),
    }));
  };

  return (
    <>
      <div className={styles.container}>
        {ticketCategories.map(({ title, begin, end }) => (
          <div key={title} className={styles.categoryContainer}>
            <h3 className={styles.categoryTitle}>{title}</h3>
            <ul className={styles.categoryList}>
              {form.admissions.slice(begin, end).map(({ code, name, price, count }) => {
                const [title, bracket] = name.split(/\((.*)\)/);
                return (
                  <li key={code} className={styles.ticketContainer}>
                    <span className={styles.ticketTitle}>
                      {title}
                      {bracket && <span className={styles.bracket}> ({bracket})</span>}
                    </span>
                    <span className={styles.ticketCounter}>
                      <div className={styles.price}>{formatPrice(price)}</div>
                      <div className={styles.countButtonWrapper}>
                        <CountButton buttonType={'minus'} name={code} onClick={(event) => onChangeNumber({ event, value: -1 })} disabled={count <= 0} />
                      </div>
                      <div className={styles.countNumber}>{count}</div>
                      <div className={styles.countButtonWrapper}>
                        <CountButton buttonType={'plus'} name={code} onClick={(event) => onChangeNumber({ event, value: 1 })} />
                      </div>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </div>
      <div className={styles.cautionContainer}>
        <ul>
          <li>- 할인권 및 초대권 선택 시 증빙 서류 지참 필수</li>
          <li>- 증빙서류 미지참 시 할인적용 불가, 현장에서 재결제</li>
          <li>- ICOM 카드소지자, 답사, 기증자 방문 시 별도 문의바랍니다.</li>
          <li>- 고객센터: 064-745-1994</li>
        </ul>
      </div>
    </>
  );
};
export default AdmissionSelect;
