import { useEffect, useState } from 'react';

const useObserverHook = (props) => {
  const { onIntersect } = props;
  const [target, setTarget] = useState(null);

  const callback = async ([entry], observer) => {
    if (entry.isIntersecting) {
      observer.unobserve(entry.target);
      await onIntersect();
      observer.observe(entry.target);
    }
  };

  useEffect(() => {
    let observer;
    if (target) {
      observer = new IntersectionObserver(callback, {
        threshold: 0.4,
      });
      observer.observe(target);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [target]);
  return { setTarget };
};

export { useObserverHook };
