const COUPON_TYPES = {
  COMMON: 1,
  EMPLOYEE: 2,
};
const DISCOUNT_TYPES = {
  PRICE: 1,
  PERCENT: 2,
};

export { COUPON_TYPES, DISCOUNT_TYPES };
