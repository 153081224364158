import { useEffect, useState } from 'react';

const useDropdownPosition = (ref) => {
  const [isUp, setIsUp] = useState(true);

  useEffect(() => {
    const observerOptions = {
      root: document.querySelector('#dropdown-container'),
      rootMargin: '0px 0px -160px 0px',
    };

    const observer = new IntersectionObserver(([entry]) => {
      const { boundingClientRect, intersectionRect, isIntersecting } = entry;
      if (!isIntersecting) return;
      const isUp = boundingClientRect.bottom > intersectionRect.bottom;
      return setIsUp(isUp);
    }, observerOptions);

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref]);

  return isUp;
};
export default useDropdownPosition;
