import { cx } from '@emotion/css';
import { usePagination } from './Pagination.hooks';

/**
 *
 * @param totalPages - 전체 페이지 개수
 * @param number - 현재 페이지 번호
 * @param onPageChange
 */
const Pagination = ({ totalPages, number, onPageChange }) => {
  const { onPrev, onNext, onFirst, onLast, pages } = usePagination({
    page: number,
    count: totalPages,
    interval: 5,
    onChange: onPageChange,
  });
  return (
    <div className="pagenation">
      <a className="first" disabled={onFirst?.disabled} onClick={onFirst?.onClick}>
        <span className="hidden">처음</span>
      </a>
      <a className="prev" disabled={onPrev?.disabled} onClick={onPrev?.onClick}>
        <span className="hidden">이전</span>
      </a>
      {pages.map((page) => (
        <a className={cx(page.selected && 'current')} key={page.number} onClick={page.onClick}>
          {page.number}
        </a>
      ))}
      <a className="next" disabled={onNext?.disabled} onClick={onNext?.onClick}>
        <span className="hidden">다음</span>
      </a>
      <a className="last" disabled={onLast?.disabled} onClick={onLast?.onClick}>
        <span className="hidden">마지막</span>
      </a>
    </div>
  );
};
export default Pagination;
