import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cx } from '@emotion/css';
import moment from 'moment';

import { AuthContext } from '@/contexts';
import { Animation } from '@/utils';

import HeaderStyle from './Header.module.scss';

export const MAIN_MENUS = [
  {
    order: '01',
    name: 'HOME',
    url: '/',
    open: false,
    outLink: false,
  },
  {
    order: '02',
    name: '박물관 안내',
    open: false,
    outLink: false,
    children: [
      {
        name: '관람 안내',
        url: '/guide',
      },
      {
        name: '이달의 프로그램',
        url: '/monthlyprogram',
      },
    ],
  },
  {
    order: '03',
    name: '카페 메이플스토리',
    open: false,
    outLink: false,
    url: '/news/list?&type=CAFE',
    // children: [
    //   {
    //     name: '개인 예약',
    //     url: '/reservation/personal/schedule',
    //   },
    //   {
    //     name: '단체 예약',
    //     url: '/reservation/group/schedule',
    //   },
    //   {
    //     name: '예약 확인 및 취소',
    //     url: '/reservation/list',
    //   },
    //   {
    //     name: '교육 프로그램 예약',
    //     url: '/education/list',
    //   },
    // ],
  },
  {
    order: '04',
    name: '뉴스',
    open: false,
    outLink: false,
    children: [
      {
        name: '새소식',
        url: '/news/list',
      },
    ],
  },
  {
    order: '05',
    name: '넥컴박피디아',
    open: false,
    url: 'https://forum.nexon.com/ncmpedia',
    outLink: false,
  },
  {
    order: '06',
    name: '기증',
    open: false,
    url: '/donation',
    outLink: false,
  },
];

const AuthLink = (props) => {
  const { onLogout } = props;
  const router = useRouter();
  const authContext = useContext(AuthContext);
  const { authState, dispatchAuth } = authContext;
  const [auth, setAuth] = useState(authState);

  useEffect(() => {
    const { authState } = authContext;

    setAuth(authState);
  }, [authContext]);

  const handleAuth = () => {
    if (!auth.isLoggedIn) {
      return router.push('/login');
    }

    dispatchAuth({ type: 'LOGOUT' });
    onLogout();
    return router.push('/');
  };

  const linkName = auth.isLoggedIn ? 'LOGOUT' : 'LOGIN';

  return (
    <button className={HeaderStyle.authProcessLink} onClick={handleAuth}>
      <span className="linkUnderline">{linkName}</span>
    </button>
  );
};

const Header = (props) => {
  const router = useRouter();
  const containerElement = useRef();
  const menuElement = useRef();
  const { hideBanner = false, className, isBlack = false, isSubPage = false } = props;

  const [mainMenus, setMainMenus] = useState(MAIN_MENUS || []);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const openNav = () => {
    document.body.classList.add('no-scroll');
    setIsNavOpen(true);
  };

  const closeNav = () => {
    document.body.classList.remove('no-scroll');
    setIsNavOpen(false);
  };

  const onClickMenu = (event, menu) => {
    if (menu.url) {
      closeNav();
      if (menu.outLink) {
        return window.open(menu.url, '_blank');
      } else return router.push(menu.url);
    }

    setMainMenus((prevState) => {
      const newState = prevState.map((prevMenu) => {
        const targetMenu = prevMenu === menu;
        return {
          ...prevMenu,
          open: targetMenu ? !menu.open : false,
        };
      });
      return [...newState];
    });
  };

  useEffect(() => {
    if (!isNavOpen) return;

    const props = {
      containerElement: containerElement.current,
      menuLinkElements: menuElement.current.querySelectorAll('li'),
      images: [
        { url: '/static/images/main/e-invite-you/image-poster-gamegame.png', width: 170, height: 237 },
        { url: '/static/images/main/e-invite-you/image-poster-nexoncomputermuseum.png', width: 170, height: 237 },
        { url: '/static/images/main/e-invite-you/image-poster-kotw.png', width: 229, height: 169 },
      ],
    };

    const followUpImage = new Animation.HoverFollowUpImage(props);

    return () => {
      followUpImage.destroy();
      document.body.classList.remove('no-scroll');
    };
  }, [isNavOpen]);

  return (
    <>
      <header className={cx(className, HeaderStyle.header)}>
        {!hideBanner && (
          <div className={HeaderStyle.reservationBannerWrapper}>
            <a href={'/news/list?&type=CAFE'} className={HeaderStyle.reservationBannerContainer}>
              <span className={HeaderStyle.reservationBanner}>
                <span className="hidden">Cafe Maplestory</span>
              </span>
            </a>
          </div>
        )}
        <div className={cx(HeaderStyle.headerContents, isBlack && HeaderStyle.black, isSubPage && HeaderStyle.subPage)}>
          <h1 className={HeaderStyle.appTitle}>
            <a id={'mainLink'} href={'/'} className={HeaderStyle.mainLink} />
          </h1>
          <div className={HeaderStyle.navigationWrapper}>
            <button id={'mainNavigationButton'} onClick={openNav} className={HeaderStyle.navigationOpenButton} />
          </div>
        </div>
      </header>
      {isNavOpen && (
        <section className={cx(HeaderStyle.navigationContainer, !isNavOpen && HeaderStyle.hide, !!hideBanner && HeaderStyle.hideBanner)}>
          <nav ref={containerElement} className={HeaderStyle.navigation}>
            <ul className={HeaderStyle.mainMenuList}>
              {mainMenus.map((menu) => {
                const activatedClassName = menu.open && HeaderStyle.activate;
                const menuListItemClassName = cx(activatedClassName);
                const hasChildren = Boolean(menu.children);
                return (
                  <li key={menu.order} className={menuListItemClassName}>
                    <a className={HeaderStyle.mainMenuLink} onClick={(event) => onClickMenu(event, menu)}>
                      <span className={HeaderStyle.mainMenuLinkOrderWrapper}>
                        <span className={HeaderStyle.menuOverflowWrapper}>
                          <sup className={HeaderStyle.mainMenuSup}>{menu.order}</sup>
                        </span>
                      </span>
                      <span className={HeaderStyle.menuOverflowWrapper}>
                        <span className={HeaderStyle.mainMenuTitle}>{menu.name}</span>
                      </span>
                      {hasChildren && <i className={HeaderStyle.iconMainMenuHasChildren} />}
                      {menu.outLink && <i className={HeaderStyle.iconMainMenuOutLink} />}
                    </a>
                    {hasChildren && (
                      <ul className={HeaderStyle.mainMenuSubList}>
                        {menu.children.map((menuChild) => {
                          return (
                            <li key={menuChild.name}>
                              <Link href={menuChild.url} passHref>
                                <span className={HeaderStyle.menuOverflowWrapper}>
                                  <span className={HeaderStyle.mainMenuSubLink}>{menuChild.name}</span>
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
            <ul ref={menuElement} className={HeaderStyle.subMenuList}>
              <li>
                <a className={HeaderStyle.subMenuTitle} href="https://www.gameagame.org/" rel="noreferrer" target="_blank">
                  게임을 게임하다 /invite you_
                  <i className={HeaderStyle.iconOutLink} />
                </a>
              </li>
              <li>
                <a className={HeaderStyle.subMenuTitle} href="https://www.ncm360.org" rel="noreferrer" target="_blank">
                  360 Virtual Museum
                  <i className={HeaderStyle.iconOutLink} />
                </a>
              </li>
              <li>
                <a className={HeaderStyle.subMenuTitle} href="https://computermuseum.nexon.com/news/detail?id=312" rel="noreferrer" target="_blank">
                  바람의나라 1996
                </a>
              </li>
            </ul>
            <section>{/* <AuthLink onLogout={closeNav} /> */}</section>
          </nav>
          <div>
            <button onClick={closeNav} className={HeaderStyle.menuCloseButton}>
              <i className={HeaderStyle.iconMenuCloseButton}>
                <span className="hidden">닫기</span>
              </i>
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default Header;
