import { cx } from '@emotion/css';

import PlayListTitle from '@/components/main/sections/c.PlayList/PlayListTitle/PlayListTitle';
import PlayListStyle from './c.PlayList.module.scss';
import { PlayListRoute } from '@/components/main/sections/c.PlayList/PlayListRoute';
import { PlayListSticker } from '@/components/main/sections/c.PlayList/PlayListSticker';
import { useEffect, useRef, useState } from 'react';
import PlayListNavigationBar from '@/components/main/sections/c.PlayList/PlayListNavigationBar';
import { useIsPCOrMobile } from '@/hooks';
import { getNumberBetween } from '@/utils';

const FADE_IN_TERM = 600;

const FADE_REFS = {
  BACKGROUND: 0,
  TITLE: 1,
  ROUTE: 2,
  NAVIGATION: 3,
};

const CPlayList = (props) => {
  const { id, className } = props;
  const [playListHeight, setPlayListHeight] = useState();
  const [scrollWidth, setScrollWidth] = useState();
  const [scrollLeft, setScrollLeft] = useState();
  const [focusButton, setFocusButton] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const playListRef = useRef();
  const playListSticker = useRef();

  const fadeInRefs = useRef([]);

  const { isPC } = useIsPCOrMobile({});

  useEffect(() => {
    const sectionElement = playListRef.current;
    const fadeInElements = fadeInRefs.current;
    const fadeInElementsLength = fadeInElements.length;
    const buttonElement = fadeInElements[FADE_REFS.ROUTE];

    const term = FADE_IN_TERM / fadeInElementsLength;
    const breakPoints = fadeInElements.map((element, index) => {
      const startPoint = sectionElement.offsetTop - (FADE_IN_TERM - term * index);
      return {
        startPoint,
        endPoint: startPoint + term,
        prevOpacity: null,
      };
    });

    const changeOpacity = () => {
      breakPoints.forEach((breakPoint, index) => {
        const { startPoint, endPoint, prevOpacity } = breakPoint;
        const opacity = (window.scrollY - startPoint) / term;
        const recalculatedOpacity = getNumberBetween(opacity, 0, 1);
        if (prevOpacity === recalculatedOpacity) return;
        breakPoints[index].prevOpacity = recalculatedOpacity;
        if (!fadeInElements[index]) return;

        if (index === 0 && recalculatedOpacity <= 0) {
          document.body.classList.remove('on-is-navigation-white');
        } else {
          document.body.classList.add('on-is-navigation-white');
        }

        if (window.scrollY < startPoint) {
          fadeInElements[index].style.opacity = recalculatedOpacity;
          sectionElement.style.pointerEvents = 'none';
        } else if (window.scrollY > endPoint) {
          fadeInElements[index].style.opacity = recalculatedOpacity;
        }

        if (index === FADE_REFS.ROUTE) {
          if (!buttonElement) return;
          if (recalculatedOpacity < 0.1) {
            buttonElement.style.pointerEvents = 'none';
          } else {
            buttonElement.style.pointerEvents = 'all';
          }
        }

        fadeInElements[index].style.opacity = recalculatedOpacity;
      });
    };

    changeOpacity();
    window.addEventListener('scroll', changeOpacity);
    return () => window.removeEventListener('scroll', changeOpacity);
  }, [playListHeight]);

  useEffect(() => {
    if (isPC && !playListSticker.current) return;
    const { stickerContainerRef, scrollRef } = playListSticker.current;
    const stickerContainerElement = stickerContainerRef.current;
    const scrollElement = scrollRef.current;
    setScrollWidth(scrollElement.scrollWidth);

    const scrollStickers = () => {
      const sectionElement = playListRef.current;
      if (!sectionElement) return;
      const clientRect = sectionElement.getBoundingClientRect();
      const { top } = clientRect;

      if (isPC) {
        scrollElement.scrollTo({ left: -top });
        setScrollLeft(scrollElement.scrollLeft);
      }
    };

    const setHeight = () => {
      if (isPC) {
        setPlayListHeight(stickerContainerElement.clientWidth);
        setScrollWidth(scrollElement.scrollWidth);
      } else {
        setPlayListHeight(stickerContainerElement.clientHeight * 2);
      }
      scrollStickers();
    };

    setHeight();
    window.addEventListener('scroll', scrollStickers);
    window.addEventListener('resize', setHeight);

    return () => {
      window.removeEventListener('scroll', scrollStickers);
      window.removeEventListener('resize', setHeight);
    };
  }, [isPC, isLoad]);

  const onLoad = () => {
    setIsLoad(true);
  };

  const addFadeInRefs = (index, element) => {
    fadeInRefs.current[index] = element;
  };

  return (
    <section
      ref={(element) => {
        playListRef.current = element;
        addFadeInRefs(FADE_REFS.BACKGROUND, element);
      }}
      className={cx(className, PlayListStyle.playlist)}
      style={{ height: playListHeight }}
    >
      <div className={PlayListStyle.anchor} id={id} />
      <div className={PlayListStyle.wrapper}>
        <div className={PlayListStyle.container}>
          <PlayListTitle ref={(element) => addFadeInRefs(FADE_REFS.TITLE, element)} className={PlayListStyle.playListTitle} focusButton={focusButton} />
          <PlayListRoute ref={(element) => addFadeInRefs(FADE_REFS.ROUTE, element)} className={PlayListStyle.playListRoute} setFocusButton={setFocusButton} />
        </div>
        <PlayListSticker ref={playListSticker} className={PlayListStyle.playListSticker} />
        {isPC && <PlayListNavigationBar ref={(element) => addFadeInRefs(FADE_REFS.NAVIGATION, element)} scrollWidth={scrollWidth} scrollLeft={scrollLeft} />}
      </div>
      <PlayListSticker className={PlayListStyle.playListStickerMobile} style={{ top: `-${playListHeight * 0.25}px` }} onLoad={() => onLoad()} />
    </section>
  );
};

export default CPlayList;
