import Styled from './index.styled';
import styled from '@emotion/styled';

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = (props) => {
  const { name, value, subTitle, mainTitle, isSelected, onClick } = props;
  return (
    <Styled.Tab name={name} value={value} isSelected={isSelected} onClick={onClick}>
      <Styled.SubTabTitle>{subTitle}</Styled.SubTabTitle>
      <Styled.TabTitle>{mainTitle}</Styled.TabTitle>
    </Styled.Tab>
  );
};

export { Tabs, Tab };
