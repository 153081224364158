import MainImageStyle from '@/components/main/sections/a.MainSection/MainImage/MainImage.module.scss';
import { cx } from '@emotion/css';
import { useEffect, useRef } from 'react';
import { changeCursor } from '@/utils/main/changeCursor';
import Link from 'next/link';

const IconSmile = (props) => {
  const { size = 'MOBILE', link = '/news/list?&type=CAFE' } = props;
  const cursorTriggerElement = useRef();
  const cursorElement = useRef();

  useEffect(() => {
    const isPCSize = size === 'PC';
    if (!isPCSize) return;

    const cursor = changeCursor(cursorTriggerElement.current, cursorElement.current, { center: false });

    return () => cursor.destroy();
  }, [size]);

  const styleSet = {
    PC: {
      iconSmileContainer: MainImageStyle.iconSmileContainer1000,
      iconSmilePointer: MainImageStyle.smilePointer1000,
    },
    TABLET: {
      iconSmileContainer: MainImageStyle.iconSmileContainer768,
      iconSmilePointer: MainImageStyle.smilePointer768,
    },
    MOBILE: {
      iconSmileContainer: MainImageStyle.iconSmileContainer360,
      iconSmilePointer: MainImageStyle.smilePointer360,
    },
  };

  const elementStyle = styleSet[size];

  return (
    <Link href={link} className={MainImageStyle.iconSmileLink} passHref={true}>
      <div ref={cursorTriggerElement} className={elementStyle.iconSmileContainer}>
        <div className={cx(MainImageStyle.fadeThirdLine, MainImageStyle.defaultFadeIn)}>
          <div className={cx(MainImageStyle.fadeSubText)}>
            <div className={MainImageStyle.iconSmile} />
            <i className={MainImageStyle.iconFunSpaceNCMRound} />
          </div>
        </div>
      </div>
      <div className={cx(MainImageStyle.fadeThirdLine, MainImageStyle.defaultFadeIn)}>
        <div className={cx(MainImageStyle.fadeSubText)}>
          <i ref={cursorElement} className={elementStyle.iconSmilePointer} />
        </div>
      </div>
    </Link>
  );
};

export default IconSmile;
