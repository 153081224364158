import { getCookie } from '@/utils';
import { getUser } from '@/apis/requests/login';
import { setAuthorizationHeader } from '@/apis/server';

const withAuth = (Component) => {
  const AuthComponent = (props) => {
    return <Component {...props} />;
  };

  AuthComponent.getInitialProps = async (context) => {
    const { req } = context;

    const accessToken = getCookie({ req, name: 'accessToken' });
    setAuthorizationHeader(accessToken);
    const responseUser = await getUser();
    const responseData = responseUser?.data || {};
    const isLoggedIn = responseData.code === 0 && !!responseData.data?.id;
    const auth = {
      isLoggedIn,
      isNotLoggedIn: !isLoggedIn,
      data: responseData.data,
    };

    if (Component.getInitialProps) {
      const props = await Component.getInitialProps(context, { auth });

      return Object.assign(props, { auth });
    }
  };

  AuthComponent.getLayout = Component.getLayout;

  return AuthComponent;
};

export default withAuth;
