const removeChildElements = (element) => {
  if (!element) return;

  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }

  return element;
};

export { removeChildElements };
