import InviteStyle from './InviteYou.module.scss';
import { IconInviteYou } from './IconInviteYou';
import { RollingPoster } from './RollingPoster';
import { useRef } from 'react';

const InviteYou = (props) => {
  const { id } = props;
  const sectionRef = useRef();

  return (
    <section ref={sectionRef} className={InviteStyle.container}>
      <div id={id} className={InviteStyle.anchor} />
      <IconInviteYou className={InviteStyle.backgroundText} sectionRef={sectionRef} />
      <RollingPoster />
    </section>
  );
};

export default InviteYou;
