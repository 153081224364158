import getConfig from 'next/config';
import { getCookie, makeUrlQueryString } from '@/utils';
import { ADMISSION_ORDER_SET } from '@/constants';

class VisitorDTO {
  constructor() {}

  /**
   * @description 주문 정보와 예약 정보를 바탕으로 결제 요청에 필요한 테이터를 생성합니다. (개인 예약 결제)
   * @param data - 주문 정보
   * @param form - 예약 정보
   * @returns {*}
   */
  static getOrderForm({ data, form }) {
    const { publicRuntimeConfig } = getConfig();
    const accessToken = getCookie({ name: 'accessToken' });
    const returnUrlQueryObject = {
      mid: data.marketId,
      oid: data.orderId,
      accessToken: accessToken,
      errorURL: '/reservation/personal/schedule',
      completeURL: '/reservation/personal/complete',
      approveAPI: '/reservations/approval',
      idKeyName: 'orderId',
    };
    const queryString = makeUrlQueryString(returnUrlQueryObject);
    return {
      marketId: data.marketId,
      orderId: data.orderId,
      price: data.price,
      timestamp: data.timestamp,
      signature: data.signature,
      currency: data.currency,
      goodsName: data.goodsName,
      mKey: data.mkey,
      buyerEmail: form.requester.email,
      buyerTel: form.requester.phone,
      buyerName: form.requester.name,
      returnUrl: `${publicRuntimeConfig.baseURL}/api/payment/auth?${queryString}`,
    };
  }

  /**
   @description 주문 정보와 예약 정보를 바탕으로 결제 요청에 필요한 테이터를 생성합니다. (교육프로그램 예약 결제)
   * @param data - 주문 정보
   * @param form - 예약 정보
   * @param educationId - 교육프로그램 id
   * @returns {*}
   */
  static getEducationOrderForm({ data, form, educationId }) {
    const { publicRuntimeConfig } = getConfig();
    const accessToken = getCookie({ name: 'accessToken' });
    const returnUrlQueryObject = {
      mid: data.marketId,
      oid: data.reservationId,
      accessToken: accessToken,
      errorURL: `/education/reservation?id=${educationId}`,
      completeURL: '/education/reservation/result',
      approveAPI: '/educations/reservations/approval',
      idKeyName: 'reservationId',
    };
    const queryString = makeUrlQueryString(returnUrlQueryObject);
    return {
      marketId: data.marketId,
      orderId: data.reservationId,
      price: data.price,
      timestamp: data.timestamp,
      signature: data.signature,
      currency: data.currency,
      goodsName: data.goodsName,
      mKey: data.mkey,
      buyerEmail: form.email,
      buyerTel: form.phone,
      buyerName: form.name,
      returnUrl: `${publicRuntimeConfig.baseURL}/api/payment/auth?${queryString}`,
    };
  }

  /**
   * @description 티켓 목록을 바탕으로 form 입장권 초기세팅을 합니다.
   * @param tickets
   * @param form
   * @returns {*}
   */
  static initialAdmissionSetting(tickets, form) {
    return [...tickets]
      .map((ticket) => {
        const { name, code, price } = ticket;
        const registeredTicket = form.admissions.find((reservationTicket) => reservationTicket.code === code);
        return {
          code,
          name,
          price,
          count: registeredTicket ? registeredTicket.count : 0,
          sortPriority: ADMISSION_ORDER_SET[ticket.code],
        };
      })
      .sort((a, b) => a.sortPriority - b.sortPriority);
  }
}
export default VisitorDTO;
