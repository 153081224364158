const determineTopOrBottomByMouseEvent = (mouseEvent) => {
  const { target: element } = mouseEvent;

  const { top, height } = element.getBoundingClientRect();
  const middle = top + height / 2;

  return mouseEvent.y < middle ? 'top' : 'bottom';
};

export default determineTopOrBottomByMouseEvent;
