import getConfig from 'next/config';
import { forwardRef } from 'react';

const { publicRuntimeConfig } = getConfig();

const sourceLoader = ({ src }) => {
  const inHouseRegExp = new RegExp(/(http(s)?:\/\/).+/);

  return inHouseRegExp.test(src) ? src : publicRuntimeConfig.assetPrefix + src.replace(/^\.\./, '');
};

const withAssetPrefix = (WrappedComponent) => {
  const Component = forwardRef((props, ref) => {
    const { children, src, ...otherProps } = props;
    return <WrappedComponent ref={ref} src={sourceLoader({ src })} {...otherProps} />;
  });

  Component.displayName = WrappedComponent.displayName;

  return Component;
};

export { withAssetPrefix };
