import moment from 'moment';
import { useRouter } from 'next/router';
import { formatPrice, Program } from '@/utils';
import PropTypes from 'prop-types';
import { ReservationProgress, TextButton } from '@/components';
import ReservationDTO from '@/apis/DTOs/ReservationDTO';
import Link from 'next/link';
import styles from '@/style/reservation.module.scss';
import { cx } from '@emotion/css';
import ReservationButton from '@/components/commons/ReservationButton';
import { useEffect, useMemo } from 'react';

const ReservationInfo = (props) => {
  const { isResultPage, reservationDTO, programs, onCancel, onRefund } = props;
  const router = useRouter();
  const {
    query: { id, ...otherQueries },
  } = router;

  useEffect(() => {
    if (isResultPage) {
      sessionStorage.removeItem('personalReservation');
      sessionStorage.removeItem('groupReservation');
    }
  }, [isResultPage]);

  let programInfoList = [];
  reservationDTO.programs.forEach((program) => {
    const registeredProgram = Program.findSchedule(program, programs);
    if (!!registeredProgram) programInfoList.push(registeredProgram);
  });
  programInfoList = programInfoList.filter((program) => !!program.visitCount);

  const isPersonalReservation = reservationDTO.reservationType === 'PERSONAL';
  const isGroupReservation = reservationDTO.reservationType === 'GROUP';

  const isRequest = reservationDTO.reservationStatus === 'REQUEST';
  const isComplete = reservationDTO.reservationStatus === 'COMPLETE';
  const isCancel = reservationDTO.reservationStatus === 'CANCEL';

  const statusPhrase = useMemo(() => {
    switch (reservationDTO.reservationStatus) {
      case 'REQUEST':
        return (
          <>
            담당자 확인 후 예약이 확정되면
            <br />
            입력하신 휴대폰 번호로 예약 내역이 발송됩니다.
          </>
        );
      case 'COMPLETE':
        if (isPersonalReservation && isResultPage)
          return (
            <>
              예약 내역은{' '}
              <Link href="/reservation/list" passHref={true}>
                예약 확인 및 취소
              </Link>
              에서 <br className={styles.breakMobile} /> 확인 가능합니다.
            </>
          );
      case 'CANCEL':
      case 'VISIT':
      case 'VISIT_CANCEL':
        return <>예약 정보를 확인할 수 있습니다.</>;
    }
  }, [reservationDTO.reservationStatus, isPersonalReservation, isResultPage]);

  const goToReservationPage = () => {
    return router.push(`/reservation/${reservationDTO.reservationType.toLowerCase()}/schedule`);
  };

  const goToEditorPage = () => {
    if (isGroupReservation) {
      return router.push(`/reservation/${reservationDTO.reservationType.toLowerCase()}/schedule?id=${reservationDTO.id}`);
    } else {
      return router.push(`/reservation/edit/${reservationDTO.id}`);
    }
  };

  const goToListPage = () => {
    return router.push(
      `/reservation/list?${Object.entries(otherQueries)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    );
  };

  const onCancelReservation = () => {
    const { approval, id } = reservationDTO;
    const isRefundable = !!approval?.transactionId;
    isRefundable ? onRefund(id) : onCancel(id);
  };
  const hasFooterButton = isResultPage || (!isResultPage && (reservationDTO.isCancelable || reservationDTO.isEditable));

  const reservationStatusNameArray = reservationDTO?.reservationStatusName?.split(' ') || [];
  return (
    <div className={styles.reservationContainer}>
      <h2 className={styles.infoTitle}>
        {reservationStatusNameArray[0]} <br className={styles.breakMobile} />
        {reservationStatusNameArray[1]}되었습니다
      </h2>
      <div className={styles.infoAnnounce}>{statusPhrase}</div>
      {isGroupReservation && (isRequest || isComplete) && (
        <ReservationProgress
          progressItems={[
            { name: '예약 신청', value: null },
            { name: '예약 확인중', value: 'REQUEST' },
            { name: '예약 완료', value: 'COMPLETE' },
          ]}
          currentProgress={reservationDTO.reservationStatus}
        />
      )}
      <div className={styles.subTitleContainer}>
        <i className={styles.noteIcon} />
        <h2 className={styles.subTitle}>{isCancel ? '최초 예약 내역' : '예약 내역'}</h2>
        <sup className={styles.subTitleSuperscript}>Visit Information</sup>
      </div>
      <hr className={styles.visitInfoDividingLine} />
      <div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            예약 일자<sup className={styles.visitInfoTitleSuperscript}>Date</sup>
          </h3>
          <div className={styles.visitInfoValue}>{reservationDTO.schedule.reservationDate}</div>
        </div>
      </div>
      <div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            예약 시간<sup className={styles.visitInfoTitleSuperscript}>Entry Time</sup>
          </h3>
          <div className={styles.visitInfoValue}>{moment(reservationDTO.schedule.reservationTime, 'HH:mm:ss').format('HH:mm')}</div>
        </div>
      </div>
      {isGroupReservation && (
        <div>
          <div className={styles.visitInfoContainer}>
            <h3 className={styles.visitInfoTitle}>
              체류 시간<sup className={styles.visitInfoTitleSuperscript}>Length of Stay</sup>
            </h3>
            <div className={styles.visitInfoValue}>{reservationDTO.stayTime}분</div>
          </div>
        </div>
      )}
      <div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            예약 인원<sup className={styles.visitInfoTitleSuperscript}>Number of Admission</sup>
          </h3>
          <div className={styles.visitInfoValue}>{reservationDTO.visitorCount}명</div>
        </div>
        <ul className={styles.ticketList}>
          {reservationDTO.admissions
            .filter((ticket) => ticket.count > 0)
            .map(({ code, name, count, price }) => (
              <li key={code} className={styles.ticketItem}>
                <span>{name}</span>
                <span className={styles.ticketContent}>
                  {count}명 / {formatPrice(price * count, '원')}
                </span>
              </li>
            ))}
        </ul>
      </div>
      <div>
        <div className={cx(styles.visitInfoContainer, styles.programContainer)}>
          <h3 className={styles.visitInfoTitle}>
            프로그램<sup className={styles.visitInfoTitleSuperscript}>Program</sup>
          </h3>
          <div className={styles.visitInfoValue}>{programInfoList.length}개</div>
        </div>
        <ul className={styles.ticketList}>
          {programInfoList.map(({ programName, programID, cost, programTime, visitCount }) => (
            <li key={programID} className={styles.ticketItem}>
              <span className={styles.name}>{programName}</span>
              <span className={styles.ticketContent}>
                {Program.hhmmssTohhmm(programTime)} / {visitCount}명 / {formatPrice(cost * visitCount, '원')}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <hr className={cx(styles.dividingLine, styles.requesterLine)} />
      {isGroupReservation && (
        <div>
          <div className={styles.visitInfoContainer}>
            <h3 className={styles.requesterInfoTitle}>소속</h3>
            <div className={styles.requesterInfoContent}>{reservationDTO.requester.agency}</div>
          </div>
        </div>
      )}
      <div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.requesterInfoTitle}>{isGroupReservation ? '담당자명' : '예약자명'}</h3>
          <div className={styles.requesterInfoContent}>{reservationDTO.requester.name}</div>
        </div>
      </div>
      <div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.requesterInfoTitle}>휴대폰번호</h3>
          <div className={styles.requesterInfoContent}>{reservationDTO.requester.phone}</div>
        </div>
      </div>
      <div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.requesterInfoTitle}>이메일</h3>
          <div className={styles.requesterInfoContent}>{reservationDTO.requester.email}</div>
        </div>
      </div>
      {isGroupReservation && (
        <>
          <div>
            <div className={styles.visitInfoContainer}>
              <h3 className={styles.requesterInfoTitle}>구분</h3>
              <div className={styles.requesterInfoContent}>{reservationDTO.groupTypeName}</div>
            </div>
          </div>
          <div>
            <div className={styles.visitInfoContainer}>
              <h3 className={styles.requesterInfoTitle}>지역</h3>
              <div className={styles.requesterInfoContent}>{reservationDTO.regionTypeName}</div>
            </div>
          </div>
          <div>
            <div className={styles.visitInfoContainer}>
              <h3 className={styles.requesterInfoTitle}>단체명</h3>
              <div className={styles.requesterInfoContent}>{reservationDTO.group.groupName}</div>
            </div>
          </div>
          <div>
            <div className={styles.visitInfoContainer}>
              <h3 className={styles.requesterInfoTitle}>인솔자</h3>
              <div className={styles.requesterInfoContent}>{reservationDTO.group.leaderCount}명</div>
            </div>
          </div>
          <div>
            <div className={styles.visitInfoContainer}>
              <h3 className={styles.requesterInfoTitle}>교통편</h3>
              <div className={styles.requesterInfoContent}>
                {reservationDTO.transportTypeName}
                {reservationDTO.group.transportCount && ` / ${reservationDTO.group.transportCount}대`}
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <div className={cx(styles.visitInfoContainer, styles.requirementContainer)}>
          <h3 className={styles.requesterInfoTitle}>요청사항</h3>
          <div className={styles.requesterInfoContent}>{reservationDTO.requester.requirements}</div>
        </div>
      </div>
      <div className={styles.lineBreak} />
      {reservationDTO.approval && (
        <div>
          <div className={styles.subTitleContainer}>
            <i className={styles.noteIcon} />
            <h2 className={styles.subTitle}>결제 정보</h2>
            <sup className={styles.subTitleSuperscript}>Payment Information</sup>
          </div>
          <hr className={styles.visitInfoDividingLine} />
          <div className={styles.visitInfoContainer}>
            <h3 className={styles.requesterInfoTitle}>결제수단</h3>
            <div className={styles.requesterInfoContent}>{reservationDTO.paymentMethodName}</div>
          </div>
          <div className={styles.visitInfoContainer}>
            <h3 className={styles.requesterInfoTitle}>결제일</h3>
            <div className={styles.requesterInfoContent}>{reservationDTO.paymentDate}</div>
          </div>
          <hr className={cx(styles.dividingLine, styles.requesterLine)} />
          <ul>
            <li className={cx(styles.ticketItem, styles.subPriceItem)}>
              <span>{isCancel ? '최초 결제 금액' : '결제 금액'}</span>
              <span>{formatPrice(reservationDTO.approval.transactionPrice)}원</span>
            </li>
            <li className={styles.ticketItem}>
              <span>관람 금액</span>
              <span className={styles.ticketContent}>{formatPrice(reservationDTO.approval.admissionPrice, '원', '0원')}</span>
            </li>
            <li className={styles.ticketItem}>
              <span>프로그램 금액</span>
              <span className={styles.ticketContent}>{formatPrice(reservationDTO.approval.programPrice, '원', '0원')}</span>
            </li>
            <li className={styles.ticketItem}>
              <span>할인 금액</span>
              <span className={cx(styles.ticketContent, styles.ticketDiscountItem)}>{`-${formatPrice(reservationDTO.approval.discountPrice, '원', '0원')}`}</span>
            </li>
          </ul>
          {(reservationDTO.isFullRefund || reservationDTO.isPartialRefund) && (
            <div className={cx(styles.ticketItem, styles.subPriceItem)}>
              <span>환불 금액</span>
              <span>{formatPrice(reservationDTO.refundSum.totalPrice)}원</span>
            </div>
          )}
          <hr className={cx(styles.dividingLine, styles.requesterLine)} />
          <div className={cx(styles.ticketItem, styles.priceItem)}>
            <span>총 결제 금액</span>
            <span className={cx(styles.highlighter, styles.totalPrice)}>
              <strong>{formatPrice(reservationDTO.approval.transactionPrice - reservationDTO.refundSum.totalPrice, '', '0')}</strong>원
            </span>
          </div>
          <div className={styles.refundInfo}>
            <div className={styles.refundInfoTitle}>예약 취소 및 환불 안내</div>
            <ul>
              <li className={styles.refundInfoItem}>
                <strong>예약 시간 2시간 전까지 취소 시 100% 환불 가능, 이후 환불 불가</strong>
              </li>
            </ul>
            <div className={styles.refundInfoTitle}>예약 수정 안내</div>
            <ul>
              <li className={styles.refundInfoItem}>예약 시간 30분 전까지 같은 일자 내에서 시간 변경 가능</li>
              <li className={styles.refundInfoItem}>방문 일자, 인원, 프로그램 변경은 기존 건 취소 후 재결제 필요</li>
            </ul>
            <div className={styles.refundInfoTitle}>할인 티켓 관련 안내</div>
            <ul>
              <li className={styles.refundInfoItem}>
                할인 티켓 구매자는 현장 방문 시&nbsp;<strong>증빙 서류 지참</strong>
              </li>
            </ul>
          </div>
        </div>
      )}
      {hasFooterButton && (
        <>
          <div className={styles.lineBreak} />
          <ReservationButton notFixed={true}>
            {isResultPage && (
              <>
                <button className={cx(styles.footerButton, styles.cancelButton)} onClick={goToReservationPage}>
                  예약 메인
                </button>
                <button className={cx(styles.footerButton, styles.nextButton)} onClick={goToListPage}>
                  예약 내역 확인
                </button>
              </>
            )}
            {!isResultPage && reservationDTO.isCancelable && (
              <button className={cx(styles.footerButton, styles.cancelButton)} onClick={onCancelReservation}>
                예약 취소
              </button>
            )}
            {!isResultPage && reservationDTO.isEditable && (
              <button className={cx(styles.footerButton, styles.nextButton)} onClick={goToEditorPage}>
                예약 수정
              </button>
            )}
          </ReservationButton>
        </>
      )}
      {!isResultPage && (
        <TextButton className={styles.listButtonWrapper} onClick={goToListPage} pcBorderWidth={3}>
          /목록으로
        </TextButton>
      )}
    </div>
  );
};
ReservationInfo.propTypes = {
  isResultPage: PropTypes.bool,
  reservationDTO: PropTypes.instanceOf(ReservationDTO),
  programs: PropTypes.array,
  onClickCancel: PropTypes.func,
};
export default ReservationInfo;
