import { useBodyClassEffect, useValidationHook } from '@/hooks';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import getConfig from 'next/config';
import { System, TextButton } from '@/components';

const { publicRuntimeConfig } = getConfig();
const cookies = new Cookies();
const NAVER_CLIENT_ID = 'hllhQVKHHCiUWfcnB16a';
const GOOGLE_CLIENT_ID = '740424551916-u0bebqncghese4f9u008ui52nvbjkdjh.apps.googleusercontent.com';
const NAVER_OAUTH_URL = 'https://nid.naver.com/oauth2.0/authorize?response_type=code';
const GOOGLE_OAUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth?scope=email profile&response_type=code';

const Nonmember = ({ sendSMS, SMSLogin, smsLoginError }) => {
  useBodyClassEffect('subHeader2');
  const router = useRouter();
  const { query } = router;
  const { redirectURL } = query;
  const redirectQueryString = redirectURL ? '?redirectURL=' + redirectURL : '';
  const [enteredForm, setEnteredForm] = useState();
  const [form, setForm] = useState({
    name: '',
    phone: '',
    secureCode: '',
  });
  const { checkValidation: smsValidation, validStatus: smsValidStatus } = useValidationHook({
    data: form,
    validate: {
      name: ['isExist'],
      phone: ['isExist', 'isPhoneNumber'],
    },
    useCheckModal: false,
  });
  const { checkValidation: secureCodeValidation, validStatus: secureCodeValidStatus } = useValidationHook({
    data: form,
    validate: {
      secureCode: ['isExist', 'isNumberOverZero'],
    },
  });
  useEffect(() => {
    const didAgreeToLoginTerms = sessionStorage.getItem('did-agree-to-login-terms');
    if (!didAgreeToLoginTerms) {
      router.push(`/login${redirectQueryString}`);
    }
  }, [redirectQueryString, router]);

  const naverLogin = () => {
    let redirect_uri = `${publicRuntimeConfig.baseURL}/auth/naver/callback/login${redirectQueryString}`;
    let newQuery = new URLSearchParams({
      client_id: NAVER_CLIENT_ID,
      redirect_uri,
    });

    return router.push(`${NAVER_OAUTH_URL}&${newQuery}`);
  };
  const googleLogin = () => {
    let redirect_uri = `${publicRuntimeConfig.baseURL}/auth/google/callback/login`;
    let newQuery = new URLSearchParams({
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri,
    });

    if (redirectURL) cookies.set('redirectURL', redirectURL, { path: '/' });

    return router.push(`${GOOGLE_OAUTH_URL}&${newQuery}`);
  };
  const isCurrentFormValid = () => {
    if (!enteredForm) return false;
    return enteredForm.name === form.name && enteredForm.phone === form.phone;
  };
  const onChangeForm = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const onClickGetSecureCode = async () => {
    if (enteredForm) {
      return System.alert({
        title: '발송 완료',
        text: `이미 인증번호를 발송했습니다.\n새로운 인증번호를 받으시려면 새로고침해주세요.`,
      });
    }
    if (smsValidation()) {
      await System.alert({
        title: '발송 완료',
        text: `인증번호는 카카오톡으로 전송됩니다.\n알림톡 수신 차단이 설정되어 있는 경우 문자메시지로 전송됩니다.\n3분 이내로 인증번호를 입력해주세요.`,
      });

      // 인증번호 요청에 사용된 form 저장
      setEnteredForm(form);
      sendSMS(form);
    }
  };
  const onClickSMSLogin = async () => {
    if (!secureCodeValidation()) {
      return;
    }

    if (!isCurrentFormValid()) {
      // 인증번호를 받고 이름, 번호를 변경했을 때
      return System.alert({
        title: '정보 변경',
        text: `인증번호 요청시에 작성한 정보를 입력해주세요.`,
      });
    }

    SMSLogin(form);
  };

  return (
    <div id="content">
      <div className="cnts">
        <div className="nonMemberBody">
          <h2 className={'sTit'}>
            / 비회원 로그인 <span>Non-Member Login</span>
          </h2>
          <div className="nonMemberTitle">
            <ul>
              <li>
                휴대폰 번호 또는 소셜 인증으로 <br />
                로그인해 주세요
              </li>
              <li>
                <img src="/static/images/login/icon_arrow_move.png" alt="화살표" />
              </li>
            </ul>
          </div>
          <div className="nonMemberInput">
            <ul>
              <li>
                <dl>
                  <dt>Name</dt>
                  <dd>이름</dd>
                </dl>
                <input type="text" placeholder="본인 이름 입력" name="name" value={form.name} onChange={onChangeForm} readOnly={!!enteredForm} autoComplete={'off'} />
                {smsValidStatus && !smsValidStatus.name.isExist && <p>이름을 입력해주세요.</p>}
              </li>
              <li>
                <dl>
                  <dt>Mobile Phone Number</dt>
                  <dd>휴대폰 번호</dd>
                </dl>
                <input type="number" placeholder="-없이 숫자만 입력" name="phone" onChange={onChangeForm} readOnly={!!enteredForm} autoComplete={'off'} />
                {smsValidStatus && (!smsValidStatus.phone.isExist || !smsValidStatus.phone.isPhoneNumber) && <p>휴대폰번호를 정확하게 입력해주세요.</p>}
                <button type="button" onClick={onClickGetSecureCode}>
                  {'{'}인증번호 받기{'}'}
                </button>
                <input type="text" placeholder="인증번호 입력" name="secureCode" onChange={onChangeForm} autoComplete={'off'} />
                {(() => {
                  if (secureCodeValidStatus && !secureCodeValidStatus.secureCode.isExist) {
                    return <p className="certifiedError">인증번호를 입력해주세요.</p>;
                  }
                  if (secureCodeValidStatus && !secureCodeValidStatus.secureCode.isNumberOverZero) {
                    return <p className="certifiedError">숫자만 입력해주세요.</p>;
                  }
                  if (smsLoginError) {
                    return <p className="certifiedError">{smsLoginError}</p>;
                  }
                })()}
              </li>
              <li>
                <TextButton onClick={onClickSMSLogin} type="button" pcBorderWidth={3}>
                  /로그인
                </TextButton>
              </li>
            </ul>
            <div className="nonMemberButton">
              <h3>
                {'{'}간편 로그인{'}'}
              </h3>
              {/* <button
                type="button"
                className="naverBut"
                onClick={naverLogin}
              ></button> */}
              <button type="button" className="ggBut" onClick={googleLogin} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nonmember;
