import * as single from './single.json';
import * as double from './double.json';
import * as friends from './friends.json';
import * as family from './family.json';
import * as hybrid from './hybrid.json';
import * as healer from './healer.json';
import * as tanker from './tanker.json';
import * as dealer from './dealer.json';
import * as n86 from './n86.json';
import * as x from './x.json';
import * as m from './m.json';
import * as z from './z.json';

const Stickers = {
  single,
  double,
  friends,
  family,
  hybrid,
  healer,
  tanker,
  dealer,
  n86,
  x,
  m,
  z,
};

export default Stickers;
