import { cx } from '@emotion/css';
import PlayListTitleStyle from './PlayListTitle.module.scss';
import CommonTitle from './CommonTitle';
import FocusedTitle from './FocusedTitle';
import React from 'react';

const PlayListTitle = React.forwardRef((props, ref) => {
  const { className, focusButton } = props;
  return (
    <div ref={ref} className={cx(className, PlayListTitleStyle.titleContainer)}>
      <div className={PlayListTitleStyle.title}>{focusButton ? <FocusedTitle /> : <CommonTitle />}</div>
      <div className={PlayListTitleStyle.titleDescription}>
        <div>
          <div>박물관을 더 재미있게</div>
          <div>관람하기</div>
          <div>Must See ! Must Do ! Must Play !</div>
        </div>
      </div>
    </div>
  );
});

PlayListTitle.displayName = 'PlayListTitle';

export default PlayListTitle;
