import { useEffect, useState } from 'react';
import { isMobile } from '@/utils';

const useIsMobileDevice = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile());
  }, []);

  return {
    isMobileDevice,
  };
};

export default useIsMobileDevice;
