import styles from './StickerName.module.scss';
import React from 'react';

const StickerName = React.forwardRef((props, ref) => {
  const { children, otherProps } = props;
  return (
    <div ref={ref} className={styles.nameTag} {...otherProps}>
      {children}
    </div>
  );
});
StickerName.displayName = 'StickerName';

export default StickerName;
