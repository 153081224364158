import styled from '@emotion/styled';

const Tab = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  box-sizing: border-box;
  color: ${(props) => (props.isSelected ? '#272627' : '#5a5a5a')};
  font-weight: 500;
  letter-spacing: -1.4px;
  margin-right: 48px;
  background-image: linear-gradient(to top, #272627 3px, #e3ff7c 3px);
  background-size: ${(props) => (props.isSelected ? `100%` : 0)} 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  &:hover {
    background-size: 100% 100%;
  }
  @media (max-width: 999px) {
    background-image: linear-gradient(to top, #272627 2px, #e3ff7c 2px);
    margin-right: 30px;
  }
`;

const TabTitle = styled.span`
  display: block;
  font-size: 28px;
  line-height: 27px;
  padding: 4px 0 16px;
  @media (max-width: 999px) {
    padding-bottom: 9px;
    font-size: 20px;
    letter-spacing: -1px;
  }
`;

const SubTabTitle = styled.sup`
  display: block;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.7px;
  @media (max-width: 999px) {
    font-size: 11px;
    letter-spacing: -0.55px;
  }
`;

const Styled = {
  Tab,
  TabTitle,
  SubTabTitle,
};

export default Styled;
