const useReservationCheckAllHooks = ({ setForm, agreementList, isInAgreementObject = true }) => {
  const isCheckAll = () => {
    const isNotChecked = Object.values(agreementList).some((value) => value === 'N');
    return !isNotChecked;
  };

  const checkAll = (event) => {
    const { checked } = event.target;
    const value = checked ? 'Y' : 'N';
    let agreement = { ...agreementList };
    Object.keys(agreement).forEach((key) => (agreement[key] = value));
    setForm((prevState) => ({
      ...prevState,
      ...(isInAgreementObject ? { agreement } : { ...agreement }),
    }));
  };
  return {
    isCheckAll,
    checkAll,
  };
};
export default useReservationCheckAllHooks;
