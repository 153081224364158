import styles from './index.module.scss';
import { cx } from '@emotion/css';

const Checkbox = ({ children, onChange, checked, className, name, darkMode = false, disabled, ...otherProps }) => {
  return (
    <label className={cx(styles.container, disabled && styles.disabledContainer, className)} {...otherProps}>
      <input className={styles.checkbox} type={'checkbox'} onChange={onChange} value={checked} checked={checked} name={name} disabled={disabled} />
      <span className={cx(styles.customCheckbox, darkMode && styles.darkMode)} />
      {children}
    </label>
  );
};
export default Checkbox;
