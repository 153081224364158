import ServerAPI from '@/apis/server';
import AuthBook from '@/apis/books/auth.json';

const getOAuthState = () => {
  return ServerAPI.get(`${AuthBook.AUTH}/state`);
};

const postSendSMS = (payload) => {
  return ServerAPI.post(`${AuthBook.SMS}/send`, payload);
};

const postSMSLogin = (payload) => {
  return ServerAPI.post(`${AuthBook.SMS}`, payload);
};

const postNexonLogin = (payload) => {
  return ServerAPI.post(`${AuthBook.AUTH}/nexon`, payload);
};

const postNaverLogin = (payload) => {
  return ServerAPI.post(`${AuthBook.NAVER}`, payload);
};

const postGoogleLogin = (payload) => {
  return ServerAPI.post(`${AuthBook.GOOGLE}`, payload);
};

const getUser = () => {
  return ServerAPI.get(`${AuthBook.USER}`);
};

const postLogout = () => {
  return ServerAPI.post(`${AuthBook.LOGOUT}`, null);
};

export { getOAuthState, postSendSMS, postSMSLogin, postNexonLogin, postNaverLogin, postGoogleLogin, getUser, postLogout };
