import { cx } from '@emotion/css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

import BoardsStyle from './d.Boards.module.scss';
import useChangeCursor from '@/hooks/useChangeCursor.hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BoardNavigationBar } from './BoardNavigationBar';
import { imageLoader } from '@/components';
import { getNumberBetween } from '@/utils';
import moment from 'moment';

const DBoards = (props) => {
  const { id, board = {} } = props;
  const boards = useMemo(() => [...board.news, ...board.guides, ...board.programs], [board]);
  const navigators = useMemo(
    () => [
      {
        title: 'News',
        sup: '소식',
        index: 0,
        start: 0,
        end: board.news.length - 1,
      },
      {
        title: 'Information',
        sup: '관람 안내',
        index: board.news.length,
        start: board.news.length,
        end: board.news.length + board.guides.length - 1,
      },
      {
        title: 'Program',
        sup: '프로그램',
        index: board.news.length + board.guides.length,
        start: board.news.length + board.guides.length,
        end: boards.length - 1,
      },
    ],
    [boards, board]
  );
  const [selectedNav, setSelectedNav] = useState(0);
  const containerRef = useRef();
  const navArrowRefs = useRef([]);
  const navRefs = useRef([]);
  const sliderNavRef = useRef(null);
  const slider = useRef();
  const boardRef = useRef(null);

  useEffect(() => {
    let previousBottom = null;

    const onScroll = () => {
      const container = containerRef.current;

      if (!container) return;

      const initScrollPosition = -100;
      const { top } = container.getBoundingClientRect();
      const calcValue = initScrollPosition + top;
      const bottom = getNumberBetween(calcValue, -100, 100);

      if (bottom === previousBottom) return;

      previousBottom = bottom;

      const translateY = `translateY(${getNumberBetween(bottom, 0, 100)}%)`;

      for (const index of navigators.keys()) {
        const nav = navRefs.current[index];
        nav.style.transform = translateY;

        const navArrow = navArrowRefs.current[index];
        navArrow.style.transform = translateY;
      }

      const boardList = slider.current.splideRef.current;
      const calculatedOpacity = (-bottom / 100 + 1) / 2;
      // boardList.style.transform = translateY;
      boardList.style.opacity = calculatedOpacity;
      sliderNavRef.current.style.opacity = calculatedOpacity;
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [navigators]);

  useEffect(() => {
    const sliderObject = slider.current;
    const { splide } = sliderObject;

    const activeEvent = (event) => {
      const { index, slideIndex } = event;
      if (index < 0 || slideIndex < 0) return;
      const currentNavIndex = navigators.findIndex((navigator) => navigator.start <= slideIndex && navigator.end >= slideIndex);
      setSelectedNav(currentNavIndex);
    };

    splide.on('active', activeEvent);

    return () => splide.off('active', activeEvent);
  }, [navigators]);

  useEffect(() => {
    const checkNavigation = () => {
      const boardElement = boardRef.current;
      if (!boardElement) return;
      const { top, height } = boardElement.getBoundingClientRect();

      if (window.innerHeight - (height - top) - 100 > 0) {
        if (window.innerHeight - (height - top) > height) return;
        document.body.classList.add('on-is-navigation-white');
      }

      if (0 > top || top >= 86) return;
      document.body.classList.remove('on-is-navigation-white');
    };

    window.addEventListener('scroll', checkNavigation);
    return () => window.removeEventListener('scroll', checkNavigation);
  }, []);

  const cursorConfig = {
    center: true,
  };
  const { triggerRef: leftTrigger, cursorRef: leftCursor } = useChangeCursor(cursorConfig);
  const { triggerRef: rightTrigger, cursorRef: rightCursor } = useChangeCursor(cursorConfig);

  const onClickNav = (navigator, navIndex) => {
    setSelectedNav(navIndex);
    slider.current.splide.go(navigator.index);
  };

  const splideAutoScrollStop = () => {
    slider.current.splide.Components.AutoScroll.pause();
  };

  const splideAutoScrollPlay = () => {
    slider.current.splide.Components.AutoScroll.play();
  };

  return (
    <section ref={containerRef} className={BoardsStyle.container}>
      <div className={BoardsStyle.paddler} />
      <div id={id} ref={boardRef} className={BoardsStyle.boardWrapper}>
        <div className={BoardsStyle.header}>
          <nav className={BoardsStyle.navigation}>
            <ul>
              {navigators.map((navigator, index) => {
                return (
                  <li key={navigator.title} className={cx(BoardsStyle.navigationListItem, selectedNav === index && 'on')}>
                    <i className={cx(BoardsStyle.arrowIcon, selectedNav === index && BoardsStyle.on)} ref={(element) => (navArrowRefs.current[index] = element)} />
                    <a
                      className={cx(BoardsStyle.navigationTitleContainer, 'highlight-pen')}
                      ref={(element) => (navRefs.current[index] = element)}
                      onClick={() => onClickNav(navigator, index)}
                    >
                      <strong className={BoardsStyle.navigationTitle}>{navigator.title}</strong>
                      <sup className={BoardsStyle.navigationSup}>{navigator.sup}</sup>
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
        <div className={BoardsStyle.boardListContainer}>
          <div className={BoardsStyle.navigationBarWrapper} ref={sliderNavRef}>
            <BoardNavigationBar />
          </div>
          <Splide
            ref={slider}
            options={{
              type: 'loop',
              drag: 'free',
              arrows: false,
              pagination: false,
              autoWidth: true,
              autoScroll: {
                pauseOnHover: false,
                pauseOnFocus: false,
                rewind: true,
                speed: 0.5,
              },
              breakpoints: {
                768: {
                  autoScroll: {
                    speed: 0.5,
                  },
                },
                1280: {
                  autoScroll: {
                    speed: 0.5,
                  },
                },
                1920: {
                  autoScroll: {
                    speed: 1,
                  },
                },
                3840: {
                  autoScroll: {
                    speed: 3,
                  },
                },
              },
            }}
            extensions={{ AutoScroll }}
            className={BoardsStyle.boardList}
            onMouseEnter={splideAutoScrollStop}
            onMouseLeave={splideAutoScrollPlay}
          >
            {boards.map((board) => {
              return (
                <SplideSlide key={board.key} className={'background-underline'}>
                  <div className={BoardsStyle.boardListItem}>
                    <a className={BoardsStyle.postItem} href={board.href}>
                      <div className={BoardsStyle.postImageContainer}>
                        <div className={BoardsStyle.postImageWrapper}>
                          <img
                            src={board.imageLocation === 'assets' ? board.imageURL : imageLoader({ src: board.imageURL })}
                            className={BoardsStyle.postImage}
                            alt="Poster Image"
                          />
                        </div>
                        {board.createdDate && <time className={BoardsStyle.publishTime}>{moment(board.createdDate).format('yyyy.MM.DD')}</time>}
                      </div>
                      <div className={BoardsStyle.postTitleContainer}>
                        <span className={BoardsStyle.postCategoryText}>{board.type}</span>
                        <strong className={BoardsStyle.postTitle}>
                          <span className={'link-underline'}>{board.title}</span>
                        </strong>
                      </div>
                    </a>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
          <div
            ref={leftTrigger}
            className={cx(BoardsStyle.boardListController, BoardsStyle.controllerLeft)}
            onMouseEnter={splideAutoScrollStop}
            onMouseLeave={splideAutoScrollPlay}
            onClick={() => slider.current.splide.go('-2')}
          >
            <i ref={leftCursor} className={BoardsStyle.iconControllerArrowLeft} />
          </div>
          <div
            ref={rightTrigger}
            className={cx(BoardsStyle.boardListController, BoardsStyle.controllerRight)}
            onMouseEnter={splideAutoScrollStop}
            onMouseLeave={splideAutoScrollPlay}
            onClick={() => slider.current.splide.go('+2')}
          >
            <i ref={rightCursor} className={BoardsStyle.iconControllerArrowRight} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DBoards;
