import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useSyncTitleContentsHooks = (props) => {
  const { defaultOptions } = props;
  const titleRef = useRef(null);
  const [contentMarginTop, setContentMarginTop] = useState(defaultOptions.contentMarginTop ?? 235);

  useEffect(() => {
    const titleContainerElement = titleRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;
        setContentMarginTop(height);
      }
    });

    resizeObserver.observe(titleContainerElement);

    return () => resizeObserver.disconnect();
  }, []);

  return { titleRef, contentMarginTop };
};

export default useSyncTitleContentsHooks;
