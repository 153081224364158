import UAParser from 'ua-parser-js';

const hasTouchPoints = () => {
  return navigator.maxTouchPoints > 0;
};

const isMobile = () => {
  try {
    const parser = new UAParser();
    const device = parser.getDevice();
    const deviceType = device.type;
    const typeChecker = /mobile|tablet/;
    const maybeMobile = typeChecker.test(deviceType);

    if (maybeMobile) {
      return hasTouchPoints();
    }

    return false;
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export { isMobile };
