import { useCallback, useState } from 'react';

const usePhase = (props) => {
  const { initialPhase } = props;
  const [phases, setPhases] = useState([initialPhase]);

  const getPhase = (currentPhase) => {
    return phases.includes(currentPhase);
  };

  const addPhase = (phase) => {
    setPhases((prevPhases) => [...prevPhases, phase]);
  };

  const removePhase = (phase) => {
    setPhases((prevPhases) => {
      const targetIndex = prevPhases.indexOf(phase);
      if (targetIndex < 0) return [...prevPhases];
      prevPhases.splice(targetIndex, 1);
      return [...prevPhases];
    });
  };

  const changePhase = useCallback((phase) => setPhases([phase]), []);

  return {
    getPhase,
    addPhase,
    changePhase,
    removePhase,
  };
};

export default usePhase;
