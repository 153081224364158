import { useRef, useEffect, useState } from 'react';

const useHorizontalScroll = ({ condition, pageSize = 0 }) => {
  const wrapperRef = useRef();
  const scrollRef = useRef();
  const [scrollPosition, setScrollPosition] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    const scrollElement = scrollRef.current;
    if (!wrapperElement || !scrollElement) return;

    const onWheel = (event) => {
      if (!condition || event.deltaY === 0) return;
      event.preventDefault();

      setScrollPosition((prevState) => {
        const moveTo = prevState + event.deltaY * 2;
        const max = scrollElement.scrollWidth - scrollElement.offsetWidth;
        if (moveTo < 0) return 0;
        else if (moveTo > max) return max;
        else return moveTo;
      });
    };

    const onScroll = () => {
      if (!scrollElement) return;
      const scrollPosition = scrollElement.scrollLeft;
      const pageSize = pageSize || scrollElement.offsetWidth;
      calcCurrentPage(scrollPosition, pageSize);
    };

    setScrollPosition(scrollElement.scrollLeft);
    wrapperElement.addEventListener('wheel', onWheel);
    scrollElement.addEventListener('scroll', onScroll);
    return () => {
      wrapperElement.removeEventListener('wheel', onWheel);
      scrollElement.removeEventListener('scroll', onScroll);
    };
  }, [condition]);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    scrollElement.scrollTo({
      left: scrollPosition,
      behavior: 'smooth',
    });
  }, [scrollPosition]);

  const calcCurrentPage = (scrollPosition, pageSize) => {
    const curPage = Math.floor(scrollPosition / pageSize) + 1;
    setCurrentPage(curPage <= 0 ? 1 : curPage);
  };

  const scrollPaging = (page) => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;
    const width = scrollElement.offsetWidth;
    const scrollTo = (pageSize || width) * (page - 1);
    setScrollPosition(scrollTo);
  };

  return { wrapperRef, scrollRef, currentPage, scrollPaging };
};

export default useHorizontalScroll;
