import { useEffect, useState } from 'react';
import { GroupType, RegionType, TransportType } from '@/apis/DTOs/ReservationDTO';
import { Checkbox, Dropdown, ToggleContent, OperationText, ReservationButton, XSSShield, MainTitle } from '@/components';
import { useValidationHook, useReservationCheckAll } from '@/hooks';
import PropTypes from 'prop-types';
import { findGuideContent, formatPrice, Program } from '@/utils';
import { EMAIL_DOMAINS, CHECKBOX_LIST } from '@/constants';
import styles from '@/style/reservation.module.scss';
import { cx } from '@emotion/css';

const customValidationNames = {
  name: '담당자명',
};

const RequesterForm = (props) => {
  const { initialForm, isUpdateForm, guides, onChange, onSubmit, returnToPrevStep } = props;
  const [form, setForm] = useState(initialForm);
  const { checkValidation } = useValidationHook({
    data: form,
    validate: {
      requester: {
        name: ['isExist', 'isNotHavingComma'],
        phone: ['isExist', 'isPhoneNumber'],
        emailID: ['isExist', 'isEmailID'],
        emailDomain: ['isExist', 'isEmailDomain'],
      },
      group: {
        groupName: ['isExist'],
        groupType: ['isExist'],
        regionType: ['isExist'],
        leaderCount: ['isExist', 'isNumberOverZero'],
        transportType: ['isExist'],
        transportCount: ['isBusOrCarAndExist', 'isNumberOverOne'],
      },
      agreement: {
        overFourteenYearsOld: ['isChecked'],
      },
    },
    customValidator: {
      isChecked: (value) => value === 'Y',
      isBusOrCarAndExist: (value) =>
        !form.group.transportType ||
        form.group.transportType === 'PUBLIC_TRANSPORT' ||
        ((form.group.transportType === 'GROUP_BUS' || form.group.transportType === 'RENTAL_CAR') && !!value),
    },
    customValidationNames,
  });
  const { checkAll, isCheckAll } = useReservationCheckAll({ setForm, agreementList: form.agreement });

  useEffect(() => {
    if (initialForm) setForm(initialForm);
  }, [initialForm]);

  const onClickDone = (event) => {
    if (!checkValidation()) {
      return;
    }
    event.target.classList.add('disabled');
    onSubmit(form);
  };

  const registeredProgram = form.programs.filter((program) => !!program.timeID && program.visitCount > 0) || [];

  return (
    <div className={styles.reservationContainer}>
      <MainTitle>{isUpdateForm ? '예약 수정' : '단체 예약'}</MainTitle>
      <div className={styles.subTitleContainer}>
        <i className={styles.noteIcon} />
        <h2 className={styles.subTitle}>예약 내역</h2>
        <sup className={styles.subTitleSuperscript}>Visit Information</sup>
      </div>
      <hr className={styles.visitInfoDividingLine} />
      <div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            예약 일자<sup className={styles.visitInfoTitleSuperscript}>Date</sup>
          </h3>
          <div className={styles.visitInfoValue}>{form.schedule?.reservationDate}</div>
        </div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            예약 시간<sup className={styles.visitInfoTitleSuperscript}>Entry Time</sup>
          </h3>
          <div className={styles.visitInfoValue}>{Program.hhmmssTohhmm(form.schedule?.reservationTime)}</div>
        </div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            체류 시간<sup className={styles.visitInfoTitleSuperscript}>Length of Stay</sup>
          </h3>
          <div className={styles.visitInfoValue}>{form.stayTime}분</div>
        </div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            예약 인원<sup className={styles.visitInfoTitleSuperscript}>Number of Admission</sup>
          </h3>
          <div className={styles.visitInfoValue}>{form.visitorCount}명</div>
        </div>
        <div className={styles.visitInfoContainer}>
          <h3 className={styles.visitInfoTitle}>
            프로그램<sup className={styles.visitInfoTitleSuperscript}>Program</sup>
          </h3>
          <div className={styles.visitInfoValue}>{registeredProgram.length}개</div>
        </div>
        <ul className={styles.ticketList}>
          {registeredProgram.map(({ programName, programID, cost, timeID, times, visitCount }) => {
            if (!times) return null;
            const { programTime } = times.find((time) => time.id === timeID);
            return (
              <li key={programID} className={styles.ticketItem}>
                <span className={styles.name}>{programName}</span>
                <span className={styles.ticketContent}>
                  {Program.hhmmssTohhmm(programTime)} / {visitCount}명 / {formatPrice(cost * visitCount, '원')}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      <hr className={cx(styles.dividingLine, styles.requesterLine)} />
      <div className={styles.goBackButtonWrapper}>
        <button className={styles.goBackButton} onClick={() => returnToPrevStep(form)}>
          다시 선택하기
        </button>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>소속</h2>
        <sup className={styles.subTitleSuperscript}>Affiliation</sup>
      </div>
      <input
        className={styles.inputForm}
        type="text"
        name="agency"
        value={form.requester.agency || ''}
        onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
        placeholder="여행사, 학교, 기관명 등 입력해주세요."
        maxLength="20"
        autoComplete="off"
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>담당자명</h2>
        <sup className={styles.subTitleSuperscript}>Name of Contact</sup>
      </div>
      <input
        className={styles.inputForm}
        type="text"
        name="name"
        value={form.requester.name || ''}
        onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
        placeholder="이름을 입력해주세요."
        maxLength="20"
        autoComplete="off"
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>휴대폰 번호</h2>
        <sup className={styles.subTitleSuperscript}>Phone Number</sup>
      </div>
      <input
        className={styles.inputForm}
        type="text"
        pattern={'^[0-9]+$'}
        name="phone"
        value={form.requester.phone || ''}
        onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
        placeholder="- 없이 숫자만 입력"
        maxLength="11"
        autoComplete="off"
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>이메일</h2>
        <sup className={styles.subTitleSuperscript}>Email</sup>
      </div>
      <div className={styles.emailFormContainer}>
        <div className={styles.emailForm}>
          <input
            className={cx(styles.inputForm, styles.emailInput)}
            type="text"
            name="emailID"
            value={form.requester.emailID || ''}
            onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
            maxLength="20"
            autoComplete="off"
          />
          <span className={styles.at}>@</span>
          <input
            className={cx(styles.inputForm, styles.emailInput)}
            type="text"
            name="emailDomain"
            value={form.requester.emailDomain || ''}
            onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
            maxLength="40"
            autoComplete="off"
          />
        </div>
        <div className={cx(styles.dropdownWrapper, styles.emailDropdownWrapper)}>
          <Dropdown
            height={50}
            defaultColor={'#eaeaea'}
            name="emailDomain"
            selected={form.requester.emailDomain}
            defaultValue="직접 입력"
            optionList={EMAIL_DOMAINS.map((domain) => ({
              name: domain,
              value: domain,
            }))}
            onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
          />
        </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>구분</h2>
        <sup className={styles.subTitleSuperscript}>Group</sup>
      </div>
      <div className={styles.dropdownWrapper}>
        <Dropdown
          height={50}
          defaultColor={'#eaeaea'}
          name="groupType"
          selected={form.group.groupType}
          defaultValue="선택"
          optionList={Object.entries(GroupType).map(([type, name]) => ({
            value: type,
            name,
          }))}
          onChange={(event) => onChange({ objectName: 'group', event, setForm })}
        />
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>지역</h2>
        <sup className={styles.subTitleSuperscript}>Area</sup>
      </div>
      <div className={styles.dropdownWrapper}>
        <Dropdown
          height={50}
          defaultColor={'#eaeaea'}
          name="regionType"
          selected={form.group.regionType}
          defaultValue="선택"
          optionList={Object.entries(RegionType).map(([type, name]) => ({
            value: type,
            name,
          }))}
          onChange={(event) => onChange({ objectName: 'group', event, setForm })}
        />
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>단체명</h2>
        <sup className={styles.subTitleSuperscript}>Name of Group</sup>
      </div>
      <input
        className={styles.inputForm}
        type="text"
        name="groupName"
        value={form.group.groupName || ''}
        onChange={(event) => onChange({ objectName: 'group', event, setForm })}
        maxLength="20"
        placeholder="단체명 입력"
        autoComplete="off"
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>인솔자</h2>
        <sup className={styles.subTitleSuperscript}>Number of Group Leader</sup>
      </div>
      <input
        className={cx(styles.inputForm, styles.smallInputForm)}
        type="text"
        name="leaderCount"
        value={form.group.leaderCount.toString() || ''}
        onChange={(event) => onChange({ objectName: 'group', event, setForm })}
        maxLength="2"
        placeholder="인솔자수 입력"
        autoComplete="off"
      />
      <span className={styles.unit}>명</span>
      <div className={styles.leaderInputDescription}>20명 예약 시 인솔자 2명 무료</div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>교통편</h2>
        <sup className={styles.subTitleSuperscript}>Transportation</sup>
      </div>
      <div className={styles.dropdownWrapper}>
        <Dropdown
          height={50}
          defaultColor={'#eaeaea'}
          name="transportType"
          defaultValue="선택"
          selected={form.group.transportType}
          optionList={Object.entries(TransportType).map(([type, name]) => ({
            value: type,
            name,
          }))}
          onChange={(event) => onChange({ objectName: 'group', event, setForm })}
        />
      </div>
      {(form.group.transportType === 'GROUP_BUS' || form.group.transportType === 'RENTAL_CAR') && (
        <span className={styles.transportInput}>
          <input
            className={cx(styles.inputForm, styles.smallInputForm)}
            type="text"
            name="transportCount"
            value={form.group.transportCount || ''}
            onChange={(event) => onChange({ objectName: 'group', event, setForm })}
            autoComplete="off"
          />
          <span className={styles.unit}>대</span>
        </span>
      )}
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>요청사항</h2>
        <sup className={styles.subTitleSuperscript}>Request</sup>
      </div>
      <textarea
        className={cx('scroll', styles.textAreaForm)}
        name="requirements"
        value={form.requester.requirements || ''}
        onChange={(event) => onChange({ objectName: 'requester', event, setForm })}
        maxLength="200"
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>이용약관 동의</h2>
        <sup className={styles.subTitleSuperscript}>Agreement to the terms and condition for use</sup>
      </div>
      <ul className={styles.checkList}>
        <li className={styles.checkItem}>
          <Checkbox checked={isCheckAll()} onChange={checkAll}>
            전체 동의
          </Checkbox>
        </li>
        {CHECKBOX_LIST.map((checkbox, index) => (
          <li key={checkbox.type} className={styles.checkItem}>
            <Checkbox name={checkbox.type} checked={form.agreement[checkbox.type] === 'Y'} onChange={(event) => onChange({ objectName: 'agreement', event, setForm })}>
              <div>
                {checkbox.isRequired ? '[필수] ' : <span className={styles.optionalCheck}>(선택) </span>}
                {checkbox.name}
              </div>
            </Checkbox>
            {checkbox.guideType && <ToggleContent innerHTML={findGuideContent(guides, checkbox.guideType)} />}
          </li>
        ))}
      </ul>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>유의사항</h2>
        <sup className={styles.subTitleSuperscript}>Notice</sup>
      </div>
      <OperationText>
        <XSSShield content={findGuideContent(guides, 'GROUP_RESERVATION_NOTICE')} />
      </OperationText>
      <div className={styles.lineBreak} />
      <ReservationButton>
        <button className={cx(styles.footerButton, styles.nextButton)} onClick={onClickDone}>
          {isUpdateForm ? '예약 수정 완료' : '예약 완료'}
        </button>
      </ReservationButton>
    </div>
  );
};

RequesterForm.propTypes = {
  isUpdateForm: PropTypes.bool,
  id: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};

RequesterForm.defaultProps = {
  isUpdateForm: false,
  id: null,
};

export default RequesterForm;
