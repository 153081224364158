const StickerNameRenderer = (props) => {
  const { type, containerClassName, subLanguageClassName, textTransform = 'none' } = props;

  if (!type) return <span />;

  let typeName = type;

  if (textTransform === 'uppercase') {
    typeName = type.toUpperCase();
  }

  const renderer = {
    family: () => <span className={containerClassName}>{typeName}</span>,
    single: () => <span className={containerClassName}>{typeName}</span>,
    friends: () => <span className={containerClassName}>{typeName}</span>,
    double: () => <span className={containerClassName}>{typeName}</span>,
    hybrid: () => <span className={containerClassName}>{typeName}</span>,
    dealer: () => <span className={containerClassName}>{typeName}</span>,
    healer: () => <span className={containerClassName}>{typeName}</span>,
    tanker: () => <span className={containerClassName}>{typeName}</span>,
    n86: () => (
      <span className={containerClassName}>
        {typeName}
        <span className={subLanguageClassName}>세대</span>
      </span>
    ),
    x: () => (
      <span className={containerClassName}>
        {typeName}
        <span className={subLanguageClassName}>세대</span>
      </span>
    ),
    m: () => (
      <span className={containerClassName}>
        {typeName}
        <span className={subLanguageClassName}>세대</span>
      </span>
    ),
    z: () => (
      <span className={containerClassName}>
        {typeName}
        <span className={subLanguageClassName}>세대</span>
      </span>
    ),
  };

  if (!renderer[type]) return <span />;

  const CurrentName = renderer[type];

  return <CurrentName />;
};

export default StickerNameRenderer;
