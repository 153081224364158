import { useEffect, useRef, useState } from 'react';
import { cx } from '@emotion/css';

import { MainVideo } from '@/components/main/sections/a.MainSection/MainVideo';
import { MainImage1000, MainImage768, MainImage360 } from './MainImage';
import SquareBanner from '@/components/main/sections/a.MainSection/SquareBanner';
import LineBanner from '@/components/main/sections/a.MainSection/LineBanner';
import MainSectionStyle from './a.MainSection.module.scss';
import { usePhase } from '@/hooks';
import { getNumberBetween } from '@/utils';

const PHASE = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  PC: 'pc',
};

const MainSection = (props) => {
  const { id } = props;
  const containerRef = useRef(null);
  const { getPhase, changePhase } = usePhase({ initialPhase: PHASE.PC });
  const [isFadeIn, setIsFadeIn] = useState(false);

  useEffect(() => {
    let previousOpacity = null;

    const onScroll = () => {
      if (!containerRef.current) return;
      const startPoint = containerRef.current.getBoundingClientRect().top;
      const opacity = (100 + startPoint) / 100;
      const recalculatedOpacity = getNumberBetween(opacity, 0, 1);

      if (previousOpacity === recalculatedOpacity) return;
      previousOpacity = recalculatedOpacity;

      containerRef.current.style.opacity = recalculatedOpacity;
      const isFadeInEnd = recalculatedOpacity === 0;

      if (isFadeInEnd) {
        document.body.classList.remove('on-is-navigation-white');
      } else {
        document.body.classList.add('on-is-navigation-white');
      }

      setIsFadeIn(isFadeInEnd);
    };

    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const onResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1000) {
        changePhase(PHASE.PC);
      } else if (windowWidth >= 768) {
        changePhase(PHASE.TABLET);
      } else {
        changePhase(PHASE.MOBILE);
      }
    };

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [changePhase]);

  return (
    <section id={id} ref={containerRef} className={MainSectionStyle.mainContainer}>
      {!isFadeIn && (
        <div className={cx(MainSectionStyle.mainSection, 'mainContainer')}>
          {getPhase(PHASE.PC) && <MainImage1000 />}
          {getPhase(PHASE.TABLET) && <MainImage768 />}
          {getPhase(PHASE.MOBILE) && <MainImage360 className={MainSectionStyle.mainSectionImage360} />}
          {(getPhase(PHASE.PC) || getPhase(PHASE.TABLET)) && <LineBanner className={MainSectionStyle.lineBanner} />}
          <MainVideo className={MainSectionStyle.mainVideo} />
          <SquareBanner className={MainSectionStyle.squareBanner} />
          <i className={MainSectionStyle.iconArrowDown} />
        </div>
      )}
    </section>
  );
};

export default MainSection;
