import { AMainSection, BCollectionHighlight, CPlayList, InviteYou, DBoards, Intro } from './sections';
import Navigation from '@/components/main/navigation/Navigation';
import { usePhase } from '@/hooks';

// import { useChangeViewportScale } from '@/hooks';

const PHASE = {
  INTRO: 'intro',
  MAIN: 'main',
};

const Main = (props) => {
  const { board = {} } = props;
  const { addPhase, getPhase, removePhase } = usePhase({
    initialPhase: PHASE.INTRO,
  });
  // useChangeViewportScale();

  return (
    <>
      {getPhase(PHASE.INTRO) && <Intro onVideoEnd={() => addPhase(PHASE.MAIN)} onIntroEnd={() => removePhase(PHASE.INTRO)} />}
      {getPhase(PHASE.MAIN) && (
        <article>
          <Navigation />
          <AMainSection id={'home'} />
          <BCollectionHighlight id={'highlight'} />
          <CPlayList id={'play-route'} />
          <DBoards id={'news'} board={board} />
          <InviteYou id={'invite-you'} />
        </article>
      )}
    </>
  );
};

export default Main;
