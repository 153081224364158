import styled from '@emotion/styled';

const OperationTextContainer = styled.section`
  line-height: 1.6;

  table {
    table-layout: fixed;
    width: 100% !important;
    border-collapse: collapse;
    box-sizing: border-box;
    border-top: 1px solid #c7c9d1;
    border-bottom: 1px solid #c7c9d1;
    margin: 16px 0;
  }

  th {
    padding: 14px 0;
    background-color: #eaeaea;
    color: #747477;
    border-left: 1px solid #c7c9d1;
    border-bottom: 1px solid #c7c9d1;
    vertical-align: middle;
    word-break: keep-all;

    &:first-of-type {
      border-left: 0;
    }
  }

  td {
    min-height: 57px;
    height: 57px;
    vertical-align: middle;
    line-height: 1.3;
    border-left: 1px solid #c7c9d1;
    border-bottom: 1px solid #c7c9d1;
    background-color: #fff;
    word-break: keep-all;

    &:first-of-type {
      border-left: 0;
    }
  }

  img {
    max-width: 100%;
    vertical-align: top;
  }
`;

const Styled = {
  OperationTextContainer,
};

export default Styled;
