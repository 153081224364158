import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { createRoot } from 'react-dom/client';

const SystemModal = ({ title, text = '', ChildContent = null, confirmLabel, cancelLabel, onClose, resolve, reject, isConfirmMessage, modalContainerStyle }) => {
  const onConfirm = () => {
    onClose();
    return resolve({ confirm: true });
  };
  const onCancel = () => {
    onClose();
    return resolve({ confirm: false });
  };
  return (
    <>
      <span className="dimmed" />
      <div
        className="layer"
        style={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          ...modalContainerStyle,
        }}
      >
        <div className="layerCnts">
          <p className="txt">{title}</p>
          <div className="cnts">
            {ChildContent ? (
              <ChildContent />
            ) : (
              <p>
                {text.split('\n').map((line, idx) => (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>
            )}
          </div>
          <div className={cx('layerBt', isConfirmMessage && 'layerBt2')}>
            {isConfirmMessage && (
              <span>
                <a onClick={onCancel} className="bt bt2">
                  {cancelLabel}
                </a>
              </span>
            )}
            <span>
              <a onClick={onConfirm} className="bt bt1">
                {confirmLabel}
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const promiseModal = (RenderComponent, { props, isConfirmMessage = false, uniqueID }) => {
  // focus 된 요소 해제
  const activeElement = document.activeElement;
  activeElement.blur();

  // 모달이 떠있을 때, Tab 키 누르면 포커싱 안되도록 수정
  const onTabClick = (event) => {
    const { keyCode } = event;
    const TAB_KEY_CODE = 9;
    if (keyCode === TAB_KEY_CODE) {
      event.preventDefault();
    }
  };
  window.addEventListener('keydown', onTabClick);

  if (uniqueID) {
    const element = document.getElementById(uniqueID);
    if (element) return {};
  }
  const wrapper = document.body.appendChild(document.createElement('div'));
  if (uniqueID) {
    wrapper.id = uniqueID;
  }
  const removeWrapper = () => {
    if (!document.body.contains(wrapper)) return;
    document.body.removeChild(wrapper);
  };
  return new Promise((resolve, reject) => {
    const root = createRoot(wrapper);
    root.render(<RenderComponent {...props} onClose={removeWrapper} resolve={resolve} reject={reject} isConfirmMessage={isConfirmMessage} />);
  }).then((payload) => {
    window.removeEventListener('keydown', onTabClick);
    return payload;
  });
};

const System = {
  alert: (props) => promiseModal(SystemModal, { props, isConfirmMessage: false }),
  confirm: (props) => promiseModal(SystemModal, { props, isConfirmMessage: true }),
};

SystemModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onClose: PropTypes.func,
};
SystemModal.defaultProps = {
  cancelLabel: '닫기',
  confirmLabel: '확인',
};

export default System;
export { promiseModal };
