import { Checkbox, CountButton, Dropdown, ReservationButton, System, ToggleContent } from '@/components';
import styles from 'style/reservation.module.scss';
import educationStyles from './index.module.scss';
import { cx } from '@emotion/css';
import { EMAIL_DOMAINS, EDUCATION_CHECKBOX_LIST } from '@/constants';
import { useCallback, useEffect, useState } from 'react';
import { useCoupon, useValidationHook, useReservationCheckAll } from '@/hooks';
import { findGuideContent, formatPrice } from '@/utils';
import { DISCOUNT_TYPES } from '@/constants/COUPON_TYPES';
import EducationReservationDTO from '@/apis/DTOs/EducationReservationDTO';
import { useRouter } from 'next/router';

// 교육 프로그램 클래스 예약 상태
const CLASS_STATUS = {
  NONE: 'NONE', // 클래스가 없는 상태
  ABLE_TO_RESERVE: 'ABLE_TO_RESERVE', // 예약 가능한 상태
  ABLE_TO_WAIT: 'ABLE_TO_WAIT', // 대기접수 가능한 상태
  DISABLED: 'DISABLED', // 예약 불가능한 상태
};

const EducationReservation = ({
  isWaitingPayment,
  title,
  classes,
  initialForm,
  guides,
  onReservationOrder,
  onReservationFreeOrder,
  onReservationWaitingOrder,
  onReservationWaitingReception,
}) => {
  const router = useRouter();
  const { errorCode } = router.query;
  const [form, setForm] = useState(initialForm);
  const [selectedClass, setSelectedClass] = useState({}); // 선택된 클래스 정보
  const [price, setPrice] = useState({
    visitorPrice: 0,
    discountPrice: 0,
    totalPrice: 0,
  });
  const { checkValidation } = useValidationHook({
    data: form,
    validate: {
      optionId: ['isExist'],
      visitorCount: ['isExist', 'isNumberOverOne'],
      name: ['isExist', 'isNotHavingComma'],
      phone: ['isExist', 'isPhoneNumber'],
      emailID: ['isExist', 'isEmailID'],
      emailDomain: ['isExist', 'isEmailDomain'],
      overFourteenYearsOld: ['isChecked'],
    },
    customValidator: {
      isChecked: (value) => value === 'Y',
    },
  });

  const { isCouponApplicable, searchKeyword, onUpdateKeyword, checkCouponCode, foundCoupon, toggleCoupon } = useCoupon({
    form,
    setForm,
    visitorPrice: price.visitorPrice,
    isEducationReservation: true,
  });

  const { isCheckAll, checkAll } = useReservationCheckAll({
    setForm,
    agreementList: { overFourteenYearsOld: form.overFourteenYearsOld, privacyCollectionAgreement: form.privacyCollectionAgreement },
    isInAgreementObject: false,
  });

  useEffect(() => {
    const openEducationReservationErrorModal = async () => {
      await System.alert({
        title: '오류',
        text: '결제에 실패하였습니다.\n잠시 후 다시 시도해주세요.',
      });
      const { query, pathname } = router;
      const params = new URLSearchParams(query);
      params.delete('errorCode');

      return router.replace({ pathname, query: params.toString() });
    };
    if (!!errorCode) openEducationReservationErrorModal();
  }, [errorCode, router]);

  // 선택한 optionId가 변경되면
  // class 정보를 새로 setting,
  // 티켓 가격 계산
  useEffect(() => {
    const selectedClass = classes.find((item) => Number(item.optionId) === Number(form.optionId));
    setSelectedClass(selectedClass || {});
    if (selectedClass && !!form.couponInfo) {
      const { price } = selectedClass;
      const visitorPrice = price * form.visitorCount;
      let discountPrice = 0;
      const { couponId, discountType, value } = form.couponInfo;
      if (couponId) {
        switch (discountType) {
          case DISCOUNT_TYPES.PERCENT:
            discountPrice = Math.floor(visitorPrice * (value / 100));
            break;
          case DISCOUNT_TYPES.PRICE:
            discountPrice = Math.floor(value);
            break;
        }
      }
      setPrice({
        visitorPrice,
        discountPrice,
        totalPrice: visitorPrice - discountPrice,
      });
    } else {
      setPrice({
        visitorPrice: 0,
        discountPrice: 0,
        totalPrice: 0,
      });
    }
  }, [classes, form.optionId, form.couponInfo, form.visitorCount]);

  // 클래스 상태 구하는 함수
  const getClassStatus = useCallback(
    (classItem) => {
      const { availableSeat, waitSeat } = classItem;
      if (Object.keys(classItem).length === 0) {
        return CLASS_STATUS.NONE;
      } else if (availableSeat >= form.visitorCount) {
        return CLASS_STATUS.ABLE_TO_RESERVE;
      } else if (availableSeat === 0 && waitSeat >= form.visitorCount) {
        return CLASS_STATUS.ABLE_TO_WAIT;
      } else return CLASS_STATUS.DISABLED;
    },
    [form.visitorCount]
  );

  const onChange = (event) => {
    if (isWaitingPayment) return;

    const { name, type, value, checked, pattern } = event.target;

    if (pattern && value) {
      const regex = new RegExp(pattern);
      const test = regex.test(value);
      if (!test) return;
    }

    setForm((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? (checked ? 'Y' : 'N') : value,
      ...(name === 'visitorCount' && { optionId: null }), // 인원수 변경시 선택된 class 초기화
    }));
  };

  // 현재 선택한 클래스 상태
  const currentClassStatus = getClassStatus(selectedClass);

  const onSubmit = async (event) => {
    const isValid = checkValidation();
    if (!isValid) return;

    event.target.classList.add(styles.disabled);
    // 대기 접수의 경우
    if (!isWaitingPayment && currentClassStatus === CLASS_STATUS.ABLE_TO_WAIT) {
      const parseForm = EducationReservationDTO.parseForm(form);
      await onReservationWaitingReception(parseForm);
    }
    // 예약 주문(무료)의 경우
    else if (!isWaitingPayment && currentClassStatus === CLASS_STATUS.ABLE_TO_RESERVE && price.totalPrice === 0) {
      const parseForm = EducationReservationDTO.parseForm(form);
      await onReservationFreeOrder(parseForm);
    }
    // 예약 주문의 경우
    else if (!isWaitingPayment && currentClassStatus === CLASS_STATUS.ABLE_TO_RESERVE) {
      const parseForm = {
        ...EducationReservationDTO.parseForm(form),
        ...EducationReservationDTO.orderInformation(form),
      };
      await onReservationOrder(parseForm);
    }
    // 대기 주문의 경우
    else if (isWaitingPayment) {
      const parseForm = EducationReservationDTO.parseForm(form);
      const submitForm = EducationReservationDTO.orderInformation(form);
      await onReservationWaitingOrder(parseForm, submitForm);
    }
    event.target.classList.remove(styles.disabled);
  };

  return (
    <div className={styles.reservationContainer}>
      <sup className={educationStyles.educationTitleSuperscript}>
        / 교육프로그램<sub>Education</sub>
      </sup>
      <h1 className={educationStyles.educationTitle}>{title}</h1>
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>예약 인원</h2>
        <sup className={styles.subTitleSuperscript}>Number of Participant</sup>
      </div>
      <div className={styles.admissionContainer}>
        <div className={styles.countButtonWrapper}>
          <CountButton
            buttonType={'minus'}
            disabled={isWaitingPayment || Number(form.visitorCount) <= 1}
            name="visitorCount"
            value={Number(form.visitorCount) - 1}
            onClick={onChange}
          />
        </div>
        <div>{form.visitorCount} 명</div>
        <div className={styles.countButtonWrapper}>
          <CountButton buttonType={'plus'} disabled={isWaitingPayment} name="visitorCount" value={Number(form.visitorCount) + 1} onClick={onChange} />
        </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>클래스</h2>
        <sup className={styles.subTitleSuperscript}>Class</sup>
      </div>
      <ul className={educationStyles.classContainer}>
        {isWaitingPayment ? (
          <li>
            <button className={cx(educationStyles.classItem, educationStyles.notEditableClassItem)} disabled>
              {selectedClass.name}
            </button>
          </li>
        ) : (
          classes
            .filter((classItem) => classItem.showType === 'SHOW' && classItem.status === 'PROGRESS')
            .map((classItem) => {
              const { optionId, name } = classItem;
              const isSelected = Number(form.optionId) === Number(optionId);
              const classStatus = getClassStatus(classItem);
              return (
                <li key={optionId}>
                  <button
                    className={cx(educationStyles.classItem, isSelected && educationStyles.selected)}
                    disabled={classStatus === CLASS_STATUS.DISABLED}
                    name={'optionId'}
                    value={optionId}
                    onClick={onChange}
                  >
                    {name}
                    {classStatus === CLASS_STATUS.ABLE_TO_WAIT && <sup className={educationStyles.waitingTag}>대기접수</sup>}
                  </button>
                </li>
              );
            })
        )}
      </ul>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>예약자명</h2>
        <sup className={styles.subTitleSuperscript}>Name</sup>
      </div>
      <input
        className={styles.inputForm}
        type="text"
        name={'name'}
        value={form.name}
        onChange={onChange}
        placeholder="이름을 입력해주세요."
        maxLength="20"
        autoComplete="off"
        disabled={isWaitingPayment}
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>휴대폰 번호</h2>
        <sup className={styles.subTitleSuperscript}>Phone Number</sup>
      </div>
      <input
        className={styles.inputForm}
        type="text"
        pattern={'^[0-9]+$'}
        name={'phone'}
        value={form.phone}
        onChange={onChange}
        placeholder="- 없이 숫자만 입력"
        maxLength="11"
        autoComplete="off"
        disabled={isWaitingPayment}
      />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={cx(styles.subTitle, styles.required)}>이메일</h2>
        <sup className={styles.subTitleSuperscript}>Email</sup>
      </div>
      <div className={styles.emailFormContainer}>
        <div className={styles.emailForm}>
          <input
            className={cx(styles.inputForm, styles.emailInput)}
            type="text"
            name="emailID"
            value={form.emailID}
            onChange={onChange}
            maxLength="20"
            autoComplete="off"
            disabled={isWaitingPayment}
          />
          <span className={styles.at}>@</span>
          <input
            className={cx(styles.inputForm, styles.emailInput)}
            type="text"
            name="emailDomain"
            value={form.emailDomain}
            onChange={onChange}
            maxLength="40"
            autoComplete="off"
            disabled={isWaitingPayment}
          />
        </div>
        <div className={cx(styles.dropdownWrapper, styles.emailDropdownWrapper)}>
          <Dropdown
            height={50}
            defaultColor={'#eaeaea'}
            name="emailDomain"
            selected={form.emailDomain}
            onChange={onChange}
            defaultValue="직접 입력"
            optionList={EMAIL_DOMAINS.map((domain) => ({
              name: domain,
              value: domain,
            }))}
            disabled={isWaitingPayment}
          />
        </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>요청사항</h2>
        <sup className={styles.subTitleSuperscript}>Request</sup>
      </div>
      <textarea className={cx('scroll', styles.textAreaForm)} name="requirements" value={form.requirements} onChange={onChange} maxLength="200" disabled={isWaitingPayment} />
      <div className={styles.lineBreak} />
      {(isWaitingPayment || currentClassStatus === CLASS_STATUS.ABLE_TO_RESERVE) && (
        <>
          <div className={styles.subTitleContainer}>
            <h2 className={styles.subTitle}>할인코드</h2>
            <sup className={styles.subTitleSuperscript}>Coupon</sup>
          </div>
          <div className={styles.couponInputContainer}>
            <input
              className={styles.inputForm}
              type="text"
              value={searchKeyword}
              onChange={onUpdateKeyword}
              disabled={!isCouponApplicable}
              placeholder={'할인코드를 입력해주세요.'}
              maxLength="15"
              autoComplete="off"
            />
            <button className={styles.button} onClick={checkCouponCode} disabled={!isCouponApplicable}>
              확인
            </button>
          </div>
          {foundCoupon && (
            <div>
              <input type="checkbox" id="coupon" onChange={toggleCoupon} hidden />
              <label className={cx(styles.coupon, form.couponDescription && styles.appliedCoupon)} htmlFor="coupon">
                <div>
                  <div className={cx(styles.couponPrice, styles.highlighter)}>{formatPrice(foundCoupon.value, foundCoupon.unit)}</div>
                  <div className={styles.couponDescription}>{foundCoupon.name}</div>
                </div>
                <div className={styles.couponDate}>{foundCoupon.endDate}까지</div>
              </label>
            </div>
          )}
        </>
      )}
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>이용약관 동의</h2>
        <sup className={styles.subTitleSuperscript}>Agreement to the terms and condition for use</sup>
      </div>
      <ul className={styles.checkList}>
        <li className={styles.checkItem}>
          <Checkbox checked={isCheckAll()} onChange={checkAll} disabled={isWaitingPayment}>
            전체 동의
          </Checkbox>
        </li>
        {EDUCATION_CHECKBOX_LIST.map((checkbox) => (
          <li key={checkbox.type} className={styles.checkItem}>
            <Checkbox name={checkbox.type} checked={form[checkbox.type] === 'Y'} onChange={onChange} disabled={isWaitingPayment}>
              <div>
                {checkbox.isRequired ? '[필수] ' : <span className={styles.optionalCheck}>(선택) </span>}
                {checkbox.name}
              </div>
            </Checkbox>
            {checkbox.guideType && <ToggleContent innerHTML={findGuideContent(guides, checkbox.guideType)} />}
          </li>
        ))}
      </ul>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>유의사항</h2>
        <sup className={styles.subTitleSuperscript}>Notice</sup>
      </div>
      <div>
        <div className={educationStyles.noticeTitle}>프로그램 취소 및 환불</div>
        <ul>
          <li className={educationStyles.noticeItem}>프로그램의 취소는 아래 환불 규정 확인 후 담당자(064-749-8404)에게 별도 문의 주시기 바랍니다.</li>
          <li className={educationStyles.noticeItem}>온라인 프로그램은 수업 시작 7일 전까지 100% 환불, 이후 환불 불가</li>
          <li className={educationStyles.noticeItem}>오프라인 프로그램은 수업 시작 3일 전까지 100% 환불 가능, 이후 환불 불가</li>
        </ul>
      </div>
      <div className={styles.dividingLine} />
      {(isWaitingPayment || currentClassStatus === CLASS_STATUS.ABLE_TO_RESERVE) && (
        <>
          <div className={styles.subTitleContainer}>
            <h2 className={styles.subTitle}>결제 정보</h2>
            <sup className={styles.subTitleSuperscript}>Payment Information</sup>
          </div>
          <ul className={styles.ticketList}>
            <li className={styles.ticketItem}>
              <span>프로그램 금액</span>
              <span className={styles.ticketContent}>{formatPrice(price.visitorPrice, '원', '0원')}</span>
            </li>
            {price.discountPrice > 0 && (
              <li className={cx(styles.ticketItem, styles.ticketDiscountItem)}>
                <span>할인 금액</span>
                <span className={styles.ticketContent}>{`-${formatPrice(price.discountPrice, '원', '0원')}`}</span>
              </li>
            )}
          </ul>
        </>
      )}
      <ReservationButton
        totalPriceLabel={'총 결제 금액'}
        totalPrice={(isWaitingPayment || currentClassStatus === CLASS_STATUS.ABLE_TO_RESERVE) && formatPrice(price.totalPrice, '', 0)}
      >
        <button
          className={cx(
            styles.footerButton,
            styles.nextButton,
            !isWaitingPayment && currentClassStatus === CLASS_STATUS.ABLE_TO_WAIT && educationStyles.waitingButton,
            !isWaitingPayment && (currentClassStatus === CLASS_STATUS.NONE || currentClassStatus === CLASS_STATUS.DISABLED) && styles.disabled
          )}
          // disabled={currentClassStatus === CLASS_STATUS.NONE || currentClassStatus === CLASS_STATUS.DISABLED}
          onClick={onSubmit}
        >
          {(() => {
            if (isWaitingPayment) return '결제하기';
            switch (currentClassStatus) {
              case CLASS_STATUS.NONE:
                return '클래스 미선택';
              case CLASS_STATUS.ABLE_TO_RESERVE:
                return '결제하기';
              case CLASS_STATUS.ABLE_TO_WAIT:
                return '대기접수';
              case CLASS_STATUS.DISABLED:
                return '예약 불가';
            }
          })()}
        </button>
      </ReservationButton>
    </div>
  );
};
export default EducationReservation;
