import { useRouter } from 'next/router';
import getConfig from 'next/config';
import { LoginTerms } from '@/components';
import { useBodyClassEffect } from '@/hooks';

const { publicRuntimeConfig } = getConfig();

const Login = ({ guidesData }) => {
  useBodyClassEffect('subHeader2');
  const router = useRouter();
  const { query } = router;
  const { redirectURL } = query;
  const redirectQueryString = redirectURL ? '?redirectURL=' + redirectURL : '';

  const nexonLogin = async () => {
    const { confirm } = await LoginTerms.open({ guidesData });
    if (!confirm) return;
    const option = {};
    option.redirect_uri = `${publicRuntimeConfig.baseURL}/auth/nexon/callback/login${redirectQueryString}`;
    inface.auth.gotoSignIn(option);
  };

  const nonMemberLogin = async () => {
    const { confirm } = await LoginTerms.open({ guidesData });
    if (!confirm) return;
    sessionStorage.setItem('did-agree-to-login-terms', 'true');
    router.push(`/login/nonmember${redirectQueryString}`);
  };

  return (
    <div id="content">
      <div className="cnts">
        <div className="loginBody">
          <h2 className={'sTit'}>
            / 로그인 <span>Login</span>
          </h2>
          <div className="loginContent">
            <ul>
              <li>
                예약 및 조회를 위해
                <br />
                로그인이 필요합니다
              </li>
              <li>
                <button type="button" onClick={nexonLogin}>
                  <dl>
                    <dt>Nexon Login</dt>
                    <dd>넥슨 로그인</dd>
                  </dl>
                </button>
                <button type="button" onClick={nonMemberLogin}>
                  <dl>
                    <dt>Non-Member Login</dt>
                    <dd>비회원 로그인</dd>
                  </dl>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Login };
export { default as Nonmember } from './Nonmember';
