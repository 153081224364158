import styled from '@emotion/styled';
import rightArrow from 'public/static/images/components/button/icon_arrow_right.png';

const Button = styled.button`
  position: relative;
  transition: background-size 300ms linear;
  background-image: linear-gradient(to top, #e3ff7c 100%, #fff);
  background-repeat: no-repeat;
  background-size: 0 100%;
  display: flex;
  align-items: center;
  width: auto;
  color: #272627;
  font-size: 46px;
  font-weight: 500;
  letter-spacing: -1.5px;
  & > .border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    transform: translateY(100%);
    height: ${(props) => props.borderWidth}px;
    transition: width 300ms linear;
    background-color: #272627;
  }
  &:hover {
    background-size: 100% 100%;
  }
  &:focus {
    & > .border {
      width: 100%;
    }
  }
  &::after {
    display: inline-block;
    width: 51px;
    height: 51px;
    margin-left: 25px;
    content: '';
    background-image: url(${rightArrow.src});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 100% 100%;
  }
  @media (max-width: 1000px) {
    font-size: 30px;
    &::after {
      width: 44px;
      height: 44px;
      margin-left: 19px;
    }
  }
`;

const Styled = {
  Button,
};

export default Styled;
