import { useEffect, useState } from 'react';
import { couponApplicableTickets } from '@/hooks/useCoupon.hooks';
import { COUPON_TYPES, DISCOUNT_TYPES } from '@/constants/COUPON_TYPES';

/**
 * 개인예약 티켓 값 계산을 위한 hook 입니다.
 * @param form
 * @returns {{programPrice: number, totalPrice: number, discountPrice: number, visitorPrice: number}}
 */
const useTicketPrice = ({ form }) => {
  const [price, setPrice] = useState({
    visitorPrice: 0,
    programPrice: 0,
    discountPrice: 0,
    totalPrice: 0,
  });

  useEffect(() => {
    const { discountType, couponType, value } = form.couponInfo;
    const EMPLOYEE_MAX_COUPON_COUNT = 5;
    // 직원할인 여부
    const isEmployeeCoupon = discountType === DISCOUNT_TYPES.PERCENT && couponType === COUPON_TYPES.EMPLOYEE && value <= 100;
    // 일반할인 - 할인율 여부
    const isCommonPercentCoupon = discountType === DISCOUNT_TYPES.PERCENT && couponType === COUPON_TYPES.COMMON && value <= 100;
    // 일반할인 - 할인금액 여부
    const isCommonPriceCoupon = discountType === DISCOUNT_TYPES.PRICE;
    let discountPrice = 0;
    const commonTickets = form.admissions.filter((ticket) => couponApplicableTickets.includes(ticket.code)).sort((a, b) => b.price - a.price);
    // 직원할인 또는 일반 할인율 쿠폰인 경우
    if (isEmployeeCoupon || isCommonPercentCoupon) {
      // 일반할인(전체) 또는 직원할인(5명까지) 적용
      let totalCount = 0;
      for (const ticket of commonTickets) {
        if (isEmployeeCoupon && totalCount >= EMPLOYEE_MAX_COUPON_COUNT) {
          break;
        }
        let discountCount = ticket.count;
        if (isEmployeeCoupon) {
          discountCount = ticket.count + totalCount >= EMPLOYEE_MAX_COUPON_COUNT ? EMPLOYEE_MAX_COUPON_COUNT - totalCount : ticket.count;
        }
        discountPrice += ticket.price * (value / 100) * discountCount;
        totalCount += discountCount;
      }
    }
    const visitorPrice = form.admissions.reduce((acc, cur) => acc + cur.price * cur.count, 0);
    const programPrice = form.programs.reduce((acc, cur) => acc + cur.cost * cur.visitCount, 0);
    // 일반할인 - 할인금액 적용
    if (isCommonPriceCoupon) {
      discountPrice += value;
    }
    const totalPrice = visitorPrice + programPrice - discountPrice;
    if (isNaN(visitorPrice) || isNaN(programPrice) || isNaN(discountPrice) || totalPrice < 0) return;
    setPrice({ visitorPrice, programPrice, totalPrice, discountPrice });
  }, [form.admissions, form.programs, form.couponInfo]);

  return { ...price };
};
export default useTicketPrice;
