import styles from './index.module.scss';
import { cx } from '@emotion/css';

const MainTitle = ({ children, className }) => {
  return (
    <h1 className={cx(styles.mainTitle, className)}>
      /<br />
      {children}
    </h1>
  );
};

export default MainTitle;
