import { cx } from '@emotion/css';

import MoreThingStyle from './MoreThing.module.scss';

const MoreThing = (props) => {
  const { className } = props;
  return (
    <section className={cx(className, MoreThingStyle.moreThingContainer)}>
      <em className={MoreThingStyle.moreThingText}>
        {"There's"}
        <br />
        {'one more thing...'}
      </em>
      <svg className={MoreThingStyle.iconMore} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.636 42.232" fill="#d8d8d8">
        <g data-name="그룹 668">
          <path data-name="사각형 1046" transform="translate(5.047)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1047" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1048" transform="translate(10.094 2.523)" d="M0 0h5.926v5.047H0z" />
          <path data-name="사각형 1049" transform="translate(13.496 7.57)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1050" transform="translate(13.496 12.616)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1051" transform="translate(13.496 17.663)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1052" transform="translate(13.496 22.543)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1053" transform="translate(13.496 27.424)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1054" transform="translate(13.496 32.305)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1055" transform="translate(13.496 37.185)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1056" transform="translate(8.45 32.471)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1057" transform="translate(18.543 32.471)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1058" transform="translate(23.59 27.424)" d="M0 0h5.047v5.047H0z" />
          <path data-name="사각형 1059" transform="translate(3.403 27.424)" d="M0 0h5.047v5.047H0z" />
        </g>
      </svg>
    </section>
  );
};

export default MoreThing;
