const FocusedTitle = () => {
  return (
    <svg data-name="그룹 3112" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 328 196">
      <defs>
        <clipPath id="sd7d07vyqa">
          <path data-name="사각형 15444" style={{ fill: 'none' }} d="M0 0h328v196H0z" />
        </clipPath>
        <clipPath id="ayrsjpzw8b">
          <path data-name="사각형 15445" style={{ fill: 'none' }} d="M0 0h210.738v116.186H0z" />
        </clipPath>
        <clipPath id="whk1ngyuvc">
          <path data-name="사각형 15445" style={{ fill: 'none' }} d="M0 0h295.345v180.985H0z" />
        </clipPath>
        <clipPath id="l0rkfokfed">
          <path data-name="사각형 15437" style={{ fill: 'none' }} d="M0 0h82.766v83.401H0z" />
        </clipPath>
        <clipPath id="kcjprgp9pe">
          <path data-name="사각형 15438" style={{ fill: 'none' }} d="M0 0h47.554v54.227H0z" />
        </clipPath>
        <clipPath id="gejyioaz2f">
          <path data-name="사각형 15439" style={{ fill: 'none' }} d="M0 0h57.682v54.805H0z" />
        </clipPath>
        <clipPath id="01jx1rc6yg">
          <path data-name="사각형 15440" style={{ fill: 'none' }} d="M0 0h73.509v70.528H0z" />
        </clipPath>
        <clipPath id="0eu6s5oq3h">
          <path data-name="사각형 15441" style={{ fill: 'none' }} d="M0 0h50.73v69.544H0z" />
        </clipPath>
        <clipPath id="qra074z00i">
          <path data-name="사각형 15442" style={{ fill: 'none' }} d="M0 0h21.541v74.906H0z" />
        </clipPath>
        <clipPath id="ry1kghbisj">
          <path data-name="사각형 15443" style={{ fill: 'none' }} d="M0 0h46.022v56.233H0z" />
        </clipPath>
        <clipPath id="r7j4r5057k">
          <path data-name="사각형 15444" style={{ fill: 'none' }} d="M0 0h52.868v74.258H0z" />
        </clipPath>
      </defs>
      <g data-name="그룹 3106">
        <g data-name="그룹 3009">
          <g data-name="그룹 8122">
            <g data-name="그룹 8149" style={{ clipPath: 'url(#sd7d07vyqa)' }}>
              <g data-name="그룹 8150" transform="translate(1.104 .051)" style={{ clipPath: 'url(#ayrsjpzw8b)' }}>
                <path data-name="패스 15173" d="M105.369 0c58.253 0 105.476 26.009 105.476 58.093s-47.223 58.093-105.476 58.093S-.107 90.177-.107 58.093 47.116 0 105.369 0" />
                <ellipse data-name="타원 243" cx="94.855" cy="48.194" rx="94.855" ry="48.194" transform="translate(10.697 8.679)" style={{ stroke: '#404040', fill: 'none' }} />
              </g>
              <g data-name="그룹 8151" style={{ clipPath: 'url(#whk1ngyuvc)' }} transform="translate(31.206 15.05)">
                <g data-name="그룹 8129" style={{ isolation: 'isolate' }}>
                  <g data-name="그룹 8128">
                    <g data-name="그룹 8127" style={{ clipPath: 'url(#l0rkfokfed)' }} transform="translate(40.005 97.073)">
                      <path data-name="패스 14819" d="m97.291 148.092 6.464 2.772-.817 2.63-6.464-2.772z" transform="translate(-72.423 -147.104)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14820" d="m95.788 153.566 6.464 2.772h-1.335l-6.464-2.772z" transform="translate(-71.744 -148.953)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14821"
                        d="M109.139 155.631a17.179 17.179 0 0 0-6.2-.795l-6.464-2.772a17.181 17.181 0 0 1 6.2.795l6.464 2.772"
                        transform="translate(-72.423 -148.446)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14822"
                        d="m102.27 154.276 6.464 2.772c-.83-.356-2.487-.71-5.806-.71l-6.464-2.772c3.319 0 4.976.354 5.806.71"
                        transform="translate(-72.42 -148.953)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14823" d="m63.088 261.247 6.464 2.772-.817 2.63-6.464-2.772z" transform="translate(-60.929 -185.332)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14824" d="m61.585 266.72 6.464 2.772h-1.334l-6.464-2.772z" transform="translate(-60.251 -187.181)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14825" d="m64.753 261.247 6.464 2.772h-1.251l-6.466-2.772z" transform="translate(-61.343 -185.332)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14826"
                        d="m105.813 153.264 6.464 2.772a3.94 3.94 0 0 1 .465.235 3.314 3.314 0 0 1 1.688 3.035 36.477 36.477 0 0 1-1.754 7.207l-6.464-2.772a36.529 36.529 0 0 0 1.754-7.207 3.314 3.314 0 0 0-1.688-3.035 3.94 3.94 0 0 0-.465-.235"
                        transform="translate(-75.561 -148.851)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14827" d="m98.7 169.087 6.464 2.772L89.91 221.22l-6.464-2.772z" transform="translate(-68.045 -154.197)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14828"
                        d="m77.377 243.631 6.464 2.772a52.372 52.372 0 0 1-2.645 7.4 7.566 7.566 0 0 1-3.352 2.976 12.8 12.8 0 0 1-5.993 1.286l-6.465-2.765a12.8 12.8 0 0 0 5.993-1.286 7.565 7.565 0 0 0 3.352-2.976 52.372 52.372 0 0 0 2.645-7.4"
                        transform="translate(-61.976 -179.381)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14829" d="m117.936 148.092 6.464 2.772h-20.231l-6.469-2.772z" transform="translate(-72.837 -147.104)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14830" d="m121.674 151.894 6.464 2.772-10.938 35.292-6.464-2.772z" transform="translate(-77.214 -148.389)" style={{ fill: '#fff' }} />
                      <path
                        data-name="패스 14831"
                        d="m123.094 153.323 6.464 2.772-10.344 33.351-6.464-2.772z"
                        transform="translate(-77.892 -148.871)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14832" d="m113.008 205.191 6.464 2.772H117.2l-6.464-2.772z" transform="translate(-77.214 -166.394)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14833" d="m105.967 208.9 6.464 2.772-6.982 22.428-6.464-2.772z" transform="translate(-73.267 -167.648)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14834" d="m112.354 208.9 6.464 2.772h-2.853L109.5 208.9z" transform="translate(-76.8 -167.648)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14835" d="m112.716 210.4 6.464 2.772h-2.117L110.6 210.4z" transform="translate(-77.169 -168.155)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14836" d="m107.176 210.4 6.464 2.772-6.764 21.716-6.464-2.772z" transform="translate(-73.746 -168.155)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14837"
                        d="M105.263 256.55q.281.139.568.262l-6.464-2.772q-.288-.123-.568-.262a4.331 4.331 0 0 1-2.452-4.261l6.464 2.772-.091-.039-.092-.04-.094-.04-.094-.04-.095-.04-.1-.041-.1-.042-.1-.042-.7-.3-.723-.31-.737-.317-.742-.318-.736-.316-.72-.309L97 249.8l-.658-.282a38.165 38.165 0 0 1 1.752-6.749l6.464 2.772a38.23 38.23 0 0 0-1.752 6.749 4.333 4.333 0 0 0 2.452 4.261"
                        transform="translate(-72.38 -179.089)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14838"
                        d="m106.745 256.052-6.464-2.772a9.971 9.971 0 0 1-.536-.248 3.352 3.352 0 0 1-1.906-3.368c0-.3.17-1.53 1.708-6.463l6.465 2.772c-1.538 4.934-1.706 6.16-1.708 6.463a3.351 3.351 0 0 0 1.906 3.368q.264.132.535.248"
                        transform="translate(-72.882 -179.235)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14839"
                        d="m147.061 150.208 6.464 2.772a21.536 21.536 0 0 0-6.262-1.687q-4.188-.428-12.625-.428l-6.465-2.772q8.444 0 12.625.429a21.537 21.537 0 0 1 6.263 1.687"
                        transform="translate(-83.075 -147.104)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14840"
                        d="M136.643 154.5c-.914 0-1.9.036-2.966.113l-6.465-2.772a41.162 41.162 0 0 1 2.967-.113 25.976 25.976 0 0 1 10.177 1.718l6.464 2.772a25.975 25.975 0 0 0-10.177-1.718"
                        transform="translate(-82.752 -148.331)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14841"
                        d="m140.343 154.868 6.464 2.772a25.09 25.09 0 0 0-9.8-1.643c-.694 0-1.436.024-2.218.066l-6.464-2.772a41.651 41.651 0 0 1 2.218-.065 25.094 25.094 0 0 1 9.8 1.643"
                        transform="translate(-83.127 -148.838)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14842"
                        d="M127.921 212.728a20.67 20.67 0 0 0-7.658-1.053L113.8 208.9a20.684 20.684 0 0 1 7.658 1.054l6.464 2.772"
                        transform="translate(-78.244 -167.648)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14843"
                        d="m121.046 211.374 6.464 2.772a19.879 19.879 0 0 0-7.258-.971l-6.465-2.772a19.88 19.88 0 0 1 7.258.971"
                        transform="translate(-78.241 -168.155)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14844"
                        d="m143.372 173.06 6.464 2.772q-.065 10.069-7.342 15.669t-21.868 5.607l-6.464-2.772q14.6 0 21.868-5.607t7.342-15.669"
                        transform="translate(-78.366 -155.539)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14845"
                        d="M144.689 208.578a64.88 64.88 0 0 1-10.509 1.08l-6.464-2.772a64.8 64.8 0 0 0 10.509-1.08l6.464 2.772"
                        transform="translate(-82.921 -166.602)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14846"
                        d="m129.643 208.2 6.464 2.772q-.926-.4-1.927-.762l-6.464-2.772q1 .364 1.927.762"
                        transform="translate(-82.921 -167.153)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14847"
                        d="m107.36 262.563-6.464-2.772a12.227 12.227 0 0 0 4.869.964l6.464 2.772a12.227 12.227 0 0 1-4.869-.964"
                        transform="translate(-73.909 -184.84)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14848" d="m109.824 261.247 6.464 2.772h-1.6l-6.464-2.772z" transform="translate(-76.373 -185.332)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14849" d="m110.218 261.247 6.464 2.772-.817 2.63-6.464-2.772z" transform="translate(-76.767 -185.332)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14850" d="m93.563 265.219 6.464 2.772H68.735l-6.464-2.772z" transform="translate(-60.929 -186.674)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14851" d="m93.553 266.72 6.464 2.772H68.725l-6.464-2.772z" transform="translate(-60.926 -187.181)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14852" d="m112.449 259.746 6.464 2.772-.4 1.285-6.464-2.773z" transform="translate(-77.657 -184.825)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14853" d="m111.638 261.687 6.464 2.772-.811 2.63-6.464-2.772z" transform="translate(-77.246 -185.481)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14854" d="m110.717 265.66 6.464 2.772-.218.7-6.464-2.772z" transform="translate(-77.136 -186.823)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14855" d="m110.126 266.72 6.464 2.772h-.736l-6.464-2.772z" transform="translate(-76.763 -187.181)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14856"
                        d="m147.007 154.317 6.464 2.772a13.137 13.137 0 0 1 3.051 1.8q4.39 3.517 4.351 10.611l-6.464-2.772q.045-7.1-4.351-10.611a13.138 13.138 0 0 0-3.051-1.8"
                        transform="translate(-89.404 -149.207)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14857"
                        d="m125.331 210.493 6.464 2.772c.068.029.135.059.2.089a7.879 7.879 0 0 1 3.791 4.464q1.35 3.32 1.3 11.265l-6.464-2.772q.051-7.945-1.3-11.266a7.881 7.881 0 0 0-3.791-4.464 6.289 6.289 0 0 0-.2-.089"
                        transform="translate(-82.12 -168.185)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14858" d="m133.251 234.383 6.464 2.772-.089 4.689-6.465-2.772z" transform="translate(-84.751 -176.256)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14859"
                        d="m170.867 159.641-6.464-2.772a18 18 0 0 0-5.48-4.465q-1.158-.626-2.3-1.116l6.464 2.772q1.144.491 2.3 1.116a18 18 0 0 1 5.48 4.465"
                        transform="translate(-92.634 -148.183)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14860"
                        d="M158.3 204.627a34.756 34.756 0 0 1-8.292 2.616l-.756-.324-.8-.344-.831-.356-.846-.362-.846-.363-.831-.356-.8-.344-.756-.324a34.751 34.751 0 0 0 8.293-2.616l6.464 2.772"
                        transform="translate(-88.24 -165.267)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14861"
                        d="m169.981 196.777-.13-.056-.138-.06-.143-.061-.146-.063-.145-.062-.143-.062-.138-.059-.13-.056-.625-.268-.663-.284-.687-.3-.7-.3-.7-.3-.687-.295-.663-.284-.625-.268a20.007 20.007 0 0 0 4.7-5.825l6.464 2.772a20.006 20.006 0 0 1-4.7 5.825"
                        transform="translate(-94.952 -160.647)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14862"
                        d="m177.06 183.391-.071-.03-.075-.032-.078-.033-.079-.034-.08-.034-.078-.034-.076-.032-.07-.03-2.929-1.256-.767-.328-.752-.323-.726-.311-.684-.293a18.379 18.379 0 0 0 2.044-8.523 16.338 16.338 0 0 0-1.157-6.295l6.464 2.772a16.35 16.35 0 0 1 1.158 6.295 18.379 18.379 0 0 1-2.045 8.523"
                        transform="translate(-97.33 -153.087)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14863"
                        d="M146.674 218.889q3.214 5.267 3.554 14.646l-6.465-2.772q-.34-9.382-3.554-14.646l6.464 2.772-6.464-2.772q-2.827-4.625-9.592-7.528l6.464 2.772q6.762 2.9 9.592 7.528"
                        transform="translate(-83.896 -167.542)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14864"
                        d="m146.742 259.957-6.464-2.772a10.052 10.052 0 0 1-3.888-2.906q-3.284-4-3.228-12.813l6.464 2.772q-.056 8.81 3.228 12.813a10.052 10.052 0 0 0 3.888 2.906"
                        transform="translate(-84.751 -178.649)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14865"
                        d="M158.3 254.453a3.973 3.973 0 0 0 2.074 2.014l-6.464-2.772a3.973 3.973 0 0 1-2.074-2.014l6.463 2.772-6.463-2.772q-1.185-2.455-1.422-9.605l6.464 2.772q.239 7.15 1.422 9.605"
                        transform="translate(-90.549 -178.855)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14866"
                        d="m155.3 244.248-6.464-2.772a3.046 3.046 0 0 1-1.589-1.545c-.72-1.506-1.169-4.606-1.323-9.213-.232-6.4-1.474-11.486-3.7-15.122a19.6 19.6 0 0 0-8.1-7.017l6.464 2.772a19.606 19.606 0 0 1 8.1 7.016c2.225 3.637 3.467 8.725 3.7 15.122.155 4.606.6 7.707 1.323 9.213a3.046 3.046 0 0 0 1.589 1.545"
                        transform="translate(-85.074 -167.539)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14867"
                        d="M156.4 269.076a15.288 15.288 0 0 1-6.056-1.1l-6.465-2.772a15.292 15.292 0 0 0 6.057 1.1l6.464 2.772"
                        transform="translate(-88.352 -186.669)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14868"
                        d="m162.144 261.019-6.464-2.772a6.24 6.24 0 0 0 2.471.444q2.9 0 7.488-3.143l6.464 2.772q-4.584 3.143-7.488 3.143a6.24 6.24 0 0 1-2.471-.444"
                        transform="translate(-92.318 -183.407)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14869"
                        d="M172.437 262.383q-6.356 4.8-12.972 4.8l-.756-.324-.8-.343-.831-.357-.846-.362-.846-.363-.831-.356-.8-.344-.755-.324q6.619 0 12.973-4.8l6.464 2.772"
                        transform="translate(-91.417 -184.779)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14870"
                        d="M126.763 222.389q2.9 0 7.487-3.143l1.234 2.69q-6.356 4.8-12.973 4.8-6.663 0-9.944-4t-3.227-12.816l.089-4.689q.052-7.945-1.3-11.266a7.883 7.883 0 0 0-3.791-4.463q-2.439-1.143-7.857-1.143H93.63l-6.982 22.425a38.23 38.23 0 0 0-1.752 6.749 4.334 4.334 0 0 0 2.452 4.261 12.106 12.106 0 0 0 5.437 1.226h1.6l-.818 2.631H62.27l.818-2.631h1.252a12.8 12.8 0 0 0 5.992-1.285 7.565 7.565 0 0 0 3.352-2.976 52.39 52.39 0 0 0 2.645-7.4l15.251-49.36a36.543 36.543 0 0 0 1.755-7.208 3.311 3.311 0 0 0-1.689-3.035c-1.14-.685-3.356-1.03-6.666-1.03l.817-2.631h20.231q8.443 0 12.625.429a23.075 23.075 0 0 1 8.565 2.8 17.369 17.369 0 0 1 6.71 6.207 15.925 15.925 0 0 1 2.311 8.582 18.575 18.575 0 0 1-7.361 14.866q-7.306 5.955-23.155 6.41 8.3 3.026 11.519 8.29t3.554 14.646q.239 7.151 1.422 9.606t4.546 2.458M96.724 185.9q14.6 0 21.868-5.606t7.342-15.67q.046-7.1-4.35-10.611t-13.228-3.513c-.913 0-1.9.036-2.966.113L94.447 185.9h2.277"
                        transform="translate(-60.929 -147.104)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14871"
                        d="M142.219 165.852q-.065 10.069-7.342 15.67t-21.869 5.606h-2.278l10.944-35.291a41.162 41.162 0 0 1 2.967-.113q8.834 0 13.228 3.517t4.351 10.611m-29.2 20.282c9.46 0 16.613-1.815 21.262-5.4s6.911-8.439 6.952-14.884c.029-4.452-1.273-7.665-3.982-9.832-2.738-2.2-6.984-3.3-12.613-3.3-.694 0-1.436.024-2.218.066l-10.344 33.351h.943"
                        transform="translate(-77.213 -148.331)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14872"
                        d="m177.19 166.518-.117-.05-.124-.053-.128-.055-.13-.056-.131-.056-.128-.055-.123-.053-.117-.05-.638-.274-.677-.29-.7-.3-.715-.307-.716-.307-.7-.3-.677-.29-.638-.274a15.581 15.581 0 0 0-1.154-2.287 16.2 16.2 0 0 0-1.23-1.742l6.158 2.641.307.132a16.2 16.2 0 0 1 1.23 1.742 15.579 15.579 0 0 1 1.154 2.287"
                        transform="translate(-96.573 -151.031)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14873"
                        d="m162.5 203.677-.027-.011-.028-.012-.029-.013-.03-.013-.029-.013-.029-.012-.029-.013-.026-.011-.729-.313-.772-.331-.8-.344-.817-.35-.816-.35-.8-.344-.772-.331-.728-.313a22.461 22.461 0 0 0 3.226-1.863l6.465 2.772a22.509 22.509 0 0 1-3.227 1.863"
                        transform="translate(-92.437 -164.317)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14874"
                        d="m167.356 201.117-.223-.1-.237-.1-.246-.105-.25-.107-.25-.107-.246-.105-.237-.1-.223-.1-.532-.228-.564-.242-.585-.251-.6-.255-.6-.256-.585-.251-.564-.242-.532-.228q.587-.41 1.127-.85.315-.256.616-.518l6.464 2.772q-.3.262-.616.518-.54.44-1.127.85"
                        transform="translate(-94.069 -163.619)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14875"
                        d="m149.454 149.914 6.464 2.772q1.188.51 2.383 1.157a18.351 18.351 0 0 1 7.081 6.552 17 17 0 0 1 2.457 9.105 19.633 19.633 0 0 1-7.734 15.64c-4.334 3.529-10.89 5.677-19.519 6.391l-6.464-2.772c8.628-.714 15.185-2.863 19.519-6.392a19.632 19.632 0 0 0 7.733-15.64 17.007 17.007 0 0 0-2.456-9.105 18.353 18.353 0 0 0-7.082-6.552q-1.192-.647-2.382-1.157"
                        transform="translate(-85.074 -147.72)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14876" d="m171.292 253.311 6.464 2.772.485 1.059-6.464-2.772z" transform="translate(-97.564 -182.651)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14877" d="m170.679 255.549 6.464 2.772 1.234 2.69-6.464-2.772z" transform="translate(-97.358 -183.407)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14878" d="m172.023 254.911 6.464 2.772 1.24 2.69-6.464-2.772z" transform="translate(-97.81 -183.191)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14879"
                        d="m149.751 267.4-6.464-2.772a16.258 16.258 0 0 0 6.443 1.183c4.608 0 9.174-1.684 13.573-5.011l6.464 2.772c-4.4 3.327-8.964 5.011-13.573 5.011a16.26 16.26 0 0 1-6.443-1.183"
                        transform="translate(-88.154 -185.18)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14880" d="m174.07 260.07 6.464 2.772-.637.482-6.465-2.772z" transform="translate(-98.283 -184.934)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14881" d="m173.891 258.973 6.464 2.772.333.726-6.464-2.772z" transform="translate(-98.438 -184.564)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14882"
                        d="m134.464 218.319 1.24 2.69.333.726-.637.482c-4.4 3.327-8.965 5.011-13.573 5.011-4.733 0-8.335-1.47-10.707-4.368-2.325-2.833-3.488-7.362-3.449-13.456l.089-4.678c.033-5.142-.376-8.808-1.223-10.885a6.955 6.955 0 0 0-3.3-3.94c-1.49-.7-4-1.053-7.442-1.053H93.68l-6.764 21.716c-1.538 4.934-1.706 6.16-1.708 6.463a3.352 3.352 0 0 0 1.906 3.368 11.078 11.078 0 0 0 5 1.125h2.93l-.4 1.285-.811 2.631-.218.7H60.251l.4-1.279.812-2.631.218-.708h1.987a11.883 11.883 0 0 0 5.535-1.173 6.685 6.685 0 0 0 2.934-2.553 52 52 0 0 0 2.567-7.237L89.955 161.2c1.4-4.613 1.707-6.308 1.71-6.922a2.336 2.336 0 0 0-1.214-2.184c-.673-.4-2.283-.887-6.156-.887h-1.334l.4-1.28.811-2.63.218-.7h20.967c5.635 0 9.916.143 12.726.434a24 24 0 0 1 8.932 2.916 18.357 18.357 0 0 1 7.081 6.553 17.012 17.012 0 0 1 2.457 9.105 19.629 19.629 0 0 1-7.734 15.64c-4.334 3.529-10.89 5.677-19.519 6.392a19.6 19.6 0 0 1 8.1 7.017c2.225 3.636 3.467 8.724 3.7 15.122.155 4.607.6 7.707 1.323 9.213.626 1.3 1.791 1.9 3.665 1.9 1.72 0 4.052-1 6.923-2.976l.965-.66zm-12.631 7.915q6.619 0 12.973-4.8l-1.234-2.69q-4.584 3.143-7.488 3.143c-2.248 0-3.761-.815-4.545-2.458s-1.262-4.838-1.422-9.605q-.341-9.382-3.554-14.646t-11.519-8.29q15.849-.456 23.155-6.41a18.578 18.578 0 0 0 7.361-14.866 15.916 15.916 0 0 0-2.312-8.582 17.374 17.374 0 0 0-6.709-6.208 23.1 23.1 0 0 0-8.565-2.8q-4.188-.428-12.625-.428h-20.23l-.818 2.631c3.31 0 5.526.345 6.666 1.03a3.312 3.312 0 0 1 1.688 3.035 36.478 36.478 0 0 1-1.755 7.2l-15.249 49.36a52.37 52.37 0 0 1-2.645 7.4 7.565 7.565 0 0 1-3.352 2.976 12.8 12.8 0 0 1-5.993 1.286h-1.252l-.817 2.63h31.292l.817-2.63h-1.6a12.1 12.1 0 0 1-5.437-1.226 4.331 4.331 0 0 1-2.452-4.261 38.18 38.18 0 0 1 1.752-6.749l6.982-22.424H95.8q5.419 0 7.857 1.142a7.882 7.882 0 0 1 3.791 4.464q1.35 3.32 1.3 11.266l-.09 4.689q-.056 8.811 3.228 12.813t9.945 4.005"
                        transform="translate(-60.251 -146.6)"
                        style={{ fill: '#4e57ff' }}
                      />
                    </g>
                  </g>
                </g>
                <g data-name="그룹 8132" style={{ isolation: 'isolate' }}>
                  <g data-name="그룹 8131">
                    <g data-name="그룹 8130" style={{ clipPath: 'url(#kcjprgp9pe)' }} transform="translate(119.092 126.473)">
                      <path
                        data-name="패스 14883"
                        d="m200.671 232.761-.331-.142-.351-.15-.365-.156-.37-.159-.736-.316-.351-.15-.331-.142-.424-.181-.45-.193-.941-.4-.475-.2-.466-.2-.45-.193-.424-.182q.5-1.71 1.1-3.485l6.464 2.772q-.608 1.774-1.1 3.485"
                        transform="translate(-184.352 -202.994)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14884"
                        d="m212.006 193.413 6.464 2.772a11.863 11.863 0 0 0-4.727-.922q-6.148 0-12.7 5.034a32.945 32.945 0 0 0-10.114 13.129 37.961 37.961 0 0 0-3.607 14.956q-.058 9.035 3.839 12.694a13.6 13.6 0 0 0 3.961 2.637l-6.464-2.772a13.574 13.574 0 0 1-3.958-2.641q-3.9-3.66-3.84-12.694a37.963 37.963 0 0 1 3.607-14.956 32.954 32.954 0 0 1 10.114-13.129q6.553-5.035 12.7-5.034a11.866 11.866 0 0 1 4.727.922"
                        transform="translate(-179.865 -191.503)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14885"
                        d="m197.847 251.277-.11-.047-.116-.05-.12-.052-.123-.052-.122-.053-.121-.052-.116-.05-.11-.047-.646-.277-.685-.293-.71-.3-.724-.311-.723-.31-.71-.3-.685-.294-.645-.277a8.855 8.855 0 0 1-.486-3.049 50.637 50.637 0 0 1 2.2-13.688l6.464 2.772a50.683 50.683 0 0 0-2.2 13.688 8.856 8.856 0 0 0 .487 3.05"
                        transform="translate(-183.239 -204.772)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14886"
                        d="m201.325 263.623-.049-.021-.052-.023-.054-.023-.055-.023-.055-.024-.054-.023-.052-.023-.049-.021-.706-.3-.749-.321-.776-.333-.791-.339-.791-.339-.776-.333-.748-.321-.706-.3a5.545 5.545 0 0 1-2-1.594 7.581 7.581 0 0 1-1.236-2.212l6.464 2.772a7.581 7.581 0 0 0 1.236 2.212 5.56 5.56 0 0 0 2 1.594"
                        transform="translate(-183.485 -213.312)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14887"
                        d="M204.86 212.533q-1.441 3.37-2.526 6.537l-.755-.324-.8-.343-.831-.356-.846-.363-.846-.363-.831-.356-.8-.344-.755-.324q1.085-3.167 2.526-6.537 5.763-13.461 13.6-13.468a7.693 7.693 0 0 1 3.047.554l6.464 2.772a7.693 7.693 0 0 0-3.047-.554q-7.838 0-13.6 13.468"
                        transform="translate(-184.91 -192.788)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14888"
                        d="m213.478 198.264 6.464 2.772a6.722 6.722 0 0 0-2.66-.47c-4.769 0-9.031 4.327-12.686 12.861-3.769 8.814-5.7 16.664-5.743 23.323a7.021 7.021 0 0 0 1.507 4.636 4.6 4.6 0 0 0 1.8 1.389L195.691 240a4.6 4.6 0 0 1-1.8-1.389 7.018 7.018 0 0 1-1.507-4.636c.042-6.659 1.973-14.509 5.743-23.323 3.654-8.534 7.917-12.861 12.686-12.861a6.723 6.723 0 0 1 2.661.47"
                        transform="translate(-183.74 -193.295)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14889"
                        d="m215.335 197.129 6.465 2.771a5.056 5.056 0 0 1 1.924 1.422q1.65 1.973 1.629 5.916-.05 7.668-5.569 22.192t-13.919 14.527a6.657 6.657 0 0 1-2.649-.5l-6.464-2.772a6.657 6.657 0 0 0 2.649.5q8.4 0 13.919-14.527t5.569-22.192q.026-3.946-1.629-5.916a5.049 5.049 0 0 0-1.923-1.422"
                        transform="translate(-185.207 -193.07)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14890"
                        d="m202.96 265.564.078.036-.755-.324-.8-.343-.831-.357-.846-.362-.846-.363-.831-.356-.8-.344-.755-.324-.078-.036 6.043 2.592c.14.06.282.119.421.181"
                        transform="translate(-185.121 -215.254)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14891"
                        d="m203.078 265.618.086.038-6.465-2.772-.086-.038 4.909 2.105c.518.222 1.038.44 1.556.667"
                        transform="translate(-185.16 -215.272)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14892"
                        d="m203.208 265.676-6.464-2.772.755.324.8.344.831.356.846.362 3.232 1.387"
                        transform="translate(-185.204 -215.291)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14893"
                        d="m238.667 200.055-6.464-2.772a11.777 11.777 0 0 0-4.433-3.4l6.464 2.772a11.772 11.772 0 0 1 4.433 3.4"
                        transform="translate(-195.63 -191.974)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14894"
                        d="m228.369 192.514 6.464 2.772a12.7 12.7 0 0 1 4.793 3.669l-6.464-2.772a12.7 12.7 0 0 0-4.793-3.669"
                        transform="translate(-195.831 -191.511)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14895"
                        d="M210.322 196.293a6.1 6.1 0 0 1 4.97 1.976q1.651 1.973 1.629 5.915-.049 7.669-5.569 22.192t-13.92 14.524a5.819 5.819 0 0 1-4.814-2.172 8.065 8.065 0 0 1-1.722-5.261q.065-10.239 5.828-23.71t13.6-13.468m.106 29.738c3.62-9.534 5.47-16.878 5.5-21.847.015-2.375-.453-4.154-1.4-5.279a5.151 5.151 0 0 0-4.218-1.618c-4.769 0-9.031 4.327-12.686 12.861-3.769 8.814-5.7 16.664-5.743 23.323a7.021 7.021 0 0 0 1.507 4.636 4.852 4.852 0 0 0 4.046 1.809c5.119 0 9.49-4.672 12.989-13.884"
                        transform="translate(-183.239 -192.788)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14896"
                        d="M204.207 267.8a12.9 12.9 0 0 1-5.138-1.023l-6.464-2.772a12.9 12.9 0 0 0 5.138 1.023q5.827 0 11.908-4.261l6.465 2.771q-6.077 4.267-11.908 4.261"
                        transform="translate(-183.813 -214.571)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14897"
                        d="m224.742 242.679-6.464-2.772a32.55 32.55 0 0 0 10.142-12.265l6.465 2.77-6.464-2.772a37.46 37.46 0 0 0 4.117-16.93q.047-7.374-3.526-11.694l6.464 2.772q3.575 4.321 3.526 11.694a37.455 37.455 0 0 1-4.118 16.931 32.557 32.557 0 0 1-10.143 12.265"
                        transform="translate(-192.44 -193.708)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14898"
                        d="M216.694 195.671c2.525 3.047 3.791 7.2 3.759 12.331a38.694 38.694 0 0 1-4.227 17.378 33.8 33.8 0 0 1-10.461 12.635c-4.206 2.946-8.4 4.44-12.478 4.44a13.871 13.871 0 0 1-9.773-3.928c-2.795-2.625-4.189-7.135-4.149-13.42a39.164 39.164 0 0 1 3.693-15.355 34.067 34.067 0 0 1 10.424-13.515c4.524-3.475 9-5.237 13.3-5.237a12.343 12.343 0 0 1 9.91 4.672m-1.35 29.263a37.447 37.447 0 0 0 4.12-16.935q.048-7.374-3.525-11.694a11.373 11.373 0 0 0-9.16-4.321q-6.148 0-12.7 5.035a32.942 32.942 0 0 0-10.114 13.128 37.952 37.952 0 0 0-3.607 14.956q-.058 9.034 3.839 12.694a12.862 12.862 0 0 0 9.1 3.66q5.827 0 11.908-4.262a32.556 32.556 0 0 0 10.143-12.265"
                        transform="translate(-179.364 -190.999)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14899"
                        d="M216.438 196.813q3.575 4.321 3.526 11.694a37.455 37.455 0 0 1-4.118 16.931A32.559 32.559 0 0 1 205.7 237.7q-6.077 4.267-11.907 4.261a12.864 12.864 0 0 1-9.1-3.66q-3.9-3.661-3.839-12.694a37.959 37.959 0 0 1 3.607-14.956 32.945 32.945 0 0 1 10.114-13.129q6.554-5.035 12.7-5.034a11.372 11.372 0 0 1 9.159 4.321m-8.461 28.28q5.52-14.524 5.569-22.192.025-3.946-1.629-5.916a6.107 6.107 0 0 0-4.97-1.976q-7.838 0-13.6 13.468t-5.828 23.71a8.068 8.068 0 0 0 1.722 5.261 5.819 5.819 0 0 0 4.814 2.173q8.4 0 13.919-14.527"
                        transform="translate(-179.865 -191.504)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14900"
                        d="m198.475 246.5-6.464-2.772a13.886 13.886 0 0 0 5.526 1.105c4.075 0 8.272-1.494 12.478-4.439a33.817 33.817 0 0 0 10.461-12.635 38.7 38.7 0 0 0 4.228-17.378c.033-5.136-1.234-9.284-3.759-12.331l6.464 2.772c2.525 3.047 3.791 7.2 3.759 12.331a38.689 38.689 0 0 1-4.227 17.377 33.8 33.8 0 0 1-10.461 12.635c-4.206 2.946-8.4 4.44-12.478 4.44a13.886 13.886 0 0 1-5.526-1.105"
                        transform="translate(-183.614 -193.383)"
                        style={{ fill: '#4e57ff' }}
                      />
                    </g>
                  </g>
                </g>
                <g data-name="그룹 8135" style={{ isolation: 'isolate' }}>
                  <g data-name="그룹 8134">
                    <g data-name="그룹 8133" style={{ clipPath: 'url(#gejyioaz2f)' }} transform="translate(165.63 126.181)">
                      <path data-name="패스 14901" d="m250.944 214.116 6.464 2.772h-1.488l-6.464-2.772z" transform="translate(-249.455 -198.517)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14902"
                        d="m269.115 192.584 6.464 2.772a6.809 6.809 0 0 0-2.712-.524q-3.95 0-8.049 3.8a28.17 28.17 0 0 0-6.646 9.808l-6.464-2.772a28.161 28.161 0 0 1 6.646-9.808q4.1-3.8 8.049-3.8a6.809 6.809 0 0 1 2.712.524"
                        transform="translate(-250.212 -191.066)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14903" d="m254.714 212.615 6.464 2.772h-3.007l-6.464-2.772z" transform="translate(-250.212 -198.01)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14904" d="m254.7 214.116 6.464 2.772h-3.007l-6.464-2.772z" transform="translate(-250.208 -198.517)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14905" d="m256.849 214.116 6.464 2.772h-.623l-6.464-2.772z" transform="translate(-251.73 -198.517)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14906" d="m257.435 213.28 6.464 2.772-.27.554-6.464-2.772z" transform="translate(-252.046 -198.235)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14907"
                        d="m267.477 205.071-6.465-2.772q2.3-3.257 4.384-3.975l3.232 1.387 3.232 1.386q-2.085.718-4.384 3.974"
                        transform="translate(-253.339 -193.182)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14908"
                        d="m274.151 201.061-.071.024-6.464-2.772.071-.024 6.464 2.772"
                        transform="translate(-255.558 -193.17)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14909"
                        d="M265.872 204.967c-1.025 1.451-2.082 3.281 5.543-4.177l-8.715 9.665 2.251-12.437-8.715 9.665a41.448 41.448 0 0 1 3.171-5.487l2.29.981.692.3.344.148 3.139 1.346"
                        transform="translate(-251.734 -193.079)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14910"
                        d="m265.863 199.579 6.464 2.772a1.263 1.263 0 0 0-.469-.06c-1.518 0-4.108 1.577-7.822 9.111l-6.464-2.772c3.714-7.535 6.3-9.112 7.822-9.112a1.263 1.263 0 0 1 .469.06"
                        transform="translate(-252.183 -193.586)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14911"
                        d="M274.187 200.97a.168.168 0 0 1-.05-.022l-.447-.191-.114-.049-.111-.048-.106-.046-.37-.159-5.265-2.258a3.519 3.519 0 0 1 1.089-.179 2.217 2.217 0 0 1 .871.152l6.464 2.772a2.217 2.217 0 0 0-.871-.152 3.52 3.52 0 0 0-1.089.179"
                        transform="translate(-255.594 -193.079)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14912"
                        d="m270.18 198.248 6.464 2.772a1.293 1.293 0 0 1 .75 1.336 27.141 27.141 0 0 1-1.726 5.493l-6.464-2.772a27.151 27.151 0 0 0 1.726-5.493 1.293 1.293 0 0 0-.75-1.336"
                        transform="translate(-256.092 -193.157)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14913" d="m265.234 208.561 6.464 2.772-7.848 20.532-6.464-2.772z" transform="translate(-252.12 -196.641)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14914"
                        d="m277.925 192.851 6.464 2.772a6.038 6.038 0 0 1 1.448.881 5.057 5.057 0 0 1 1.7 4.2q-.012 2-2.246 8l-6.464-2.772q2.227-6 2.246-8a5.057 5.057 0 0 0-1.7-4.2 6.05 6.05 0 0 0-1.448-.881"
                        transform="translate(-259.022 -191.333)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14915"
                        d="m278.521 191.471 6.464 2.772a7.04 7.04 0 0 1 1.681 1.02 6.029 6.029 0 0 1 2.062 4.975c-.009 1.464-.768 4.2-2.313 8.356l-6.465-2.772c1.545-4.16 2.3-6.892 2.313-8.356a6.031 6.031 0 0 0-2.062-4.975 7.04 7.04 0 0 0-1.681-1.02"
                        transform="translate(-259.222 -190.867)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14916" d="m275.216 212.615 6.464 2.772-8.036 21.163-6.464-2.772z" transform="translate(-255.411 -198.01)" style={{ fill: '#fff' }} />
                      <path
                        data-name="패스 14917"
                        d="m272.921 248.66-.334-.143-.353-.152-.367-.158-.374-.16-.374-.16-.367-.158-.354-.152-.334-.143-.421-.181-.447-.191-.463-.2-.472-.2-.472-.2-.464-.2-.447-.191-.421-.181q.226-.631.48-1.313l6.464 2.772q-.254.682-.48 1.313"
                        transform="translate(-255.168 -208.807)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14918"
                        d="m276.606 213.145 6.464 2.772-8.035 21.157-6.465-2.774z"
                        transform="translate(-255.878 -198.189)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14919"
                        d="m263.951 260.2-6.464-2.772a7.124 7.124 0 0 1-2.23-1.494 7.457 7.457 0 0 1-2.218-5.553q.02-3.258 2.886-10.807l6.464 2.772q-2.868 7.552-2.886 10.807a7.457 7.457 0 0 0 2.218 5.553 7.136 7.136 0 0 0 2.23 1.494"
                        transform="translate(-250.659 -207.116)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14920"
                        d="M271.024 258.83a2.707 2.707 0 0 0 .9.615l-6.464-2.772a2.718 2.718 0 0 1-.9-.616 3.712 3.712 0 0 1-.861-2.69q.011-1.731 1.832-6.811l6.469 2.773q-1.821 5.079-1.832 6.811a3.718 3.718 0 0 0 .86 2.69"
                        transform="translate(-254.241 -209.477)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14921"
                        d="m272.82 258.041-6.464-2.772a1.733 1.733 0 0 1-.579-.394 2.818 2.818 0 0 1-.586-2c0-.809.4-2.809 2.244-7.778l6.464 2.772c-1.846 4.97-2.239 6.969-2.244 7.778a2.818 2.818 0 0 0 .586 2 1.739 1.739 0 0 0 .579.394"
                        transform="translate(-254.742 -208.984)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14922" d="m312 193.183 6.464 2.772-1.082 3.261-6.464-2.772z" transform="translate(-270.109 -191.446)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14923"
                        d="m272.812 243.078-6.464-2.772a4.214 4.214 0 0 0 1.669.3q5.517 0 13.171-10.7t14.753-31.8l6.464 2.772q-7.1 21.1-14.753 31.8t-13.17 10.7a4.216 4.216 0 0 1-1.67-.3"
                        transform="translate(-255.132 -193.11)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14924"
                        d="M270.168 264.979a10 10 0 0 1-3.966-.731l-6.464-2.772a9.988 9.988 0 0 0 3.966.731q7.806 0 15.816-10.643l5.4 2.315.573.246.074.032.145.062.139.06a.823.823 0 0 0 .132.056q-8.01 10.646-15.816 10.644"
                        transform="translate(-252.91 -211.169)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14925"
                        d="m296 249.524-3.8-1.628-2.668-1.143a80.776 80.776 0 0 0 6.009-9.431l6.459 2.772a80.744 80.744 0 0 1-6 9.43"
                        transform="translate(-262.922 -206.357)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14926"
                        d="m265.613 264.195-6.464-2.772a10.971 10.971 0 0 0 4.35.812c6.181 0 12.235-4.446 18.4-13.533l6.464 2.772c-6.161 9.088-12.216 13.533-18.4 13.533a10.974 10.974 0 0 1-4.351-.811"
                        transform="translate(-252.712 -210.202)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14927" d="m320.075 193.183 6.464 2.772h-7.527l-6.464-2.772z" transform="translate(-270.656 -191.446)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14928" d="m325.74 191.682 6.464 2.772-.447 1.309-6.465-2.772z" transform="translate(-274.939 -190.938)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14929"
                        d="m302.792 259.618-6.464-2.772a4.569 4.569 0 0 1-1.475-.991 6.276 6.276 0 0 1-1.507-4.636q.028-4.339 3.475-13.9l6.465 2.772q-3.451 9.552-3.476 13.9a6.276 6.276 0 0 0 1.507 4.636 4.577 4.577 0 0 0 1.475.991"
                        transform="translate(-264.204 -206.357)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14930" d="m318.224 193.183 6.464 2.772-11.183 32.833-6.464-2.772z" transform="translate(-268.806 -191.446)" style={{ fill: '#fff' }} />
                      <path
                        data-name="패스 14931"
                        d="m313.434 245.54-.143.428-.755-.324-.8-.343-.831-.356-.846-.363-.846-.363-.831-.356-.8-.343-.755-.324.143-.428 6.464 2.772"
                        transform="translate(-268.734 -208.197)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14932"
                        d="m310.719 251.594-.176-.075-.187-.08-.193-.083-.2-.085-.2-.084-.194-.083-.186-.079-.176-.076-.58-.249-.613-.263-.637-.273-.649-.278-.649-.278-.637-.273-.614-.264-.579-.248q.623-2.157 1.707-5.407l6.464 2.772q-1.084 3.25-1.707 5.407"
                        transform="translate(-267.87 -208.416)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14933"
                        d="m319.633 193.66 6.464 2.772-11.183 32.827-6.464-2.772z"
                        transform="translate(-269.279 -191.606)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14934"
                        d="m309.459 257.989-6.464-2.772a21.828 21.828 0 0 1 .837-3.637l6.464 2.772a21.824 21.824 0 0 0-.837 3.637"
                        transform="translate(-267.446 -211.174)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14935"
                        d="M310.061 262.181a1.886 1.886 0 0 0 .694.5l-6.464-2.772a1.879 1.879 0 0 1-.694-.5 3.134 3.134 0 0 1-.616-2.142c0-.059 0-.123.009-.193l6.465 2.772c-.005.07-.009.134-.009.193a3.132 3.132 0 0 0 .615 2.143"
                        transform="translate(-267.441 -213.03)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14936"
                        d="m311.651 257.1-6.464-2.772a.875.875 0 0 1-.343-.247 2.3 2.3 0 0 1-.37-1.488c0-.559.348-2.523 2.64-9.355l6.464 2.772c-2.291 6.832-2.637 8.8-2.64 9.355a2.3 2.3 0 0 0 .37 1.488.874.874 0 0 0 .343.247"
                        transform="translate(-267.943 -208.355)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14937"
                        d="m311.417 259.425-6.464-2.772a2.989 2.989 0 0 0 1.182.213q4.76 0 9.087-9.433l6.465 2.772q-4.322 9.436-9.088 9.433a2.99 2.99 0 0 1-1.182-.213"
                        transform="translate(-268.104 -209.773)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14938"
                        d="M307.425 267.619a7.919 7.919 0 0 1-3.123-.55l-6.464-2.772a7.919 7.919 0 0 0 3.123.55q5.814 0 10.123-5.469l6.464 2.772q-4.309 5.471-10.123 5.47"
                        transform="translate(-265.713 -213.808)"
                        style={{ fill: '#fff' }}
                      />
                      <path data-name="패스 14939" d="m323.053 247.433 6.464 2.772h-2.633l-6.464-2.772z" transform="translate(-273.302 -209.773)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14940"
                        d="m324.251 258.113-6.464-2.772a32.406 32.406 0 0 0 4.382-7.909l6.464 2.772a32.406 32.406 0 0 1-4.382 7.909"
                        transform="translate(-272.417 -209.773)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14941"
                        d="M266.4 192.06a6.365 6.365 0 0 1 4.16 1.4 5.057 5.057 0 0 1 1.7 4.2q-.013 2-2.246 8l-8.036 21.163q-2.3 6.177-2.312 8.123a3.716 3.716 0 0 0 .86 2.69 3.4 3.4 0 0 0 2.568.917q5.517 0 13.171-10.7t14.753-31.8l1.082-3.253h7.527l-11.183 32.833q-2.684 8.008-2.7 9.665a3.139 3.139 0 0 0 .615 2.143 2.352 2.352 0 0 0 1.876.714q4.76 0 9.088-9.433h2.633q-5.415 13.381-14.5 13.378a6.193 6.193 0 0 1-4.6-1.541 6.279 6.279 0 0 1-1.507-4.636q.028-4.339 3.476-13.9-11.107 20.076-21.825 20.073a8.425 8.425 0 0 1-6.2-2.226 7.462 7.462 0 0 1-2.218-5.553q.021-3.258 2.887-10.807l7.848-20.532a27.189 27.189 0 0 0 1.726-5.493c.006-.988-.536-1.488-1.622-1.488q-3.95 0-8.715 9.665h-3.007a28.161 28.161 0 0 1 6.646-9.808q4.1-3.8 8.049-3.8"
                        transform="translate(-250.212 -191.066)"
                        style={{ fill: '#fff' }}
                      />
                      <path data-name="패스 14942" d="m326.316 245.932 6.464 2.772-.548 1.357-6.464-2.772z" transform="translate(-275.098 -209.266)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14943"
                        d="M299.213 227.225h1.459l-.548 1.357c-3.761 9.3-8.951 14.009-15.428 14.009a7.168 7.168 0 0 1-5.3-1.833 7.254 7.254 0 0 1-1.793-5.338 27.126 27.126 0 0 1 1.031-6.362c-6.161 9.088-12.216 13.533-18.4 13.533a9.373 9.373 0 0 1-6.888-2.518 8.435 8.435 0 0 1-2.51-6.255c.015-2.315.981-5.963 2.954-11.159l7.841-20.526c1.545-4.089 1.663-4.975 1.664-5.148 0-.34 0-.494-.637-.494-1.518 0-4.109 1.577-7.823 9.111l-.27.553h-5.119l.584-1.375a29.345 29.345 0 0 1 6.886-10.152c2.911-2.7 5.85-4.071 8.727-4.071a7.354 7.354 0 0 1 4.783 1.625 6.028 6.028 0 0 1 2.062 4.975c-.009 1.464-.768 4.2-2.313 8.356l-8.036 21.157c-1.847 4.97-2.239 6.969-2.244 7.778a2.818 2.818 0 0 0 .586 2 2.452 2.452 0 0 0 1.858.613c3.321 0 7.484-3.458 12.361-10.278 5.027-7.017 9.941-17.628 14.615-31.536l1.089-3.261.23-.685h9.614l-.448 1.31-11.174 32.829c-2.292 6.832-2.637 8.8-2.64 9.355a2.294 2.294 0 0 0 .37 1.488c.082.1.329.381 1.136.381 2.74 0 5.494-2.982 8.183-8.855l.27-.583h3.269zM284.7 241.6q9.092 0 14.5-13.378h-2.633q-4.322 9.436-9.088 9.433a2.35 2.35 0 0 1-1.876-.714 3.136 3.136 0 0 1-.615-2.143q.01-1.661 2.7-9.665l11.185-32.833h-7.527l-1.083 3.262q-7.1 21.1-14.753 31.8t-13.17 10.7a3.4 3.4 0 0 1-2.568-.916 3.716 3.716 0 0 1-.86-2.69q.013-1.946 2.312-8.123l8.036-21.163q2.227-6 2.246-8a5.057 5.057 0 0 0-1.7-4.2 6.367 6.367 0 0 0-4.16-1.4q-3.95 0-8.049 3.8a28.152 28.152 0 0 0-6.646 9.808h3.007q4.768-9.659 8.715-9.665c1.086 0 1.628.5 1.621 1.488a27.167 27.167 0 0 1-1.726 5.493l-7.847 20.532q-2.869 7.552-2.887 10.807a7.462 7.462 0 0 0 2.218 5.553 8.424 8.424 0 0 0 6.2 2.226q10.72 0 21.825-20.073-3.451 9.552-3.476 13.9a6.275 6.275 0 0 0 1.507 4.636 6.189 6.189 0 0 0 4.6 1.542"
                        transform="translate(-249.455 -190.559)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14944"
                        d="m303.708 264.129-6.464-2.772a8.874 8.874 0 0 0 3.51.634c6.477 0 11.667-4.714 15.428-14.009l6.464 2.772c-3.761 9.3-8.951 14.009-15.428 14.009a8.888 8.888 0 0 1-3.51-.634"
                        transform="translate(-265.514 -209.958)"
                        style={{ fill: '#4e57ff' }}
                      />
                    </g>
                  </g>
                </g>
                <g data-name="그룹 8138" style={{ isolation: 'isolate' }}>
                  <g data-name="그룹 8137">
                    <g data-name="그룹 8136" style={{ clipPath: 'url(#01jx1rc6yg)' }} transform="translate(221.837 110.457)">
                      <path data-name="패스 14945" d="m341.87 194.477 6.464 2.772-.894 2.518-6.464-2.772z" transform="translate(-336.416 -176.159)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14946" d="m340.261 199.78 6.464 2.772h-1.394l-6.464-2.772z" transform="translate(-335.707 -177.951)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14947" d="m350.917 194.477 6.464 2.772h-8.594l-6.464-2.772z" transform="translate(-336.868 -176.159)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14948" d="m349.629 198.279 6.464 2.772h-8.653l-6.464-2.772z" transform="translate(-336.416 -177.444)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14949" d="m348.25 199.78 6.464 2.772h-7.283l-6.465-2.772z" transform="translate(-336.412 -177.951)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14950" d="m361.142 168.306 6.464 2.772-5.876 17.33-6.464-2.772z" transform="translate(-341.218 -167.317)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14951"
                        d="m349.836 198.279 6.464 2.772-8.246 24.364-6.464-2.772z"
                        transform="translate(-336.622 -177.444)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14952" d="m371.643 168.306 6.464 2.772h-7.527l-6.464-2.772z" transform="translate(-344.192 -167.317)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14953" d="m377.3 166.814 6.464 2.772-.441 1.3-6.465-2.772z" transform="translate(-348.474 -166.813)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14954" d="m372.475 168.306 6.464 2.772-5.882 17.33-6.464-2.772z" transform="translate(-345.024 -167.317)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14955" d="m374.11 168.782 6.464 2.772-5.435 16.027-6.464-2.772z" transform="translate(-345.724 -167.478)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14956" d="m360.978 198.279 6.464 2.772-8.434 25-6.465-2.772z" transform="translate(-340.303 -177.444)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14957" d="m362.157 199.78 6.464 2.772-8.2 24.317-6.465-2.772z" transform="translate(-340.776 -177.951)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14958"
                        d="m346.041 258.842-6.464-2.772a6.1 6.1 0 0 1-2.1-1.493 7.29 7.29 0 0 1-1.877-5.2q.018-2.687 3.977-14.3l6.464 2.772q-3.962 11.613-3.977 14.3a7.292 7.292 0 0 0 1.877 5.2 6.1 6.1 0 0 0 2.1 1.493"
                        transform="translate(-334.609 -189.875)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14959"
                        d="m352.851 263.219-.7-.3-.723-.31-.737-.316-.742-.318-.736-.316-.72-.309-.694-.3-.334-.143a1.978 1.978 0 0 1-.324-.139l2.808 1.2 2.9 1.244"
                        transform="translate(-338.488 -198.556)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14960"
                        d="M353.281 255.183a1.985 1.985 0 0 0 .7.5l-6.464-2.772a1.985 1.985 0 0 1-.7-.5l.658.282.343.148 1.807.775 3.656 1.567-.755-.324-5.709-2.448a2.958 2.958 0 0 1-.645-2.082l6.464 2.772-.277-.119-.284-.122-.292-.124-.3-.128-.3-.13-.307-.132-.311-.133-.314-.135-.528-.226-.53-.228-.529-.226-.523-.224-.515-.22-.5-.216-.485-.208-.466-.2q.013-2.008 4.231-14.3l6.465 2.772q-4.216 12.292-4.231 14.3a2.958 2.958 0 0 0 .645 2.083"
                        transform="translate(-338.162 -190.196)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14961"
                        d="m351.838 236.5 6.464 2.772c-3.626 10.569-4.172 13.295-4.176 13.986a2.032 2.032 0 0 0 .388 1.41l-6.464-2.768a2.033 2.033 0 0 1-.388-1.41c0-.691.55-3.416 4.176-13.986"
                        transform="translate(-338.663 -190.357)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14962"
                        d="m355.075 262.786-6.464-2.772a.949.949 0 0 1-.363-.258l6.464 2.772a.949.949 0 0 0 .363.258"
                        transform="translate(-338.859 -198.212)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14963" d="m376 194.477 6.464 2.772h-9.406l-6.465-2.772z" transform="translate(-345.024 -176.159)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14964" d="m380.308 194.477 6.464 2.772-.894 2.518-6.464-2.772z" transform="translate(-349.332 -176.159)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14965" d="m374.653 198.279 6.464 2.772h-9.406l-6.465-2.772z" transform="translate(-344.571 -177.444)" style={{ fill: '#fff' }} />
                      <path data-name="패스 14966" d="m375 199.78 6.464 2.772h-8.7l-6.464-2.772z" transform="translate(-344.928 -177.951)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14967" d="m382.631 192.986 6.464 2.772-.471 1.315-6.464-2.773z" transform="translate(-350.255 -175.655)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14968" d="m381.708 194.972 6.464 2.772-.894 2.518-6.464-2.772z" transform="translate(-349.803 -176.326)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14969" d="m380.1 199.78 6.464 2.772h-.7l-6.464-2.772z" transform="translate(-349.329 -177.951)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 14970" d="m380.694 198.774 6.464 2.772-.236.667-6.464-2.772z" transform="translate(-349.683 -177.61)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14971"
                        d="m354.661 257.429-6.464-2.772a3.052 3.052 0 0 0 1.211.218q2.251 0 5.915-3.886a40.417 40.417 0 0 0 6.587-9.784l6.464 2.772a40.4 40.4 0 0 1-6.587 9.784q-3.663 3.891-5.915 3.886a3.052 3.052 0 0 1-1.211-.218"
                        transform="translate(-338.842 -191.945)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14972" d="m371.607 241.2 6.464 2.772h-2.758l-6.464-2.772z" transform="translate(-345.782 -191.945)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14973"
                        d="M351.053 261.479a7.547 7.547 0 0 1-3-.567l-6.464-2.772a7.545 7.545 0 0 0 3 .567l6.464 2.772-6.464-2.772q4.075 0 9.026-4.487t8.832-13.016l6.464 2.772q-3.88 8.526-8.831 13.015t-9.027 4.487"
                        transform="translate(-336.622 -191.945)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14974"
                        d="m362.061 168.306-5.882 17.33h9.407l-.894 2.518h-9.406l-8.434 25q-4.216 12.292-4.231 14.3a2.955 2.955 0 0 0 .645 2.083 2.435 2.435 0 0 0 1.911.714q2.251 0 5.915-3.886a40.4 40.4 0 0 0 6.587-9.784h2.758q-3.88 8.525-8.831 13.015t-9.027 4.487a6.6 6.6 0 0 1-5.1-2.059 7.288 7.288 0 0 1-1.876-5.2q.017-2.688 3.977-14.3l8.246-24.364h-8.656l.894-2.518h8.594l5.876-17.33z"
                        transform="translate(-334.61 -167.317)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14975"
                        d="M347.464 262.038 341 259.266a8.524 8.524 0 0 0 3.386.645c2.959 0 6.219-1.594 9.691-4.749 3.389-3.071 6.436-7.558 9.071-13.337l6.466 2.772c-2.636 5.779-5.684 10.266-9.072 13.337-3.473 3.155-6.732 4.749-9.693 4.749a8.52 8.52 0 0 1-3.384-.645"
                        transform="translate(-336.424 -192.155)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 14976" d="m374.995 239.712 6.464 2.772-.637 1.4-6.464-2.772z" transform="translate(-347.633 -191.441)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14977"
                        d="M365.09 184.143h1.394l-.471 1.316-.894 2.517-.236.667h-9.395l-8.2 24.317c-3.626 10.569-4.172 13.295-4.176 13.986a2.032 2.032 0 0 0 .388 1.41 1.408 1.408 0 0 0 1.184.4c.831 0 2.412-.619 5.195-3.577a39.5 39.5 0 0 0 6.413-9.54l.277-.554h4.9l-.637 1.4c-2.635 5.779-5.683 10.266-9.071 13.337-3.473 3.154-6.733 4.749-9.693 4.749a7.55 7.55 0 0 1-5.815-2.375 8.285 8.285 0 0 1-2.145-5.88c.013-1.922 1.336-6.7 4.032-14.617l7.8-23.055h-8.677l.471-1.321.894-2.518.235-.661h8.582l5.646-16.657.23-.673h9.609l-.442 1.3-5.435 16.027zm-.9 3.505.894-2.518h-9.406l5.882-17.33h-7.527l-5.876 17.33h-8.594l-.894 2.518h8.653l-8.246 24.364q-3.962 11.614-3.977 14.3a7.292 7.292 0 0 0 1.877 5.2 6.6 6.6 0 0 0 5.1 2.059q4.074 0 9.026-4.487t8.832-13.015h-2.758a40.4 40.4 0 0 1-6.587 9.784q-3.663 3.893-5.914 3.886a2.436 2.436 0 0 1-1.912-.714 2.957 2.957 0 0 1-.645-2.082q.013-2.009 4.232-14.3l8.434-25h9.407"
                        transform="translate(-334.108 -166.813)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14978"
                        d="m411.208 192.664 6.464 2.772a8.374 8.374 0 0 0-3.319-.6q-5.711 0-13.427 6.463a44.9 44.9 0 0 0-11.949 15.385q-4.23 8.926-4.272 16.014-.036 5.606 3.17 8.9a10.49 10.49 0 0 0 3.394 2.342l-6.464-2.772a10.5 10.5 0 0 1-3.395-2.342q-3.208-3.294-3.169-8.9.045-7.088 4.272-16.014a44.914 44.914 0 0 1 11.949-15.385q7.72-6.463 13.428-6.463a8.374 8.374 0 0 1 3.319.6"
                        transform="translate(-348.938 -175.342)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14979"
                        d="M408.843 209.383q-5.158 6.669-8.355 17.36l-6.464-2.772q3.2-10.694 8.355-17.36l6.464 2.772"
                        transform="translate(-354.241 -180.258)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14980"
                        d="M417.492 202.287a27.772 27.772 0 0 0-4.421 4.7l-5.709-2.449-.756-.324a27.827 27.827 0 0 1 4.422-4.7l6.464 2.772"
                        transform="translate(-358.47 -177.861)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14981"
                        d="m419.925 202.056-.195.153-2.386-1.024-.846-.363-.846-.363-2.386-1.023c.064-.052.129-.1.194-.153l6.464 2.772"
                        transform="translate(-360.708 -177.783)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14982"
                        d="m420.023 201.466-.339-.145-.359-.154-.372-.16-.379-.163-.379-.162-.372-.16-.359-.154-.338-.145-.417-.179-.442-.189-.459-.2-.467-.2-.467-.2-.458-.2-.442-.189-.416-.179a10.9 10.9 0 0 1 1.813-1.158l6.464 2.772a10.931 10.931 0 0 0-1.813 1.158"
                        transform="translate(-360.806 -177.192)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14983"
                        d="M423.042 200.1c-.1.042-.191.088-.288.136l-.755-.324-.8-.344-.831-.356-.846-.363-.846-.363-.831-.356-.8-.343-.755-.324c.1-.048.193-.093.288-.136l6.464 2.772"
                        transform="translate(-361.723 -177.123)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14984"
                        d="M397.834 254.936a4.773 4.773 0 0 0 1.787 1.31l-6.464-2.772a4.763 4.763 0 0 1-1.787-1.31 8.227 8.227 0 0 1-1.53-5.493 28.537 28.537 0 0 1 1.816-9.439l6.463 2.768a28.535 28.535 0 0 0-1.816 9.438 8.232 8.232 0 0 0 1.53 5.493"
                        transform="translate(-352.835 -190.603)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14985"
                        d="m400.53 255.731-6.464-2.772a3.827 3.827 0 0 1-1.437-1.055 7.327 7.327 0 0 1-1.3-4.844 27.386 27.386 0 0 1 1.6-8.678l6.464 2.772a27.366 27.366 0 0 0-1.6 8.677 7.324 7.324 0 0 0 1.3 4.844 3.834 3.834 0 0 0 1.438 1.055"
                        transform="translate(-353.336 -190.992)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14986"
                        d="m423.187 199.84-.21-.09-.222-.1-.231-.1-.235-.1-.235-.1-.231-.1-.222-.1-.21-.089-.545-.234-.578-.248-.6-.258-.611-.262-.611-.262-.6-.257-.578-.248-.545-.234a5.408 5.408 0 0 1 2.2-.514l6.464 2.772-6.464-2.772a4.745 4.745 0 0 1 1.878.336l6.464 2.772a4.745 4.745 0 0 0-1.878-.336 5.4 5.4 0 0 0-2.2.514"
                        transform="translate(-361.869 -176.861)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14987"
                        d="m413.583 198.309 6.464 2.772a3.791 3.791 0 0 0-1.492-.254c-2.147 0-4.955 2.166-8.13 6.273a50.1 50.1 0 0 0-7.545 14.985l-6.464-2.772a50.1 50.1 0 0 1 7.545-14.985c3.176-4.107 5.984-6.273 8.13-6.273a3.79 3.79 0 0 1 1.492.254"
                        transform="translate(-355.045 -177.368)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14988"
                        d="M399.545 269.928a12.455 12.455 0 0 1-4.953-.943l-6.464-2.772a12.453 12.453 0 0 0 4.953.943l6.465 2.772"
                        transform="translate(-352.26 -200.394)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14989"
                        d="m422.863 197.061 6.464 2.772a2.905 2.905 0 0 1 1.027.724 4.617 4.617 0 0 1 .952 3.231l-6.464-2.772a4.617 4.617 0 0 0-.952-3.231 2.917 2.917 0 0 0-1.027-.724"
                        transform="translate(-363.933 -177.032)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14990"
                        d="m415.151 203.034 6.464 2.772q-.03 4.749-6.527 10.581a50.024 50.024 0 0 1-14.6 9.147l-6.464-2.772a50.025 50.025 0 0 0 14.6-9.147q6.5-5.838 6.527-10.581"
                        transform="translate(-354.241 -179.05)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14991"
                        d="M408.854 268.4a17.605 17.605 0 0 1-6.8 1.333l-.754-.324-.8-.343-.831-.357-.846-.362-.847-.363-.831-.356-.8-.344-.754-.324a17.6 17.6 0 0 0 6.8-1.333l6.465 2.772"
                        transform="translate(-354.768 -200.195)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14992"
                        d="M419.423 214.1q-6.807 5.7-20.384 10.95l-6.464-2.772a89.2 89.2 0 0 0 16.558-8.131l6.464 2.772-.755-.324-.8-.344-.831-.356-.846-.363-.846-.363-.831-.356-.8-.343-.755-.324a41.5 41.5 0 0 0 3.826-2.819l6.464 2.771-6.372-2.733c-.031-.013-.056-.068-.092-.038q6.806-5.687 6.849-11.867l6.464 2.772-6.464-2.772a8.152 8.152 0 0 0-.437-2.782l6.464 2.772-.184-.079-.187-.08-.191-.082-.194-.083-.2-.084-.2-.085-.2-.087-.2-.087-.621-.266-.631-.271-.636-.273-.633-.271-.625-.268-.61-.262-.59-.252-.561-.241a6.819 6.819 0 0 0-1.352-2.282l6.464 2.772-6.464-2.772a5.572 5.572 0 0 0-1.994-1.426l6.464 2.772a5.567 5.567 0 0 1 1.994 1.426 7.258 7.258 0 0 1 1.789 5.064q-.04 6.178-6.849 11.867m5.851-15.853-.371-.159-.384-.165-.4-.17-.4-.173-.413-.177-.418-.179-.422-.181-.424-.181-.424-.182-.422-.181-.418-.179-.412-.177-.405-.173-.4-.17-.384-.165-.371-.159.371.159.384.165.4.17.405.173.412.177.418.179.422.181.424.182.424.181.422.181.418.179.413.177.4.173.4.17.384.165.371.159"
                        transform="translate(-353.755 -175.651)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14993"
                        d="m401.3 258.262-6.464-2.772a7.183 7.183 0 0 0 2.846.517q8.585 0 15.188-12.807l6.465 2.772q-6.6 12.811-15.189 12.808a7.183 7.183 0 0 1-2.846-.517"
                        transform="translate(-354.514 -192.619)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14994"
                        d="m412.3 265.568-1.915-.821-.532-.228-.563-.242-.585-.251-.6-.255-.595-.256-.585-.251-.563-.242-.532-.228a21.411 21.411 0 0 0 7.6-5.543l6.464 2.772a21.409 21.409 0 0 1-7.6 5.543"
                        transform="translate(-358.209 -197.367)"
                        style={{ fill: '#fff' }}
                      />
                      <path data-name="패스 14995" d="m425.009 243.2 6.464 2.772h-3.013L422 243.2z" transform="translate(-363.641 -192.619)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 14996"
                        d="m423.735 255.277-.163-.07-.172-.074-.179-.077-.182-.078-.182-.078-.179-.076-.172-.074-.163-.07-.592-.254-.628-.27-.652-.279-.664-.285-1.316-.564-.628-.27-.592-.254a40.762 40.762 0 0 0 6.15-9.305l6.464 2.772a40.76 40.76 0 0 1-6.15 9.305"
                        transform="translate(-362.053 -192.619)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 14997"
                        d="M406.8 224.422h4.6l-.714 1.428c-5.534 11.111-12.747 16.741-21.436 16.741a12.16 12.16 0 0 1-9.051-3.582c-2.316-2.375-3.476-5.6-3.45-9.594.031-4.85 1.5-10.385 4.37-16.432a46.1 46.1 0 0 1 12.212-15.729c5.307-4.44 10.037-6.7 14.064-6.7a7.7 7.7 0 0 1 6.046 2.363 8.256 8.256 0 0 1 2.04 5.725c-.029 4.4-2.458 8.653-7.21 12.629-4.526 3.785-11.335 7.469-20.236 10.95a27.366 27.366 0 0 0-1.6 8.677 7.329 7.329 0 0 0 1.3 4.844 4.765 4.765 0 0 0 3.882 1.488c5.3 0 10.11-4.13 14.307-12.271l.276-.542zM389.256 241.6q12.482 0 20.548-16.181h-3.013q-6.6 12.81-15.189 12.807a5.739 5.739 0 0 1-4.633-1.827 8.237 8.237 0 0 1-1.53-5.493 28.55 28.55 0 0 1 1.816-9.438q13.575-5.258 20.384-10.95t6.849-11.867a7.255 7.255 0 0 0-1.789-5.064 6.746 6.746 0 0 0-5.313-2.03q-5.712 0-13.428 6.463a44.914 44.914 0 0 0-11.948 15.38q-4.23 8.926-4.272 16.014-.036 5.607 3.169 8.9a11.15 11.15 0 0 0 8.348 3.285"
                        transform="translate(-348.436 -174.835)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14998"
                        d="M415.151 200.845q-.031 4.75-6.527 10.581a50.021 50.021 0 0 1-14.6 9.147q3.2-10.694 8.355-17.36t8.916-6.659a3.729 3.729 0 0 1 2.906 1.059 4.617 4.617 0 0 1 .952 3.231m-19.54 17.961a49.211 49.211 0 0 0 12.359-8.117c4.093-3.678 6.172-6.987 6.19-9.844a3.75 3.75 0 0 0-.69-2.559 2.8 2.8 0 0 0-2.184-.738c-2.147 0-4.955 2.166-8.13 6.273a50.1 50.1 0 0 0-7.545 14.985"
                        transform="translate(-354.241 -176.861)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 14999"
                        d="m416.81 191.592 6.464 2.772a6.534 6.534 0 0 1 2.342 1.679 8.255 8.255 0 0 1 2.039 5.725c-.028 4.4-2.457 8.653-7.21 12.629-4.526 3.785-11.335 7.469-20.236 10.95l-6.465-2.772c8.9-3.481 15.71-7.165 20.236-10.95 4.753-3.976 7.182-8.225 7.21-12.629a8.255 8.255 0 0 0-2.039-5.725 6.534 6.534 0 0 0-2.342-1.679"
                        transform="translate(-354.148 -175.184)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15000"
                        d="M392.1 238.73q8.586 0 15.189-12.807h3.013q-8.066 16.184-20.548 16.181a11.15 11.15 0 0 1-8.348-3.285q-3.208-3.294-3.169-8.9.045-7.089 4.272-16.015a44.914 44.914 0 0 1 11.949-15.385q7.72-6.462 13.428-6.463a6.748 6.748 0 0 1 5.314 2.03 7.255 7.255 0 0 1 1.788 5.064q-.04 6.178-6.849 11.867t-20.384 10.95a28.57 28.57 0 0 0-1.816 9.438 8.237 8.237 0 0 0 1.53 5.493 5.74 5.74 0 0 0 4.633 1.827m4.97-37.035q-5.158 6.669-8.355 17.36a50.033 50.033 0 0 0 14.6-9.147q6.5-5.838 6.527-10.581a4.617 4.617 0 0 0-.952-3.231 3.729 3.729 0 0 0-2.906-1.059q-3.764 0-8.916 6.659"
                        transform="translate(-348.938 -175.342)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15001"
                        d="m394 262.344-6.464-2.772a13.426 13.426 0 0 0 5.339 1.026c8.689 0 15.9-5.63 21.436-16.741l6.465 2.772c-5.534 11.111-12.747 16.741-21.436 16.741a13.437 13.437 0 0 1-5.34-1.026"
                        transform="translate(-352.062 -192.841)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 15002" d="m428.576 241.7 6.464 2.772-.715 1.428-6.464-2.772z" transform="translate(-365.612 -192.112)" style={{ fill: '#4e57ff' }} />
                    </g>
                  </g>
                </g>
                <g data-name="그룹 8141" style={{ isolation: 'isolate' }}>
                  <g data-name="그룹 8140">
                    <g data-name="그룹 8139" style={{ clipPath: 'url(#0eu6s5oq3h)' }} transform="translate(0 4.268)">
                      <path data-name="패스 15003" d="m20.2 7.937 6.923 4.619h-18L2.2 7.937z" transform="translate(-.739 -6.95)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15004" d="m23.135 17.785 6.923 4.615h-9l-6.923-4.619z" transform="translate(-4.749 -10.277)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15005" d="m14.039 17.785 6.923 4.615-.183 25.04-6.923-4.619z" transform="translate(-4.656 -10.277)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15006" d="m23.626 19.276 6.923 4.619h-8.01l-6.924-4.619z" transform="translate(-5.247 -10.78)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15007" d="m15.53 19.276 6.923 4.619-.169 23.06-6.924-4.619z" transform="translate(-5.162 -10.78)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15008"
                        d="M46.96 24.932C44.2 23.116 40.08 22.4 34.615 22.4l-6.923-4.619c5.464 0 9.584.711 12.344 2.527l6.923 4.619"
                        transform="translate(-9.305 -10.277)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15009"
                        d="m39.5 21.674 6.923 4.619c-2.5-1.665-6.34-2.4-11.819-2.4l-6.923-4.619c5.478 0 9.322.732 11.819 2.4"
                        transform="translate(-9.302 -10.78)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15010"
                        d="m40.167 21.655 6.923 4.619c2.791 1.862 4.177 4.87 4.143 9.44-.066 9.006-5.325 13.037-16.385 13.037l-6.923-4.619c11.06 0 16.319-4.031 16.385-13.037.033-4.57-1.352-7.578-4.143-9.44"
                        transform="translate(-9.384 -11.584)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15011"
                        d="M53.207 26.221c-2.313-1.532-4.616-3.079-6.923-4.619l.053.036 6.924 4.619-.054-.036"
                        transform="translate(-15.553 -11.566)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15012"
                        d="m45.9 11.777 6.92 4.623c-4.07-2.716-9.781-3.841-16.585-3.841l-6.923-4.623c6.8 0 12.514 1.125 16.585 3.841"
                        transform="translate(-9.85 -6.949)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15013"
                        d="m55.11 12.481 6.923 4.619q.222.148.438.3l-6.923-4.619q-.216-.154-.438-.3"
                        transform="translate(-18.519 -8.485)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 15014" d="m23.2 55.6 6.923 4.619h-9.344L13.856 55.6z" transform="translate(-4.656 -23.052)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15015"
                        d="m63.63 20.355-6.923-4.619a14.847 14.847 0 0 0-2.416-2l6.923 4.619a14.818 14.818 0 0 1 2.416 2"
                        transform="translate(-18.244 -8.909)"
                        style={{ fill: '#fff' }}
                      />
                      <path data-name="패스 15016" d="m1.967 7.937 6.923 4.619L8.429 75.5l-6.923-4.618z" transform="translate(-.506 -6.95)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15017" d="m.992 104.5 6.923 4.619h-.991L0 104.5z" transform="translate(0 -39.571)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15018" d="m24.16 65.306 6.923 4.619H20.707l-6.923-4.619z" transform="translate(-4.632 -26.331)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15019" d="m13.692 65.306 6.923 4.619-.183 24.957-6.923-4.619z" transform="translate(-4.539 -26.331)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15020" d="m24.652 66.806 6.923 4.619h-9.384l-6.923-4.619z" transform="translate(-5.131 -26.838)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15021" d="m9.475 103 6.923 4.619H8.429L1.506 103z" transform="translate(-.506 -39.064)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15022" d="m9.464 104.5 6.923 4.619H8.418L1.495 104.5z" transform="translate(-.502 -39.571)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15023" d="m15.179 66.806 6.921 4.619-.175 23.964L15 90.77z" transform="translate(-5.041 -26.838)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15024" d="m14.49 104.5 6.923 4.619h-.992L13.5 104.5z" transform="translate(-4.536 -39.571)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15025" d="m15 103 6.923 4.619-.007.993-6.923-4.619z" transform="translate(-5.038 -39.064)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15026"
                        d="M55.849 58.653c-3.987 5.054-10.888 7.464-19.513 7.464L29.412 61.5c8.626 0 15.527-2.411 19.514-7.465l3.915 2.612 3.007 2.008"
                        transform="translate(-9.883 -22.523)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15027"
                        d="M37.029 10.744c4.546 3.24 6.826 8.358 6.776 15.213-.1 13-8.946 20.456-24.285 20.456h-9.383l-.175 23.965-.007.993H0l.007-.993.462-62.945.007-.987H19.47c7.756 0 13.5 1.4 17.56 4.3m-17.5 34.677c13.8 0 23.187-6.171 23.285-19.464.1-13.721-9.29-18.525-23.351-18.525h-18L1 70.378h7.97l.183-24.957h10.376"
                        transform="translate(0 -6.446)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15028"
                        d="M23.042 17.786c11.06 0 16.607 2.913 16.541 12-.067 9.014-5.325 13.04-16.383 13.04h-9.344l.183-25.04zm.163 24.047c10.584 0 15.324-3.715 15.385-12.045.058-7.924-4.3-11.016-15.555-11.016h-8.01l-.169 23.06h8.35"
                        transform="translate(-4.656 -10.277)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15029"
                        d="M69.2 34.06c-.037 4.985-1.38 8.968-3.771 12l-2.556-1.706-.906-.6-.454-.3-.9-.6-2.105-1.414c2.391-3.032 3.734-7.015 3.771-12 .041-5.639-1.52-9.772-4.351-12.684l6.923 4.619c2.83 2.912 4.392 7.045 4.351 12.684"
                        transform="translate(-19.466 -9.929)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15030"
                        d="M19.969 7.936c14.06 0 23.451 4.8 23.35 18.525-.1 13.293-9.485 19.464-23.283 19.464H9.659l-.183 24.956h-7.97l.461-62.945zM19.048 39.5c11.06 0 16.318-4.031 16.385-13.037.066-9.09-5.481-12-16.541-12h-9L9.706 39.5h9.342"
                        transform="translate(-.506 -6.949)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15031"
                        d="m46.909 12.937 6.923 4.619c4.547 3.24 6.827 8.358 6.776 15.213-.095 13-8.946 20.456-24.284 20.456L29.4 48.606c15.339 0 24.189-7.455 24.285-20.456.05-6.855-2.23-11.973-6.776-15.213"
                        transform="translate(-9.88 -8.639)"
                        style={{ fill: '#4e57ff' }}
                      />
                    </g>
                  </g>
                </g>
                <g data-name="그룹 8144" style={{ isolation: 'isolate' }}>
                  <g data-name="그룹 8143">
                    <g data-name="그룹 8142" style={{ clipPath: 'url(#qra074z00i)' }} transform="translate(52.657)">
                      <path data-name="패스 15032" d="m89.122 1.5 6.923 4.619h-7.678L81.444 1.5z" transform="translate(-80.024 -.507)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15033" d="m94.51 0 6.923 4.619-.007.993L94.5.993z" transform="translate(-84.413)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15034" d="m81.228 1.5 6.923 4.619-.426 58.088-6.925-4.62z" transform="translate(-79.809 -.507)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15035" d="m92.79 1.5 6.923 4.619-.429 58.593-6.924-4.619z" transform="translate(-83.693 -.507)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15036" d="m94.285 1.5 6.923 4.619-.429 58.593-6.924-4.619z" transform="translate(-84.195 -.507)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15037"
                        d="m91.094 102.847-6.923-4.619c-2.377-1.586-3.4-4.678-3.37-9l6.924 4.619c-.032 4.327.992 7.419 3.369 9"
                        transform="translate(-79.809 -30.143)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15038"
                        d="M99.284 94.606c-.012 1.6.446 2.542 1.109 2.984l-6.923-4.619c-.663-.442-1.121-1.385-1.109-2.984l6.923 4.619"
                        transform="translate(-83.693 -30.401)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15039"
                        d="m101.452 96.764-6.923-4.619c-.586-.391-.679-1.463-.673-2.157l6.923 4.619c-.005.695.088 1.767.673 2.158"
                        transform="translate(-84.195 -30.401)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15040"
                        d="m100.955 99.113-6.923-4.619a1.892 1.892 0 0 0 1.068.306 6.821 6.821 0 0 0 1.6-.166l6.924 4.619a6.823 6.823 0 0 1-1.6.166 1.893 1.893 0 0 1-1.066-.309"
                        transform="translate(-84.255 -31.924)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 15041" d="m99.249 92.941 6.923 4.619.177.987-6.923-4.619z" transform="translate(-86.008 -31.399)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15042"
                        d="m88.906 1.5-.43 58.593c-.017 2.366.992 3.294 2.175 3.294a6.8 6.8 0 0 0 1.6-.167l1.056 5.91a11.881 11.881 0 0 1-4.48.677c-5.735 0-8.073-3.715-8.025-10.219L81.228 1.5z"
                        transform="translate(-79.809 -.506)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15043"
                        d="m92.8 107.441-6.923-4.619a8.21 8.21 0 0 0 4.656 1.215 11.876 11.876 0 0 0 4.48-.677l6.924 4.619a11.866 11.866 0 0 1-4.48.678 8.211 8.211 0 0 1-4.656-1.215"
                        transform="translate(-81.514 -34.737)"
                        style={{ fill: '#fff' }}
                      />
                      <path data-name="패스 15044" d="m98.046 94.71 6.923 4.619 1.056 5.909-6.925-4.619z" transform="translate(-85.603 -31.997)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15045" d="m99.516 94.431 6.923 4.619 1.056 5.915-6.923-4.619z" transform="translate(-86.097 -31.902)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15046"
                        d="m91.943 108.691-6.923-4.619a9.214 9.214 0 0 0 5.218 1.375 13 13 0 0 0 4.837-.737l6.925 4.618a12.978 12.978 0 0 1-4.837.738 9.216 9.216 0 0 1-5.218-1.375"
                        transform="translate(-81.226 -35.159)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 15047" d="m101.106 103.365 6.923 4.619.143.809-6.923-4.619z" transform="translate(-86.632 -34.92)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15048" d="m100.932 104.586 6.923 4.619-.769.3-6.923-4.619z" transform="translate(-86.315 -35.333)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15049"
                        d="m93.78 68.443.143.808-.769.3a12.992 12.992 0 0 1-4.837.737c-6.032 0-9.065-3.769-9.011-11.206l.426-58.09L79.74 0h9.66v.992l-.429 58.593c-.007.862.137 2.306 1.189 2.306a5.626 5.626 0 0 0 1.41-.154l.982-.2.177.987zm-5.456.856a11.876 11.876 0 0 0 4.48-.677l-1.056-5.91a6.8 6.8 0 0 1-1.6.167c-1.183 0-2.192-.928-2.174-3.294L88.4.992h-7.675L80.3 59.08c-.048 6.5 2.291 10.219 8.025 10.219"
                        transform="translate(-79.306)"
                        style={{ fill: '#4e57ff' }}
                      />
                    </g>
                  </g>
                </g>
                <g data-name="그룹 8147" style={{ isolation: 'isolate' }}>
                  <g data-name="그룹 8146">
                    <g data-name="그룹 8145" style={{ clipPath: 'url(#ry1kghbisj)' }} transform="translate(72.333 18.709)">
                      <path data-name="패스 15050" d="m113.776 39.09 6.924 4.62 3.1 5.487-6.924-4.619z" transform="translate(-110.566 -31.915)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15051" d="m118.814 48.616 6.923 4.619-.89.594-6.923-4.619z" transform="translate(-111.96 -35.133)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15052"
                        d="m141.873 32.254 6.923 4.619a16.623 16.623 0 0 0-9.42-2.5c-7.577 0-14.3 3.394-18.677 6.183l-6.923-4.619c4.376-2.788 11.1-6.183 18.676-6.183a16.624 16.624 0 0 1 9.421 2.5"
                        transform="translate(-110.566 -28.76)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15053"
                        d="M128.337 49.246a38.658 38.658 0 0 0-2.973 1.821l-6.923-4.619a39.827 39.827 0 0 1 2.973-1.821l6.923 4.619"
                        transform="translate(-112.133 -33.785)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15054"
                        d="m129.842 47.6-6.923-4.619a23.574 23.574 0 0 1 11.25-3.232 9.837 9.837 0 0 1 5.589 1.475l6.924 4.619a9.84 9.84 0 0 0-5.589-1.475 23.574 23.574 0 0 0-11.25 3.232"
                        transform="translate(-113.638 -32.136)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15055"
                        d="m138 42.562 6.923 4.619a8.939 8.939 0 0 0-5.061-1.317c-4.309 0-8.778 1.593-13.669 4.881l-6.923-4.619c4.891-3.288 9.36-4.881 13.668-4.881A8.94 8.94 0 0 1 138 42.562"
                        transform="translate(-112.41 -32.643)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15056"
                        d="m166.528 41.643-6.928-4.619a12.93 12.93 0 0 0-3.512-3.494l6.923 4.619a12.911 12.911 0 0 1 3.512 3.494"
                        transform="translate(-124.786 -30.036)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15057"
                        d="m122.726 91.147-6.926-4.62c-3.347-2.232-5.413-6.014-5.375-11.088.076-10.273 9.081-15.415 29.2-17.674l6.923 4.619c-20.122 2.259-29.128 7.4-29.2 17.674-.037 5.073 2.028 8.855 5.375 11.088"
                        transform="translate(-109.44 -38.224)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15058"
                        d="M129.029 84.443c-.022 2.985 1.081 4.985 2.867 6.176L124.972 86q-.257-.172-.494-.365l6.923 4.618-6.923-4.619a7.016 7.016 0 0 1-2.372-5.811c.022-3.047 1.39-5.55 4.745-7.531l6.924 4.619c-3.356 1.981-4.723 4.484-4.745 7.531"
                        transform="translate(-113.365 -43.132)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15059"
                        d="m148.279 41.972 6.923 4.619c3.169 2.114 4.1 6.221 4.071 10.457l-6.923-4.619c.031-4.236-.9-8.343-4.071-10.457"
                        transform="translate(-122.16 -32.888)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15060"
                        d="M152.841 70.266c-7.97.96-13.311 2.42-16.666 4.4l-6.924-4.619c3.355-1.981 8.7-3.44 16.666-4.4l6.923 4.619"
                        transform="translate(-115.766 -40.887)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15061"
                        d="m132.974 88.156-6.923-4.619a6.071 6.071 0 0 1-2.451-5.384c.042-5.791 5.867-9.048 19.424-10.808l6.923 4.619c-13.557 1.76-19.381 5.018-19.423 10.808a6.069 6.069 0 0 0 2.451 5.384"
                        transform="translate(-113.867 -41.46)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15062"
                        d="M168.305 43.426c2.292 3.351 3.326 7.956 3.287 13.421l-6.923-4.619c.04-5.465-1-10.069-3.287-13.421l6.923 4.619"
                        transform="translate(-126.563 -31.819)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15063"
                        d="m156.875 32.288 6.923 4.619c4.809 3.208 7.334 9.24 7.271 17.738l-6.923-4.619c.062-8.5-2.463-14.53-7.271-17.738"
                        transform="translate(-125.048 -29.617)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15064"
                        d="m133.347 95.129-6.923-4.619a9.747 9.747 0 0 0 5.433 1.4c4.612 0 8.372-2.176 13.013-6.266l6.924 4.619c-4.642 4.09-8.4 6.266-13.014 6.266a9.75 9.75 0 0 1-5.433-1.4"
                        transform="translate(-114.816 -47.642)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 15065" d="m154.3 65.648 6.923 4.619-.1 13.239-6.923-4.619z" transform="translate(-124.152 -40.887)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15066"
                        d="M133.815 108.145a14.958 14.958 0 0 1-8.367-2.323l-6.923-4.619a14.953 14.953 0 0 0 8.367 2.323 19.623 19.623 0 0 0 8.318-1.982l6.923 4.619a19.623 19.623 0 0 1-8.318 1.983"
                        transform="translate(-112.161 -52.899)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15067"
                        d="m150.577 103.881-6.923-4.619a35.449 35.449 0 0 0 7.4-4.813l6.924 4.619a35.48 35.48 0 0 1-7.4 4.813"
                        transform="translate(-120.606 -50.617)"
                        style={{ fill: '#fff' }}
                      />
                      <path data-name="패스 15068" d="m155.066 94.449 6.923 4.619h-.261l-6.928-4.619z" transform="translate(-124.352 -50.617)" style={{ fill: '#fff' }} />
                      <path
                        data-name="패스 15069"
                        d="m124.691 105.241-6.923-4.619a15.786 15.786 0 0 0 8.863 2.473c4.9 0 9.88-1.991 15.187-6.087l6.923 4.619c-5.307 4.1-10.284 6.087-15.187 6.087a15.8 15.8 0 0 1-8.863-2.473"
                        transform="translate(-111.907 -51.482)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 15070" d="m155.2 94.449 6.923 4.619.654 5.66-6.923-4.619z" transform="translate(-124.485 -50.617)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15071"
                        d="M131.327 29.754c11.488 0 16.3 7.746 16.219 19.416l-.213 29.083h-6.525l-.654-5.66h-.262c-4.556 3.745-9.885 6.794-15.721 6.794-7.922 0-13.805-4.88-13.743-13.411.075-10.273 9.081-15.415 29.2-17.674.044-6.1-1.91-11.932-9.659-11.932-5.485 0-10.466 2.527-14.223 5.054l-3.1-5.487c4.377-2.788 11.1-6.183 18.676-6.183m8.168 37.007.1-13.239c-15.941 1.92-21.366 5.838-21.411 11.931-.039 5.4 3.6 7.574 8.3 7.574 4.612 0 8.372-2.176 13.014-6.266"
                        transform="translate(-109.44 -28.761)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15072"
                        d="M122.1 77.579c.045-6.093 5.47-10.011 21.411-11.932l-.1 13.239c-4.642 4.09-8.4 6.266-13.013 6.266-4.7 0-8.34-2.176-8.3-7.574m20.416-10.808c-13.557 1.76-19.381 5.017-19.424 10.808-.022 3 1.23 6.587 7.315 6.587 3.887 0 7.28-1.611 12.023-5.731l.086-11.664"
                        transform="translate(-113.364 -40.887)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 15073" d="m155.726 104.5 6.923 4.619h-.879l-6.924-4.619z" transform="translate(-124.367 -54.011)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15074" d="m166.224 59.076 6.923 4.619-.213 29.082-6.923-4.619z" transform="translate(-128.118 -38.667)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15075" d="m162.708 103 6.923 4.619h-6.525L156.182 103z" transform="translate(-124.816 -53.505)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15076" d="m162.7 104.5 6.923 4.619H163.1l-6.924-4.619z" transform="translate(-124.812 -54.011)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15077" d="m167.719 59.076 6.923 4.619-.213 29.082-6.923-4.619z" transform="translate(-128.621 -38.667)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15078" d="m166.992 104.5 6.923 4.619h-.992L166 104.5z" transform="translate(-128.114 -54.011)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15079" d="m167.5 103 6.923 4.619-.007.993-6.923-4.619z" transform="translate(-128.617 -53.505)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15080"
                        d="M130.835 28.253c11.191 0 17.3 7.247 17.2 20.409l-.213 29.083-.007.993h-8.4l-.1-.873-.469-4.084c-5.307 4.1-10.284 6.087-15.187 6.087-8.867 0-14.785-5.784-14.722-14.4.077-10.564 8.819-16.134 29.191-18.56-.17-6.759-3.016-10.053-8.668-10.053-4.308 0-8.778 1.593-13.669 4.881l-.89.595-.522-.928-3.092-5.487-.463-.814.794-.511c6.6-4.2 13.068-6.338 19.213-6.338m16 49.492.213-29.083c.086-11.67-4.731-19.416-16.219-19.416-7.577 0-14.3 3.394-18.677 6.183l3.1 5.487c3.757-2.527 8.737-5.054 14.223-5.054 7.75 0 9.7 5.832 9.659 11.932-20.122 2.259-29.127 7.4-29.2 17.674-.063 8.531 5.82 13.412 13.743 13.412 5.836 0 11.165-3.05 15.721-6.8h.261l.654 5.66h6.525"
                        transform="translate(-108.941 -28.254)"
                        style={{ fill: '#4e57ff' }}
                      />
                    </g>
                  </g>
                </g>
                <g data-name="그룹 8150" style={{ isolation: 'isolate' }}>
                  <g data-name="그룹 8149">
                    <g data-name="그룹 8148" style={{ clipPath: 'url(#r7j4r5057k)' }} transform="translate(115.56 19.928)">
                      <path data-name="패스 15081" d="m184.508 31.594 6.923 4.619h-8.272l-6.923-4.619z" transform="translate(-174.78 -30.601)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15082" d="m189.744 30.095 6.923 4.619.233.648-6.923-4.619z" transform="translate(-179.32 -30.095)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15083" d="m188.694 31.594 6.923 4.619 9.471 26.294-6.923-4.619z" transform="translate(-178.967 -30.601)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15084" d="m190.095 31.073 6.923 4.619 9.477 26.324-6.924-4.616z" transform="translate(-179.438 -30.425)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15085" d="m210.063 91.289 6.923 4.619h-.44l-6.923-4.619z" transform="translate(-186 -50.769)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15086"
                        d="m202.958 71.3 6.923 4.619c1.361 4.1 2.977 8.971 4.426 13.233l-6.924-4.619c-1.448-4.262-3.065-9.137-4.425-13.233"
                        transform="translate(-183.76 -44.017)"
                        style={{ fill: '#fff' }}
                      />
                      <path data-name="패스 15087" d="m204.367 70.828 6.923 4.619.617 1.843-6.924-4.619z" transform="translate(-184.233 -43.856)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15088" d="m237.214 31.594 6.923 4.619h-7.749l-6.924-4.619z" transform="translate(-192.667 -30.601)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15089" d="m243.005 30.095 6.923 4.619-.467 1.32-6.924-4.619z" transform="translate(-197.06 -30.095)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15090" d="m225.093 31.594 6.923 4.619-8.638 26.294-6.923-4.619z" transform="translate(-188.296 -30.601)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15091" d="m180.265 122.721 6.923 4.619-1.615 6.355-6.923-4.619z" transform="translate(-175.592 -61.388)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15092" d="m177.677 133.538 6.923 4.619-.788-.321-6.923-4.619z" transform="translate(-175 -64.933)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15093"
                        d="m205.3 73.611 6.923 4.619c.971 2.907 2.041 6.129 3.065 9.167l-6.923-4.619c-1.024-3.038-2.093-6.26-3.065-9.167"
                        transform="translate(-184.545 -44.796)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15094"
                        d="m214.383 71.3 6.923 4.619c-1.332 4.179-2.763 8.971-4.1 13.233l-6.923-4.619c1.333-4.262 2.765-9.054 4.1-13.233"
                        transform="translate(-186.222 -44.017)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 15095" d="m176.235 31.594 6.923 4.619 18.629 47.369-6.923-4.619z" transform="translate(-174.78 -30.601)" style={{ fill: '#4e57ff' }} />
                      <path data-name="패스 15096" d="m203.752 103.131 6.923 4.619-1.066 3.567-6.924-4.619z" transform="translate(-183.669 -54.769)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15097"
                        d="m195.427 108.518 6.923 4.619c-1.962 5.838-5.387 10.189-10.784 10.189a11.165 11.165 0 0 1-3.56-.785l-6.923-4.619a11.142 11.142 0 0 0 3.56.785c5.4 0 8.821-4.352 10.784-10.189"
                        transform="translate(-176.409 -56.589)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path data-name="패스 15098" d="m231.914 31.594 6.923 4.619-18.222 51.2-6.923-4.619z" transform="translate(-187.367 -30.601)" style={{ fill: '#fff' }} />
                      <path data-name="패스 15099" d="m233.319 32.088 6.923 4.619L222.026 87.9l-6.926-4.615z" transform="translate(-187.841 -30.768)" style={{ fill: '#4e57ff' }} />
                      <path
                        data-name="패스 15100"
                        d="M185.573 129.03c-.126-.068-.278-.185-.4-.265l-6.526-4.354a14.436 14.436 0 0 0 5.561.958l.756.5.262.175 1.089.726 4.815 3.212-6.923-4.619c9.4 0 14.416-7.14 17.705-16.455l6.923 4.619c-3.289 9.316-8.3 16.456-17.706 16.456a14.441 14.441 0 0 1-5.561-.957m-1.88-2.206.282.188.283.189.284.189.284.189.284.189.283.189.281.187.28.187.277.185.274.183.27.18.267.178.262.175.258.172.252.168.247.165-.246-.164-.252-.168-.258-.172-.263-.175-.267-.178-.271-.181-.274-.183-.278-.185-.279-.187-.282-.187-.283-.189-.284-.189-.284-.189-.284-.189-.283-.189-.282-.188-.169-.113-.168-.112-.167-.111-.167-.111-.165-.11-.164-.11-.163-.109-.161-.107-.16-.107-.159-.106-.157-.1-.155-.1-.153-.1-.151-.1-.149-.1-.147-.1.147.1.149.1.151.1.153.1.155.1.157.1.159.106.16.107.161.107.163.109.164.11.165.11.167.111.167.111.168.112.169.113"
                        transform="translate(-175.592 -56.723)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15101"
                        d="m219.328 31.594-18.222 51.2c-3.29 9.316-8.3 16.455-17.707 16.455a14.438 14.438 0 0 1-5.561-.957l1.615-6.355a11.143 11.143 0 0 0 3.56.785c5.4 0 8.821-4.351 10.783-10.189l1.066-3.567-18.626-47.372h8.272l9.47 26.294c1.36 4.1 2.977 8.971 4.426 13.233h.439c1.333-4.262 2.765-9.054 4.1-13.233l8.637-26.294z"
                        transform="translate(-174.781 -30.601)"
                        style={{ fill: '#fff' }}
                      />
                      <path
                        data-name="패스 15102"
                        d="m202.66 109.407 6.923 4.619c-2.6 7.348-7.352 17.121-18.65 17.121a15.468 15.468 0 0 1-5.933-1.034l-6.924-4.619a15.486 15.486 0 0 0 5.936 1.034c11.3 0 16.052-9.774 18.65-17.122"
                        transform="translate(-175.399 -56.89)"
                        style={{ fill: '#4e57ff' }}
                      />
                      <path
                        data-name="패스 15103"
                        d="M218.6 30.094h1.391l-.467 1.32-18.216 51.2c-2.6 7.348-7.352 17.121-18.65 17.121a15.47 15.47 0 0 1-5.938-1.035l-.788-.321.208-.832 1.615-6.355.269-1.07 1.038.375a9.962 9.962 0 0 0 3.222.725c5.491 0 8.382-5.166 9.827-9.476l.969-3.24-18.5-47.049-.536-1.361h10.424l.233.648 9.476 26.324.617 1.843c.971 2.907 2.041 6.129 3.065 9.167.341-1.094.681-2.212 1.023-3.329.791-2.574 1.607-5.238 2.386-7.669l8.632-26.296.23-.69h8.47zm-18.231 52.191 18.222-51.2h-7.75L202.2 57.382c-1.332 4.179-2.763 8.971-4.1 13.233h-.44c-1.449-4.262-3.065-9.137-4.425-13.233l-9.471-26.294H175.5l18.628 47.369-1.066 3.567c-1.963 5.838-5.388 10.189-10.784 10.189a11.146 11.146 0 0 1-3.56-.785l-1.618 6.356a14.455 14.455 0 0 0 5.561.957c9.4 0 14.417-7.14 17.706-16.455"
                        transform="translate(-174.044 -30.095)"
                        style={{ fill: '#4e57ff' }}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FocusedTitle;
