const ReservationType = {
  PERSONAL: '개인예약',
  GROUP: '단체예약',
  PROXY: '대리예약',
};

const ReservationStatus = {
  REQUEST: '예약 신청',
  COMPLETE: '예약 완료',
  CANCEL: '예약 취소',
  VISIT: '방문 완료',
  VISIT_CANCEL: '방문 취소',
};

const PaymentMethods = {
  CARD: '신용카드',
};

export { ReservationType, ReservationStatus, PaymentMethods };
