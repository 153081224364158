import React from 'react';
import styled from '@emotion/styled';

const HoverUnderlineWrapper = styled.div`
  ${(props) => props.isLink && `cursor: pointer;`}
  display: inline-block;
  .underline {
    background-image: linear-gradient(to right, transparent, transparent), ${({ underlineColor }) => `linear-gradient(to right, ${underlineColor}, ${underlineColor})`};
    background-size: ${({ height }) => `100% ${height}px, 0 ${height}px`};
    background-position: right bottom, left bottom;
    background-repeat: no-repeat;
    transition: background-size 400ms;
  }
  &:hover .underline {
    background-size: ${({ height }) => `0 ${height}px, 100% ${height}px`};
  }
`;

const Underline = ({ wrapperTag = 'div', className, ...otherProps }) => {
  const WrapperTag = wrapperTag;
  const classNames = () => {
    return className ? `${className} underline` : 'underline';
  };
  return <WrapperTag className={classNames()} {...otherProps} />;
};

export { HoverUnderlineWrapper, Underline };
