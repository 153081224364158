import MainVideoStyle from './MainVideo.module.scss';
import { cx } from '@emotion/css';
import getConfig from 'next/config';
import YoutubeVideo from '@/components/main/sections/a.MainSection/MainVideo/YoutubeVideo';
import { useEffect, useRef, useState } from 'react';
import { getVideos } from '@/apis/requests/video';
import IconCrossMover from '@/utils/classes/IconCrossMover';
const { publicRuntimeConfig } = getConfig();

const MainVideo = (props) => {
  const { className } = props;
  const [videos, setVideos] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isShowYoutube, setIsShowYoutube] = useState(false);
  const videoRefs = useRef([]);
  const playIconRef = useRef(null);

  // React bug
  // https://stackoverflow.com/questions/61510160/why-muted-attribute-on-video-tag-is-ignored-in-react
  // reporting: https://github.com/facebook/react/issues/10389
  const mutedAllVideos = () => {
    videoRefs.current.forEach((video) => {
      video.defaultMuted = true;
      video.muted = true;
    });
  };

  useEffect(() => {
    let mounted = true;
    const fetchVideos = async () => {
      const { data: response } = await getVideos();
      const videos = response.data.filter((item) => item.useState === 'USE');
      if (mounted) setVideos(videos);
    };
    fetchVideos();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    mutedAllVideos();
    const [firstVideo] = videoRefs.current;
    if (!firstVideo) return;
    playVideo(firstVideo);
  }, [videos]);

  useEffect(() => {
    // 비디오 동적 호출 하기 때문에 해당 아이콘이 존재하지 않을 수 있음
    if (!playIconRef.current) return;
    const iconMover = IconCrossMover.build({ element: playIconRef.current });

    return () => iconMover.destroy();
  }, [playIconRef]);

  const pauseAllVideos = () => {
    videoRefs.current.forEach((video) => {
      if (!video.paused) video.pause();
    });
  };

  const stopAllVideos = () => {
    videoRefs.current.forEach((video) => {
      if (!video.paused) {
        video.pause();
        video.currentTime = 0;
      }
    });
  };

  const openYoutube = () => {
    pauseAllVideos();

    setIsShowYoutube(true);
  };

  const onCloseYoutube = () => {
    pauseAllVideos();

    playVideo(videoRefs.current[selectedIndex]);
    setIsShowYoutube(false);
  };

  const goToPrevVideo = () => {
    stopAllVideos();

    const length = videos.length;
    const prevIndex = selectedIndex <= 0 ? length - 1 : selectedIndex - 1;
    setSelectedIndex(prevIndex);

    playVideo(videoRefs.current[prevIndex]);
  };

  const goToNextVideo = () => {
    stopAllVideos();

    const length = videos.length;
    const nextIndex = selectedIndex >= length - 1 ? 0 : selectedIndex + 1;
    setSelectedIndex(nextIndex);

    playVideo(videoRefs.current[nextIndex]);
  };

  const playVideo = (video, callback = () => {}) => {
    const playPromise = video.play();
    playPromise.then(() => callback()).catch((error) => null);
  };

  return (
    <>
      {videos.length > 0 && (
        <section className={cx(className, MainVideoStyle.mainVideoContainer)}>
          <ul className={MainVideoStyle.dotList}>
            {videos.map((video, index) => {
              const isSelected = selectedIndex === index;
              return <li key={video.id} className={cx(MainVideoStyle.dotListItem, isSelected && MainVideoStyle.selectedDot)} />;
            })}
          </ul>
          <ul>
            {videos.map((video, index) => {
              const isSelected = selectedIndex === index;
              return (
                <li key={video.id}>
                  <video
                    ref={(videoElement) => (videoRefs.current[index] = videoElement)}
                    className={cx(MainVideoStyle.video, isSelected && MainVideoStyle.selectedVideo)}
                    playsInline
                    onEnded={goToNextVideo}
                  >
                    <source src={`${publicRuntimeConfig.fileAssetPrefix}/${video.thumbnail}`} />
                  </video>
                </li>
              );
            })}
          </ul>
          <div className={MainVideoStyle.videoController}>
            <button className={MainVideoStyle.videoControllerButton} onClick={goToPrevVideo}>
              {'{'}
              <span>PREV</span>
              {'}'}
            </button>
            <button className={cx(MainVideoStyle.videoControllerButton, MainVideoStyle.videoControllerPlayButton)} onClick={openYoutube}>
              <span ref={playIconRef} className={MainVideoStyle.playText}>
                PLAY
              </span>
            </button>
            <button className={MainVideoStyle.videoControllerButton} onClick={goToNextVideo}>
              {'{'}
              <span>NEXT</span>
              {'}'}
            </button>
          </div>
          {isShowYoutube && <YoutubeVideo onClose={onCloseYoutube} videos={videos} selectedIndex={selectedIndex} />}
        </section>
      )}
    </>
  );
};

export default MainVideo;
