import { useState, useReducer, useEffect } from 'react';
import Head from 'next/head';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { StaticScript } from '@/components';
import getConfig from 'next/config';
import { AuthContext, authReducer } from '@/contexts';

import '@/style/global.scss';

import '@/ui/css/datepicker.css';
import '@/ui/css/error.css';
import '@/ui/css/global.css';
import '@/ui/css/global-2.css';
import '@/ui/css/sub_global.css';
import '@/ui/css/main.css';
import '@/ui/css/section2-footer.css';
import '@/ui/css/sub_clause.css';
import '@/ui/css/sub_login.css';
import '@/ui/css/sub_month_program.css';
import '@/ui/css/sub_museum_guide.css';
import '@/ui/css/sub_news.css';
import '@/ui/css/sub_reservation.css';
import '@/ui/css/sub_loginAgree_pop.css';

import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css';

import '@/utils/FollowingImage/FollowingImage.css';
import GoogleTagManagerNoScript from '@/public/js/GoogleTagManagerNoScript';
import { SubPageLayout } from '@/layouts';

const App = (context) => {
  const { Component, pageProps, router } = context;
  const [queryClient] = useState(() => new QueryClient());
  const { publicRuntimeConfig } = getConfig();
  const [authState, dispatchAuth] = useReducer(authReducer, pageProps.auth || {});
  const seo = {
    title: '넥슨컴퓨터박물관',
    description: '컴퓨터, 세상을 바꾼 아이디어',
    url: publicRuntimeConfig.baseURL,
    image: `${publicRuntimeConfig.assetPrefix}/static/images/layout/nexon_computer_museum.png`,
  };

  useEffect(() => {
    const isMain = router.pathname === '/';
    const bodyClass = isMain ? 'main-contents' : 'sub-contents';
    document.body.classList.remove('main-contents');
    document.body.classList.remove('sub-contents');
    document.body.classList.add(bodyClass);
  }, [router.pathname]);

  const getLayout = Component.getLayout || ((page) => <SubPageLayout>{page}</SubPageLayout>);

  return (
    <>
      <Head>
        <title>Nexon Computer Museum</title>
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover" />
        <meta name="git-commit-hash" content={publicRuntimeConfig.appGitCommitHash} />
        <meta name="inface-web-auth" content="site.nexon.com" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:description" content={seo.description} />
        {publicRuntimeConfig.isProduction && <GoogleTagManagerNoScript />}
      </Head>
      {publicRuntimeConfig.isProduction && <StaticScript src="/js/googleTagManager.js" strategy="beforeInteractive" />}
      <StaticScript src="https://enter.nexon.com/sdk/eosbrowsersupport/eos-browser-support-df9a3ff7-1.0.3.js " strategy="beforeInteractive" />
      <StaticScript src="/js/EOSBrowserSupport.js" strategy="beforeInteractive" />
      <StaticScript src="https://signin.nexon.com/sdk/inface.js" strategy="beforeInteractive" />
      <StaticScript src="/auth_insign.js" strategy="beforeInteractive" />
      <StaticScript src="/swiper.min.js" strategy="beforeInteractive" />
      <StaticScript src="/js/jquery-3.6.0.min.js" strategy="beforeInteractive" />
      <StaticScript src="/js/TweenMax.min.js" strategy="beforeInteractive" />
      <StaticScript src="/js/swiper.min.js" strategy="beforeInteractive" />
      <StaticScript src="/js/charming.min.js" strategy="beforeInteractive" />
      <StaticScript src={`${publicRuntimeConfig.inicisPCBaseURL}/stdjs/INIStdPay.js`} strategy="beforeInteractive" />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <AuthContext.Provider value={{ authState, dispatchAuth }}>{getLayout(<Component {...pageProps} />)}</AuthContext.Provider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default App;
