const CommonTitle = () => {
  return (
    <svg data-name="구성 요소 6 – 28" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 328 196.205">
      <defs>
        <clipPath id="arfkdfftma">
          <path data-name="사각형 15404" style={{ fill: 'none' }} d="M0 0h210.738v116.186H0z" />
        </clipPath>
      </defs>
      <g data-name="그룹 8125">
        <g data-name="그룹 8072">
          <g data-name="그룹 8071" style={{ clipPath: 'url(#arfkdfftma)' }} transform="translate(1.104 .051)">
            <path data-name="패스 14707" d="M105.369 0c58.253 0 105.476 26.009 105.476 58.093s-47.223 58.093-105.476 58.093S-.107 90.177-.107 58.093 47.116 0 105.369 0" />
            <ellipse data-name="타원 242" cx="94.855" cy="48.194" rx="94.855" ry="48.194" transform="translate(10.697 8.679)" style={{ stroke: '#404040', fill: 'none' }} />
          </g>
        </g>
        <g data-name="그룹 8126">
          <g data-name="그룹 8127">
            <path
              data-name="패스 14805"
              d="m128.275 216.531 1.264 2.692q-6.375 4.811-13.036 4.811-6.72 0-10.05-4.009t-3.332-12.825l.058-4.7q0-7.959-1.378-11.283a7.978 7.978 0 0 0-3.848-4.467q-2.47-1.145-7.925-1.146h-2.872l-6.891 22.451a38.088 38.088 0 0 0-1.723 6.758 4.365 4.365 0 0 0 2.5 4.267 12.224 12.224 0 0 0 5.484 1.232h1.608l-.8 2.634H55.8l.8-2.634h1.264a12.916 12.916 0 0 0 6.03-1.289 7.574 7.574 0 0 0 3.36-2.978 51.911 51.911 0 0 0 2.613-7.417L84.916 159.2a36.179 36.179 0 0 0 1.723-7.216 3.326 3.326 0 0 0-1.723-3.036q-1.723-1.031-6.719-1.03l.8-2.635h20.391q8.5 0 12.721.43a23.244 23.244 0 0 1 8.643 2.806 17.611 17.611 0 0 1 6.806 6.214 15.956 15.956 0 0 1 2.384 8.59 18.445 18.445 0 0 1-7.323 14.892q-7.322 5.957-23.287 6.414 8.384 3.036 11.658 8.3t3.676 14.662q.287 7.16 1.493 9.622t4.594 2.463q2.929 0 7.523-3.15M87.96 183.141h2.3q14.7 0 22-5.613t7.294-15.693q0-7.1-4.451-10.624t-13.352-3.522q-1.378 0-2.987.115z"
              transform="translate(15.776 -31.204)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="패스 14806"
              d="M175.129 222.9a37.7 37.7 0 0 1 3.538-14.977 32.693 32.693 0 0 1 10.108-13.145q6.569-5.039 12.761-5.04a11.556 11.556 0 0 1 9.254 4.324q3.632 4.325 3.633 11.712a37.164 37.164 0 0 1-4.043 16.953 32.36 32.36 0 0 1-10.139 12.286q-6.1 4.267-11.971 4.266a13.09 13.09 0 0 1-9.192-3.665q-3.949-3.665-3.948-12.715m6.759 6.587a8.086 8.086 0 0 0 1.769 5.269 5.925 5.925 0 0 0 4.864 2.177q8.465 0 13.929-14.548t5.464-22.221q0-3.953-1.674-5.928a6.214 6.214 0 0 0-5.022-1.977q-7.9 0-13.613 13.488t-5.717 23.741"
              transform="translate(-24.323 -46.224)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="패스 14807"
              d="M292.435 226.021h2.653q-5.37 13.4-14.53 13.4a5.469 5.469 0 0 1-6.191-6.186q0-4.35 3.411-13.917-11.056 20.1-21.858 20.1a8.538 8.538 0 0 1-6.254-2.233 7.47 7.47 0 0 1-2.274-5.556q0-3.265 2.842-10.824l7.77-20.561a26.922 26.922 0 0 0 1.706-5.5q0-1.489-1.643-1.489-3.98 0-8.718 9.68h-3.032a27.9 27.9 0 0 1 6.633-9.822q4.106-3.808 8.086-3.809a6.48 6.48 0 0 1 4.2 1.4 5.092 5.092 0 0 1 1.737 4.209q0 2.005-2.211 8.019l-7.96 21.191q-2.274 6.185-2.274 8.133a3.723 3.723 0 0 0 .884 2.691 3.433 3.433 0 0 0 2.59.917q5.558 0 13.2-10.71t14.656-31.844l1.074-3.264h7.58l-11.055 32.875q-2.653 8.018-2.653 9.679a3.161 3.161 0 0 0 .631 2.147 2.389 2.389 0 0 0 1.895.716q4.8 0 9.1-9.45"
              transform="translate(-48.245 -46.077)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="패스 14808"
              d="m361.071 182.877-.884 2.52h-9.477l-8.339 25.028q-4.171 12.315-4.171 14.319a2.972 2.972 0 0 0 .663 2.09 2.5 2.5 0 0 0 1.927.716q2.274 0 5.938-3.895a39.947 39.947 0 0 0 6.57-9.794h2.779q-3.853 8.535-8.812 13.03t-9.065 4.5a6.708 6.708 0 0 1-5.148-2.063 7.326 7.326 0 0 1-1.927-5.211q0-2.692 3.917-14.319l8.149-24.4h-8.718l.884-2.52h8.655l5.812-17.354h7.581l-5.805 17.353z"
              transform="translate(-76.743 -38.042)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="패스 14809"
              d="M403.285 223.215h3.032q-8.023 16.208-20.594 16.208a11.336 11.336 0 0 1-8.434-3.293 12.113 12.113 0 0 1-3.253-8.905q0-7.1 4.2-16.037a44.673 44.673 0 0 1 11.94-15.407q7.738-6.47 13.488-6.471a6.862 6.862 0 0 1 5.37 2.033 7.3 7.3 0 0 1 1.832 5.069q0 6.186-6.823 11.884t-20.467 10.967a28.258 28.258 0 0 0-1.769 9.45 8.232 8.232 0 0 0 1.579 5.5 5.831 5.831 0 0 0 4.675 1.833q8.654 0 15.225-12.829m-18.762-6.873a50.036 50.036 0 0 0 14.656-9.164q6.505-5.842 6.507-10.6a4.638 4.638 0 0 0-.979-3.236 3.811 3.811 0 0 0-2.937-1.059q-3.791 0-8.939 6.672t-8.307 17.382"
              transform="translate(-91.162 -46.077)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="패스 14810"
              d="m131.15 220.094 1.264 2.692q-6.375 4.811-13.036 4.811-6.72 0-10.05-4.009T106 210.758l.058-4.7q0-7.959-1.378-11.283a7.978 7.978 0 0 0-3.848-4.467q-2.47-1.145-7.925-1.146h-2.876l-6.891 22.451a38.088 38.088 0 0 0-1.723 6.758 4.365 4.365 0 0 0 2.5 4.267 12.224 12.224 0 0 0 5.484 1.232h1.608l-.8 2.634H58.674l.8-2.634h1.264a12.915 12.915 0 0 0 6.03-1.289 7.574 7.574 0 0 0 3.36-2.978 51.909 51.909 0 0 0 2.613-7.417l15.047-49.426a36.18 36.18 0 0 0 1.723-7.216 3.326 3.326 0 0 0-1.723-3.036q-1.723-1.031-6.719-1.03l.8-2.635h20.387q8.5 0 12.721.43a23.244 23.244 0 0 1 8.643 2.806 17.611 17.611 0 0 1 6.806 6.214 15.956 15.956 0 0 1 2.384 8.59 18.445 18.445 0 0 1-7.323 14.892q-7.322 5.956-23.287 6.414 8.384 3.036 11.658 8.3t3.676 14.662q.287 7.16 1.493 9.622t4.594 2.463q2.935.008 7.529-3.142zM90.835 186.7h2.3q14.7 0 22-5.613t7.294-15.693q0-7.1-4.451-10.624t-13.352-3.522q-1.378 0-2.987.115z"
              transform="translate(14.81 -32.407)"
              style={{ stroke: '#fff', strokeMiterlimit: 10, fill: 'none' }}
            />
            <path
              data-name="패스 14811"
              d="M178 226.465a37.7 37.7 0 0 1 3.538-14.977 32.693 32.693 0 0 1 10.108-13.145q6.569-5.039 12.761-5.04a11.556 11.556 0 0 1 9.254 4.324q3.632 4.325 3.633 11.712a37.164 37.164 0 0 1-4.043 16.953 32.359 32.359 0 0 1-10.139 12.286q-6.1 4.267-11.971 4.266a13.091 13.091 0 0 1-9.192-3.665Q178 235.515 178 226.465zm6.759 6.587a8.086 8.086 0 0 0 1.769 5.269 5.925 5.925 0 0 0 4.872 2.179q8.465 0 13.929-14.548t5.464-22.221q0-3.953-1.674-5.928a6.214 6.214 0 0 0-5.022-1.977q-7.9 0-13.613 13.488t-5.721 23.738z"
              transform="translate(-25.289 -47.427)"
              style={{ stroke: '#fff', strokeMiterlimit: 10, fill: 'none' }}
            />
            <path
              data-name="패스 14812"
              d="M295.31 229.584h2.653q-5.37 13.4-14.53 13.4a5.469 5.469 0 0 1-6.191-6.186q0-4.35 3.411-13.917-11.056 20.1-21.858 20.1a8.539 8.539 0 0 1-6.254-2.234 7.47 7.47 0 0 1-2.274-5.556q0-3.265 2.842-10.824l7.77-20.561a26.921 26.921 0 0 0 1.706-5.5q0-1.489-1.643-1.489-3.98 0-8.718 9.68h-3.032a27.9 27.9 0 0 1 6.633-9.823q4.106-3.808 8.086-3.809a6.48 6.48 0 0 1 4.2 1.4 5.092 5.092 0 0 1 1.737 4.209q0 2.005-2.211 8.019l-7.96 21.191q-2.274 6.185-2.274 8.133a3.722 3.722 0 0 0 .884 2.691 3.433 3.433 0 0 0 2.59.917q5.558 0 13.2-10.71t14.656-31.844l1.074-3.264h7.581l-11.048 32.884q-2.653 8.018-2.653 9.679a3.161 3.161 0 0 0 .631 2.147 2.389 2.389 0 0 0 1.895.716q4.8.001 9.097-9.449z"
              transform="translate(-49.211 -47.281)"
              style={{ stroke: '#fff', strokeMiterlimit: 10, fill: 'none' }}
            />
            <path
              data-name="패스 14813"
              d="m363.946 186.439-.884 2.52h-9.476l-8.339 25.028q-4.169 12.314-4.169 14.319a2.972 2.972 0 0 0 .663 2.09 2.5 2.5 0 0 0 1.927.716q2.274 0 5.938-3.895a39.946 39.946 0 0 0 6.57-9.794h2.779q-3.853 8.535-8.812 13.03t-9.065 4.5a6.707 6.707 0 0 1-5.148-2.063 7.326 7.326 0 0 1-1.93-5.214q0-2.692 3.917-14.319l8.149-24.4h-8.716l.884-2.52h8.655l5.812-17.354h7.581l-5.811 17.354z"
              transform="translate(-77.709 -39.245)"
              style={{ stroke: '#fff', strokeMiterlimit: 10, fill: 'none' }}
            />
            <path
              data-name="패스 14814"
              d="M406.16 226.778h3.032q-8.023 16.208-20.594 16.208a11.336 11.336 0 0 1-8.434-3.293 12.113 12.113 0 0 1-3.253-8.905q0-7.1 4.2-16.037a44.674 44.674 0 0 1 11.939-15.407q7.738-6.47 13.488-6.471a6.862 6.862 0 0 1 5.37 2.033 7.294 7.294 0 0 1 1.832 5.069q0 6.186-6.823 11.885t-20.467 10.966a28.257 28.257 0 0 0-1.769 9.45 8.232 8.232 0 0 0 1.579 5.5 5.831 5.831 0 0 0 4.675 1.833q8.654-.002 15.225-12.831zM387.4 219.9a50.035 50.035 0 0 0 14.656-9.164q6.506-5.842 6.507-10.6a4.639 4.639 0 0 0-.979-3.236 3.811 3.811 0 0 0-2.937-1.059q-3.791 0-8.939 6.672T387.4 219.9z"
              transform="translate(-92.128 -47.281)"
              style={{ stroke: '#fff', strokeMiterlimit: 10, fill: 'none' }}
            />
            <path
              data-name="패스 14815"
              d="M0 6.459h18.1c14.137 0 23.618 4.813 23.618 18.568 0 13.325-9.4 19.513-23.273 19.513H8.017v25.016H0zm17.412 31.634c11.119 0 16.377-4.041 16.377-13.066 0-9.112-5.6-12.035-16.722-12.035h-9.05v25.1z"
              transform="translate(34.526 15.696)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="패스 14816"
              d="M75.634 58.233V0h7.723v58.74c0 2.37 1.019 3.3 2.206 3.3a6.792 6.792 0 0 0 1.613-.17l1.1 5.924a11.9 11.9 0 0 1-4.5.678c-5.771 0-8.148-3.725-8.148-10.242"
              transform="translate(9.111 17.878)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="패스 14817"
              d="M105.392 64.634c0-10.3 9.015-15.45 29.233-17.719 0-6.11-2.013-11.958-9.8-11.958-5.514 0-10.5 2.531-14.267 5.063l-3.151-5.5c4.377-2.794 11.116-6.2 18.731-6.2 11.554 0 16.455 7.768 16.455 19.464v29.158h-6.565l-.7-5.673h-.262c-4.551 3.753-9.89 6.808-15.755 6.808-7.964 0-13.916-4.888-13.916-13.443m29.233.786V52.153c-16.018 1.92-21.443 5.848-21.443 11.958 0 5.412 3.676 7.594 8.4 7.594 4.639 0 8.4-2.182 13.042-6.285"
              transform="translate(-.889 8.309)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="패스 14818"
              d="m173.274 97.031 1.576-6.372a11.387 11.387 0 0 0 3.588.785c5.427 0 8.84-4.364 10.766-10.213l1.05-3.579-19.08-47.484h8.315l9.711 26.361c1.4 4.1 3.064 8.99 4.552 13.267h.438c1.313-4.277 2.713-9.078 4.026-13.267l8.489-26.361h7.79l-17.94 51.325c-3.238 9.34-8.227 16.5-17.679 16.5a14.677 14.677 0 0 1-5.6-.959"
              transform="translate(-22.994 7.686)"
              style={{ fill: '#fff' }}
            />
          </g>
        </g>
        <path data-name="사각형 15434" style={{ fill: 'none' }} d="M0 0h328v196H0z" />
      </g>
    </svg>
  );
};
export default CommonTitle;
