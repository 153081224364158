class Donations {
  collections = [];
  majorCollectionPosition = [1, 5, 8, 13, 14];

  constructor(props) {
    const { collections = [] } = props;
    this.collections = collections;
  }

  /**
   * 주요 기증품의 순서를 정렬한 배열을 리턴하는 함수
   * 순서의 기준은 majorCollectionPosition 바탕으로 한다.
   * @return {array}
   */
  get sortMajorCollections() {
    const majorCollection = this.filterCollectionsByIsMajor(true);
    const minorCollection = this.filterCollectionsByIsMajor(undefined);

    let majorIndex = 0;
    return this.collections.map((collection, index) => {
      const isMajorPosition = this.majorCollectionPosition.includes(index + 1);
      if (isMajorPosition) {
        return majorCollection[majorIndex++];
      } else {
        return minorCollection[index - majorIndex];
      }
    });
  }

  /**
   * 주요 기증품 여부를 기준으로 필터링해주는 함수
   * @param isMajor {boolean}
   * @return {array}
   */
  filterCollectionsByIsMajor(isMajor) {
    return this.collections.filter((collection) => collection.isMajor === isMajor);
  }

  /**
   * noImage 값을 바탕으로 기증품 또는 기본 기증품 이미지 src 를 찾아주는 함수
   * @param collection - 기증품 object
   * @return {string}
   */
  getImageSrc(collection) {
    const { noImage } = collection;
    return noImage ? this.getDefaultImageSrc(collection) : this.getCollectionImageSrc(collection);
  }

  /**
   * 이미지 파일 이름에 있는 index 를 찾아주는 함수
   * ex) 일반_21_김국현.jpg 의 21을 리턴
   * @param collection - noImage 값이 false 인 기증품 object
   * @return {string}
   */
  getCollectionImageIndex(collection) {
    const foundCollectionIndex = this.collections.findIndex((item) => item === collection);
    const { isMajor } = collection;
    const imageIndex = isMajor ? foundCollectionIndex + 1 : foundCollectionIndex - 4;
    return imageIndex.toString().padStart(2, '0');
  }

  /**
   * 기증품 이미지 src 를 찾아주는 함수
   * @param collection - noImage 값이 false 인 기증품 object
   * @return {string}
   */
  getCollectionImageSrc(collection) {
    const { isMajor, donor } = collection;
    const imagePrefix = isMajor ? '주요' : '일반';
    const imageIndex = this.getCollectionImageIndex(collection);
    const imageExt = isMajor ? 'png' : 'jpg';
    return `/static/images/donations/${imagePrefix}_${imageIndex}_${donor}.${imageExt}`;
  }

  /**
   * 기본 기증품 이미지 src 를 찾아주는 함수
   * @param collection - noImage 값이 true 인 기증품 object
   * @return {string}
   */
  getDefaultImageSrc(collection) {
    const defaultCollections = this.collections.filter((item) => item.noImage === true);
    const foundDefaultCollectionIndex = defaultCollections.findIndex((item) => item === collection);
    if (foundDefaultCollectionIndex === -1) return '';
    const defaultImageIndex = foundDefaultCollectionIndex % 2;
    return `/static/images/donations/img-default-${defaultImageIndex}.png`;
  }
}
export default Donations;
