import VideoStyle from './VideoPlayer.module.scss';
import { StaticVideo } from '@/components';
import { useEffect, useRef } from 'react';
import { cx } from '@emotion/css';

const VideoPlayer = (props) => {
  const { isShow, onClose } = props;
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    video.defaultMuted = true;
    video.muted = true;
  }, [videoRef]);

  useEffect(() => {
    const video = videoRef.current;
    if (isShow) {
      video.play();
    } else if (!video.paused) {
      video.pause();
    }
  }, [isShow]);

  return (
    <div className={cx(VideoStyle.videoModal, isShow && VideoStyle.isShow)}>
      <div className={VideoStyle.videoModalContainer}>
        <button className={VideoStyle.videoModalCloseButton} onClick={onClose} />
        <div className={VideoStyle.videoContainer}>
          <StaticVideo ref={videoRef} className={VideoStyle.video} src={'/videos/chronicle.mp4'} playsInline loop />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
