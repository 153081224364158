import { MainTitle, Pagination, Tab, Tabs } from '@/components';
import ReservationDTO from '@/apis/DTOs/ReservationDTO';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import styles from 'style/reservationList.module.scss';
import { cx } from '@emotion/css';

const ReservationList = (props) => {
  const { setTarget, renderingDTOs, pageInfo, onPageChange, isMobile, hasNextPage } = props;
  const router = useRouter();
  const [filter, setFilter] = useState(ReservationDTO.initialFilter());
  useEffect(() => {
    setFilter({
      ...ReservationDTO.initialFilter(),
      ...router.query,
    });
  }, [router.query]);

  const goToEducationReservationList = () => {
    return router.push('/education/reservation/list');
  };

  return (
    <div className={styles.container}>
      <MainTitle>예약 확인 및 취소</MainTitle>
      <Tabs>
        <Tab name={'status'} isSelected={true} mainTitle={'관람 예약'} />
        <Tab name={'status'} onClick={goToEducationReservationList} isSelected={false} mainTitle={'교육 프로그램 예약'} />
      </Tabs>
      {renderingDTOs.length !== 0 ? (
        <ul className={styles.reservationList}>
          {renderingDTOs.map((reservationDTO, index) => {
            let statusClass = '';
            switch (reservationDTO.reservationStatus) {
              case 'REQUEST':
                statusClass = styles.requestStatus;
                break;
              case 'COMPLETE':
                statusClass = styles.completeStatus;
                break;
              case 'VISIT':
                statusClass = styles.visitStatus;
                break;
              case 'CANCEL':
              case 'VISIT_CANCEL':
                statusClass = styles.cancelStatus;
            }
            return (
              <li className={cx(styles.reservationItem, statusClass)} key={index}>
                <Link
                  className={styles.reservationLink}
                  href={`/reservation/detail?id=${reservationDTO.id}&${Object.entries(router.query)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&')}`}
                  passHref={true}
                >
                  <div className={styles.statusArea}>
                    <div className={styles.date}>예약일: {moment(reservationDTO.createdDate).format('YYYY-MM-DD')}</div>
                    <div className={styles.state}>{reservationDTO.reservationStatusName}</div>
                  </div>
                  <ul className={styles.infoArea}>
                    <li className={styles.infoItem}>{reservationDTO.reservationTypeName.substr(0, 2)}</li>
                    <li className={styles.infoItem}>{reservationDTO.schedule.reservationDate}</li>
                    <li className={styles.infoItem}>{moment(reservationDTO.schedule.reservationTime, 'HH:mm:ss').format('HH:mm')}</li>
                    <li className={styles.infoItem}>{reservationDTO.visitorCount}명</li>
                  </ul>
                </Link>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={styles.noItemContainer}>
          <i className={styles.noItemIcon} />
          <div className={styles.noItemDescription}>예약 내역이 없습니다.</div>
        </div>
      )}
      {isMobile && hasNextPage && <div className="observer" style={{ position: 'absolute', bottom: '350' }} ref={setTarget} />}
      {!isMobile && renderingDTOs.length !== 0 && <Pagination {...pageInfo} onPageChange={(event, page) => onPageChange(page, filter)} />}
    </div>
  );
};
ReservationList.propTypes = {
  reservationDTOs: PropTypes.arrayOf(PropTypes.instanceOf(ReservationDTO)),
  pageInfo: PropTypes.object,
  onPageChange: PropTypes.func,
};
export default ReservationList;
