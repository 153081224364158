import { promiseModal } from '../System/System';
import { useCheckbox } from '@/hooks';
import { cx } from '@emotion/css';
import { Checkbox, OperationText, XSSShield } from '@/components';
import { findGuideContent } from '@/utils';

const agreements = ['check01', 'check02'];
const requiredAgreements = ['check01', 'check02'];

const LoginTermsModal = ({ guidesData, onClose, resolve, reject }) => {
  const { checkList, isCheckedRequiredItem, onCheckSingle, onCheckAll } = useCheckbox({ ids: agreements, checkRequiredList: requiredAgreements });

  const onConfirm = () => {
    onClose();
    return resolve({ confirm: true });
  };
  const onCancel = () => {
    onClose();
    return resolve({ confirm: false });
  };
  const loginGuide = findGuideContent(guidesData, 'LOGIN');
  return (
    <>
      <span className="dimmed" />
      <div
        className="layer loginAgree_layer"
        style={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="layerCnts">
          <p className="txt">로그인</p>
          <div className="cnts">
            <div className="loginAgreeBody scroll">
              <div className="loginAgreeBody_content">
                <h3>
                  <div className="checkboxSec">
                    <Checkbox darkMode={true} checked={checkList.length === agreements.length} onChange={onCheckAll}>
                      전체 동의
                    </Checkbox>
                  </div>
                </h3>
                <div className="loginAgree_collection">
                  <ul>
                    <li>
                      <div className="checkboxSec">
                        <Checkbox darkMode={true} name={'check01'} checked={checkList.includes('check01')} onChange={onCheckSingle}>
                          [필수] 만 14세 이상입니다.
                        </Checkbox>
                      </div>
                    </li>
                    <li>
                      <div className="checkboxSec">
                        <Checkbox darkMode={true} name={'check02'} checked={checkList.includes('check02')} onChange={onCheckSingle}>
                          [필수] 개인정보 수집 및 이용 동의
                        </Checkbox>
                      </div>
                    </li>
                  </ul>
                  <div className="grayBox">
                    <OperationText>
                      <XSSShield content={loginGuide} />
                    </OperationText>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="layerBt layerBt2">
            <span>
              <a onClick={onCancel} className="bt bt2">
                취소
              </a>
            </span>
            <span>
              <a onClick={isCheckedRequiredItem ? onConfirm : null} className={cx('bt', 'bt1', !isCheckedRequiredItem && 'disabled')}>
                동의
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const LoginTerms = {
  open: (props) => promiseModal(LoginTermsModal, { props }),
};

export default LoginTerms;
