import { cx } from '@emotion/css';
import styles from './index.module.scss';
const CountButton = ({ buttonType, disabled, onClick, ...otherProps }) => {
  return (
    <button className={cx(styles.circleButton)} disabled={disabled} onClick={onClick} {...otherProps}>
      <div className={styles[buttonType]} />
    </button>
  );
};
export default CountButton;
