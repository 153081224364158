import { getUser } from '@/apis/requests/login';
import { routingServerAndClientSide, getCookie, setCookie } from '@/utils';
import { setAuthorizationHeader } from '@/apis/server';
import { makeSecureURL } from '@/utils';

const validateUser = async (req, res, query) => {
  const { redirectURL } = query;
  const accessToken = getCookie({ req, name: 'accessToken' });
  setAuthorizationHeader(accessToken);
  if (!!accessToken) {
    const { data } = await getUser();
    if (data.code === 0) {
      const secureURL = makeSecureURL(redirectURL);
      routingServerAndClientSide({ res, path: secureURL || '/' });
    } else {
      setCookie({ res, name: 'accessToken', value: null });
    }
  }
};
export default validateUser;
