import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PaymentPC from './PaymentPC';
import PaymentMobile from './PaymentMobile';
import useIsMobileDevice from '@/hooks/useIsMobileDevice';

const Payment = forwardRef((props, ref) => {
  const { isMobileDevice } = useIsMobileDevice();
  const paymentMobileRef = useRef(null);
  const paymentPCRef = useRef(null);

  useImperativeHandle(ref, () => ({
    pay: (payload) => {
      if (isMobileDevice) {
        paymentMobileRef.current.pay(payload);
      } else {
        paymentPCRef.current.pay(payload);
      }
    },
  }));

  return <div>{isMobileDevice ? <PaymentMobile ref={paymentMobileRef} /> : <PaymentPC ref={paymentPCRef} />}</div>;
});
Payment.displayName = 'Payment';

export default Payment;
