import ServerAPI from '@/apis/server';
import EducationBook from '@/apis/books/education.json';

const getEducations = (payload) => {
  const { pageParam = 0, filter, ...otherPayload } = payload;
  const { status, ...otherFilter } = filter;
  const statusParam = status === 'ALL' ? {} : { status };
  return ServerAPI.get(EducationBook.REST, { params: { ...otherFilter, page: pageParam, ...statusParam, ...otherPayload } });
};

const getEducation = (payload) => {
  const { id } = payload;

  return ServerAPI.get(`${EducationBook.REST}/${id}`);
};

const getEducationClass = (payload) => {
  const { id } = payload;
  return ServerAPI.get(`${EducationBook.REST}/${id}/options`);
};

const getEducationCoupon = (payload) => {
  const { description } = payload;
  return ServerAPI.get(`${EducationBook.REST}/coupons`, {
    params: { description },
  });
};

// 예약 목록 조회
const getEducationReservations = (payload) => {
  const { pageParam = 0, filter } = payload;
  return ServerAPI.get(`${EducationBook.REST}/reservations`, {
    params: { ...filter, page: pageParam },
  });
};

// 교육 프로그램 대기 접수
const createEducationWaitingReception = (payload) => {
  const { form } = payload;
  return ServerAPI.post(`${EducationBook.REST}/reservations/wait`, form);
};

// 교육 프로그램 대기 주문
const createEducationWaitingOrder = (payload) => {
  const { id, form } = payload;
  return ServerAPI.post(`${EducationBook.REST}/reservations/${id}/checkout`, form);
};

// 교육 프로그램 예약 주문
const createEducationReservationOrder = (payload) => {
  const { form } = payload;
  return ServerAPI.post(`${EducationBook.REST}/reservations`, form);
};

// 교육 프로그램 예약 주문 (무료)
const createEducationReservationFreeOrder = (payload) => {
  const { form } = payload;
  return ServerAPI.post(`${EducationBook.REST}/reservations/free`, form);
};

// 교육 프로그램 예약 상세 조회
const getEducationReservation = (payload) => {
  const { id } = payload;
  return ServerAPI.get(`${EducationBook.REST}/reservations/${id}`);
};

// 교육 프로그램 예약 취소
const cancelEducationReservation = (payload) => {
  const { id } = payload;
  return ServerAPI.put(`${EducationBook.REST}/reservations/${id}/cancel`);
};

export {
  getEducations,
  getEducation,
  getEducationClass,
  getEducationCoupon,
  createEducationWaitingReception,
  getEducationReservations,
  createEducationWaitingOrder,
  createEducationReservationOrder,
  createEducationReservationFreeOrder,
  getEducationReservation,
  cancelEducationReservation,
};
