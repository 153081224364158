import styles from './index.module.scss';
import { cx } from '@emotion/css';

const EDUCATION_STATUS_CLASSNAME = {
  OFFLINE: styles.attendanceTag,
  ONLINE: styles.attendanceTag,
  WAIT: styles.waitTag,
  SCHEDULE: styles.scheduleTag,
  PROGRESSING: styles.progressTag,
  END: styles.endTag,
};
const EDUCATION_STATUS = {
  OFFLINE: '오프라인 수업',
  ONLINE: '온라인 수업',
  WAIT: '대기 접수중',
  PROGRESSING: '접수 중',
  SCHEDULE: '접수 예정',
  END: '마감',
  ALL: '전체',
};

const EducationStatus = ({ status, className }) => {
  return <span className={cx(styles.status, EDUCATION_STATUS_CLASSNAME[status], className)}>{EDUCATION_STATUS[status]}</span>;
};
export default EducationStatus;
