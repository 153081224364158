import ReservationDTO from '@/apis/DTOs/ReservationDTO/ReservationDTO';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { getReservationSchedule } from '@/apis/requests/schedule';
import { getProgramSchedules } from '@/apis/requests/programReservation';
import { getUser } from '@/apis/requests/login';
import { useTicketPrice, useValidationHook } from '@/hooks';
import PropTypes from 'prop-types';
import { System, Datepicker, ClientSideOnly, ProgramSelect, ReservationButton, AdmissionSelect, MainTitle } from '@/components';
import { cx } from '@emotion/css';
import { formatPrice, Program } from '@/utils';
import styles from '@/style/reservation.module.scss';
import { VisitorDTO } from '@/apis/DTOs/VIsitorDTO';

const ScheduleForm = (props) => {
  const { tickets, maxSeat, holidays, errorCode } = props;
  const router = useRouter();
  const [form, setForm] = useState(ReservationDTO.personalInitialForm());
  const { checkValidation } = useValidationHook({
    data: form,
    validate: {
      schedule: {
        reservationDate: ['isExist'],
        reservationTime: ['isExist'],
      },
      visitorCount: ['isExist', 'isNumberOverOne'],
    },
  });

  const { totalPrice } = useTicketPrice({ form });

  const { data: reservationScheduleData, isFetched: isReservationScheduleFetched } = useQuery(
    ['reservation-schedules', { date: form.schedule.reservationDate }],
    ({ queryKey: [, params] }) => getReservationSchedule(params),
    {
      enabled: !!form?.schedule?.reservationDate,
    }
  );
  const { data: programScheduleData, isFetched: isProgramScheduleFetched } = useQuery(
    ['program-schedules', { date: form.schedule.reservationDate, programType: 'PERSONAL' }],
    ({ queryKey: [, params] }) => getProgramSchedules(params),
    {
      enabled: !!form?.schedule?.reservationDate,
    }
  );

  const reservationSchedules = reservationScheduleData?.data?.data || [];

  useEffect(() => {
    const openReservationErrorModal = async () => {
      await System.alert({
        title: '오류',
        text: '예약 정원 또는 프로그램 정원이 초과되었습니다.',
      });
      const { query, pathname } = router;
      const params = new URLSearchParams(query);
      params.delete('errorCode');

      return router.replace({ pathname, query: params.toString() });
    };
    if (errorCode === `-1105`) {
      openReservationErrorModal();
    }
  }, [errorCode, router]);

  useEffect(() => {
    const getStorage = sessionStorage.getItem('personalReservation');
    if (!getStorage) return;
    const initialFormData = JSON.parse(getStorage);
    setForm(initialFormData);
    sessionStorage.removeItem('personalReservation');
  }, []);

  // form 입장티켓 설정
  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      admissions: VisitorDTO.initialAdmissionSetting(tickets, prevState),
    }));
  }, [tickets]);

  // form 프로그램 설정
  useEffect(() => {
    if (!isProgramScheduleFetched) return;
    const programSchedules = programScheduleData?.data?.data?.sort((a, b) => a.orderNo - b.orderNo) || [];

    setForm((prevState) => ({
      ...prevState,
      programs: Program.initialProgramsSetting(programSchedules, prevState),
    }));
  }, [isProgramScheduleFetched, programScheduleData]);

  const onChangeSchedule = async (newSchedule, type) => {
    const isDateChange = type === 'reservationDate';
    const isTimeChange = type === 'reservationTime';
    setForm((prevState) => ({
      ...prevState,
      schedule: {
        reservationDate: isDateChange ? moment(newSchedule).format('YYYY-MM-DD') : prevState.schedule.reservationDate,
        reservationTime: isDateChange ? '' : newSchedule,
      },
      programs: prevState.programs.map((program) => ({
        ...program,
        timeID: null,
        visitCount: 0,
      })),
    }));
    if (isTimeChange) {
      if (newSchedule === '10:00:00') {
        await System.alert({
          title: '입장 안내',
          text: '10시 예약 시 정시부터 입장 가능합니다.',
        });
      } else if (newSchedule === '17:00:00') {
        await System.alert({
          title: '입장 안내',
          text: '17시 예약 시 18시까지 관람하실 수 있습니다.',
        });
      }
    }
  };

  const checkAdmissionValidation = () => {
    const disabilityCodes = [7, 8, 9];
    let disabilityCount = 0;
    form.admissions.forEach(({ code, count }) => {
      if (disabilityCodes.includes(code)) {
        disabilityCount += count;
      }
    });
    const disabilityGuardianCode = 10;
    const disabilityGuardianCount = form.admissions.find(({ code }) => code === disabilityGuardianCode).count;

    // 중증장애 보호자 수가 장애인 수 보다 작거나 같아야 한다.
    return disabilityGuardianCount <= disabilityCount;
  };

  const goToNextStep = async () => {
    if (!checkAdmissionValidation()) {
      return System.alert({
        title: '오류',
        text: '중증장애보호자는 장애인 인원을 \n초과하여 예약할 수 없습니다.',
      });
    }
    if (!checkValidation()) {
      return;
    }
    const nextStepURL = `/reservation/personal/requester`;
    sessionStorage.setItem('personalReservation', JSON.stringify(form));
    const responseUser = await getUser();
    // 비 로그인 상태의 경우
    if (responseUser.data.code !== 0) {
      return router.push(`/login?redirectURL=${nextStepURL}`);
    }
    return router.push(nextStepURL);
  };

  return (
    <div className={styles.reservationContainer}>
      <MainTitle>개인 예약</MainTitle>
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>예약 일자</h2>
        <sup className={styles.subTitleSuperscript}>Date</sup>
      </div>
      <div className={styles.datePickerWrapper}>
        <ClientSideOnly>
          <Datepicker
            selectedDate={form.schedule.reservationDate && moment(form.schedule.reservationDate).toDate()}
            holidays={holidays}
            onChangeSelectedDay={(newDate) => onChangeSchedule(newDate, 'reservationDate')}
          />
        </ClientSideOnly>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>입장권</h2>
        <sup className={styles.subTitleSuperscript}>Admission</sup>
      </div>
      <AdmissionSelect form={form} setForm={setForm} maxSeat={maxSeat} />
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>예약 시간</h2>
        <sup className={styles.subTitleSuperscript}>Entry Time</sup>
      </div>
      <ul className={styles.timeList}>
        {reservationSchedules.map((timeObject) => {
          const { reservationTime, reservationDate } = form.schedule;
          const isSelected = timeObject.time === reservationTime;
          const isDisabled = (timeObject.remainingSeat < form.visitorCount && 'disabled') || moment(`${reservationDate} ${timeObject.time}`).isBefore(moment(), 'minute');
          return (
            <li key={timeObject.time}>
              <button className={cx(styles.timeItem, isSelected && styles.selected)} disabled={isDisabled} onClick={() => onChangeSchedule(timeObject.time, 'reservationTime')}>
                {Program.hhmmssTohhmm(timeObject.time)}
              </button>
            </li>
          );
        })}
      </ul>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>프로그램</h2>
        <sup className={styles.subTitleSuperscript}>Program</sup>
      </div>
      <div className={styles.programSelectWrapper}>
        <ProgramSelect form={form} setForm={setForm} />
      </div>
      <ReservationButton totalPrice={formatPrice(totalPrice, '', 0)}>
        <button className={cx(styles.footerButton, styles.nextButton)} onClick={goToNextStep}>
          다음단계
        </button>
      </ReservationButton>
    </div>
  );
};
ScheduleForm.propType = {
  maxSeat: PropTypes.number,
};
ScheduleForm.defaultProps = {
  maxSeat: 4,
};

export default ScheduleForm;
