import ReservationDTO from '@/apis/DTOs/ReservationDTO/ReservationDTO';
import { System, Datepicker, ProgramSelect, ReservationButton, ClientSideOnly, CountButton, MainTitle } from '@/components';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { getReservationSchedule } from '@/apis/requests/schedule';
import { getProgramSchedules } from '@/apis/requests/programReservation';
import { getUser } from '@/apis/requests/login';
import { useValidationHook } from '@/hooks';
import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { Program } from '@/utils';
import styles from '@/style/reservation.module.scss';

const ScheduleForm = (props) => {
  const { formData, minSeat, isUpdateForm, holidays } = props;
  const router = useRouter();
  const { id } = router.query;
  const initialFormData = isUpdateForm ? formData.settingForm : ReservationDTO.groupInitialForm({ minSeat });

  const [form, setForm] = useState(initialFormData);

  const [newSchedule, setNewSchedule] = useState([]);
  const [newProgramSchedule, setNewProgramSchedule] = useState(null);

  const { checkValidation, isInvalid } = useValidationHook({
    data: form,
    validate: {
      schedule: {
        reservationDate: ['isExist'],
        reservationTime: ['isExist'],
      },
      visitorCount: ['isExist'],
      stayTime: ['isExist'],
    },
  });

  const { data: reservationScheduleData, isFetched: isReservationScheduleFetched } = useQuery(
    ['reservation-schedules', { date: form.schedule.reservationDate }],
    ({ queryKey: [, params] }) => getReservationSchedule(params),
    {
      enabled: !!form?.schedule?.reservationDate,
    }
  );

  const { data: programScheduleData, isFetched: isProgramScheduleFetched } = useQuery(
    ['program-schedules', { date: form.schedule.reservationDate, programType: 'GROUP' }],
    ({ queryKey: [, params] }) => getProgramSchedules(params),
    {
      enabled: !!form?.schedule?.reservationDate,
    }
  );

  // 스토리지에 저장된 예약 정보 가져오기
  useEffect(() => {
    const getStorage = sessionStorage.getItem('groupReservation');
    sessionStorage.removeItem('groupReservation');
    // 예약 수정 페이지이거나, 스토리지에 정보 없는 경우 패스
    if (isUpdateForm || !getStorage) return;
    const initialFormData = JSON.parse(getStorage);
    const { id } = initialFormData;
    // 예약 등록페이지인데, 수정 페이지의 정보를 가져오는 경우 패스
    if (id && !isUpdateForm) return;
    setForm(initialFormData);
  }, [isUpdateForm]);

  //예약 일정 잔여인원 계산
  useEffect(() => {
    const reservationSchedule = reservationScheduleData?.data?.data || [];
    if (!formData) {
      setNewSchedule(reservationSchedule);
      return;
    }
    const initialDate = formData.schedule.reservationDate;
    const initialStartTime = formData.schedule.reservationTime;
    const initialStayTime = formData.stayTime;
    const initialEndTime = moment(initialStartTime, 'HH:mm:ss').add(initialStayTime, 'minute').format('HH:mm:ss');
    const initialVisitorCount = formData.visitorCount;
    setNewSchedule(
      reservationSchedule.map((schedule) => {
        let isInitialSelectedTime = false;
        if (schedule.date === initialDate && schedule.time >= initialStartTime && schedule.time < initialEndTime) {
          isInitialSelectedTime = true;
        }
        return {
          ...schedule,
          remainingSeat: isInitialSelectedTime ? schedule.remainingSeat + initialVisitorCount : schedule.remainingSeat,
        };
      })
    );
  }, [formData, reservationScheduleData]);

  //프로그램 일정 잔여인원 계산
  useEffect(() => {
    if (!isProgramScheduleFetched) return;

    const programSchedule = programScheduleData?.data?.data || [];
    if (!formData) {
      setNewProgramSchedule(programSchedule);
      return;
    }
    const initialPrograms = formData.programs;
    const newProgramSchedule = programSchedule.map((program) => {
      const foundProgram = initialPrograms.find((initialProgram) => {
        return initialProgram.programID === program.id;
      });
      const foundTimeIdx = program.times.findIndex((time) => time.id === foundProgram?.timeID);

      let newTimes = [...program.times];
      if (foundTimeIdx !== -1) {
        newTimes[foundTimeIdx] = {
          ...newTimes[foundTimeIdx],
          remainingSeat: newTimes[foundTimeIdx].remainingSeat + foundProgram.visitCount,
        };
      }

      return {
        ...program,
        times: newTimes,
      };
    });
    setNewProgramSchedule(newProgramSchedule);
  }, [formData, isProgramScheduleFetched, programScheduleData]);

  //form 프로그램 설정
  useEffect(() => {
    if (!newProgramSchedule) return;

    setForm((prevState) => ({
      ...prevState,
      programs: Program.initialProgramsSetting(newProgramSchedule, prevState),
    }));
  }, [newProgramSchedule]);

  const stayTimes = [];
  for (let minute = 1; minute <= 6; minute++) {
    stayTimes.push(minute * 30);
  }

  const onChangeSchedule = async (newSchedule, type) => {
    const isDateChange = type === 'reservationDate';
    const isTimeChange = type === 'reservationTime';
    setForm((prevState) => ({
      ...prevState,
      schedule: {
        reservationDate: isDateChange ? moment(newSchedule).format('YYYY-MM-DD') : prevState.schedule.reservationDate,
        reservationTime: isDateChange ? '' : newSchedule,
      },
      stayTime: '',
      programs: prevState.programs.map((program) => ({
        ...program,
        timeID: null,
        visitCount: 0,
      })),
    }));
    if (isTimeChange) {
      if (newSchedule === '10:00:00') {
        await System.alert({
          title: '입장 안내',
          text: '10시 예약 시 정시부터 입장 가능합니다.',
        });
      } else if (newSchedule === '17:00:00') {
        await System.alert({
          title: '입장 안내',
          text: '17시 예약 시 18시까지 관람하실 수 있습니다.',
        });
      }
    }
  };

  const onChangeVisitorCount = (count) => {
    if (isNaN(count)) {
      return;
    }
    setForm((prevState) => ({
      ...prevState,
      schedule: {
        ...prevState.schedule,
        reservationTime: '',
      },
      visitorCount: count,
      stayTime: '',
      programs: prevState.programs.map((program) => ({
        ...program,
        visitCount: 0,
      })),
    }));
  };

  const onClickCancel = async () => {
    const { confirm } = await System.confirm({
      title: '예약수정 취소',
      text: '예약 수정을 취소하시겠습니까?',
    });
    if (confirm) {
      return router.push(`/reservation/list`);
    }
  };

  const goToNextStep = async () => {
    if (Number(form.visitorCount) < minSeat) {
      return System.alert({
        title: '예약 안내',
        text: `예약은 최소 ${minSeat}명부터 가능합니다.`,
      });
    }
    if (!checkValidation()) {
      return;
    }
    const nextStepURL = `/reservation/group/requester${isUpdateForm ? `?id=${id}` : ''}`;
    sessionStorage.setItem('groupReservation', JSON.stringify(form));
    const responseUser = await getUser();
    if (responseUser.data.code !== 0) {
      return router.push(`/login?redirectURL=${nextStepURL}`);
    }
    return router.push(nextStepURL);
  };

  return (
    <div className={styles.reservationContainer}>
      <MainTitle>{isUpdateForm ? '예약 수정' : '단체 예약'}</MainTitle>
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>예약 일자</h2>
        <sup className={styles.subTitleSuperscript}>Date</sup>
      </div>
      <div className={styles.datePickerWrapper}>
        <ClientSideOnly>
          <Datepicker
            selectedDate={form.schedule.reservationDate && moment(form.schedule.reservationDate).toDate()}
            holidays={holidays}
            onChangeSelectedDay={(newDate) => onChangeSchedule(newDate, 'reservationDate')}
          />
        </ClientSideOnly>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>예약 인원</h2>
        <sup className={styles.subTitleSuperscript}>Number of Admission</sup>
      </div>
      <input
        className={cx(styles.inputForm, styles.smallInputForm)}
        placeholder={'예약 인원수 입력'}
        value={form.visitorCount}
        onChange={(event) => onChangeVisitorCount(event.target.value)}
      />
      <span className={styles.unit}>명</span>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>예약 시간</h2>
        <sup className={styles.subTitleSuperscript}>Entry Time</sup>
      </div>
      <ul className={styles.timeList}>
        {newSchedule.map((timeObject) => {
          const { reservationTime, reservationDate } = form.schedule;
          const isSelected = timeObject.time === reservationTime;
          const isDisabled = (timeObject.remainingSeat < form.visitorCount && 'disabled') || moment(`${reservationDate} ${timeObject.time}`).isBefore(moment(), 'minute');
          return (
            <li key={timeObject.time}>
              <button className={cx(styles.timeItem, isSelected && styles.selected)} disabled={isDisabled} onClick={() => onChangeSchedule(timeObject.time, 'reservationTime')}>
                {Program.hhmmssTohhmm(timeObject.time)}
              </button>
            </li>
          );
        })}
      </ul>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>체류 시간</h2>
        <sup className={styles.subTitleSuperscript}>Length of Stay</sup>
      </div>
      <ul className={styles.timeList}>
        {stayTimes.map((stayTime) => {
          return (
            <li key={stayTime}>
              <button className={cx(styles.timeItem, stayTime === form.stayTime && styles.selected)} onClick={() => setForm((prevState) => ({ ...prevState, stayTime }))}>
                {stayTime}분
              </button>
            </li>
          );
        })}
      </ul>
      <div className={styles.lineBreak} />
      <div className={styles.subTitleContainer}>
        <h2 className={styles.subTitle}>프로그램</h2>
        <sup className={styles.subTitleSuperscript}>Program</sup>
      </div>
      <ProgramSelect form={form} setForm={setForm} />
      <ReservationButton>
        {isUpdateForm && (
          <button className={cx(styles.footerButton, styles.cancelButton)} onClick={onClickCancel}>
            취소
          </button>
        )}
        <button className={cx(styles.footerButton, styles.nextButton)} onClick={goToNextStep}>
          {isUpdateForm ? '다음' : '예약신청'}
        </button>
      </ReservationButton>
    </div>
  );
};
ScheduleForm.propType = {
  formData: PropTypes.instanceOf(ReservationDTO),
  isUpdateForm: PropTypes.bool,
};
ScheduleForm.defaultProps = {
  isUpdateForm: false,
};

export default ScheduleForm;
