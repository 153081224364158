import { useEffect, useState } from 'react';
import { System } from '@/components';

const useValidationHook = ({ data, validate = {}, customValidator = {}, customValidationNames, useCheckModal = true }) => {
  const [validStatus, setValidStatus] = useState();

  // 유효성 모달 팝업
  useEffect(() => {
    if (!validStatus || !useCheckModal) return;
    const defaultValidationNames = {
      reservationDate: '예약날짜',
      reservationTime: '예약시간',
      visitorCount: '방문자 수',
      stayTime: '체류시간',
      name: '예약자명',
      phone: '휴대폰 번호',
      emailID: '이메일 아이디',
      emailDomain: '이메일 도메인',
      groupName: '단체명',
      groupType: '단체 구분',
      regionType: '지역',
      leaderCount: '인솔자',
      transportType: '교통편',
      transportCount: '차량 대수',
      overFourteenYearsOld: '필수이용약관 동의',
    };
    const validationNames = Object.assign(defaultValidationNames, customValidationNames);
    const existValidations = ['isExist'];
    const correctValidations = ['isNotHavingComma', 'isPhoneNumber', 'isEmailID', 'isEmailDomain', 'isNumberOverZero', 'isBusOrCarAndExist', 'isNumberOverOne'];
    const checkedValidations = ['isChecked'];

    let notExistNames = [],
      notCorrectNames = [],
      notCheckedNames = [];

    Object.entries(validStatus).forEach(([key, validationObj]) => {
      const validationName = validationNames[key];
      // 존재하지 않는 값 검사
      existValidations.forEach((existValidation) => {
        if (validationObj[existValidation] === false) {
          notExistNames.push(validationName);
        }
      });
      // 올바르지 않은 값 검사
      correctValidations.forEach((correctValidation) => {
        if (validationObj[correctValidation] === false) {
          notCorrectNames.push(validationName);
        }
      });
      // 체크되지 않은 값 검사
      checkedValidations.forEach((correctValidations) => {
        if (validationObj[correctValidations] === false) {
          notCheckedNames.push(validationName);
        }
      });
    });

    notExistNames = notExistNames.join(', ');
    notCorrectNames = notCorrectNames.join(', ');
    notCheckedNames = notCheckedNames.join(', ');
    const notExist = notExistNames ? `${notExistNames}을(를) 입력해주세요. \n` : '';
    const notCorrect = notCorrectNames ? `${notCorrectNames}에 올바른 값을 입력해주세요. \n` : '';
    const notChecked = notCheckedNames ? `${notCheckedNames}를 해주세요. \n` : '';

    if (!notExist && !notCorrect && !notChecked) return;

    System.alert({
      title: '안내',
      text: notExist + notCorrect + notChecked,
    });
  }, [customValidationNames, useCheckModal, validStatus]);

  const isNotExist = (value) => value === '' || typeof value === 'undefined' || value === null;

  const isExist = (value) => (value === 0 ? true : !!value);
  const isPhoneNumber = (value) => isNotExist(value) || /^01[0|1|6|7|8|9][0-9]{7,8}$/.test(value);
  const isEmailID = (value) => isNotExist(value) || /^[_0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*$/i.test(value);
  const isEmailDomain = (value) => isNotExist(value) || /^[0-9a-zA-z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-z]{2,3}$/i.test(value);
  const isNumberOverOne = (value) => isNotExist(value) || (/^[0-9]+$/.test(value) && value > 0);
  const isNumberOverZero = (value) => isNotExist(value) || /^[0-9]+$/.test(value);
  const isNotHavingComma = (value) => isNotExist(value) || !value.includes(',');

  const validator = {
    ...customValidator,
    isExist,
    isPhoneNumber,
    isEmailID,
    isEmailDomain,
    isNumberOverOne,
    isNumberOverZero,
    isNotHavingComma,
  };

  const isInvalid = (name) => {
    if (!validStatus) return false;
    return Object.values(validStatus[name]).includes(false);
  };

  const validateLoop = (object, parentsNode = []) => {
    let isValid = true;
    Object.entries(object).forEach(([name, objectOrArray]) => {
      if (Array.isArray(objectOrArray)) {
        objectOrArray.forEach((validKey) => {
          let findData = data;
          parentsNode.forEach((node) => {
            findData = findData[node];
          });
          findData = findData[name];
          const isCorrect = validator[validKey](findData);
          setValidStatus((prevState) => ({
            ...prevState,
            [name]: {
              ...prevState?.[name],
              [validKey]: isCorrect,
            },
          }));
          if (isCorrect === false) isValid = false;
        });
      } else {
        const recursiveValue = validateLoop(objectOrArray, parentsNode.concat([name]));
        if (isValid) isValid = recursiveValue;
      }
    });
    return isValid;
  };

  const checkValidation = () => {
    setValidStatus();
    return validateLoop(validate, []);
  };

  return {
    checkValidation,
    isInvalid,
    validStatus,
  };
};
export { useValidationHook };
