import LandscapeStyle from './LandscapeBlocker.module.scss';

const LandscapeBlocker = () => {
  return (
    <div className={LandscapeStyle.wrapper}>
      <div className={LandscapeStyle.container}>
        <i className={LandscapeStyle.iconLandscapeBlocker} />
        <div className={LandscapeStyle.notice}>
          <p>가로모드를 지원하지 않습니다.</p>
          <p>
            <span className={LandscapeStyle.highlightText}>세로모드</span>를 사용해주세요.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandscapeBlocker;
