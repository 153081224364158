const EDUCATION_STATUS = {
  PROGRESSING: '접수 중',
  SCHEDULE: '접수 예정',
  END: '마감',
  ALL: '전체',
};

const EDUCATION_STATUS_ENG = {
  ALL: 'All',
  SCHEDULE: 'Upcoming',
  PROGRESSING: 'New Open',
  END: 'Closed',
};

const EDUCATION_INFO = {
  target: {
    name: '대상',
    className: 'info1',
  },
  method: {
    name: '방법',
    className: 'info2',
  },
  term: {
    name: '신청 기간',
    className: 'info3',
  },
  operatingTerm: {
    name: '운영 기간',
    className: 'info7',
  },
  time: {
    name: '시간',
    className: 'info4',
  },
  cost: {
    name: '비용',
    className: 'info5',
  },
  etc: {
    name: '비고',
    className: 'info6',
  },
};

export { EDUCATION_STATUS, EDUCATION_STATUS_ENG, EDUCATION_INFO };
