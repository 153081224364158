import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

/**
 * pathname을 받아서 현재 도메인 하위 path로 만들어주는 함수
 * @param pathname {string} - URL 하위 path
 * @return string
 */
const makeSecureURL = (pathname) => {
  const domain = publicRuntimeConfig.baseURL;
  const url = domain + (pathname || '');
  const secureURL = url.replace(/@/g, '%40');
  return secureURL;
};

export default makeSecureURL;
