import styles from 'style/reservationList.module.scss';
import { MainTitle, Pagination, Tab, Tabs } from '@/components';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { cx } from '@emotion/css';
import { EDUCATION_RESERVATION_STATUSES } from '@/apis/DTOs/EducationReservationDTO';
import { useEffect, useState } from 'react';
import ReservationDTO from '@/apis/DTOs/ReservationDTO';

const EducationReservationList = ({ setTarget, renderingDTOs, isMobile, pageInfo, hasNextPage, onPageChange }) => {
  const router = useRouter();
  const [filter, setFilter] = useState(ReservationDTO.initialFilter());
  useEffect(() => {
    setFilter({
      ...ReservationDTO.initialFilter(),
      ...router.query,
    });
  }, [router.query]);

  const goToReservationList = () => {
    return router.push('/reservation/list');
  };

  return (
    <div className={styles.container}>
      <MainTitle>예약 확인 및 취소</MainTitle>
      <Tabs>
        <Tab name={'status'} onClick={goToReservationList} isSelected={false} mainTitle={'관람 예약'} subTitle={' '} />
        <Tab name={'status'} isSelected={true} mainTitle={'교육 프로그램 예약'} subTitle={' '} />
      </Tabs>
      {renderingDTOs.length !== 0 ? (
        <ul className={styles.reservationList}>
          {renderingDTOs.map((renderingDTO) => {
            let statusClass = '';
            switch (renderingDTO.reservationStatus) {
              case EDUCATION_RESERVATION_STATUSES.WAIT.value:
                statusClass = styles.waitStatus;
                break;
              case EDUCATION_RESERVATION_STATUSES.PAYMENT.value:
                statusClass = styles.paymentStatus;
                break;
              case EDUCATION_RESERVATION_STATUSES.COMPLETE.value:
                statusClass = styles.completeStatus;
                break;
              case EDUCATION_RESERVATION_STATUSES.VISIT.value:
                statusClass = styles.visitStatus;
                break;
              case EDUCATION_RESERVATION_STATUSES.CANCEL.value:
              case EDUCATION_RESERVATION_STATUSES.VISIT_CANCEL.value:
                statusClass = styles.cancelStatus;
                break;
            }
            return (
              <li key={renderingDTO.reservationId} className={cx(styles.reservationItem, statusClass)}>
                <Link href={`/education/reservation/detail?id=${renderingDTO.reservationId}`} className={styles.reservationLink} passHref={true}>
                  <div className={styles.statusArea}>
                    {renderingDTO.confirmDate && <div className={styles.date}>결제일: {renderingDTO.confirmDate}</div>}
                    <div className={styles.state}>{renderingDTO.educationReservationStatusName}</div>
                  </div>
                  <div className={styles.educationInfoArea}>
                    <h3 className={styles.educationTitle}>{renderingDTO.programName}</h3>
                    <ul className={styles.infoArea}>
                      {renderingDTO.term && <li className={styles.infoItem}>{renderingDTO.term}</li>}
                      <li className={styles.infoItem}>{renderingDTO.visitorCount}명</li>
                    </ul>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={styles.noItemContainer}>
          <i className={styles.noItemIcon} />
          <div className={styles.noItemDescription}>예약 내역이 없습니다.</div>
        </div>
      )}
      {isMobile && hasNextPage && <div className="observer" style={{ position: 'absolute', bottom: '350' }} ref={setTarget} />}
      {!isMobile && renderingDTOs.length !== 0 && <Pagination {...pageInfo} onPageChange={(event, page) => onPageChange(page, filter)} />}
    </div>
  );
};
export default EducationReservationList;
