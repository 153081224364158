const ADMISSION_ORDER_SET = {
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
  7: 6,
  8: 7,
  9: 8,
  10: 9,
  11: 11,
  12: 12,
  13: 13,
  14: 10,
};

// for sitemap-generator (module only)
module.exports = ADMISSION_ORDER_SET;
