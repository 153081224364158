import sleep from '@/utils/functions/sleep';

const fadeOut = async (element, duration = 50, fadeOutEnd) => {
  const opacity = window.getComputedStyle(element).getPropertyValue('opacity');
  const currentOpacity = Number(opacity);

  if (currentOpacity <= 0) return;

  element.style.opacity = currentOpacity - 0.1;
  await sleep(duration);
  await fadeOut(element, duration, fadeOutEnd);
};

class AnimateImage {
  constructor(props) {
    const { introContainerElement, introPlayerElement, spriteMap, duration = 25 } = props;
    this.introContainerElement = introContainerElement;
    this.introPlayerElement = introPlayerElement;
    this.spriteMap = spriteMap;
    this.duration = duration;
    this.currentIndex = 0;
    this.currentY = 0;
  }

  static build(props) {
    AnimateImage.assertProps(props);
    return new AnimateImage(props);
  }

  static assertProps(props) {
    const { spriteMap, introContainerElement, introPlayerElement } = props;

    if (!spriteMap) {
      const message = `prop spriteMap is required. current: ${spriteMap}`;
      throw new Error(message);
    }

    if (!introContainerElement) {
      const message = `prop introContainerElement is required. current: ${introContainerElement}`;
      throw new Error(message);
    }

    if (!introPlayerElement) {
      const message = `prop introPlayerElement is required. current: ${introPlayerElement}`;
      throw new Error(message);
    }
  }

  play() {
    return new Promise(
      function (resolve) {
        this.playNextIntro(resolve);
      }.bind(this)
    );
  }

  playNextIntro(resolve) {
    setTimeout(() => {
      this.currentIndex++;
      const isMobile = window.innerWidth < 768;
      const singleWidth = isMobile ? this.spriteMap.SINGLE_WIDTH / 2 : this.spriteMap.SINGLE_WIDTH;
      this.introPlayerElement.style.backgroundPosition = `${-this.currentIndex * singleWidth}px ${-this.currentY * singleWidth}px`;

      if (this.spriteMap.MAX_INDEX > this.currentIndex) {
        this.playNextIntro(resolve);
      } else if (this.spriteMap.END_INDEX > this.currentY) {
        this.currentY++;
        this.currentIndex = 0;
        this.playNextIntro(resolve);
      } else {
        resolve();
      }
    }, this.duration);
  }

  destroy() {
    this.introContainerElement.remove();
  }
}

export default AnimateImage;
export { fadeOut };
